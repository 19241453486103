import React, {Component} from 'react';
import partnerSaleImg from './img/partner_sale.png';

class Partner extends Component {
  render() {
    return (
      <div style={{
        maxWidth: '100%',
        minHeight: 'calc(100vh - 70px)',
        backgroundColor: '#fff',
        textAlign: 'center'
      }}>
        <img style={{maxWidth: '100%'}} src={partnerSaleImg}
             alt='partenaires'/>
      </div>
    );
  }
}

export default Partner;
