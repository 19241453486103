import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import NumberFormat from "react-number-format";
import { changeActionName } from "./utils";

class TableServiceFullHistorique extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      open: false,
      comment: "",
      activeRow: -1
    };

    this.handleClickDetails = this.handleClickDetails.bind(this);
  }

  handleClickDetails(rowInfo) {
    // this.props.goToDetails(rowInfo);
  }

  render() {
    let { data } = this.props;

    let dataArray = [];

    let dataByTable = {};
    let finalData = [];
    if (data) {
      Object.keys(data).map(elem => {
        if (dataByTable[data[elem].table_name]) {
          dataByTable[data[elem].table_name].push(data[elem]);
        } else {
          dataByTable[data[elem].table_name] = [];
          dataByTable[data[elem].table_name].push(data[elem]);
        }
      });
    }

    //chaque table
    if (dataByTable) {
      Object.keys(dataByTable).map(table => {
        let action = "create";

        let finalRow = {};
        dataByTable[table].forEach((row, index) => {
          Object.keys(dataByTable[table][index]).map(property => {
            if (
              (finalRow[dataByTable[table][index].key_] &&
                finalRow[dataByTable[table][index].key_] !=
                  dataByTable[table][index].value) ||
              action != row.action
            ) {
              if (finalRow.action && finalRow.id && finalRow.table_name) {
                finalData.push(finalRow);
                finalRow = {};
              }
            }
            finalRow[property] = dataByTable[table][index][property];
            finalRow[dataByTable[table][index].key_] =
              dataByTable[table][index].value;
          });
          action = row.action;
        });
      });
    }
    function dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function(a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    }
    finalData = finalData.sort(dynamicSort("timestamp"));

    if (finalData && finalData.length > 0) {
      finalData.forEach(element => {
        if (["create", "update", "delete"].includes(element.action)) {
          element.action = changeActionName(element.action);
        }
        if (element.opening_date && element.opening_date != "0") {
          element.formatOpen = moment
            .unix(element.opening_date)
            .format("DD-MM-YYYY H:mm:ss");
        } else {
          element.formatOpen = "";
        }
        if (element.closing_date && element.closing_date != "0") {
          element.formatClose = moment
            .unix(element.closing_date)
            .format("DD-MM-YYYY H:mm:ss");
        } else {
          element.formatClose = "";
        }
        if (element.dateHour && element.dateHour != "0") {
          element.formatDateHour = moment
            .unix(element.dateHour)
            .format("DD-MM-YYYY H:mm:ss");
        } else {
          element.dateHour = "";
        }
      });
    }

    let columnsTable = [
      {
        Header: "Action",
        accessor: "action",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search
                  style={{
                    color: "white",
                    lineHeight: "17.4px",
                    verticalAlign: "middle"
                  }}
                />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "ID tracking", accessor: "id", pivot: true },
      { Header: "ID", accessor: "table_id", pivot: true },
      { Header: "Table", accessor: "table_name", pivot: true },
      { Header: "Ouverture Service", accessor: "formatOpen", pivot: true },
      { Header: "Ouverture Commande", accessor: "formatDateHour", pivot: true },
      { Header: "Fermeture", accessor: "formatClose", pivot: true },
      {
        Header: "Montant",
        accessor: "amount",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Fond de caisse",
        accessor: "cash_float",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Remise globale",
        accessor: "global_discount",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Prix",
        accessor: "price",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Remises",
        accessor: "discount",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Offerts",
        accessor: "offered",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      { Header: "Taux", accessor: "taxe_rate", pivot: true },

      { Header: "Commande", accessor: "id_order", pivot: true },
      { Header: "Nom", accessor: "product_name", pivot: true },
      {
        Header: "Prix initial",
        accessor: "standardPrice",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "Prix carte",
        accessor: "standardPrice_product",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },

      {
        Header: "CA",
        accessor: "turnover",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      { Header: "Supprimé", accessor: "removed", pivot: true },
      { Header: "Commentaire", accessor: "comment", pivot: true }
    ];

    let columnsTableExport = [
      { label: "Action", keu: "action" },
      { label: "ID tracking", key: "id" },
      { label: "ID", key: "table_id" },
      { label: "Table", key: "table_name" },
      { label: "Ouverture service", key: "formatOpen" },
      { label: "Ouverture commande", key: "formatDateHour" },
      { label: "Fermeture service", key: "formatClose" },
      { label: "Montant", key: "amount" },
      { label: "Fond de caisse", key: "cash_float" },
      { label: "Remise globale", key: "global_discount" },
      { label: "Prix", key: "price" },
      { label: "Remises", key: "discount" },
      { label: "Offerts", key: "offered" },
      { label: "Taux", key: "taxe_rate" },

      { label: "Commande", key: "id_order" },
      { label: "Nom", key: "product_name" },
      { label: "Prix initial", key: "standardPrice" },
      { label: "Prix carte", key: "standardPrice_product" },

      { label: "CA", key: "turnover" },
      { label: "Supprimé", key: "removed" },
      { label: "Commentaire", key: "comment" }
    ];
    return (
      <div>
        <ReactTable
          className=" -highlight -striped"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={finalData}
          defaultPageSize={25}
          filterable
          defaultSorted={[{ id: "timestamp", desc: false }]}
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo);
              }
            };
          }}
        />
        {finalData ? (
          <CSVLink
            style={{ float: "right" }}
            data={finalData}
            filename="export-ServiceFullHistorique.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload
                    style={{ lineHeight: "20px", verticalAlign: "middle" }}
                  />{" "}
                  Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableServiceFullHistorique;
