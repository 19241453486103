import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Proptypes from 'prop-types';

const styles = theme => ({
	margin: {
		margin: theme.spacing(1),
	},
	bootstrapRoot: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	bootstrapInput: {
		borderRadius: 4,
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
	},
	bootstrapFormLabel: {
		fontSize: 18,
		color: 'rgba(0, 0, 0, 0.54)',
	},
	labelFocus: {
		color: '#e84249 !important',
	},
	labelOk: {
		color: '#7cc4ac',
	},
	focused: {},
});

const genericInput = props => {
	const { classes } = props;
	const colorLabel = props.colorInput ? props.colorInput : null;

	return (
		<FormControl className={classes.margin}>
			<InputLabel
				shrink
				htmlFor={props.idInput}
				className={classes.bootstrapFormLabel}
				formlabelclasses={{
					focused: colorLabel ? classes.labelOK : classes.labelFocus,
				}}
				style={{
					color: colorLabel,
				}}>
				{props.label}
			</InputLabel>
			<InputBase
				id={props.idInput}
				onChange={props.onChange}
				name={props.name}
				type={props.type}
				value={props.value}
				classes={{
					root: classes.bootstrapRoot,
					input: classes.bootstrapInput,
				}}
				onFocus={props.onFocus ? props.onFocus : null}
				onBlur={props.onBlur ? props.onBlur : null}
			/>
		</FormControl>
	);
};

genericInput.propTypes = {
	classes: Proptypes.object.isRequired,
};

export default withStyles(styles)(genericInput);
