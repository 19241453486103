import React, {Component} from 'react';  import PropTypes from 'prop-types';
import FaqCategory from './FaqCategory';
import '../css/faqCategories.css';

class FaqCategories extends Component {
  constructor(props) {
    super(props);

    const {faqCategories} = props;
    this.state = {
      selected: faqCategories[0] || ''
    };

  }

  componentDidMount() {
    const script = document.createElement('script');

    script.src = '../build/js/faqManager.js';
    script.crossorigin = 'anonymous';
    script.id = 'launchFaqManager';

    document.body.appendChild(script);
  }

  componentWillUnmount() {
    document.getElementById('launchFaqManager').remove();
  }

  render() {
    const {faqCategories} = this.props;
    return (
      <ul className='cd-faq-categories'>
        {
          faqCategories.map((faqCategory) => {
            return <FaqCategory key={faqCategory} faqCategory={faqCategory}/>;
          })
        }
      </ul>
    );
  }
}

FaqCategories.propTypes = {
  faqCategories: PropTypes.array.isRequired
};

export default FaqCategories;
