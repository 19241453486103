export const dataExportLabels = {
	orderLines: [
		{
			Header: `Établissement`,
			accessor: 'ETABLISSEMENT',
			headerClassName: 'main_header_title',
			className: 'table_string_field',
		},
		{
			Header: `ID_LIGNE_VENTE`,
			accessor: 'ID_LIGNE_VENTE',
			headerClassName: 'main_header_title',
		},
		{
			Header: `HEURE VENTE`,
			accessor: 'HEURE VENTE',
			headerClassName: 'main_header_title',
		},
		{
			Header: `TYPE PRODUIT`,
			accessor: 'TYPE PRODUIT',
			headerClassName: 'main_header_title',
		},
		{
			Header: `ID PRODUIT`,
			accessor: 'ID_PRODUIT',
			headerClassName: 'main_header_title',
		},
		{
			Header: `NOM PRODUIT `,
			accessor: 'NOM PRODUIT',
			headerClassName: 'main_header_title',
		},

		{
			Header: `HT`,
			accessor: 'HT',
			headerClassName: 'main_header_title',
		},
		{
			Header: `TVA`,
			accessor: 'TVA',
			headerClassName: 'main_header_title',
		},
		{
			Header: `TTC`,
			accessor: 'TTC',
			headerClassName: 'main_header_title',
		},
		{
			Header: `ID PARENT`,
			accessor: 'ID_PARENT',
			headerClassName: 'main_header_title',
		},
		{
			Header: `ID SALLE`,
			accessor: 'ID_SALLES',
			headerClassName: 'main_header_title',
		},
		{
			Header: `NOM SALLE`,
			accessor: 'NOM SALLES',
			headerClassName: 'main_header_title',
		},
	],
};
