import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import moment from "moment";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";

import TableServices from "../components/Tables/TableServices";
import TableClotures from "../components/Tables/TableClotures";
import TableIntervTech from "../components/Tables/TableIntervTech";
import Popover from "material-ui/Popover";
import * as fiscApiActions from "../actions/fiscApiActions";
import { withRouter } from "react-router";
import { browserHistory } from "react-router";
import { cleanData } from "../helpers/clotures";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";
import urls from "../../../utils/config";

class ErrorsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      open: false,
      comment: "",
      dataIntervTech: {},
      isLoading: false,
    };

    this.goToDetailsPos = this.goToDetailsPos.bind(this);
    this.goToDetailsServeur = this.goToDetailsServeur.bind(this);
  }

  async componentDidMount() {
    //rowInfo = info de la cloture donc avec start date et end date au bon format
    let { errors, errorsServ, startDate, endDate } = this.props.location.state;
    let errorsServIds = [];
    errorsServ.forEach((error) => {
      errors.push(error.idError);
      errorsServIds.push(error.id);
    });
    startDate = moment(startDate).startOf("day").format("YYYY-MM-DD H:mm:ss");
    endDate = moment(endDate).endOf("day").format("YYYY-MM-DD H:mm:ss");
    this.setState({ isLoading: true });
    await this.props.actions.fetchErrors(errors);
    await this.props.actions.fetchTechnicalInterventions();
    await this.props.actions.fetchServices(startDate, endDate);
    await this.props.actions.resetClotures(this.props.entities);
    await this.props.actions.fetchCloturesById(errorsServIds);
    this.setState({ isLoading: false });
  }

  openPopover = (event, rowInfo) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      open: true,
      comment: rowInfo.original.comment,
      anchorEl:
        event.currentTarget.childNodes[
          event.currentTarget.childNodes.length - 1
        ],
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ services: nextProps.entities.services });
    this.setState({ clotures: nextProps.entities.clotures });
    this.setState({ data: nextProps.entities.pos });
    this.setState({
      dataIntervTech: nextProps.entities.technicalInterventions,
    });
  }

  goBack() {
    browserHistory.goBack();
  }

  goHome() {
    browserHistory.push("/administration-fiscale");
  }

  goToDetailsPos(rowInfo) {
    const pos = this.props.entities.pos;
    let errorPosToGo = {};
    Object.keys(pos).map((service) => {
      if (pos[service].id_cash_register === rowInfo.original.id) {
        errorPosToGo[pos[service].id] = pos[service];
      }
    });
    browserHistory.push({
      pathname: `/administration-fiscale/errors/details/${rowInfo.original.id}`,
      state: { error: errorPosToGo },
    });
  }

  goToDetailsServeur(rowInfo) {
    const serv = this.props.entities.server;
    let errorServToGo = {};
    Object.keys(serv).map((cloture) => {
      if (serv[cloture].id === rowInfo.original.id) {
        errorServToGo[serv[cloture].id] = serv[cloture];
      }
    });
    browserHistory.push({
      pathname: `/administration-fiscale/errors/details/${rowInfo.original.id}`,
      state: { error: errorServToGo },
    });
  }

  render() {
    let { dataIntervTech } = this.state;
    let { pos, server } = this.props.entities;
    let { services, clotures } = this.state;
    const { adminServerUrl } = urls;
    /* let documentIncidents = {};
    if (this.props.documentation) {
      Object.keys(this.props.documentation).forEach(key => {
        let doc = this.props.documentation[key];
        if (doc.title == "list-incident-fisc") {
          documentIncidents = doc;
        }
      });
    } */
    let documentIncidents = {
      pdf_url: "document/ADS_LAD_ENR_Incidents_donnees_fiscales.pdf",
    };
    let idServicesErrors = [];
    let idCloturesErrors = [];
    if (pos) {
      Object.keys(pos).map((service) => {
        if (typeof service !== "undefined") {
          idServicesErrors.push(pos[service].id_cash_register);
        }
      });
    }

    if (server) {
      Object.keys(server).map((cloture) => {
        if (typeof cloture !== "undefined") {
          idCloturesErrors.push(server[cloture].id);
        }
      });
    }

    if (services && (pos || server)) {
      Object.keys(services).map((service) => {
        if (idServicesErrors.includes(service) === false) {
          delete services[service];
        }
      });
    }

    if (clotures && (pos || server)) {
      Object.keys(clotures).map((cloture) => {
        if (idCloturesErrors.includes(clotures[cloture].id) === false) {
          delete clotures[cloture];
        }
      });
    }

    let dataIntervTechArray = [];

    if (dataIntervTech) {
      Object.keys(dataIntervTech).map((elem) => {
        dataIntervTechArray.push(dataIntervTech[elem]);
      });
    }
    let { isLoading } = this.state;
    let { cloturesRows } = cleanData(clotures);
    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={this.goBack}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={this.goHome}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>
          <div className="fiscContainer" style={{ paddingTop: "50px" }}>
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
              onRequestClose={this.handleRequestClose}
              style={{ borderRadius: "15px" }}
            >
              <div
                style={{
                  minHeight: "30px",
                  padding: "20px",
                  color: "black",
                  backgroundColor: "white",
                }}
              >
                <span style={{ fontStyle: "italic" }}>Commentaire</span> :{" "}
                {this.state.comment}
              </div>
            </Popover>
            <a
              className="pdfButton"
              href={urls.fiscalErrorPdfUrl}
              download={documentIncidents.pdf_url}
            >
              Consulter la liste des incidents
            </a>
            <div className="containerTable">
              <h1 className="titlePageInterventionTechnique">
                Liste des services erronés (POS)
              </h1>
              <TableServices
                goToDetails={this.goToDetailsPos}
                data={services}
              />
            </div>
            <div className="containerTable">
              <h1 className="titlePageInterventionTechnique">
                Liste des clôtures erronées (Serveur)
              </h1>
              <TableClotures
                goToDetails={this.goToDetailsServeur}
                data={cloturesRows}
              />
            </div>
            <div className="containerTable">
              <h1 className="titlePageInterventionTechnique">
                Liste des interventions techniques
              </h1>
              <TableIntervTech data={dataIntervTechArray} />
            </div>
          </div>
          <div
            id="fisc-loader-overlay"
            className={classnames({ hidden: !isLoading })}
          >
            <div class={classnames("pulse", { hidden: !isLoading })}>
              <img
                alt="loader L'addition"
                src={logoLoader}
                width="150px"
                height="150px"
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    documentation: state.entities.documentation,
    user: state.auth.user,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorsPage)
);
