export const setCookie = (cName, cValue, expDays = 3000 ) => {
    let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

export const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

export const deleteCookie = (cName) => {
    document.cookie = `${cName}=; expires= Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}



