import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import config from "../../../utils/config";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";
import Modal from "react-responsive-modal";
import axios from "axios";

import TableReceiptsHistorique from "../components/Tables/TableReceiptsHistorique";

import * as fiscApiActions from "../actions/fiscApiActions";
import { browserHistory } from "react-router";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";
import Print from "material-ui/svg-icons/action/print";
import DetailedInformations from "../components/DetailedInformations";

class DetailsReceipt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataReceiptHistory: {},
      open: false,
      dbName: "",
      pdfUrl: "",
      timestamp: "",
      signature: "",
      modal: "",
      isLoading: false
    };

    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  // === MODAL ===
  getPdfData = async () => {
    const res = await axios.get(
      config.reportingUrl + "api/administration-fiscale/pdf-data",
      {
        headers: { Authorization: `Bearer ${window.localStorage.accessToken}` }
      }
    );
    this.setState({
      dbName: res.data.dbName,
      timestamp: res.data.timestamp,
      signature: res.data.signature
    });
  };

  onOpenModal = () => {
    // this.setState(() => {
    //   this.getPdfData();
    // });

    if (this.state.pdfUrl != "") {
      this.setState({ open: true, isLoading: false });
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  goBack() {
    browserHistory.goBack();
  }

  goHome() {
    browserHistory.push("/administration-fiscale");
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.props.actions.resetHistory(this.props.entities);
    await this.getPdfData();
    await this.props.actions.fetchTrackingHistory(
      "receipt",
      this.props.location.state.cashRegisterId,
      this.props.location.state.rowInfo.original.id
    );
    this.setState({ isLoading: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ dataReceiptHistory: nextProps.entities.history });
  }

  render() {
    let rowInfo = this.props.location.state.rowInfo;
    let rowInfoData = this.props.location.state.rowInfo.row._original;
    let columns = this.props.location.state.columns;

    let modal = this.state.modal;
    let open = this.state.open;
    const { isLoading } = this.state;
    const { pdfUrl, timestamp, dbName, signature } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={this.goBack}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={this.goHome}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>

          <div className="fiscContainer">
            <div className="fiscHeader">
              <div className="fiscHeaderLeft">
                <h1 className="titlePage">Détails d'une impression</h1>
                <h3 className="fiscPath">
                  {" "}
                  <span style={{ color: "#9A9A9A" }}>
                    cloture/service/commande/impression/
                  </span>
                  historique
                </h3>
              </div>
              <div
                style={{ marginLeft: "auto", marginRight: "5%", width: "55%" }}
              >
                <h3 className="rowInfoTitle">Informations détaillées</h3>
                <div className="gridRowInfo">
                  <DetailedInformations
                    rowInfo={rowInfoData}
                    columns={columns}
                  />
                  <div
                    className="rowInfo"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Modal
                      open={open}
                      showCloseIcon={false}
                      onClose={this.onCloseModal}
                      classNames={{
                        modal: "modal-fisc-json-data"
                      }}
                    >
                      <iframe
                        src={pdfUrl}
                        style={{ width: "750px", minHeight: "90vh" }}
                        type="application/pdf"
                      />
                    </Modal>
                    <button
                      className="btn btn-fisc btn-impression"
                      onClick={async (e, t) => {
                        this.setState({ isLoading: true });
                        await this.getPdfData();
                        await this.setState({
                          pdfUrl:
                            config.dataUrl +
                            "scripts/reporting.php?db=" +
                            this.state.dbName +
                            "&receipt_id=" +
                            rowInfo.original.id +
                            "&action=receipt&format=pdf&ts=" +
                            this.state.timestamp +
                            "&signature=" +
                            this.state.signature
                        });
                        this.onOpenModal();
                      }}
                    >
                      <Print
                        style={{ lineHeight: "20px", verticalAlign: "middle" }}
                      />
                      Voir l'impression
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tabsContainer">
              <div className="containerTable">
                <TableReceiptsHistorique data={this.state.dataReceiptHistory} />
              </div>
            </div>
            <div
              id="fisc-loader-overlay"
              className={classnames({ hidden: !isLoading })}
            >
              <div class={classnames("pulse", { hidden: !isLoading })}>
                <img
                  alt="loader L'addition"
                  src={logoLoader}
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsReceipt);
