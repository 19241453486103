import { SET_CURRENT_USER, SET_UNAUTHORIZED_USER, SET_SUBUSER_SERVICES } from '../constant';
import isEmpty from 'lodash/isEmpty';

const initialState = {
    isAuthenticated: false,
    isNotAuthorized: false,
    user: {},
    subUserServices: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.user),
                user: action.user,
            };
        case SET_UNAUTHORIZED_USER:
            return {
                isAuthenticated: false,
                isNotAuthorized: true,
            };
        case SET_SUBUSER_SERVICES:
            return {
                ...state,
                subUserServices: action.services.map((s) => s.name),
            };
        default:
            return state;
    }
}
