import React from 'react';

const CloseModal = props => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter
				x="-5.6%"
				y="-9.7%"
				width="111.1%"
				height="122.8%"
				filterUnits="objectBoundingBox"
				id="filter-2">
				<feOffset
					dx="0"
					dy="6"
					in="SourceAlpha"
					result="shadowOffsetOuter1"></feOffset>
				<feGaussianBlur
					stdDeviation="12.5"
					in="shadowOffsetOuter1"
					result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix
					values="0 0 0 0 0.149019608   0 0 0 0 0.160784314   0 0 0 0 0.341176471  0 0 0 0.1 0"
					type="matrix"
					in="shadowBlurOuter1"></feColorMatrix>
			</filter>
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Desktop" transform="translate(-896.000000, -151.000000)">
				<g id="Modal-Fiscal" transform="translate(202.000000, 142.000000)">
					<g id="Rectangle">
						<use fill="black" fillOpacity="1" filter="url(#filter-2)"></use>
						<use fill="#FFFFFF" fillRule="evenodd"></use>
					</g>
					<g id="(+)" transform="translate(695.000000, 10.000000)">
						<circle id="Oval" stroke="#E74248" cx="11" cy="11" r="11"></circle>
						<g
							id="+"
							transform="translate(11.000000, 11.000000) rotate(-45.000000) translate(-11.000000, -11.000000) translate(6.000000, 6.000000)"
							fill="#E74248"
							fillRule="nonzero">
							<path
								d="M4,0.0866666667 C4,-0.465618083 4.44771525,-0.913333333 5,-0.913333333 C5.55228475,-0.913333333 6,-0.465618083 6,0.0866666667 L6,9.66666667 C6,10.2189514 5.55228475,10.6666667 5,10.6666667 C4.44771525,10.6666667 4,10.2189514 4,9.66666667 L4,0.0866666667 Z"
								id="Line-5"></path>
							<path
								d="M4,0.0866666667 C4,-0.465618083 4.44771525,-0.913333333 5,-0.913333333 C5.55228475,-0.913333333 6,-0.465618083 6,0.0866666667 L6,9.66666667 C6,10.2189514 5.55228475,10.6666667 5,10.6666667 C4.44771525,10.6666667 4,10.2189514 4,9.66666667 L4,0.0866666667 Z"
								id="Line-5"
								transform="translate(5.000000, 4.876667) rotate(-270.000000) translate(-5.000000, -4.876667) "></path>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default CloseModal;
