import { combineReducers } from 'redux';

import flashMessages from './common/flashMessage/reducers/flashMessages';
import notifications from './common/notification/reducers/notification';
import video from './module/Video/reducers/video';
import auth from './common/login/reducers/auth';
import variables from './front/NewFrontNavigation/reducers/variables';
import entities from './reducers/entities';
import { i18nReducer } from 'react-redux-i18n';

export default combineReducers({
	flashMessages,
	notifications,
	auth,
	video,
	entities,
	variables,
	i18n: i18nReducer,
});
