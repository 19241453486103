import React, { Component } from "react";
import "../css/loader.css";
import verre from "../img/verre.png";
import bouteille from "../img/bouteille.png";
import burger from "../img/burger.png";
import {
  easing,
  physics,
  spring,
  tween,
  styler,
  listen,
  value,
  transform
} from "popmotion";
import { I18n } from "react-redux-i18n";

const { pipe, clampMax } = transform;

class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0
    };
  }

  componentDidMount() {
    const ball = document.querySelector(".head-ball");
    const verre = document.querySelector(".verre");
    const bouteille = document.querySelector(".bouteille");
    const burger = document.querySelector(".burger");
    const ballStyler = styler(ball);
    const verreStyler = styler(verre);
    const bouteilleStyler = styler(bouteille);
    const burgerStyler = styler(burger);
    const ballY = value(0, v => ballStyler.set("y", Math.min(0, v)));
    const verreY = value(0, v => verreStyler.set("y", Math.min(0, v)));
    const bouteilleY = value(0, v => bouteilleStyler.set("y", Math.min(0, v)));
    const burgerY = value(0, v => burgerStyler.set("y", Math.min(0, v)));
    const ballScale = value(1, v => {
      ballStyler.set("scaleX", 1 + (1 - v));
      ballStyler.set("scaleY", v);
    });

    let isFalling = false;
    const ballBorder = value(
      {
        borderColor: "",
        borderWidth: 0
      },
      ({ borderColor, borderWidth }) =>
        ballStyler.set({
          boxShadow: `0 0 0 ${borderWidth}px ${borderColor}`
        })
    );

    const checkBounce = () => {
      if (!isFalling || ballY.get() < 0) return;

      isFalling = false;
      const impactVelocity = ballY.getVelocity();
      const compression = spring({
        to: 1,
        from: 1,
        velocity: -impactVelocity * 0.01,
        stiffness: 800
      })
        .pipe(s => {
          if (s >= 1) {
            s = 1;
            compression.stop();

            if (impactVelocity > 20) {
              isFalling = true;
              gravityVerre.set(0).setVelocity(-impactVelocity * 0.4);
              gravityBouteille.set(0).setVelocity(-impactVelocity * 0.3);
              gravityBurger.set(0).setVelocity(-impactVelocity * 0.5);
              gravity.set(0).setVelocity(-impactVelocity * 0.6);
            }
          }
          return s;
        })
        .start(ballScale);
    };

    const checkFail = () => {
      if (ballY.get() >= 0 && ballY.getVelocity() !== 0) {
        this.setState({ count: 0 });
        tween({
          from: { borderWidth: 0, borderColor: "rgb(255, 28, 104, 1)" },
          to: { borderWidth: 30, borderColor: "rgb(255, 28, 104, 0)" }
        }).start(ballBorder);
      }
    };
    const gravity = physics({
      acceleration: 1700,
      restSpeed: false
    }).start(v => {
      ballY.update(v);
      checkBounce(v);
      checkFail(v);
    });

    const gravityVerre = physics({
      acceleration: 1700,
      restSpeed: false
    }).start(v => {
      verreY.update(v);
    });

    const gravityBouteille = physics({
      acceleration: 1700,
      restSpeed: false
    }).start(v => {
      bouteilleY.update(v);
    });

    const gravityBurger = physics({
      acceleration: 1700,
      restSpeed: false
    }).start(v => {
      burgerY.update(v);
    });

    listen(ball, "mousedown touchstart").start(e => {
      e.preventDefault();
      let count = this.state.count;
      count++;
      this.setState({ count: count });
      ball.innerHTML = count;

      isFalling = true;
      ballScale.stop();
      ballScale.update(1);

      gravity.set(Math.min(0, ballY.get())).setVelocity(-400);

      tween({
        from: { borderWidth: 0, borderColor: "rgb(20, 215, 144, 1)" },
        to: { borderWidth: 30, borderColor: "rgb(20, 215, 144, 0)" }
      }).start(ballBorder);
    });
  }

  render() {
    return this.props.show ? (
      <div className="overlay" id="reportLoader">
        <div className="ronds">
          <svg>
            {/* cercle bleu */}
            <circle id="oval1" cx="-30" cy="50%" r="76.5" fill="#03CBFA" />{" "}
            {/* cercle bleu clair*/}
            <circle
              id="oval2"
              cx="3%"
              cy="60%"
              r="10"
              fill="rgba(3,203,250, 0.5)"
            />{" "}
            {/* cercle jaune*/}
            <circle id="oval3" cx="30%" cy="8%" r="40" fill="#F9B868" />{" "}
            {/* cercle jaune clair*/}
            <circle
              id="oval6"
              cx="35%"
              cy="12%"
              r="8"
              fill="rgba(249,184,104, 0.5)"
            />{" "}
            {/* cercle rouge*/}
            <circle id="oval4" cx="98%" cy="30%" r="15" fill="#e84249" />{" "}
            {/* cercle rouge clair*/}
            <circle
              id="oval5"
              cx="95%"
              cy="30%"
              r="5"
              fill="rgba(232,66,73, 0.5)"
            />{" "}
            <circle
              className="circleDesktop"
              id="oval7"
              cx="35%"
              cy="105%"
              r="15"
              fill="rgba(3,203,250, 0.75)"
            />{" "}
          </svg>
        </div>
        <div className={"adidot-container"}>
          <div className={"addidot addidot__first"} key={0} />
          <div className={"addidot addidot__second"} key={1} />
          <div className={"addidot addidot__third"} key={2} />
          <div className={"addidot addidot__fourth"} key={3} />
        </div>
        <div className={"logo-background-scene"}>
          <img src={verre} width={15} className={"verre"} />
          <img src={bouteille} width={10} className={"bouteille"} />
          <img src={burger} width={15} className={"burger"} />
        </div>
        <svg
          className={"logo-text"}
          width={450}
          viewBox="0 0 203 33"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs />
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="logo_addition_red" fill="#FF3950" fillRule="nonzero">
              <path
                d="M200.61,14 C199.120402,12.4870553 197.049331,11.6921724 194.93,11.82 C192.530747,11.8450953 190.284243,13.0017114 188.87,14.94 C188.514825,13.118775 186.84977,11.8538496 185,12 L184.81,12 L184.81,31.85 L189.11,31.85 L189.11,26.24 L189.11,20.36 C188.981432,19.1928159 189.389754,18.0303855 190.22,17.2 C191.23397,16.3752079 192.513949,15.9485481 193.82,16 C195.133443,15.9294248 196.424402,16.3621286 197.43,17.21 C198.25279,18.0450572 198.659828,19.2038331 198.54,20.37 L198.54,31.85 L202.86,31.85 L202.86,20.08 C202.960621,17.832835 202.149242,15.640308 200.61,14 Z"
                id="Shape"
              />
              <path
                d="M171.64,11.63 C167.999464,11.6299999 164.635469,13.572203 162.815201,16.725 C160.994933,19.877797 160.994933,23.762203 162.815201,26.915 C164.635469,30.067797 167.999464,32.0100001 171.64,32.01 C174.378821,32.0927755 177.031633,31.0480889 178.978584,29.1200403 C180.925535,27.1919917 181.996052,24.5494977 181.94,21.81 C181.990503,19.0739393 180.917539,16.4367156 178.971218,14.51307 C177.024897,12.5894243 174.375281,11.5474381 171.64,11.63 Z M171.64,27.82 C170.038805,27.8530249 168.493418,27.2314946 167.360962,26.0990381 C166.228505,24.9665816 165.606975,23.4211948 165.64,21.82 C165.592463,20.2145997 166.209326,18.6607003 167.345013,17.5250133 C168.4807,16.3893263 170.0346,15.7724631 171.64,15.82 C173.258305,15.725943 174.83969,16.3278188 175.985936,17.4740642 C177.132181,18.6203096 177.734057,20.2016952 177.64,21.82 C177.702527,23.429654 177.090489,24.9923774 175.951433,26.1314331 C174.812377,27.2704888 173.249654,27.8825273 171.64,27.82 Z"
                id="Shape"
              />
              <path
                d="M51.4,7.75 L52.7,2.64 L48.08,2.64 L48.08,10.01 L48.52,10.01 C49.8816561,9.99759351 51.0641674,9.06965058 51.4,7.75 Z"
                id="Shape"
              />
              <polygon
                id="Shape"
                points="128.62 16.16 137.9 16.16 137.9 31.86 142.19 31.86 142.19 16.16 151.46 16.16 151.47 12.04 128.62 12.04"
              />
              <path
                d="M70.84,12 L70.66,12 C68.6830687,11.8055514 66.9102711,13.2193575 66.66,15.19 C65.3105859,12.9351674 62.8120068,11.62602 60.19,11.8 C53.49,11.8 50.26,17.27 50.26,21.99 C50.26,29.87 56.6,32.18 59.85,32.18 C63.27,32.18 65.5,30.65 66.49,29.04 L66.49,31.91 L70.8,31.91 L70.8,15.35 L70.84,12 Z M60.74,28.06 C57.4262916,28.0599999 54.7400001,25.3737084 54.7400001,22.06 C54.7400001,18.7462916 57.4262916,16.0600001 60.74,16.06 C64.0537085,16.06 66.74,18.7462915 66.74,22.06 C66.7673484,23.6595261 66.1439933,25.2015911 65.0127922,26.3327922 C63.8815911,27.4639933 62.3395261,28.0873484 60.74,28.06 Z"
                id="Shape"
              />
              <path
                d="M117.33,0.07 C114.51,0.07 113.22,1.85 113.22,4.77 L113.22,14.93 C111.842144,12.8191698 109.435786,11.6121712 106.92,11.77 C100.22,11.77 97,17.24 97,22 C97,29.88 103.34,32.19 106.59,32.19 C110.01,32.19 112.24,30.66 113.23,29.05 L113.23,31.92 L117.53,31.92 L117.53,0.07 L117.34,0.07 L117.33,0.07 Z M107.42,28.07 C104.106292,28.0699999 101.42,25.3837084 101.42,22.07 C101.42,18.7562916 104.106292,16.0700001 107.42,16.07 C110.733708,16.07 113.42,18.7562915 113.42,22.07 C113.444609,23.667806 112.820044,25.2072569 111.689149,26.3362675 C110.558253,27.4652781 109.017763,28.0872742 107.42,28.06 L107.42,28.07 Z"
                id="Shape"
              />
              <path
                d="M94,2.63 C91.18,2.68 89.89,4.41 89.89,7.33 L89.89,14.93 C88.5121442,12.8191698 86.1057863,11.6121712 83.59,11.77 C76.89,11.77 73.66,17.24 73.66,21.96 C73.66,29.84 79.99,32.15 83.24,32.15 C86.66,32.15 88.89,30.62 89.89,29.01 L89.89,31.88 L94.19,31.88 L94.19,2.63 L94,2.63 Z M84.07,28.06 C81.9264064,28.06 79.9456443,26.9164065 78.8738475,25.06 C77.8020507,23.2035936 77.8020507,20.9164064 78.8738475,19.06 C79.9456443,17.2035935 81.9264064,16.06 84.07,16.06 C87.3837085,16.06 90.07,18.7462915 90.07,22.06 C90.0945368,23.6586947 89.470278,25.199116 88.339697,26.329697 C87.209116,27.460278 85.6686947,28.0845368 84.07,28.06 Z"
                id="Shape"
              />
              <path
                d="M155.9,2.67 C155.049849,2.66124778 154.233179,3.0009248 153.64,3.61 C153.026916,4.20044873 152.686485,5.0189303 152.7,5.87 C152.683491,6.71580386 153.024855,7.52926516 153.64,8.11 C154.233833,8.71218134 155.04427,9.05119401 155.89,9.05119401 C156.73573,9.05119401 157.546167,8.71218134 158.14,8.11 C158.755145,7.52926516 159.096509,6.71580386 159.08,5.87 C159.093515,5.0189303 158.753084,4.20044873 158.14,3.61 C157.553651,3.00301087 156.743917,2.66321174 155.9,2.67 Z"
                id="Shape"
              />
              <path
                d="M153.74,16.67 L153.74,31.84 L158,31.84 L158,12 L157.81,12 C155,12 153.74,13.75 153.74,16.67 Z"
                id="Shape"
              />
              <path
                d="M122.11,9.28 L121.92,9.28 L121.92,31.89 L126.22,31.89 L126.22,14 C126.22,11.06 124.93,9.32 122.11,9.28 Z"
                id="Shape"
              />
              <path
                className={"head-ball"}
                d="M124.06,0 C123.216083,-0.00678825584 122.406349,0.333010867 121.82,0.94 C121.206916,1.53044873 120.866485,2.3489303 120.88,3.2 C120.863491,4.04580386 121.204855,4.85926516 121.82,5.44 C122.413833,6.04218134 123.22427,6.38119401 124.07,6.38119401 C124.91573,6.38119401 125.726167,6.04218134 126.32,5.44 C126.935145,4.85926516 127.276509,4.04580386 127.26,3.2 C127.273515,2.3489303 126.933084,1.53044873 126.32,0.94 C125.726821,0.330924802 124.910151,-0.00875222116 124.06,0 Z"
                id="Shape"
              />
              <path
                d="M39.83,2.63 L39.64,2.63 L39.64,31.88 L43.94,31.88 L43.94,7.33 C43.94,4.41 42.65,2.68 39.83,2.63 Z"
                id="Shape"
              />
            </g>
          </g>
        </svg>
        <div className={"loader-container__text"}>
          {I18n.t("loader.title2")}
          <div className={"dot__wave"} />
          <div className={"dot__wave"} />
          <div className={"dot__wave"} />
          <div className={"dot__wave"} />
          <div className={"center"}>
            {this.state.count > 0 ? this.state.count + "  " : null}
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default Loader;
