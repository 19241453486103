import React from "react";
import Proptypes from "prop-types";
import classnames from "classnames";
import "./PasswordHint.css";
import { I18n } from "react-redux-i18n";

const PasswordHint = ({
  nbCharIsOk,
  nbUpperIsOk,
  nbLowerIsOk,
  nbNumericIsOk,
  nbSpecialCharIsOk
}) => {
  return (
    <div id={"password-hint-container"}>
      <section className={"password-hint-check"}>
        <h5>{I18n.t("passwordHint.title")}</h5>
        <ul>
          <li
            className={classnames({
              success: nbCharIsOk,
              error: !nbCharIsOk
            })}
          >
            {I18n.t("passwordHint.nbCharacters")}
          </li>
          <li
            className={classnames({
              success: nbUpperIsOk,
              error: !nbUpperIsOk
            })}
          >
            {I18n.t("passwordHint.uppercase")}
          </li>
          <li
            className={classnames({
              success: nbLowerIsOk,
              error: !nbLowerIsOk
            })}
          >
            {I18n.t("passwordHint.lowercase")}
          </li>
          <li
            className={classnames({
              success: nbNumericIsOk,
              error: !nbNumericIsOk
            })}
          >
            {I18n.t("passwordHint.numerical")}
          </li>
          <li
            className={classnames({
              success: nbSpecialCharIsOk,
              error: !nbSpecialCharIsOk
            })}
          >
            {I18n.t("passwordHint.special")}
          </li>
        </ul>
      </section>
    </div>
  );
};

PasswordHint.propTypes = {
  nbCharIsOk: Proptypes.bool.isRequired,
  nbUpperIsOk: Proptypes.bool.isRequired,
  nbLowerIsOk: Proptypes.bool.isRequired,
  nbSpecialCharIsOk: Proptypes.bool.isRequired
};

PasswordHint.defaultProps = {
  nbCharIsOk: false,
  nbUpperIsOk: false,
  nbLowerIsOk: false,
  nbSpecialCharIsOk: false
};

export default PasswordHint;
