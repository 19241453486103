import {UPDATE_VIDEOSTATES, FETCH_VIDEOSTATES_SUCCESS} from '../constant';

export default function video(state = {}, action) {
    switch (action.type) {
        case UPDATE_VIDEOSTATES:
            return {
                ...action.payload.video
            };
        case FETCH_VIDEOSTATES_SUCCESS:
            return{
              ...action.payload  
            };
        default:
            return state;
    }
}
