import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Media, Player } from 'react-media-player';
import IconButton from '../components/IconButton/IconButton';
import { getCookie, setCookie, deleteCookie } from '../utils/cookieHandlers';

import '../sass/style.scss';
import logo from '../img/logo.svg';
import logoMobile from '../img/logo-mobile.svg';
import previous from '../img/previous.svg';
import playerFrame from '../img/player-frame.png';

const videosList = [
	{
		title: 'video-menu',
		label: 'La carte',
		link: 'https://player.vimeo.com/video/843926265',
	},
	{
		title: 'video-order',
		label: 'Commande et encaissement',
		link: 'https://player.vimeo.com/video/843926374',
	},
	{
		title: 'video-service',
		label: 'Le service',
		link: 'https://player.vimeo.com/video/843926331',
	},

	{
		title: 'video-bar-counter',
		label: 'Mode comptoir',
		link: 'https://player.vimeo.com/video/843926127',
	},
	{
		title: 'video-seating-plan',
		label: 'Plan de salle',
		link: 'https://player.vimeo.com/video/844254859',
	},
	{
		title: 'video-print',
		label: 'Imprimantes',
		link: 'https://player.vimeo.com/video/843926198',
	},
	{
		title: 'video-users',
		label: 'Gestion utilisateurs',
		link: 'https://player.vimeo.com/video/843926233',
	},
];

function SuiteVideos({ groups }) {
	const seenVideos = getCookie('watched-videos')?.split(' ');
	const [videoPlaying, setVideoPlaying] = useState('');
	const [watchedVideos, setWatchedVideos] = useState(seenVideos || []);
	const [videoIntro, setVideoIntro] = useState(false);

	const firstVideoActive =
		watchedVideos.length && watchedVideos.length < 7
			? videosList.find(video => !watchedVideos.includes(video.title)).link
			: videosList[0].link;

	useEffect(() => {
		setVideoPlaying(firstVideoActive);
	}, []);

	const videoPlayerRef = useRef(null);

	const handleGoBack = () => browserHistory.goBack();

	// --------------------------------------- LOGIC FOR VIDEO INTRO --------------------------------------- //
	// the purpose here is to launch the video "addition jingle" only if its the first video read of the day

	// const videoIntroLink = "***** LADDITION INTRO VIDEO LINK HERE *****"
	// const date = new Date()
	// const today = date.toDateString();
	// const lastTimeVideoRead = getCookie("video-date");

	// if (lastTimeVideoRead !== today) {
	//   setVideoIntro(true)
	//   setCookie("video-date", today)
	// }

	// ---------------------------------------------------------------------------------------------------- //

	const handleClick = videoTitle => {
		const readingVideo = videosList.find(video => video.title === videoTitle);
		setVideoPlaying(readingVideo.link);
		videoPlayerRef.current.scrollIntoView();
	};

	const getStatus = video => {
		if (videoPlaying === video.link) {
			return 'reading';
		} else if (watchedVideos.length && watchedVideos.includes(video.title)) {
			return 'read';
		} else return 'default';
	};

	const handlePlay = (media, videoPaused) => {
		const pausedTime = parseFloat(getCookie(videoPaused));
		if (pausedTime && media.currentTime == 0) {
			media.seekTo(pausedTime);
		}
	};

	const handlePause = (media, videoPaused) => {
		const pauseTime = media.currentTime;
		if (pauseTime !== 0) {
			setCookie(videoPaused, pauseTime);
		}
		media.seekTo(pauseTime);
	};

	const handleVideoEnd = videoPlayed => {
		deleteCookie(videoPlayed);
		if (videoIntro) {
			setVideoIntro(false);
		} else {
			const readVideo = videosList.find(video => video.link === videoPlayed);
			const watched = [...watchedVideos];
			if (!watchedVideos.includes(readVideo.title)) {
				watched.push(readVideo.title);
				setCookie('watched-videos', watched.join(' '), 1000);
				setWatchedVideos(watched);
			}

			let nextVideoIndex =
				videosList.findIndex(video => video.link === videoPlaying) + 1;

			// case all videos already read
			if (watched.length === videosList.length) {
				setVideoPlaying('');

				// case next indexed video already seen, jump to the next unread video
			} else if (
				nextVideoIndex == videosList.length ||
				watched.includes(videosList[nextVideoIndex].title)
			) {
				const reorderedList = videosList
					.slice(nextVideoIndex)
					.concat(videosList.slice(0, nextVideoIndex));
				const unreadVideo = reorderedList.find(
					video => !watched.includes(video.title)
				);
				setVideoPlaying(unreadVideo.link);
			} else {
				setVideoPlaying(videosList[nextVideoIndex].link);
			}
		}
	};

	const getHeight = () => {
		const vw = Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		);
		const fullWidth = vw > 763 ? vw - 100 : vw - 30;
		if (vw > 1139) {
			return 718;
		} else {
			return Math.ceil(fullWidth / 1.33);
		}
	};

	return (
		<div className={`background-mask`}>
			<div className="background">
				<div className="video-header">
					<img src={logo} className="logo" />
					<img
						src={logoMobile}
						className="logo-mobile"
						onClick={handleGoBack}
					/>
					<div className="title-container">
						<p>Formation vidéo </p>
					</div>
				</div>
				<div className="go-back" onClick={handleGoBack}>
					<img src={previous} />
					<p> Retour </p>
				</div>
				<div className="buttons-container">
					{videosList.map((video, index) => (
						<IconButton
							label={video.label}
							id={video.title}
							key={index}
							status={getStatus(video)}
							onClick={handleClick}
						/>
					))}
				</div>
				<div className={videoPlaying ? 'media-container' : 'empty-player'}>
					<img src={playerFrame} ref={videoPlayerRef} />
					{videoPlaying && (
						<Media>
							{props => (
								<div className="player-container">
									<Player
										src={videoIntro ? videoIntroLink : videoPlaying}
										className="video-player"
										style={{ height: `${getHeight()}px` }}
										isMuted={false}
										onEnded={() => handleVideoEnd(videoPlaying)}
										onPlay={() => handlePlay(props, videoPlaying)}
										onPause={() => handlePause(props, videoPlaying)}
										autoPlay
									/>{' '}
								</div>
							)}
						</Media>
					)}
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		groups: state.auth.user.groups,
	};
}

export default connect(mapStateToProps, null)(SuiteVideos);
