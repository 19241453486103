import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../css/global.css";
import "../css/video.css";
import VideoGrid from "../components/VideoGrid";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import LadditionLoader from "../../../components/LadditionLoader";
import Valide from "../img/valide.png";
import Forme from "../img/forme.png";
import Pause from "../img/pause.png";
import Header from "../../../components/Header/Header";
import { Media, Player } from "react-media-player";
import { getStates, updateStates } from "../actions/video";
import find from "lodash/find";
import { I18n } from "react-redux-i18n";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

class Video extends React.Component {
  constructor(props) {
    super(props);
    let videoToPlay;
    if (props.videos) {
      videoToPlay = find(props.videos, video => {
        if (video.state) return video.state.state === "inProgress";
      });
      if (typeof videoToPlay == "undefined") {
        videoToPlay = find(props.videos, video => {
          if (video.state) return video.state.state === "paused";
        });
      }
    }
    if (typeof videoToPlay == "undefined") {
      videoToPlay = props.videos[0];
    }
    this.state = {
      autoPlay: false,
      openVideoUrl: (videoToPlay && videoToPlay.link) || "",
      videos: props.videos
    };
    this.changeOpenedVideoUrl = this.changeOpenedVideoUrl.bind(this);
    this.changeAutoPlay = this.changeAutoPlay.bind(this);
    this.handleUpdateStates = this.handleUpdateStates.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function(to, element) {});

    Events.scrollEvent.register("end", function(to, element) {});

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  async changeOpenedVideoUrl(url) {
    this.media.pause();
    await this.handleUpdateStates("pause", this.mediaProps.currentTime);
    if (url === this.state.openVideoUrl) {
      this.media.playPause();
    } else {
      this.setState({ openVideoUrl: url });
    }
  }

  changeAutoPlay(autoPlay) {
    this.setState({ autoPlay });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.videos !== this.state.videos) {
      if (nextProps.videos && nextProps.videos.length > 0) {
        const videoToPlay = find(nextProps.videos, video => {
          return video.state.state === "inProgress";
        });
        this.setState({ videos: nextProps.videos, openVideoUrl: videoToPlay.link });
      } else {
        this.setState({ videos: nextProps.videos });
      }
    }
  }

  scrollTo() {
    scroller.scrollTo("videoPlayer-container", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  /*
    met a jours les etats des videos
    si l'utilisateur joue la vidéo (action play) la video avance au temps enregistré
    si l'action est pause enregistre l'état en cours avec le temps
    si l'action est la fin de la video, changement de l'état a vu
    met ensuite a jours les etats dans la base et les recuperes pour actualiser la vue
  */
  async handleUpdateStates(action, currentTime = 0, seekTo) {
    let { videos } = this.props;
    let states = [];
    for (let video in videos) {
      //clean previous in progress
      if (
        videos[video].link !== this.state.openVideoUrl &&
        videos[video].state.state == "inProgress"
      ) {
        videos[video].state.state = "paused";
      }
      if (videos[video].link === this.state.openVideoUrl && action == "play") {
        seekTo(videos[video].state.time);
        videos[video].state.state = "inProgress";
      }
      if (videos[video].link === this.state.openVideoUrl && action == "pause") {
        videos[video].state.state = "paused";
        videos[video].state.time = currentTime;
      }

      if (videos[video].link === this.state.openVideoUrl && action == "ended") {
        videos[video].state.state = "watched";
      }
      states.push(videos[video].state);
    }
    await this.props.actions.updateStates(states);
    await this.props.actions.getStates();
  }

  render() {
    let { videos } = this.state;
    videos = sortBy(videos, ["position"]);
    return !isEmpty(videos) ? (
      <div>
        <Header title={"Formation"} />
        <div id="video-container" className="container-fluid">
          <div className="row">
            <div id="videoGrid-container" className="col-lg-6 col-md-12">
              <h1> {I18n.t("video.title")}</h1>
              <VideoGrid
                videos={videos}
                openVideoUrl={this.state.openVideoUrl}
                autoPlay={this.state.autoPlay}
                changeAutoPlay={this.changeAutoPlay}
                changeOpenedVideoUrl={this.changeOpenedVideoUrl}
                scrollTo={this.scrollTo}
              />
            </div>

            <div
              id="videoPlayer-container"
              className="col-lg-6 col-md-12"
              style={{ marginTop: "5%" }}
            >
              <Media
                ref={media => {
                  this.media = media;
                }}
              >
                {mediaProps => (
                  <div className="media" style={{ width: "100%" }}>
                    <div className="media-player">
                      <Player
                        mozallowfullscreen=""
                        webkitallowfullscreen=""
                        allowfullscreen=""
                        ref={mediaProps => {
                          this.mediaProps = mediaProps;
                        }}
                        style={{ border: "none", width: "100%", height: "400px" }}
                        onPlay={() =>
                          this.handleUpdateStates("play", mediaProps.currentTime, mediaProps.seekTo)
                        }
                        onPause={() =>
                          this.handleUpdateStates(
                            "pause",
                            mediaProps.currentTime,
                            mediaProps.seekTo
                          )
                        }
                        onEnded={() => this.handleUpdateStates("ended")}
                        autoPlay={this.state.autoPlay}
                        src={this.state.openVideoUrl}
                      />
                    </div>
                  </div>
                )}
              </Media>
            </div>
          </div>
          <div id="videoLegend">
            <div>
              <img src={Forme} className="videoLegend-image" />
              <p className="videoLegend-label"> {I18n.t("video.playing")}</p>
            </div>
            <div>
              <img src={Valide} className="videoLegend-image" />
              <p className="videoLegend-label"> {I18n.t("video.alreadySeen")}</p>
            </div>
            <div>
              <img src={Pause} className="videoLegend-image" />
              <p className="videoLegend-label"> {I18n.t("video.pause")}</p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <LadditionLoader hidden={this.props.entities.informations !== undefined} />
        <h1>{I18n.t("videoEmpty")}</h1>
      </div>
    );
  }
}

Video.propTypes = {
  videos: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    videos: state.entities.videos,
    entities: state.entities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getStates, updateStates }, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Video);
