import React from "react";
import PropTypes from "prop-types";
import Notification from "../components/Notification";
import { connect } from "react-redux";
import { deleteNotification } from "../actions/notification";
import classnames from "classnames";
import config from "../../../utils/config";
import ReactGA from "react-ga";
import { I18n } from "react-redux-i18n";
import MediaQuery from "react-responsive";
import { BellImg } from "../../../components/Svg/Svg";

class NotificationCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    this.toggleNotificationCenter = this.toggleNotificationCenter.bind(this);
  }

  toggleNotificationCenter(e) {
    e.preventDefault();

    if (config.useReactGa && !this.state.isOpen) {
      ReactGA.event({
        category: "Notification",
        action: "Open Notification center"
      });
    }

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const notifications = this.props.notifications.length ? (
      this.props.notifications.map(notification => (
        <Notification
          key={notification.id}
          message={notification}
          deleteNotification={this.props.deleteNotification}
        />
      ))
    ) : (
      <li className="item no-data">{I18n.t("notification.noData")}</li>
    );
    return (
      <div>
        <button
          type="button"
          onClick={this.toggleNotificationCenter}
          className={classnames(
            "button-default show-notifications js-show-notifications",
            {
              active: this.state.isOpen
            }
          )}
        >
          <MediaQuery minDeviceWidth={764}>
            {/* <img width="25" src={bellIcon} alt="bell" /> */}
            <BellImg />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={764}>
            {/* <img width="20" src={bellIcon} alt="bell" /> */}
            <BellImg />
          </MediaQuery>

          {this.props.notifications.length ? (
            <div className="notifications-count js-count">
              {this.props.notifications.length}
            </div>
          ) : null}
        </button>
        <div
          className={classnames("notifications js-notifications", {
            empty: !this.props.notifications.length
          })}
        >
          <h3>{I18n.t("notification.title")}</h3>
          <ul className="notifications-list">{notifications}</ul>
        </div>
      </div>
    );
  }
}

NotificationCenter.propTypes = {
  notifications: PropTypes.array.isRequired,
  deleteNotification: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications ? state.notifications : []
  };
}

export default connect(
  mapStateToProps,
  { deleteNotification }
)(NotificationCenter);
