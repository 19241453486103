import React, { Component } from 'react';
import '../css/documentation.css';
import DocumentationItem from '../components/DocumentationItem';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import urls from '../../../utils/config';
import { pickBy } from 'lodash';

class Documentation extends Component {
	render() {
		const { adminServerUrl } = urls;

		const { groups } = this.props.auth.user;

		//Replace groupe by suite_group
		const findGroup = groups.find(g => g.name === 'laddition-suite');

		const filteredDataSuite = pickBy(this.props.documentations, value => {
			return value.is_suite === 1;
		});
		const filteredDataNoSuite = pickBy(this.props.documentations, value => {
			return value.is_suite !== 1;
		});

		//handle sales demo accounts.
		const documentationObject = findGroup
			? filteredDataSuite
			: filteredDataNoSuite;

		return (
			<div id="main-container" className="main-container nav-effect-1">
				<header>
					<h1 className="documentation-header">
						{I18n.t('documentation.title')}
					</h1>
				</header>
				<section id="book_list">
					<div className="grid-shuffle">
						<ul id="grid" className="row">
							{Object.keys(documentationObject).map(key => {
								let doc = documentationObject[key];
								if (doc.title !== 'list-incident-fisc') {
									return (
										<DocumentationItem
											key={doc.title}
											backgroundUrl={doc.background_url}
											pdfUrl={doc.pdf_url}
											pdfName={doc.pdf_url}
											description={doc.description}
											title={doc.title}
											author={doc.author}
										/>
									);
								}
							})}
						</ul>
					</div>
				</section>
			</div>
		);
	}
}

Documentation.propTypes = {};
Documentation.defaultProps = {};

function mapStateToProps(state) {
	return {
		documentations: state.entities.documentation,
		entities: state.entities,
		auth: state.auth,
	};
}

export default connect(mapStateToProps, null)(Documentation);
