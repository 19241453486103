import React, { Component } from 'react'

export default class DetailedInformations extends Component {
    render() {

        let {rowInfo,columns} = this.props;

        return Object.keys(columns).map(elem => {
            //si objet imbriqué provoque une erreur
            if (typeof rowInfo[elem] !== "object") {
                return (
                    <div className="rowInfo" key={elem}>
                        <span className="keyRowInfo">{columns[elem].Header} :</span> {rowInfo[columns[elem].accessor]}
                    </div>
                );
            }
        });
    }
}
