import React from "react";
import renderIcon from "../../icons/renderIcon";
import check from "../../img/check.svg";
import "./style.scss";

export default function IconButton({ status, id, label, onClick }) {
  return (
    <div className={`icon-button button-${status}`} onClick={() => onClick(id)}>
      <div>
        <img src={check} className={`check--${status}`} />
      </div>
      <div className="button--main-content">
        <div>{renderIcon(id, status)}</div>
        <p>{label}</p>
      </div>   
    </div>
  );
}


IconButton.defaultProps = {
    status: "default"
};