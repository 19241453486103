import { CALL_API } from 'redux-api-middleware';
import setAuthorizationToken from '../../../utils/setAuthorizationToken';
import * as constant from '../constant';
import config from '../../../utils/config';
import detectIe from '../../../utils/detectIe';
import axios from 'axios';
import { getRefreshToken } from '../../../utils/utils';

export function setCurrentUser(user) {
	return {
		type: constant.SET_CURRENT_USER,
		user,
	};
}

export const setUnauthorizedUser = () => {
  return {
    type: constant.SET_UNAUTHORIZED_USER
  }
};

export function checkAuth() {
	return {
		type: constant.CHECK_AUTH,
		user: {},
	};
}

export function setSubUserServices(services) {
  return {
    type: constant.SET_SUBUSER_SERVICES,
    services
  }
}

export function checkAccessToken() {
	if (detectIe()) {
		return dispatch => {
			axios
				.get(
					config.reportingUrl +
						'api/auth/accessToken/check/?refreshToken=' +
						getRefreshToken() +
						'&accessToken=' +
						window.localStorage.accessToken
				)
				.then(res => {
					window.localStorage.accessToken = res.data.accessToken;
					return res.data.accessToken;
				})
				.catch(err => {
					setAuthorizationToken(false);
					window.localStorage.removeItem('accessToken');
					window.localStorage.removeItem('refreshToken');
					window.location.replace(
						config.authServerUrl + '?scope=' + config.scope
					);
				});
		};
	} else {
		return {
			[CALL_API]: {
				endpoint:
					config.reportingUrl +
					'api/auth/accessToken/check/?refreshToken=' +
					getRefreshToken() +
					'&accessToken=' +
					window.localStorage.accessToken,
				method: 'GET',
				types: [
					constant.FETCH_CHECK_ACCESS_TOKEN_REQUEST,
					{
						type: constant.FETCH_CHECK_ACCESS_TOKEN_SUCCESS,
						payload: (action, state, res) => {
							const contentType = res.headers.get('Content-Type');
							if (contentType && contentType.indexOf('json') > -1) {
								return res.json().then(json => {
									window.localStorage.accessToken = json.accessToken;
									return json.accessToken;
								});
							}
						},
					},
					{
						type: constant.FETCH_CHECK_ACCESS_TOKEN_FAILURE,
						payload: (action, state, res) => {
							const contentType = res.headers.get('Content-Type');
							if (contentType && contentType.indexOf('json') > -1) {
								return res.json().then(json => {
									setAuthorizationToken(false);
									window.localStorage.removeItem('accessToken');
									window.localStorage.removeItem('refreshToken');
									window.location.replace(
										config.authServerUrl + '?scope=' + config.scope
									);
								});
							}
						},
					},
				],
			},
		};
	}
}

export function generateAccessToken(token, type = 'refreshToken') {
	let url;
	switch (type) {
		case 'refreshToken':
			url = config.reportingUrl + 'api/auth/accessToken/?refreshToken=' + token;
			break;
		case 'fiscAccessToken':
			url =
				config.reportingUrl + 'api/auth/accessToken/administration-fiscale/?refreshToken=' + token;
			break;
		default:
			url = config.reportingUrl + 'api/auth/accessToken/?refreshToken=' + token;
			break;
	}
	if (detectIe()) {
		return dispatch => {
			axios
				.get(url)
				.then(res => {
					window.localStorage.accessToken = res.data.accessToken;
					document.location.reload(true);
					return res.data.accessToken;
				})
				.catch(err => {
					setAuthorizationToken(false);
					window.localStorage.removeItem('accessToken');
					window.localStorage.removeItem('refreshToken');
					// window.location.replace(config.authServerUrl);
				});
		};
	} else {
		return {
			[CALL_API]: {
				endpoint: url,
				method: 'GET',
				types: [
					constant.FETCH_ACCESS_TOKEN_REQUEST,
					{
						type: constant.FETCH_ACCESS_TOKEN_SUCCESS,
						payload: (action, state, res) => {
							const contentType = res.headers.get('Content-Type');
							if (contentType && contentType.indexOf('json') > -1) {
								return res.json().then(json => {
									window.localStorage.accessToken = json.accessToken;
									return json.accessToken;
								});
							}
						},
					},
					{
						type: constant.FETCH_ACCESS_TOKEN_FAILURE,
						payload: (action, state, res) => {
							const contentType = res.headers.get('Content-Type');
							if (contentType && contentType.indexOf('json') > -1) {
								return res.json().then(json => {
									setAuthorizationToken(false);
									window.localStorage.removeItem('accessToken');
									window.localStorage.removeItem('refreshToken');
								  const isSubUserForbidden = !!json.code && json.code === "SUB_USER_NO_ACCESS";
									if(isSubUserForbidden) {
									  return { type: 'SUB_USER_NO_ACCESS' }
									}
									// window.location.replace(config.authServerUrl);
								});
							}
						},
					},
				],
			},
		};
	}
}

export function logout() {
	return dispatch => {
		window.localStorage.removeItem('refreshToken');
		window.localStorage.removeItem('accessToken');
		window.localStorage.removeItem('fiscAccessToken');
		setAuthorizationToken(false);
		dispatch(setCurrentUser({}));
	};
}

export async function getSubUserServices(refreshToken) {
  return axios.get(config.reportingUrl + 'api/auth/accessToken/subuser/services', { headers: { refreshToken }})
    .then((response) => response.data)
    .catch(() => []);
}
