import * as zoid from 'zoid/dist/zoid.frameworks';
import config from '../../../utils/config';
function destroyElement(element) {
	if (element && element.parentNode) {
		element.parentNode.removeChild(element);
	}
}

function isPerc(str) {
	return typeof str === 'string' && /^[0-9]+%$/.test(str);
}

function isPx(str) {
	return typeof str === 'string' && /^[0-9]+px$/.test(str);
}

export function toNum(val) {
	if (typeof val === 'number') {
		return val;
	}

	let match = val.match(/^([0-9]+)(px|%)$/);

	if (!match) {
		throw new Error(`Could not match css value from ${val}`);
	}

	return parseInt(match[1], 10);
}

export function toPx(val) {
	return `${toNum(val)}px`;
}

function toCSS(val) {
	if (typeof val === 'number') {
		return toPx(val);
	}

	return isPerc(val) ? val : toPx(val);
}

export const EVENT = {
	RENDER: 'zoid-render',
	RENDERED: 'zoid-rendered',
	DISPLAY: 'zoid-display',
	ERROR: 'zoid-error',
	CLOSE: 'zoid-close',
	PROPS: 'zoid-props',
	RESIZE: 'zoid-resize',
	FOCUS: 'zoid-focus',
};

const CLASS = {
	VISIBLE: 'visible',
	INVISIBLE: 'invisible',
};

let url = config.dashboardUrl + `?t=${Date.now()}`;

let DashboardWidget = zoid.create({
	tag: 'board-laddition',
	url: url,
	dimensions: {
		width: '100%',
		height: 'calc(100vh - 85px)',
	},

	contexts: {
		popup: false,
	},

	prerenderTemplate: function containerTemplate({ doc, props }) {
		const html = doc.createElement('html');
		const body = doc.createElement('body');
		const style = doc.createElement('style');
		const spinner = doc.createElement('div');
		const loader = document.getElementsByClassName(
			'laddition-loader-container__hidden'
		)[0];

		loader.className = 'laddition-loader-container';
	},
	containerTemplate: function containerTemplate({
		uid,
		frame,
		prerenderFrame,
		doc,
		props,
		event,
		dimensions: { width, height },
	}) {
		if (!frame || !prerenderFrame) {
			return;
		}

		const divParent = doc.createElement('div');
		divParent.classList.add('overlay');
		const styleParent = doc.createElement('style');
		if (props.cspNonce) {
			styleParent.setAttribute('nonce', props.cspNonce);
		}

		styleParent.appendChild(
			doc.createTextNode(`
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 100000000000000;
      }
  `)
		);
		const div = doc.createElement('div');
		divParent.appendChild(div);
		divParent.appendChild(styleParent);
		div.setAttribute('id', uid);
		const style = doc.createElement('style');
		if (props.cspNonce) {
			style.setAttribute('nonce', props.cspNonce);
		}

		style.appendChild(
			doc.createTextNode(`
      #${uid} {
          display: inline-block;
          position: relative;
          width: ${width};
          height: ${height};
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, -50%);
      }
      #${uid} > iframe {
		
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 100%;
          transition: opacity .2s ease-in-out;
      }
      #${uid} > iframe.${CLASS.INVISIBLE} {
          opacity: 0;
      }
      #${uid} > iframe.${CLASS.VISIBLE} {
          opacity: 1;
  }
  `)
		);

		div.appendChild(frame);
		div.appendChild(prerenderFrame);
		div.appendChild(style);

		const loader = document.getElementById('laddition-loader-container');

		prerenderFrame.classList.add(CLASS.VISIBLE);
		frame.classList.add(CLASS.INVISIBLE);

		event.on(EVENT.RENDERED, () => {
			prerenderFrame.classList.remove(CLASS.VISIBLE);
			prerenderFrame.classList.add(CLASS.INVISIBLE);
			frame.classList.remove(CLASS.INVISIBLE);
			frame.classList.add(CLASS.VISIBLE);

			setTimeout(() => {
				loader.className = 'laddition-loader-container__hidden';
				destroyElement(prerenderFrame);
			}, 500);
		});

		event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
			if (typeof newWidth === 'number') {
				div.style.width = toCSS(newWidth);
			}

			if (typeof newHeight === 'number') {
				div.style.height = toCSS(newHeight);
			}
		});

		return divParent;
	},
});

export default DashboardWidget;
