import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './btn.css';

class Btn extends Component {
  render() {
    const {active, text, disabled, isImage, imgPath, imgSize, onClick, style, btnClass} = this.props;
    return (
      <div
        className={active ?
          'button-container-lplus button-container-lplus-active'
          :
          'button-container-lplus'}
        style={style.buttonContainer}>
        <button disabled={disabled} onClick={onClick} style={style.button}
                className={btnClass}>
          {isImage ? (
            <img src={imgPath} width={imgSize}/>
          ) : null}
          {text !== '' ? (
            <span style={style.buttonSpan}>{text}</span>
          ) : null}
        </button>
      </div>
    );
  }
}

Btn.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  isImage: PropTypes.bool,
  imgPath: PropTypes.string,
  imgSize: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.object,
  btnClass: PropTypes.string
};
Btn.defaultProps = {
  active: false,
  text: '',
  disabled: false,
  isImage: false,
  imgPath: '',
  imgSize: 15,
  style: {buttonContainer: {}, button: {}, buttonSpan: {}},
  btnClass: ''
};

export default Btn;
