import React, {Component} from 'react';
import PropTypes from 'prop-types';
import getUrlParams from '../../../utils/getUrlParams';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addFlashMessage} from
  '../../../common/flashMessage/actions/flashMessages';
import * as fiscApiActions from '../actions/fiscApiActions';
import Card from '../../../components/Card/Card';
import Btn from '../../../components/Button/Btn';

class FiscAccountAccessPage extends Component {
  constructor(props) {
    super(props);

    const token = getUrlParams('token');

    this.state = {
      token: token || '',
      email: '',
      isLoading: false
    };
    this.onClickConnectAs = this.onClickConnectAs.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onClickConnectAs(e) {
    e.preventDefault();

    const {token, email} = this.state;

    this.props.actions.checkFiscAccess({
      token: token,
      askerEmail: email
    }).then(
      (res) => {
        this.setState({errors: {}, isLoading: false});
        if (!res.error) {
          this.props.addFlashMessage({
            type: 'success',
            text: 'Succès, connexion en cours ...',
            persistent: false
          });
          this.context.router.push('/');
        } else {
          this.props.addFlashMessage({
            type: 'error',
            text: 'connexion refusée',
            persistent: false
          });
        }
      },
      (err) => {
        this.setState({errors: {err}, isLoading: false});
        this.props.addFlashMessage({
          type: 'error',
          text: err
        });
      }
    );
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    const {email, token, isLoading} = this.state;
    return (
        <Card title={'Authentification Admin fiscale'}>
            <div className="input-container">
              <input type="email" id="login-input" name="email" required="required"
                     onChange={this.handleChange} value={email}/>
              <label htmlFor="login-input">Email</label>
              <div className="bar"></div>
            </div>
            <div className="input-container">
              <input type="text" name="token" id="password-input" required="required"
                     onChange={this.handleChange} value={token}/>
              <label htmlFor="password-input">Token</label>
              <div className="bar"></div>
            </div>
          <Btn disabled={isLoading} onClick={this.onClickConnectAs} text={'Se connecter'} active={email && token}/>
        </Card>
    );
  }
}

FiscAccountAccessPage.propTypes = {};
FiscAccountAccessPage.defaultProps = {};
FiscAccountAccessPage.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch),
    addFlashMessage: (message) => {
      dispatch(addFlashMessage(message));
    }
  };
}

export default connect(null, mapDispatchToProps)(FiscAccountAccessPage);
