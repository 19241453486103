import React, { PureComponent } from "react";

import ReactTable from "react-table";
import ArrowDown from "../../svg/ArrowDownSvg";
import ArrowUp from "../../svg/ArrowUpSvg";
import InfosSvg from "../../svg/InfosSvg";
import "react-table/react-table.css";
import "./sass/style.scss";
import { green } from "@material-ui/core/colors";
import CallApi from "../../../../utils/CallApi";
import { I18n } from "react-redux-i18n";
class TableData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      indexRowSeleted: -1,
    };
  }

  render() {
    const { handleClickWareDetails, tableHeight, labelNoData, datas } =
      this.props;

    const columnsFirstLevel = [
      {
        expander: true,
        width: 35,
        Expander: ({ isExpanded, ...rest }) => {
          {
            return <div>{isExpanded ? <ArrowUp /> : <ArrowDown />}</div>;
          }
        },
      },
      {
        Header: `${I18n.t("barDistrib.products")}`,
        accessor: "category_ware_name",
        headerClassName: "main_header_title",
        className: "table_string_field",
      },
      {
        Header: `${I18n.t("barDistrib.totalProductsCashed")}`,
        accessor: "total_products",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.waresShift")}`,
        accessor: "total_in_shift",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.waresOutShift")}`,
        accessor: "total_out_shift",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.totalWares")}`,
        accessor: "total_wares",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.difference")}`,
        accessor: "difference",
        headerClassName: "main_header_title",
      },
    ];
    const columnsSecondLevel = [
      {
        expander: true,
        width: 35,
        Expander: ({ isExpanded, original, ...rest }) => {
          return original &&
            (original.ware_products.length > 0 ||
              original.difference < 0 ||
              original.total_out_shift > 0) ? (
            <InfosSvg />
          ) : null;
        },
      },
      {
        Header: `${I18n.t("barDistrib.products")}`,
        accessor: "ware_name",
        className: "first_cell",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.totalProductsCashed")}`,
        accessor: "total_products",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.waresShift")}`,
        accessor: "total_in_shift",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.waresOutShift")}`,
        accessor: "total_out_shift",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.totalWares")}`,
        accessor: "total_wares",
        headerClassName: "main_header_title",
      },
      {
        Header: `${I18n.t("barDistrib.difference")}`,
        accessor: "difference",
        headerClassName: "main_header_title",
      },
    ];

    return (
      <div>
        {!datas && !datas[0] ? null : (
          <div className="main_table_container">
            <ReactTable
              data={datas[0]}
              columns={columnsFirstLevel}
              showPagination={false}
              resizable={false}
              sortable={false}
              noDataText={labelNoData}
              className="main_table"
              pageSize={tableHeight}
              headerClassName="main_table_header"
              SubComponent={(row) => {
                return (
                  <div className="main_second_table_container">
                    <ReactTable
                      data={row.original.wares}
                      noDataText={labelNoData}
                      columns={columnsSecondLevel}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginLeft: "22px",
                          },
                        };
                      }}
                      TheadComponent={(props) => null}
                      headerClassName
                      className="main_table_secondary"
                      showPagination={false}
                      pageSize={row.original.wares.length}
                      getTdProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: (e, handleOriginal) => {
                            handleClickWareDetails(rowInfo);
                          },
                        };
                      }}
                      /*   SubComponent={({ original }) => {
                        return (
                          <React.Fragment>
                            {original.ware_products &&
                            original.ware_products.length > 0 ? (
                              <div className="main_third_table_container">
                                <ReactTable
                                  data={original.ware_products}
                                  columns={columnsThirdLevel}
                                  noDataText={labelNoData}
                                  className="main_table_third"
                                  TheadComponent={(props) => null}
                                  showPagination={false}
                                  pageSize={original.ware_products.length}
                                />
                              </div>
                            ) : null}
                          </React.Fragment>
                        );
                      }}  */
                    />
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TableData;
