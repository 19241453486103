import React, { Component } from 'react';
import { MuiThemeProvider } from 'material-ui';
import { Tabs, Tab } from 'material-ui/Tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../css/fisc.css';
import classnames from 'classnames';
import logoLoader from '../img/logo-loader.png';

import TableCloturePaiements from '../components/Tables/TableCloturePaiements';
import TableServices from '../components/Tables/TableServices';
import TableCloturesTVA from '../components/Tables/TableCloturesTVA';

import * as fiscApiActions from '../actions/fiscApiActions';
import { withRouter } from 'react-router';
import { browserHistory } from 'react-router';
import IconButton from 'material-ui/IconButton';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import Home from 'material-ui/svg-icons/action/home';
import moment from 'moment';
import DetailedInformations from '../components/DetailedInformations';

class DetailsCloture extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			isLoading: false,
			tab: 1,
		};

		this.navigate = this.navigate.bind(this);
	}

	async componentDidMount() {
		//rowInfo = info de la cloture donc avec start date et end date au bon format
		let startDate = this.props.location.state.rowInfo.original.start_date;
		let endDate = this.props.location.state.rowInfo.original.end_date;
		let sessionStorage = window.sessionStorage;
		let lastTab = parseInt(sessionStorage.getItem('clotureTab'));
		if (lastTab) {
			this.setState({ tab: lastTab });
		}
		let formatStart = moment(startDate)
			.startOf('day')
			.format('YYYY-MM-DD H:mm:ss');
		let formatEnd = moment(endDate).endOf('day').format('YYYY-MM-DD H:mm:ss');
		this.setState({ isLoading: true });
		await this.props.actions.fetchServices(formatStart, formatEnd);
		this.setState({ isLoading: false });
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ data: nextProps.entities.services });
	}

	navigate(entity, rowInfo, columns) {
		//purge des donnees trop importantes sinon dépassement de la limite et bug
		//TODO: utilisation de react-router-redux
		let sessionStorage = window.sessionStorage;
		sessionStorage.setItem('clotureTab', this.state.tab);
		if (entity === 'back') {
			browserHistory.goBack();
			return;
		} else if (entity === 'home') {
			browserHistory.push('/administration-fiscale');
		} else {
			Object.keys(window.sessionStorage).map(elem => {
				if (elem.startsWith('@@History')) {
					let row = window.sessionStorage.getItem(elem);
					row = JSON.parse(row);
					delete row.fullHistory;
					row = JSON.stringify(row);
					window.sessionStorage.removeItem(elem);
					window.sessionStorage.setItem(elem, row);
				}
			});
			browserHistory.push({
				pathname: `/administration-fiscale/${entity}/details/${rowInfo.original.id}`,
				state: { rowInfo, columns },
			});
		}
	}

	render() {
		let rowInfo = this.props.location.state.rowInfo;

		let rowInfoData = this.props.location.state.rowInfo.row._original;
		let columns = this.props.location.state.columns;
		const { isLoading } = this.state;
		let paymentsInfo = rowInfo.original.payments;
		let taxesInfo = this.props.location.state.rowInfo.original.taxes;
		return (
			<MuiThemeProvider>
				<div>
					<div
						className="header-container"
						style={{ marginTop: '0px', color: 'white', paddingLeft: '50px' }}>
						<div style={{ textAlign: 'left' }}>
							<IconButton
								onClick={() => this.navigate('back')}
								iconStyle={{ color: 'white' }}
								tooltip="Retour arrière">
								<ArrowBack />
							</IconButton>
							<IconButton
								onClick={() => this.navigate('home')}
								iconStyle={{ color: 'white' }}
								tooltip="Accueil">
								<Home />
							</IconButton>
						</div>
					</div>
					<div className="fiscContainer">
						<div className="tabsContainer">
							<Tabs
								value={this.state.tab}
								onChange={value => this.setState({ tab: value })}
								inkBarStyle={{ backgroundColor: '#ab2221', height: '3px' }}>
								<Tab
									value={1}
									style={{ backgroundColor: '#f3f3f3', color: 'black' }}
									key={'services'}
									label="Services de la cloture">
									<div className="fiscHeader">
										<div className="fiscHeaderLeft">
											<h1 className="titlePage">Détails de clôture</h1>
											<h3 className="fiscPath">
												{' '}
												<span style={{ color: '#9A9A9A' }}>cloture/</span>
												services
											</h3>
										</div>
										<div
											style={{
												marginLeft: 'auto',
												marginRight: '5%',
												width: '55%',
											}}>
											<h3 className="rowInfoTitle">Informations détaillées</h3>
											<div className="gridRowInfo">
												<DetailedInformations
													rowInfo={rowInfoData}
													columns={columns}
												/>
											</div>
										</div>
									</div>
									<div className="containerTable">
										<TableServices
											goToDetails={(rowInfo, columns) =>
												this.navigate('services', rowInfo, columns)
											}
											data={this.state.data}
										/>
									</div>
								</Tab>
								<Tab
									value={2}
									style={{ backgroundColor: '#f3f3f3', color: 'black' }}
									key={'tva'}
									label="Détails TVA">
									<div className="fiscHeader">
										<div className="fiscHeaderLeft">
											<h1 className="titlePage">Détails de clôture</h1>
											<h3 className="fiscPath">
												{' '}
												<span style={{ color: '#9A9A9A' }}>cloture/</span>tva
											</h3>
										</div>

										<div
											style={{
												marginLeft: 'auto',
												marginRight: '5%',
												width: '55%',
											}}>
											<h3 className="rowInfoTitle">Informations détaillées</h3>
											<div className="gridRowInfo">
												<DetailedInformations
													rowInfo={rowInfoData}
													columns={columns}
												/>
											</div>
										</div>
									</div>
									<div className="containerTable">
										<TableCloturesTVA data={taxesInfo} />
									</div>
								</Tab>
								<Tab
									value={3}
									style={{ backgroundColor: '#f3f3f3', color: 'black' }}
									key={'payments'}
									label="Paiements">
									<div className="fiscHeader">
										<div className="fiscHeaderLeft">
											<h1 className="titlePage">Détails de clôture</h1>
											<h3 className="fiscPath">
												{' '}
												<span style={{ color: '#9A9A9A' }}>cloture/</span>
												paiements
											</h3>
										</div>
										<div
											style={{
												marginLeft: 'auto',
												marginRight: '5%',
												width: '55%',
											}}>
											<h3 className="rowInfoTitle">Informations détaillées</h3>
											<div className="gridRowInfo">
												<DetailedInformations
													rowInfo={rowInfoData}
													columns={columns}
												/>
											</div>
										</div>
									</div>
									<div className="containerTable">
										<TableCloturePaiements data={paymentsInfo} />
									</div>
								</Tab>
							</Tabs>
						</div>
						<div
							id="fisc-loader-overlay"
							className={classnames({ hidden: !isLoading })}>
							<div class={classnames('pulse', { hidden: !isLoading })}>
								<img
									alt="loader L'addition"
									src={logoLoader}
									width="150px"
									height="150px"
								/>
							</div>
						</div>
					</div>
				</div>
			</MuiThemeProvider>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(fiscApiActions, dispatch),
	};
}

function mapStateToProps(state) {
	return {
		entities: state.entities,
		user: state.auth.user,
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DetailsCloture)
);
