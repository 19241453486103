import React from 'react';  import PropTypes from 'prop-types';
import {map} from '../../../utils/utils';
import Item from './Item';
import '../css/faqItem.css';
// import {TransitionGroup} from 'react-transition-group';

const FaqItem = ({faqItem}) => {
  const title = Object.keys(faqItem)[0];
  const getItem = (item) => <Item key={item.trigger} item={item}/>;

  return (
    <ul id={title} className='cd-faq-group'>
      <li key={title} className='cd-faq-title'>
        <h2>{title}</h2>
      </li>
      {/*<TransitionGroup*/}
        {/*transitionName='example'*/}
        {/*transitionEnterTimeout={500}*/}
        {/*transitionLeaveTimeout={500}>*/}
        {map(getItem)(faqItem[title])}
      {/*</TransitionGroup>*/}
    </ul>
  );
};

FaqItem.propTypes = {
  faqItem: PropTypes.object.isRequired
};

export default FaqItem;
