import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";
import NumberFormat from "react-number-format";

class TableCloturesTVA extends Component {
  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem]);
        dataArray[dataArray.length - 1].type = elem;
      });
    }
    let columnsTable = [
      {
        Header: "Taux",
        accessor: "type",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      {
        Header: "Total TTC",
        accessor: "amount_total_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total HT",
        accessor: "amount_total_evat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },

      {
        Header: "Total TVA",
        accessor: "amount_tax",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      }
    ];

    let columnsTableExport = [
      { label: "Taux", key: "type" },
      { label: "Total TTC", key: "amount_total_ivat" },
      { label: "Total HT", key: "amount_total_evat" },

      { label: "Total TVA", key: "amount_tax" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        element.type = element.type * 100 + "%";
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={5}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-cloturesTVA.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableCloturesTVA;
