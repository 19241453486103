import React, { useEffect, useState } from 'react';
import '../sass/dataExport.css';
import HeaderExport from './HeaderExport';
import { I18n } from 'react-redux-i18n';
import Drawer from '@material-ui/core/Drawer';
//import InputBase from '@material-ui/core/InputBase';
import DatePicker from '../../BarDistrib/components/DatePicker/DatePicker';
import ReactTable from 'react-table';
import Input from '../../../components/Input/Input';
import { Button } from '@material-ui/core';
import VatSettings from './NewVatSettings';

import { connect } from 'react-redux';
import CallApi from '../../../utils/CallApi';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';

import { vatExportLabels } from '../constant';
import moment from 'moment';

const orderTypes = [
	{ label: 'Sur place', type: 1 },
	{
		label: 'À emporter',
		type: 2,
	},
	{
		label: 'Livraison',
		type: 3,
	},
];

function ExportVat({ companies, user, addFlashMessage }) {
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [selectedOrderTypeAway, setSelectedOrderTypeAway] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [dateBegin, setDateBegin] = useState(
		moment().subtract(7, 'days').toDate()
	);
	const [dateBeginLabel, setDateBeginLabel] = useState(
		moment().subtract(7, 'days').format('DD-MM-YYYY')
	);
	const [dateEnd, setDateEnd] = useState(moment().toDate());
	const [dateEndLabel, setDateEndLabel] = useState(
		moment().format('DD-MM-YYYY')
	);
	const [datas, setDatas] = useState([]);
	const [orderLabels, setOrderLabels] = useState('orderLines');
	const [userEmail, setUserEmail] = useState(user.email);
	const [dataLoading, setDataLoading] = useState(false);
	const [exportRoute, setExportRoute] = useState('exportVat/getExportDatas');
	const [openDrawer, setOpenDrawer] = useState(false);
	const [needToReload, setNeedToReload] = useState(false);
	useEffect(() => {
		getDataExport();
	}, [
		dateBegin,
		dateEnd,
		exportRoute,
		selectedOrderTypeAway,
		selectedCompanies,
		needToReload,
	]);

	const onDatePickerValidate = params => {
		if (
			moment(dateBegin).format('DD-MM-YYYY') !==
				moment(params.from).format('DD-MM-YYYY') ||
			moment(dateEnd).format('DD-MM-YYYY') !==
				moment(params.enteredTo).format('DD-MM-YYYY')
		) {
			setDateBeginLabel(params.fromLabel);
			setDateBegin(params.from);
			setDateEndLabel(params.toLabel);
			setDateEnd(params.enteredTo);
			setOpenDatePicker(false);
		}
	};

	const formateDate = (data, formatDisplay) => {
		return moment(data).format(formatDisplay);
	};

	const getDataExport = async () => {
		const accessToken = window.localStorage.getItem('accessToken');
		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;
		setDataLoading(true);
		try {
			const dataToExport = await CallApi(
				'GET',
				exportRoute,
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: tmpCompanies.map(c => c.id_company),
					limit: true,
				},
				`accessToken ${accessToken}`
			);

			if (dataToExport && dataToExport.length > 0 && dataToExport[0].success) {
				setDatas(dataToExport[0].datas);
				setDataLoading(false);
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const exportDataCsv = async () => {
		const accessToken = window.localStorage.getItem('accessToken');

		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;

		try {
			const exportCsv = await CallApi(
				'GET',
				'exportVat/exportVatCsv',
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: [945], //tmpCompanies.map(c => c['id_company']),
					email: userEmail,
					limit: false,
				},
				`accessToken ${accessToken}`
			);

			if (exportCsv && exportCsv.length > 0 && exportCsv[0].success) {
				addFlashMessage({
					type: 'success',
					text: I18n.t('lneExport.exportProcessing'),
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const onEmailChange = e => {
		e.preventDefault();
		setUserEmail(e.target.value);
	};

	const handleOpenDatePicker = () => {
		setOpenDatePicker(!openDatePicker);
	};

	const handleCompanyChanges = async c => {
		if (JSON.stringify(c) !== JSON.stringify(selectedCompanies)) {
			setSelectedCompanies(c);
		}
	};

	const handleRouteChanges = r => {
		if (r !== exportRoute) {
			setExportRoute(r);
		}
	};

	const handleCloseDatePicker = c => {
		setOpenDrawer(false);

		if (c) {
			setNeedToReload(!needToReload);
		}
	};

	const columnsFirstLevel = vatExportLabels[orderLabels];

	return (
		<div className="vat-export-main">
			<div className="export-header">
				<h3>Export écriture comptable</h3>
			</div>
			<div className="export-filters-container">
				<HeaderExport
					companies={companies}
					selectedCompanies={selectedCompanies}
					onDatePickerClick={handleOpenDatePicker}
					onCompaniesChange={c => handleCompanyChanges(c)}
					dateBegin={dateBegin}
					dateBeginLabel={dateBeginLabel}
					dateEnd={dateEnd}
					dateEndLabel={dateEndLabel}
				/>
				<div>
					<button
						className="export-settings-btn"
						onClick={() => {
							setOpenDrawer(true);
						}}>
						Réglages
					</button>
				</div>
			</div>
			<div className="export-visualisation-container">
				<span className="export-visualisation-title"> Prévisualisation </span>
				<ReactTable
					columns={columnsFirstLevel}
					showPagination={false}
					data={datas && datas.length > 0 ? datas : []}
					loadingText={I18n.t('fisc.table.loadingText')}
					noDataText={dataLoading ? '' : I18n.t('fisc.table.noDataText')}
					loading={dataLoading}
					resizable={true}
					sortable={false}
					className="visualisation-table"
				/>
				<DatePicker
					open={openDatePicker}
					anchorEl={anchorEl}
					onValidate={params => onDatePickerValidate(params)}
				/>
			</div>
			<div className="export-sender-container">
				<Input
					label={I18n.t('lneExport.emailHint')}
					type="email"
					name="email"
					required="required"
					idInput="email-input"
					value={userEmail}
					onChange={onEmailChange}
				/>
				<Button
					disabled={datas && datas.length > 0 ? false : true}
					variant="contained"
					className={
						datas && datas.length > 0 ? 'btn-export' : 'btn-export disabled'
					}
					onClick={() => {
						exportDataCsv();
					}}>
					Exporter
				</Button>
			</div>
			<Drawer
				anchor="right"
				open={openDrawer}
				classes={{
					root: 'export-drawer',
					paper: 'export-drawer-paper',
				}}
				onClose={handleCloseDatePicker}>
				<div className="export-setting-container">
					<VatSettings onCloseDrawer={handleCloseDatePicker} />
				</div>
			</Drawer>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		companies: state.entities.informations.undefined.companies,
		user: state.auth.user,
	};
}
export default connect(mapStateToProps, { addFlashMessage })(ExportVat);
