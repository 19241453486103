import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as fiscApiActions from "../actions/fiscApiActions";
import "../css/fisc.css";
import moment from "moment";
import axios from "axios";
import config from "../../../utils/config";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";

import Header from "../../../components/Header/Header";
import { addFlashMessage } from "../../../common/flashMessage/actions/flashMessages";

import DatePicker from "react-date-picker";
import "react-toggle/style.css";
import { MuiThemeProvider } from "material-ui";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import { cleanData } from "../helpers/clotures";
import TableCloture from "../components/Tables/TableClotures";
import { withRouter } from "react-router";
import { browserHistory } from "react-router";
import KeyboardArrowDown from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import { generateAccessToken } from "../../../common/login/actions/authActions";
import "../fisc.css";

class FiscPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endDate: moment()
        .endOf("month")
        .toDate(),
      startDate: moment()
        .startOf("month")
        .toDate(),
      dataClotures: {},
      errors: [],
      open: false,
      type: 7,
      loadingErrors: false,
      isLoading: false,
      counter: "",
      predefinedDates: {
        [moment().format("YYYY")]: [
          moment().startOf("year"),
          moment().endOf("year")
        ],
        [moment()
          .subtract(1, "year")
          .format("YYYY")]: [
          moment()
            .startOf("year")
            .subtract(1, "year"),
          moment()
            .endOf("year")
            .subtract(1, "year")
        ],
        [moment()
          .subtract(2, "year")
          .format("YYYY")]: [
          moment()
            .startOf("year")
            .subtract(2, "year"),
          moment()
            .endOf("year")
            .subtract(2, "year")
        ],
        [moment()
          .startOf("year")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month"),
          moment()
            .startOf("year")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(1, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(1, "month"),
          moment()
            .startOf("year")
            .add(1, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(2, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(2, "month"),
          moment()
            .startOf("year")
            .add(2, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(3, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(3, "month"),
          moment()
            .startOf("year")
            .add(3, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(4, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(4, "month"),
          moment()
            .startOf("year")
            .add(4, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(5, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(5, "month"),
          moment()
            .startOf("year")
            .add(5, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(6, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(6, "month"),
          moment()
            .startOf("year")
            .add(6, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(7, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(7, "month"),
          moment()
            .startOf("year")
            .add(7, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(8, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(8, "month"),
          moment()
            .startOf("year")
            .add(8, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(9, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(9, "month"),
          moment()
            .startOf("year")
            .add(9, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(10, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(10, "month"),
          moment()
            .startOf("year")
            .add(10, "month")
            .endOf("month")
        ],
        [moment()
          .startOf("year")
          .add(11, "month")
          .format("MM")]: [
          moment()
            .startOf("year")
            .startOf("month")
            .add(11, "month"),
          moment()
            .startOf("year")
            .add(11, "month")
            .endOf("month")
        ]
      },
      monthNames: {
        "01": "Janvier " + moment().format("YYYY"),
        "02": "Février " + moment().format("YYYY"),
        "03": "Mars " + moment().format("YYYY"),
        "04": "Avril " + moment().format("YYYY"),
        "05": "Mai " + moment().format("YYYY"),
        "06": "Juin " + moment().format("YYYY"),
        "07": "Juillet " + moment().format("YYYY"),
        "08": "Août " + moment().format("YYYY"),
        "09": "Septembre " + moment().format("YYYY"),
        "10": "Octobre " + moment().format("YYYY"),
        "11": "Novembre " + moment().format("YYYY"),
        "12": "Décembre " + moment().format("YYYY")
      }
    };

    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.goToDetails = this.goToDetails.bind(this);
    this.onClickCheck = this.onClickCheck.bind(this);
  }

  handleChangeType = async (event, index, value) => {
    await this.setState({ type: value });
    this.handleSubmitSearch();
  };

  changeDateStartDate = async date => {
    await this.setState({
      startDate: date
    });
    this.handleSubmitSearch();
  };

  changeEndDate = async date => {
    await this.setState({ endDate: date });
    this.handleSubmitSearch();
  };

  async handleSubmitSearch() {
    let { startDate, endDate } = this.state;
    let sessionStorage = window.sessionStorage;

    sessionStorage.setItem("startDate", startDate);
    sessionStorage.setItem("endDate", endDate);

    this.setState({ isLoading: true });
    //changement de format car celui retourné par react-date-picker est different de celui en base
    startDate = moment(startDate).startOf("day").format("YYYY-MM-DD H:mm:ss");
    endDate = moment(endDate).endOf("day").format("YYYY-MM-DD H:mm:ss");
    
    await this.props.actions.resetClotures(this.props.entities);
    await this.props.actions.fetchClotures(startDate, endDate).then(
      res => {
        this.setState({ isLoading: false });
      },
      err => {
        this.setState({ isLoading: false });
      }
    );
    let { clotures } = this.props.entities;
    for (let element in clotures) {
      if (clotures[element].type != this.state.type && this.state.type != 7) {
        delete clotures[element];
      }
    }
    this.setState({ dataClotures: clotures });
  }

  goToDetails(rowInfo, columns) {
    //Clear de l'historique car sinon depassement de la limite de session storage
    Object.keys(window.sessionStorage).map(elem => {
      if (elem.startsWith("@@History")) {
        window.sessionStorage.removeItem(elem);
      }
    });
    browserHistory.push({
      pathname: `/administration-fiscale/clotures/details/${
        rowInfo.original.id
      }`,
      state: { rowInfo, columns }
    });
  }

  /* vérifie les signatures, si des erreurs sont présentes
            on est dirigé vers la page d'erreurs sinon on notifie juste aucune erreur
        */
  async onClickCheck(e) {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    const startDateTimestamp = moment(startDate)
      .startOf("day")
      .format("X");
    const endDateTimestamp = moment(endDate)
      .endOf("day")
      .format("X");

    this.setState({ isLoading: true });
    let fullEndPoint =
      config.reportingUrl +
      "api/administration-fiscale/check/fetchIdsCashRegisters";
    fullEndPoint +=
      "?startDate=" + startDateTimestamp + "&endDate=" + endDateTimestamp;
    const token = window.localStorage.getItem("accessToken");

    const ids = await axios.get(fullEndPoint, {
      headers: { Authorization: `Bearer ${token}` }
    });
    const length = ids.data.length;
    const errors = [];
    let lastSignatures = {};
    let average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
    let index = 0;
    let counter = 0;
    let timers = [];
    for (let service of ids.data) {
      let timerStart = new Date().getTime();
      generateAccessToken(token);
      index += 1;
      if (length > 0) {
        counter = Number((index / length) * 100).toFixed(2);
      }
      let time;
      if (timers.length > 0) {
        time = Math.round(average(timers) / 1000) * (ids.data.length - index);
      } else {
        if (ids.data.length < 3) {
          time = 10;
        } else {
          time = -1;
        }
      }

      await this.setState({
        loadingErrors: true,
        counter: `${counter} % - Temps restant : ${
          time == -1
            ? "En cours de calcul"
            : time > 60
            ? Math.floor(time / 60) +
              " minute(s) " +
              Math.round(time - Math.floor(time / 60) * 60) +
              " seconde(s)"
            : time + " seconde(s)"
        }`
      });
      const resService = await this.props.actions.checkSignaturePos(
        service.id_cash_register,
        lastSignatures
      );
      lastSignatures = resService.payload.lastSignatures;
      // if(!lastSignatures){
      //     lastSignatures={};
      // }
      if (resService.payload.errors) {
        errors.push(...resService.payload.errors);
      }
      let timerEnd = new Date().getTime();
      timers.push(timerEnd - timerStart);
    }
    const resServer = await this.props.actions.checkSignatureServer(
      startDateTimestamp,
      endDateTimestamp
    );
    let errorsServ = [];
    if (resServer.payload.errors) {
      errorsServ = resServer.payload.errors;
    }
    this.setState({ loadingErrors: false, counter: "" });
    if (
      (errors && errors.length > 0) ||
      (errorsServ && errorsServ.length > 0)
    ) {
      browserHistory.push({
        pathname: `/administration-fiscale/errors/`,
        state: { errors, errorsServ, startDate, endDate }
      });
    } else {
      this.setState({ isLoading: false });
      this.props.addFlashMessage({
        type: "success",
        text: "Aucune erreur détectée",
        persistent: false
      });
    }
  }

  renderPredefinedDates() {
    return Object.keys(this.state.predefinedDates)
      .sort()
      .map((date, index) => {
        let text = date;
        if (date < 13) {
          text = this.state.monthNames[date];
        } else {
          text = "Année " + text + " complète";
        }
        return (
          <MenuItem
            key={date}
            value={date}
            //onClick={() => this.changeDates(this.state.predefinedDates[date])}
            primaryText={text}
          />
        );
      });
  }

  async changeDates(dates) {
    let { startDate, endDate } = this.state;
    if (dates[0]._d > endDate) {
      await this.setState({ endDate: dates[1]._d });
      await this.setState({ startDate: dates[0]._d });
    } else if (dates[1]._d < startDate) {
      await this.setState({ startDate: dates[0]._d });
      await this.setState({ endDate: dates[1]._d });
    } else {
      await this.setState({
        startDate: dates[0]._d,
        endDate: dates[1]._d
      });
    }

    this.handleSubmitSearch();
  }

  handleChangePredefinedDate = (event, index, value) => {
    this.setState({ selectedPredefinedDate: value });
    this.changeDates(this.state.predefinedDates[value]);
  };

  async componentDidMount() {
    let sessionStorage = window.sessionStorage;
    let startDate = sessionStorage.getItem("startDate");
    let endDate = sessionStorage.getItem("endDate");

    if (startDate !== null && endDate !== null) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      await this.changeDates([{ _d: startDate }, { _d: endDate }]);
    }
    this.handleSubmitSearch();
  }

  render() {
    let clotures = this.state.dataClotures;
    // transforme l'objet reçu de normalizer en un tableau
    // d'objets, chaque objet est une ligne et transforme des données si besoin
    // ex : enum sur le type et changement formatage de dates
    let { cloturesRows, clotureKeys } = cleanData(clotures);
    cloturesRows.forEach(row => {
      row.formatStart = moment(row.start_date).format("DD-MM-YYYY H:mm:ss");
      row.formatEnd = moment(row.end_date).format("DD-MM-YYYY H:mm:ss");
    });

    let { isLoading } = this.state;
    let renderPredefinedDates = this.renderPredefinedDates();
    return (
      <MuiThemeProvider>
        <div>
          <Header title={"Administration Fiscale"} />

          <div className="fiscContainer">
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "50px"
                }}
              >
                <h1
                  style={{ marginLeft: "5%" }}
                  className="titlePageInterventionTechnique"
                >
                  Liste des clôtures
                </h1>
                <div style={{ marginLeft: "auto", marginRight: "30px" }}>
                  <button
                    onClick={this.handleSubmitSearch}
                    className="btn btn-fisc"
                  >
                    Actualiser{" "}
                  </button>
                  <button className="btn btn-fisc" onClick={this.onClickCheck}>
                    Vérifier les erreurs de signatures
                  </button>
                </div>
              </div>

              <div
                style={{
                  paddingLeft: "5%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px"
                }}
              >
                <DatePicker
                  className="datePicker"
                  calendarClassName="datePickerCalendar"
                  onChange={this.changeDateStartDate}
                  value={this.state.startDate}
                  minDetail="decade"
                  maxDate={this.state.endDate}
                  locale="fr"
                />
                <DatePicker
                  className="datePicker"
                  onChange={this.changeEndDate}
                  value={this.state.endDate}
                  minDetail="decade"
                  minDate={this.state.startDate}
                  locale="fr"
                />

                <div>
                  <SelectField
                    value={this.state.selectedPredefinedDate}
                    onChange={this.handleChangePredefinedDate}
                    floatingLabelText={"Dates prédéfinies"}
                    floatingLabelStyle={{ left: "10px" }}
                    style={{ marginLeft: "30px", marginBottom: "25px" }}
                    dropDownMenuProps={{
                      iconButton: <KeyboardArrowDown />,
                      iconStyle: { fill: "black" }
                    }}
                  >
                    {this.renderPredefinedDates()}
                  </SelectField>
                  <SelectField
                    value={this.state.type}
                    onChange={this.handleChangeType}
                    floatingLabelText={"Type de clôtures"}
                    floatingLabelStyle={{ left: "10px" }}
                    style={{ marginLeft: "30px" }}
                    dropDownMenuProps={{
                      iconButton: <KeyboardArrowDown />,
                      iconStyle: { fill: "black" }
                    }}
                  >
                    <MenuItem value={7} primaryText="Toutes" />
                    <MenuItem value={1} primaryText="Clôtures journalières" />
                    <MenuItem value={3} primaryText="Clôtures mensuelles" />
                    <MenuItem value={5} primaryText="Clôtures annuelles" />
                    <MenuItem
                      value={2}
                      primaryText="Clôtures journalières cumulées"
                    />
                    <MenuItem
                      value={4}
                      primaryText="Clôtures mensuelles cumulées"
                    />
                    <MenuItem
                      value={6}
                      primaryText="Clôtures annuelles cumulées"
                    />
                  </SelectField>
                </div>
              </div>
            </div>
            <div id="tableClotures">
              <section
                id="section-content"
                style={{
                  marginLeft: "50px",
                  marginTop: "50px",
                  marginRight: "50px",
                  padding: "0"
                }}
                className="check-tracking"
              >
                <TableCloture
                  goToDetails={this.goToDetails}
                  data={cloturesRows}
                  columns={clotureKeys}
                />
              </section>
              <div
                id="fisc-loader-overlay"
                className={classnames({ hidden: !isLoading })}
              >
                <div class={classnames("pulse", { hidden: !isLoading })}>
                  <img
                    alt="loader L'addition"
                    src={logoLoader}
                    width="150px"
                    height="150px"
                  />
                </div>

                {this.state.loadingErrors ? (
                  <h2
                    style={{
                      textAlign: "center",
                      color: "white",
                      position: "absolute",
                      width: "100%",
                      top: "60%",

                      fontSize: "36px"
                    }}
                  >
                    {this.state.counter}
                  </h2>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch),
    addFlashMessage: message => {
      dispatch(addFlashMessage(message));
    }
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FiscPage)
);
