import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { logout, setCurrentUser } from '../common/login/actions/authActions';
import '../css/NavigationBar.css';
import MediaQuery from 'react-responsive';
import logo from '../img/logo_symbol.png';
import logo_plein from '../img/logo_plein.png';
import NotificationCenter from '../common/notification/containers/NotificationCenter';
import { addNotification } from '../common/notification/actions/notification';
import imgUser from '../img/userborder.svg';
import giftImg2 from '../img/gift_.png';
import classnames from 'classnames';
import Category from '../module/Category/containers/Category';
import config from '../utils/config';
import ReactGA from 'react-ga';
import { I18n } from 'react-redux-i18n';
import {
	HelpImg,
	GiftImg,
	HelpImgMobile,
	GiftImgMobile,
	Home,
} from '../components/Svg/Svg';
import axios from 'axios';
import ReactFlagsSelect from '../module/Lang-switch/';
import '../module/Lang-switch/react-flags-select.css';
import { setLocale } from 'react-redux-i18n';
import CallApi from '../utils/CallApi';
import FeatureFlag from '../components/FeatureFlag';
import ConnectAs from './ConnectAs';
import { MenuSandwichIcon } from '../front/NewFrontNavigation/assets/MenuSvg';
import A_B_Tester from '../components/A_B_Tester';
import { setOpenDrawer } from './NewFrontNavigation/actions/index';
import { subUserTools } from '@capps/subuser-tools';

class NavigationBarFront extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: 'close',
			showMenuIcon: props.showMenuIcon,
			currentPathname: window.location.pathname,
			hasEspaceClientGroup: false,
		};

		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.redirect = this.redirect.bind(this);
	}

	componentDidMount() {
		this.checkEspaceClientGroup();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.user !== this.props.auth.user) {
			this.checkEspaceClientGroup();
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			showMenuIcon: nextProps.showMenuIcon,
		});
	}

	onSelectFlag = countryCode => {
		const locale = countryCode;
		this.props.setLocale(countryCode);

		CallApi(
			'PUT',
			'user/local',
			'',
			{
				local: locale,
			},
			`accessToken ${window.localStorage.accessToken}`
		).then(() => {
			window.location.reload();
		});
	};

	toggleMenu(e) {
		e.preventDefault();
		let tmpMenuOpen = '';
		if (this.state.menuOpen === 'open') {
			tmpMenuOpen = 'close';
			document.getElementsByClassName('content-container')[0].className =
				'content-container';
			this.setState({ menuOpen: tmpMenuOpen });
		}
		if (this.state.menuOpen === 'close' || this.state.menuOpen === '') {
			tmpMenuOpen = 'open';
			document.getElementsByClassName('content-container')[0].className =
				'content-container hidden';
		}
		this.setState({ menuOpen: tmpMenuOpen });
	}

	redirect() {
		if (this.state.menuOpen === 'open') {
			this.closeMenu();
		}
	}

	logout(e) {
		e.preventDefault();
		if (window.localStorage.viewAsCustomerToken) {
			window.localStorage.removeItem('viewAsCustomerToken');
			document.location.reload();
		} else if (window.localStorage.fiscAccessToken) {
			window.localStorage.removeItem('fiscAccessToken');
			window.localStorage.removeItem('accessToken');
			this.props.setCurrentUser({});
			this.context.router.push('/fisc-connect-as');
		} else {
			this.props.logout();
			window.location.replace(config.authServerUrl + 'logout');
		}
	}

	closeMenu() {
		this.setState({ menuOpen: 'close' });
	}

	checkEspaceClientGroup() {
		const user = this.props.auth.user;
		if (user && user.groups) {
			const hasEspaceClientGroup = user.groups.some(group => group.name === 'espace_client');
			this.setState({ hasEspaceClientGroup });
		}
	}

	redirectToEC(e) {
		e.preventDefault();

		const typeUser = this.props.auth.user.type;

		const urlAuth = config.authServerUrl;
		const urlEspaceClient = config.urlEspaceClient;

		if (typeUser == "user_laddition") {
			window.location.href = urlEspaceClient;
		} else {
			window.location.href = urlAuth;
		}
	}

	render() {
		const { isAuthenticated, user } = this.props.auth;
		let isSubUser = false;

		try {
      isSubUser = subUserTools.isSubUserAccount(user);
		} catch(e) {
		  const accessToken = window.localStorage.getItem("accessToken");
			isSubUser = subUserTools.isSubUserAccount(accessToken);
		}
		const { hasEspaceClientGroup } = this.state;

		let newVideoModule = false;

		if (user && user.groups && Array.isArray(user.groups)) {
			const isSuiteClient = user.groups.some(
				group => group.name === 'laddition-suite'
			);
			newVideoModule =
				isSuiteClient && window.location.pathname === '/videos-suite';
		}

		if (config.useReactGa && isAuthenticated) {
			ReactGA.set({ username: user.username });
		}
		const btnModalClassname =
			'button-default show-notifications ' + 'js-show-notifications';
		const userLinks = (
			<ul className="nav navbar-nav navbar-right">
				{hasEspaceClientGroup && (
					<li className="nav-item marginTop6">
						<button className="">
							<div id="btn-ec" onClick={(e) => this.redirectToEC(e)}>
								<Home />
							</div>
						</button>
					</li>
				)}
				<li className="nav-item marginTop6">
					<button className={btnModalClassname}>
						<div id="help-btn-video-ctn" onClick={this.props.openHelpModal}>
							<HelpImg />
						</div>
					</button>
				</li>
				{this.props.i18n.locale.includes('fr') ? (
					<li className="nav-item marginTop6">
						<div className="dropdown show-notifications">
							<button
								className="dropdown-toggle sponsorship-setting-dropdown"
								type="button"
								id="dropdownMenusponsorship"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="true">
								<GiftImg />
							</button>
							<ul
								className="dropdown-menu"
								aria-labelledby="dropdownMenusponsorship"
								id="sponsorship-setting-dropdown">
								<li>
									<a
										target="_blank"
										href="https://www.laddition.com/fr/parrainez-un-confrere">
										{I18n.t('front.navigationBarFront.sponsorship')}
									</a>
								</li>
							</ul>
						</div>
					</li>
				) : null}

				<li className="nav-item marginTop6">
					<NotificationCenter />
				</li>
				<li className="nav-item marginTop6">
					<ReactFlagsSelect
						countries={['fr-FR', 'es-ES', 'ca-ES']}
						//customLabels={{ FR: "fr-FR", ES: "ES-es" }}
						defaultCountry={this.props.i18n.locale}
						showSelectedLabel={false}
						onSelect={this.onSelectFlag}
					/>
				</li>
				<li className="nav-item user-item">
					<div className="dropdown">
						<button
							className="dropdown-toggle user-setting-dropdown button-user"
							type="button"
							id="dropdownMenu1"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="true">
							<img
								className="arrow-down"
								src={imgUser}
								alt={imgUser}
								width="25px"
								height="27px"
							/>
							<div className="underlineHover">
								<span className="hidden-md-down">
									{!isSubUser && window.localStorage.viewAsCustomerToken
										? I18n.t('front.navigationBarFront.connectAs')
										: null}
									{!isSubUser && window.localStorage.fiscAccessToken
										? I18n.t('front.navigationBarFront.connectAsFisc')
										: null}
									{ !isSubUser && <ConnectAs />}
									{user.username}
								</span>
							</div>
							&nbsp;
						</button>
						<ul
							className="dropdown-menu"
							aria-labelledby="dropdownMenu1"
							id="user-setting-dropdown">
						  {!isSubUser && <>
  							<li>
  								<Link to="/account/migration">
  									{I18n.t('front.navigationBarFront.changeUserLogin')}
  								</Link>
  							</li>
  							<li>
  								<a href={config.authServerUrl + 'reset-password'}>
  									{I18n.t('front.navigationBarFront.changeUserPassword')}
  								</a>
  							</li>

  							<A_B_Tester
  								featureFlag="export_product_lines"
  								FeatureFlagComponent={
  									<React.Fragment>
  										<li>
  											<a href="#" onClick={this.props.openPaymentExportModal}>
  												{I18n.t('front.navigationBarFront.exporPaymentProduct')}
  											</a>
  										</li>
  									</React.Fragment>
  								}
  							/>
              </>}
							<li role="separator" className="divider" />

							<React.Fragment>
								{/*this.props.i18n.locale.includes('fr') ? (
									<li>
										<a href="#" onClick={this.props.openFiscalModal}>
											{I18n.t('front.navigationBarFront.downloadFiscal')}
										</a>
									</li>
								) : null}
								{this.props.i18n.locale.includes('fr') ? (
									<li>
										<a href="#" onClick={this.props.openCheckFiscal}>
											{I18n.t('front.navigationBarFront.checkFiscal')}
										</a>
									</li>
								) : null*/}
								{
									<li>
										<a href="#" onClick={this.props.openVersionModal}>
											{I18n.t('front.navigationBarFront.versionInfos')}
										</a>
									</li>
								}
								<li role="separator" className="divider" />
							</React.Fragment>

							{/*Modifier le label dans fichier i18n fr et ajouter le href vers le module de configuration*/}
							{!isSubUser &&
  							<A_B_Tester
  								featureFlag="espace_client"
  								FeatureFlagComponent={
  									<React.Fragment>
  										<li>
  											<a
  												href={config.onboardingUrl}>
  												{I18n.t('front.navigationBarFront.customerSettings')}
  											</a>
  										</li>
  										<li role="separator" className="divider" />
  									</React.Fragment>
  								}
  							/>
							}
							<li>
								<a href="#" onClick={this.logout.bind(this)}>
									{I18n.t('front.navigationBarFront.logout')}
								</a>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		);
		const loginUrl = config.authServerUrl + '?scope=' + config.scope;
		const guestLinks = (
			<ul className="nav navbar-nav navbar-right">
				<li>
					<a href={loginUrl}>{I18n.t('front.navigationBarFront.login')}</a>
				</li>
			</ul>
		);

		let menuIcon = (
			<div
				id="menu-icon"
				className={classnames('', {
					'menu-icon-open': this.state.menuOpen === 'open',
				})}
				onClick={this.toggleMenu}>
				<div
					className={classnames(
						'stick stick-1',
						{ 'stick-open': this.state.menuOpen === 'open' },
						{ 'stick-close': this.state.menuOpen === 'close' }
					)}>
					{}
				</div>
				<div
					className={classnames(
						'stick stick-2',
						{ 'stick-open': this.state.menuOpen === 'open' },
						{ 'stick-close': this.state.menuOpen === 'close' }
					)}>
					{}
				</div>
				<div
					className={classnames(
						'stick stick-3',
						{ 'stick-open': this.state.menuOpen === 'open' },
						{ 'stick-close': this.state.menuOpen === 'close' }
					)}>
					{}
				</div>
				<div
					className={classnames(
						'stick stick-4',
						{ 'stick-open': this.state.menuOpen === 'open' },
						{ 'stick-close': this.state.menuOpen === 'close' }
					)}>
					{}
				</div>
			</div>
		);
		return (
			<div>
				<div
					id={newVideoModule ? 'header-navbar-hidden' : 'header-navbar'}
					className={classnames('header', {
						'header-menu-open zindex101': this.state.menuOpen === 'open',
					})}>
					<nav className="navbar navbar-default">
						<div className="container-fluid flexBox">
							<div className="navbar-header">
								<MediaQuery maxDeviceWidth={764}>
									<div
										onClick={() => {
											this.props.setOpenDrawer(!this.props.openDrawer);
										}}>
										<MenuSandwichIcon />
									</div>
								</MediaQuery>
								<div className="mobile-header">
									<MediaQuery maxDeviceWidth={764}>
										<a href="/">
											<img
												id="logo-header"
												alt="laddition"
												src={logo}
												height="48px"
												width="48px"
												onClick={this.redirect}
											/>
										</a>
									</MediaQuery>
									<div
										id="mobile-help-btn"
										className="navbar-toggle collapsed iconMargin marginTop6">
										<div
											id="help-btn-video-ctn"
											onClick={this.props.openHelpModal}>
											<HelpImgMobile />
										</div>
									</div>
									<div
										id="mobile-notification-btn"
										className="navbar-toggle collapsed iconMargin marginTop6">
										<NotificationCenter />
									</div>
									<div
										id="mobile-notification-btn"
										className="navbar-toggle collapsed iconMargin marginTop6">
										<ReactFlagsSelect
											countries={['fr-FR', 'es-ES', 'ca-ES']}
											//customLabels={{ FR: "fr-FR", ES: "ES-es" }}
											defaultCountry={this.props.i18n.locale}
											showSelectedLabel={false}
											onSelect={this.onSelectFlag}
										/>
									</div>
									<div>
										<button
											className="dropdown-toggle user-setting-dropdown navbar-toggle collapsed"
											type="button"
											id="dropdownMenu1"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="true">
											<img
												className="arrow-down"
												src={imgUser}
												alt={imgUser}
												width="21px"
												height="22px"
											/>
										</button>
										<ul
											className="dropdown-menu dropdown-menu-mobile"
											aria-labelledby="dropdownMenu1"
											id="user-setting-dropdown">
											<li style={{ textAlign: 'center' }}>
												<span>{user.username}</span>
											</li>
											<li role="separator" className="divider" />
											<li>
												<Link to="/account/migration">
													{I18n.t('front.navigationBarFront.changeUserLogin')}
												</Link>
											</li>
											<li>
												<a href={config.authServerUrl + 'reset-password'}>
													{I18n.t(
														'front.navigationBarFront.changeUserPassword'
													)}
												</a>
											</li>
											<li role="separator" className="divider" />
											<li>
												<a href="#" onClick={this.logout.bind(this)}>
													{I18n.t('front.navigationBarFront.logout')}
												</a>
											</li>
										</ul>
									</div>
								</div>

								<MediaQuery minDeviceWidth={764}>
									{/* <Link to="/"> */}
									<a href="/">
										<img
											id="logo-header"
											alt="laddition"
											src={logo_plein}
											height="59px"
											width="197px"
											onClick={this.redirect}
										/>
									</a>
									<span className="header-version">
										{config.reportingClientVersion}
									</span>
									{/* </Link> */}
								</MediaQuery>
							</div>

							<div className="collapse navbar-collapse pictoHeaderContainer">
								{isAuthenticated ? userLinks : guestLinks}
							</div>
						</div>
					</nav>
				</div>
				{this.state.menuOpen === 'open' ? (
					<div className="under-menu">
						<div className="ronds">
							<svg>
								{/* cercle bleu */}
								<circle
									id="oval1"
									cx="100%"
									cy="10%"
									r="120"
									fill="#03CBFA"
								/>{' '}
								{/* cercle bleu clair*/}
								<circle
									id="oval2"
									cx="95%"
									cy="25%"
									r="10"
									fill="rgba(3,203,250, 0.5)"
								/>{' '}
								{/* cercle rouge*/}
								<circle
									id="oval5"
									cx="-10"
									cy="60%"
									r="50"
									fill="#e84249"
								/>{' '}
								{/* cercle rouge clair*/}
								<circle
									id="oval4"
									cx="5%"
									cy="70%"
									r="15"
									fill="rgba(232,66,73, 0.5)"
								/>{' '}
								<circle
									className="circleDesktop"
									id="oval7"
									cx="70%"
									cy="105%"
									r="30"
									fill="rgba(249,184,104, 0.75)"
								/>{' '}
							</svg>
						</div>
						<div className="content-container2">
							<Category closeMenu={this.closeMenu} />
						</div>
					</div>
				) : undefined}
			</div>
		);
	}
}

NavigationBarFront.propTypes = {
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
};

NavigationBarFront.contextTypes = {
	router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		auth: state.auth,
		i18n: state.i18n,
		notifications: state.entities.notifications,
		openDrawer: state.variables.openDrawer,
	};
}

export default connect(mapStateToProps, {
	logout,
	setCurrentUser,
	addNotification,
	setLocale,
	setOpenDrawer,
})(NavigationBarFront);
