import React, { Component } from "react";
import "./../module/Fisc/css/fisc.css";
import classnames from "classnames";
import logoLoader from "./.././module/Fisc/img/logo-loader.png";

class LogoLoader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="loader_fisc">
        <div className={classnames("pulse", { hidden: false })}>
          <img
            alt="loader L'addition"
            src={logoLoader}
            width="150px"
            height="150px"
          />
        </div>
        <p className="fisc_loader_label">{this.props.label}</p>
      </div>
    );
  }
}

export default LogoLoader;
