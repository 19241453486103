import React, { Component } from "react";
import PropTypes from "prop-types";
import "../css/drawer.css";
import ClassNames from "classnames";

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: props.isOpened,
      position: "right",
      noOverlay: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.isOpened });
    this.setState({ position: nextProps.position });
  }

  render() {
    return (
      <div className="drawer">
        <div
          className={ClassNames(this.state.position, {
            visible: this.state.visible
          })}
        >
          <div className="close-btn">
            <a href="#" onClick={e => this.props.clickCloseBtn(e)}>
              X
            </a>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  position: PropTypes.string
};

export default Drawer;
