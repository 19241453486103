import {
  ADD_FLASH_MESSAGE,
  DELETE_FLASH_MESSAGE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE
} from '../constant';
import {CALL_API} from 'redux-api-middleware';
import {schema, normalize} from 'normalizr';
import config from '../../../utils/config';

export function addFlashMessage(message) {
  return {
    type: ADD_FLASH_MESSAGE,
    message
  };
}

export function deleteFlashMessage(id) {
  return {
    type: DELETE_FLASH_MESSAGE,
    id
  };
}

const messageSchema = new schema.Entity('messages');
const messageListSchema = [messageSchema];

export function getMessages() {
  return {
    [CALL_API]: {
      endpoint: config.reportingUrl + 'api/messages',
      method: 'GET',
      types: [
        FETCH_MESSAGES_REQUEST,
        {
          type: FETCH_MESSAGES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json().then((json) => normalize(json.messages, messageListSchema));
            }
          }
        },
        FETCH_MESSAGES_FAILURE
      ]
    }
  };
}

