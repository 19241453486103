import React from "react";
import jwtDecode from "jwt-decode";
import { I18n } from "react-redux-i18n";
import { getRefreshToken } from "../utils/utils";

const ConnectAs = () => {
  let isConnectedAs = false;
  const refreshToken = getRefreshToken();
  try {
    if (refreshToken) {
      const decoded = jwtDecode(refreshToken);
      if (decoded.connected_as) {
        isConnectedAs = true;
      }
    }
  } catch (err) {
    console.log(err);
  }
  return (
    <React.Fragment>
      {isConnectedAs ? I18n.t("front.navigationBarFront.connectAs") : null}
    </React.Fragment>
  );
};

export default ConnectAs;
