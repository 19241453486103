import React, { Component } from 'react';
import Feed from './Feed';

class FeedContainer extends Component {
	render() {
		return <Feed name={this.props.params.name} />;
	}
}

export default FeedContainer;
