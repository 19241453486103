import {UPDATE_VIDEOSTATES_REQUEST, UPDATE_VIDEOSTATES_SUCCESS, UPDATE_VIDEOSTATES_FAILURE, FETCH_VIDEOSTATES_REQUEST, FETCH_VIDEOSTATES_SUCCESS, FETCH_VIDEOSTATES_FAILURE} from '../constant';
import {CALL_API} from 'redux-api-middleware';
import {schema, normalize} from 'normalizr';
import config from "../../../utils/config";

//const videoStatesSchema = new schema.Entity('videoStates');
const categorySchema = new schema.Entity('categories');
const categoryListSchema = [categorySchema];

const videoSchema = new schema.Entity('videos');
const videoListSchema = [videoSchema];

const messageSchema = new schema.Entity('messages');
const messageListSchema = [messageSchema];

const notificationSchema = new schema.Entity('notifications');
const notificationListSchema = [notificationSchema];

const partnersSchema = new schema.Entity('partners');
const partnersListSchema = [partnersSchema];

const partnersCategoriesSchema = new schema.Entity('partnersCategories', {
  partners: partnersListSchema
});
const partnersCategoriesListSchema = [partnersCategoriesSchema];

const informationsSchema = new schema.Entity('informations', {
  categories: categoryListSchema,
  messages: messageListSchema,
  notifications: notificationListSchema,
  partnersCategories: partnersCategoriesListSchema,
  videos: videoListSchema
});

export function updateStates(states) {
    return {
        [CALL_API]: {
            endpoint: config.reportingUrl + 'api/videos/states',
            method: 'POST',
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(states),
            types: [
                UPDATE_VIDEOSTATES_REQUEST,
                {
                    type: UPDATE_VIDEOSTATES_SUCCESS,
                    payload: (action, state, res) => {
                        const contentType = res.headers.get('Content-Type');
                        if (contentType && contentType.indexOf('json') > -1) {
                            //return res.json().then((json) => normalize(normalize(JSON.parse(json), {videos: videoListSchema}), informationsSchema))
                        }
                    }
                },
                UPDATE_VIDEOSTATES_FAILURE
            ]
        }
    }
}





export function getStates() {
    return {
        [CALL_API]: {
            endpoint: config.reportingUrl + 'api/videos/states',
            method: 'GET',
            types: [
                FETCH_VIDEOSTATES_REQUEST,
                {
                    type: FETCH_VIDEOSTATES_SUCCESS,
                    payload: (action, state, res) => {
                        const contentType = res.headers.get('Content-Type');
                        if (contentType && contentType.indexOf('json') > -1) {
                            return res.json().then((json) => normalize(normalize(JSON.parse(json), {videos: videoListSchema}), informationsSchema))
                        }
                    }
                },
                FETCH_VIDEOSTATES_FAILURE
            ]
        }
    }
}