import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import CloseModal from '../svg/CloseModal';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Filter from '../components/Filters/Filters';

import '../sass/modalExport.css';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import 'moment/locale/fr';
import Button from '@material-ui/core/Button';
import { FormControl, Input, MenuItem, Select } from '@material-ui/core';
import TableHistory from './TableHistory';
import CallApi from '../../../utils/CallApi';
import { Call } from '@material-ui/icons';
function ModalHistory({
	openModal,
	user,
	onClose,
	exportFct,
	allUsers,
	paymentTypes,
	companies,
	onError,
	onDisplayMessage,
}) {
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(moment().format('YYYYMMDD'));
	const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
	const [email, setEmail] = useState(user && user.email ? user.email : '');
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedPayments, setSelectedPayments] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [historyDatas, setHistoryDatas] = useState([]);
	const [userEmail, setUserEmail] = useState(
		user && user.email ? user.email : ''
	);
	const [searchType, setSearchType] = useState('customer_firstname');
	const [searchValue, setSearchValue] = useState('');
	const [disableBtnSearch, setDisableBtnSearch] = useState(false);

	useEffect(() => {
		getHistoryDatas();
	}, []);

	useEffect(() => {
		getHistoryDatas();
	}, [startDate, endDate]);

	useEffect(() => {
		const regex = /^[0-9]+([,.][0-9]*)?$/;

		const testIsInt = regex.test(searchValue);

		setDisableBtnSearch(
			searchType !== 'customer_firstname' && !testIsInt && searchValue !== ''
		);
	}, [searchValue]);

	const exportHistoryDatas = async () => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const exportHistory = await CallApi(
				'GET',
				'customer-account/exportCustomerHistory',
				'',
				{
					startDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
					userEmail: userEmail,
					endDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
					id_companies: JSON.stringify(
						selectedCompanies.length !== companies.length
							? selectedCompanies
							: []
					),
					idPayments: JSON.stringify(
						paymentTypes.length !== selectedPayments.length
							? selectedPayments
							: []
					),
					idUsers: JSON.stringify(
						allUsers.length !== selectedUsers.length ? selectedUsers : []
					),
					[searchType]: searchValue,
					searchCategory: searchType,
				},
				`accessToken ${accessToken}`
			).then(res => {
				if (res[0].success) {
					onDisplayMessage({
						type: 'infos',
						text: I18n.t('lneExport.exportProcessing'),
						persistent: false,
					});
				}
			});
		} catch (error) {}
	};
	const getHistoryDatas = async () => {
		try {
			setLoading(true);
			const accessToken = await window.localStorage.getItem('accessToken');
			const getUsers = await CallApi(
				'GET',
				'customer-account/getCustomerHistory',
				'',
				{
					startDate: moment(startDate).format('YYYY-MM-DD 00:00:00'),
					endDate: moment(endDate).format('YYYY-MM-DD 23:59:59'),
					userEmail: userEmail,
					id_companies: JSON.stringify(
						selectedCompanies.length !== companies.length
							? selectedCompanies
							: []
					),
					idPayments: JSON.stringify(
						paymentTypes.length !== selectedPayments.length
							? selectedPayments
							: []
					),
					idUsers: JSON.stringify(
						allUsers.length !== selectedUsers.length ? selectedUsers : []
					),
					[searchType]: searchValue,
					searchCategory: searchType,
				},
				`accessToken ${accessToken}`
			).then(function (res) {
				if (res[0].success) {
					setHistoryDatas(res[0].datas);
					setLoading(false);
				}
			});
		} catch (error) {
			console.log(error);
			onError();
		}
	};

	const onEmailChange = e => {
		e.preventDefault();
		setUserEmail(e.target.value);
	};
	const _handleStartDayChange = day => {
		if (!day) return;
		let startDate = moment(day).format('YYYYMMDD');
		setStartDate(startDate > endDate ? endDate : startDate);
		setErrorMessage('');
	};
	const _handleEndDayChange = day => {
		if (
			!day ||
			moment(startDate).format('YYYYMMDD') > moment(day).format('YYYYMMDD')
		)
			return;
		let endDate = moment(day).format('YYYYMMDD');

		let compareToStart =
			moment(startDate).format('YYYYMMDD') > moment(day).format('YYYYMMDD')
				? startDate
				: endDate;

		setEndDate(compareToStart);
		setErrorMessage('');
	};

	const _handleCompaniesChanged = async c => {
		if (JSON.stringify(c) === JSON.stringify(selectedCompanies)) return false;
		setSelectedCompanies(c);
	};
	const _handleUsersChanged = async u => {
		if (JSON.stringify(u) === JSON.stringify(selectedUsers)) return false;
		setSelectedUsers(u);
	};
	const _handlePaymentChanged = async p => {
		if (JSON.stringify(p) === JSON.stringify(selectedPayments)) return false;
		setSelectedPayments(p);
	};

	return (
		<div>
			<Modal
				classNames={{
					modal: 'export_history_modal',
					overlay: 'modal_export_history_overlay',
				}}
				open={openModal}
				showCloseIcon={false}
				closeOnOverlayClick={true}
				onClose={() => {
					if (loading) return false;
					onClose();
				}}>
				<div className="export_modal_content">
					<div
						className="close_icon"
						onClick={e => {
							if (loading) return false;
							onClose();
						}}>
						<CloseModal />
					</div>
					<h3>Historique comptes clients</h3>
					<div className="export_history_main">
						<div className="export_history_filters_input">
							<div className="filters_container">
								<div className="filters_main_date">
									<div className="input_main">
										<p>Début</p>
										<DayPickerInput
											local="fr"
											value={`${formatDate(startDate, 'L', 'fr')}`}
											formatDate={formatDate}
											parseDate={parseDate}
											placeholder={`${formatDate(startDate, 'L', 'fr')}`}
											onDayChange={_handleStartDayChange}
											dayPickerProps={{
												locale: 'fr',
												localeUtils: MomentLocaleUtils,
												modifiers: {
													disabled: [
														{
															before: moment(endDate).subtract(1, 'y').toDate(),
														},
													],
												},
											}}
										/>
									</div>

									<div className="input_main">
										<p>{I18n.t('fisc.modalFisc.end')}</p>
										<DayPickerInput
											local="fr"
											formatDate={formatDate}
											parseDate={parseDate}
											value={`${formatDate(endDate, 'L', 'fr')}`}
											placeholder={`${formatDate(endDate, 'L', 'fr')}`}
											onDayChange={_handleEndDayChange}
											dayPickerProps={{
												locale: 'fr',
												localeUtils: MomentLocaleUtils,
												fromMonth: moment(endDate).subtract(1, 'y').toDate(),
												toMonth: moment(startDate).add(1, 'y').toDate(),
												modifiers: {
													disabled: [
														{
															after: moment(startDate).add(1, 'y').toDate(),
														},
														{
															before: moment(startDate).toDate(),
														},
													],
												},
											}}
										/>
									</div>
								</div>
								<div className="filters_main">
									{companies && companies.length > 0 ? (
										<Filter
											label="Tous les établissements"
											datas={companies}
											isNumberData={false}
											multiple={true}
											monoSelect={false}
											paramsValue={'id_company'}
											paramsLabel="company_name"
											inputClassName="dataExport_filter_input"
											inputLabelClassName="dataExport_input_label"
											onClose={data => {
												if (data && data.length > 0) {
													_handleCompaniesChanged(data);
												}
											}}
										/>
									) : null}
								</div>
								<div className="filters_main">
									{allUsers && allUsers.length > 0 ? (
										<Filter
											label="Tous les utilisateurs"
											datas={allUsers.map(u => {
												return {
													label: u.user_firstname_lastname,
													user_firstname_lastname: u.user_firstname_lastname,
												};
											})}
											isNumberData={false}
											multiple={true}
											monoSelect={false}
											paramsValue={'user_firstname_lastname'}
											paramsLabel="label"
											inputClassName="dataExport_filter_input"
											inputLabelClassName="dataExport_input_label"
											onClose={data => {
												if (data && data.length > 0) {
													_handleUsersChanged(data);
												}
											}}
										/>
									) : null}
								</div>
								<div className="filters_main">
									{paymentTypes && paymentTypes.length > 0 ? (
										<Filter
											label="Tous les types de paiements"
											datas={paymentTypes.map(p => {
												return {
													label: p.payment_name,
													payment_name: p.payment_name,
												};
											})}
											monoSelect={false}
											isNumberData={false}
											multiple={true}
											paramsValue="payment_name"
											paramsLabel="label"
											inputClassName="dataExport_filter_input"
											inputLabelClassName="dataExport_input_label"
											onClose={data => {
												if (data && data.length > 0) {
													_handlePaymentChanged(data);
												}
											}}
										/>
									) : null}
								</div>
							</div>
							<div className="search_bar_container">
								<span style={{ textAlign: 'center', margin: 'auto 0' }}>
									Rechercher par :{' '}
								</span>
								<FormControl
									variant="outlined"
									classes={{
										root: 'search_form_control',
									}}>
									<Select
										id="search_select"
										value={searchType}
										onChange={e => {
											e.preventDefault();
											setSearchType(e.target.value);
											setSearchValue('');
										}}
										classes={{
											root: 'search_select_root',
											select: 'search_select',
											selectMenu: 'search_select_menu',
										}}>
										<MenuItem value="customer_firstname">Nom/Prénom</MenuItem>
										<MenuItem value="amount">Montant</MenuItem>
										<MenuItem value="balance">Solde</MenuItem>
									</Select>
								</FormControl>
								<input
									type="search"
									className="search_input"
									style={{
										border: `1px solid ${
											disableBtnSearch ? '#e84249' : '#bfbfbf'
										}`,
									}}
									value={searchValue}
									pattern="[0-9]+([,.][0-9]+)?"
									onChange={e => {
										setSearchValue(e.target.value);
									}}
								/>
								<Button
									className="search_btn"
									style={{
										backgroundColor: `${
											disableBtnSearch ? '#bfbfbf' : '#262957'
										}`,
									}}
									disabled={disableBtnSearch}
									onClick={e => {
										getHistoryDatas();
									}}>
									Valider
								</Button>
							</div>
						</div>
						<div className="export_history_table">
							{loading ? (
								<div className="export_history_label">
									<span>Chargement...</span>
								</div>
							) : historyDatas && historyDatas.length === 0 ? (
								<div className="export_history_label">
									{' '}
									<span>Aucunes données</span>
								</div>
							) : (
								<TableHistory historyDatas={historyDatas} />
							)}
						</div>
						<div className="export_history_footer">
							<Input
								label={I18n.t('lneExport.emailHint')}
								type="email"
								name="email"
								required={true}
								onChange={onEmailChange}
								value={userEmail}
								disableUnderline={true}
							/>
							<button
								onClick={e => {
									if (historyDatas && historyDatas.length > 0) {
										exportHistoryDatas();
									}
								}}>
								Exporter
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default ModalHistory;

{
}
