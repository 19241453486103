import React from 'react';

const ArrowDownSvg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13">
		<g>
			<g>
				<path
					fill="#e84249"
					d="M20 1.734c0 .464-.169.927-.507 1.28l-9.498 9.912L.497 3.012A1.868 1.868 0 0 1 .488.464 1.657 1.657 0 0 1 2.914.456l7.081 7.39 7.081-7.39a1.657 1.657 0 0 1 2.426.008c.332.352.498.811.498 1.27z"
				/>
			</g>
		</g>
	</svg>
);

export default ArrowDownSvg;
