import React from "react";
import "./style.scss";

export default function renderIcon(iconId, status) {
  const color = status === "reading" ? "#fff" : "#262957";
  switch (iconId) {
    case "video-menu":
      return (
        <svg
          width="50px"
          height="40px"
          viewBox="0 0 50 36"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="1---Formation-vidéo---Hover-Carte"
              transform="translate(-282.000000, -257.000000)"
              fill={color}
              fillRule="nonzero"
            >
              <g
                id="Carte---Icon"
                transform="translate(282.000000, 257.000000)"
              >
                <path
                  d="M47.781039,27.9970826 L46.3971236,27.9970826 C45.8629293,17.3448253 38.6813912,8.57892979 29.28236,6.50401099 L29.5575288,3.65179332 C29.647784,2.7209441 29.3674788,1.78850362 28.7899912,1.09394668 C28.2125036,0.398594393 27.3862636,0 26.5233345,0 L23.4773995,0 C22.6137365,0 21.7882301,0.39938994 21.210009,1.09394668 C20.6325214,1.78929916 20.35295,2.72173966 20.4432052,3.65179332 L20.718374,6.50401099 C11.3193426,8.57892979 4.13780457,17.3440297 3.60361026,27.9962873 L2.21969489,27.9962873 C0.99574401,27.9962873 0,29.0759131 0,30.4029701 L0,33.593317 C0,34.9203739 0.99574401,36 2.21969489,36 L47.7803052,36 C49.004256,36 50,34.9203739 50,33.593317 L50,30.4029701 C50,29.076709 49.0049898,27.9970826 47.781039,27.9970826 L47.781039,27.9970826 Z M23.3291754,3.16329643 C23.3687996,3.11635613 23.4179631,3.09248808 23.4773995,3.09248808 L26.5233345,3.09248808 C26.5820371,3.09248808 26.6319343,3.11635613 26.6715586,3.16329643 C26.7111829,3.21103229 26.72806,3.26751977 26.7214557,3.33037186 L26.4550924,6.09268704 C25.9744645,6.05768074 25.4894336,6.03779086 25.0007338,6.03779086 C24.5113002,6.03779086 24.0270033,6.05768074 23.5463753,6.09268704 L23.2792779,3.33037186 C23.2734076,3.26751977 23.2902847,3.21103229 23.3291754,3.16329643 Z M47.1485179,32.9083075 L2.85221607,32.9083075 L2.85221607,31.0887756 L8.86116819,31.0887756 C9.64925164,31.0887756 10.2876431,30.3966053 10.2876431,29.5429291 C10.2876431,28.689253 9.64925164,27.9970826 8.86116819,27.9970826 L6.46096267,27.9970826 C7.07000298,17.4824636 15.1518933,9.13027913 25,9.13027913 C34.8488407,9.13027913 42.930731,17.4824636 43.539771,27.9970826 L15.4960377,27.9970826 C14.7079542,27.9970826 14.0702965,28.689253 14.0702965,29.5429291 C14.0702965,30.3966053 14.7079542,31.0887756 15.4960377,31.0887756 L47.1485179,31.0887756 L47.1485179,32.9083075 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      );
    case "video-print":
      return (
        <svg
          width="28px"
          height="40px"
          viewBox="0 0 28 38"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="1---Formation-vidéo---Hover-Carte"
              transform="translate(-1118.000000, -260.000000)"
            >
              <g id="Group" transform="translate(200.000000, 186.000000)">
                <g id="Print" transform="translate(918.000000, 74.000000)">
                  <path
                    d="M25,15 C26.6568542,15 28,16.3431458 28,18 L28,35 C28,36.6568542 26.6568542,38 25,38 L3,38 C1.34314575,38 2.02906125e-16,36.6568542 0,35 L0,18 C-2.02906125e-16,16.3431458 1.34314575,15 3,15 L25,15 Z M23,18 L5,18 C3.8954305,18 3,18.8954305 3,20 L3,20 L3,33 C3,34.1045695 3.8954305,35 5,35 L5,35 L23,35 C24.1045695,35 25,34.1045695 25,33 L25,33 L25,20 C25,18.8954305 24.1045695,18 23,18 L23,18 Z"
                    id="Combined-Shape"
                    fill={color}
                  ></path>
                  <path
                    d="M22,0 C23.6568542,1.39730022e-16 25,1.34314575 25,3 L25,18 L3,18 L3,3 C3,1.34314575 4.34314575,7.48448398e-16 6,0 L22,0 Z M21,3 L7,3 C6.44771525,3 6,3.44771525 6,4 L6,4 L6,15 L22,15 L22,4 C22,3.44771525 21.5522847,3 21,3 L21,3 Z"
                    id="Combined-Shape-Copy"
                    fill={color}
                  ></path>
                  <circle
                    id="Oval"
                    fill={color}
                    cx="21"
                    cy="31"
                    r="2"
                  ></circle>
                  <circle
                    id="Oval-Copy-4"
                    fill={color}
                    cx="16"
                    cy="31"
                    r="2"
                  ></circle>
                  <line
                    x1="9"
                    y1="12"
                    x2="17"
                    y2="12"
                    id="Path-2-Copy"
                    stroke={color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></line>
                  <line
                    x1="9"
                    y1="7"
                    x2="17"
                    y2="7"
                    id="Path-2-Copy-2"
                    stroke={color}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  ></line>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case "video-service":
      return (
        <svg
          width="44px"
          height="40px"
          viewBox="0 0 44 36"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="1---Formation-vidéo---Hover-Carte"
              transform="translate(-616.000000, -261.000000)"
              fill={color}
              stroke={color}
            >
              <g id="Group" transform="translate(200.000000, 186.000000)">
                <g
                  id="icon--service-ON-2"
                  transform="translate(417.000000, 75.000000)"
                >
                  <g
                    id="porte-ouverte"
                    transform="translate(13.846859, 18.767210)"
                  >
                    <g
                      id="left"
                      transform="translate(7.038552, 8.000000) scale(-1, 1) translate(-7.038552, -8.000000) translate(0.538552, 0.000000)"
                    >
                      <path
                        d="M2.51253342,3.00679233 L2.51253342,13.7971738 L2.51253342,13.7971738 L10.4347736,13.7971738 L10.4347736,3.00679233 C10.4347736,2.45450758 9.9870584,2.00679233 9.43477365,2.00679233 L3.51253342,2.00679233 C2.96024867,2.00679233 2.51253342,2.45450758 2.51253342,3.00679233 Z M2.49886588,0 L10.4484412,0 C11.5530107,5.56104062e-16 12.4484412,0.8954305 12.4484412,2 L12.4484412,15.8039661 L12.4484412,15.8039661 L0.498865879,15.8039661 L0.498865879,2 C0.498865879,0.8954305 1.39429638,-1.91384796e-17 2.49886588,0 Z"
                        id="Rectangle-20"
                      ></path>
                    </g>
                  </g>
                  <g id="toit" transform="translate(0.000000, 0.229939)">
                    <path
                      d="M4.87940992,13.8581234 C6.439571,13.8581234 7.70433145,12.8520829 7.70433145,11.6110691 L10.4558784,2.73330485 L6.72633328,2.73330485 L2.05448839,11.6110691 C2.05448839,12.8520829 3.31924884,13.8581234 4.87940992,13.8581234 Z M4.87940992,15.6557669 C2.18458623,15.6557669 0,13.844894 0,11.6110691 L6.14830628,0.757809078 L11.9626097,0.757809078 L9.75881984,11.6110691 C9.75881984,13.844894 7.57423361,15.6557669 4.87940992,15.6557669 Z"
                      id="Combined-Shape"
                    ></path>
                    <path
                      d="M12.6466339,13.8581234 C14.206795,13.8581234 15.4715554,12.8520829 15.4715554,11.6110691 L16.6993436,2.73330485 L11.9626097,2.73330485 L9.82171234,11.6110691 C9.82171234,12.8520829 11.0864728,13.8581234 12.6466339,13.8581234 Z M12.6466339,15.6557669 C9.95181019,15.6557669 7.76722396,13.844894 7.76722396,11.6110691 L11.1228751,0.757809078 L18.7698518,0.757809078 L17.5260438,11.6110691 C17.5260438,13.844894 15.3414576,15.6557669 12.6466339,15.6557669 Z"
                      id="Oval-17"
                    ></path>
                    <path
                      d="M20.9117568,13.8581234 C22.4719179,13.8581234 23.7366783,12.8520829 23.7366783,11.6110691 L22.9033527,2.73330485 L18.9201609,2.73330485 L18.0868353,11.6110691 C18.0868353,12.8520829 19.3515957,13.8581234 20.9117568,13.8581234 Z M20.9117568,15.6557669 C18.2169331,15.6557669 16.0323469,13.844894 16.0323469,11.6110691 L17.5573815,0.757809078 L24.3125007,0.757809078 L25.7911667,11.6110691 C25.7911667,13.844894 23.6065805,15.6557669 20.9117568,15.6557669 Z"
                      id="Oval-17"
                    ></path>
                    <path
                      d="M29.1768797,13.8581234 C30.7370408,13.8581234 32.0018013,12.8520829 32.0018013,11.6110691 L29.280697,2.73330485 L25.0190025,2.73330485 L26.3519582,11.6110691 C26.3519582,12.8520829 27.6167186,13.8581234 29.1768797,13.8581234 Z M29.1768797,15.6557669 C26.482056,15.6557669 24.2974698,13.844894 24.2974698,11.6110691 L23.2979141,0.757809078 L30.4175428,0.757809078 L34.0562896,11.6110691 C34.0562896,13.844894 31.8717034,15.6557669 29.1768797,15.6557669 Z"
                      id="Oval-17"
                    ></path>
                    <path
                      d="M36.9441037,13.8581234 C38.5042648,13.8581234 39.7690252,12.8520829 39.7690252,11.6110691 L34.9305925,2.73330485 L31.7259708,2.73330485 L34.1191822,11.6110691 C34.1191822,12.8520829 35.3839426,13.8581234 36.9441037,13.8581234 Z M36.9441037,15.6557669 C34.24928,15.6557669 32.0646938,13.844894 32.0646938,11.6110691 L29.8401063,0.757809078 L35.6523709,0.757809078 L41.8235136,11.6110691 C41.8235136,13.844894 39.6389274,15.6557669 36.9441037,15.6557669 Z"
                      id="Oval-17"
                    ></path>
                  </g>
                  <path
                    d="M35.2125743,14.8162183 L36.8445239,14.8162183 L36.8445239,32.571176 C36.8445239,33.6757455 35.9490934,34.571176 34.8445239,34.571176 L24.8949486,34.571176 L24.8949486,34.571176 L24.9241002,32.5956802 L34.2125743,32.5956802 C34.764859,32.5956802 35.2125743,32.1479649 35.2125743,31.5956802 L35.2125743,14.8162183 L35.2125743,14.8162183 Z"
                    id="Path-2"
                  ></path>
                  <path
                    d="M7.83247809,32.5956802 L16.928565,32.5956802 L16.928565,32.5956802 L16.928565,34.571176 L6.97898971,34.571176 C5.87442022,34.571176 4.97898971,33.6757455 4.97898971,32.571176 L4.97898971,14.8162183 L4.97898971,14.8162183 L6.83247809,14.8162183 L6.83247809,31.5956802 C6.83247809,32.1479649 7.28019334,32.5956802 7.83247809,32.5956802 Z"
                    id="Path-3"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case "video-bar-counter":
      return (
        <svg
          width="61px"
          height="40px"
          viewBox="0 0 61 33"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="1---Formation-vidéo---Hover-Carte"
              transform="translate(-772.000000, -263.000000)"
              fill={color}
            >
              <g id="Group" transform="translate(200.000000, 186.000000)">
                <g
                  id="icon-comptoir"
                  transform="translate(572.000000, 77.000000)"
                >
                  <path
                    d="M56.5,28 L59.5,28 C60.3284271,28 61,28.6715729 61,29.5 C61,30.3284271 60.3284271,31 59.5,31 L56.5,31 C55.6715729,31 55,30.3284271 55,29.5 C55,28.6715729 55.6715729,28 56.5,28 Z"
                    id="Rectangle-3"
                  ></path>
                  <path
                    d="M1.5,28 L29.5,28 C30.3284271,28 31,28.6715729 31,29.5 C31,30.3284271 30.3284271,31 29.5,31 L1.5,31 C0.671572875,31 1.01453063e-16,30.3284271 0,29.5 C-1.01453063e-16,28.6715729 0.671572875,28 1.5,28 Z"
                    id="Rectangle-3"
                  ></path>
                  <path
                    d="M9.19881521,27.9453296 L23.8031701,27.9453296 C23.2561216,23.0356662 20.0518803,19.0747191 16.5311943,19.0747191 C13.0004272,19.0747191 9.75576972,23.0594695 9.19881521,27.9453296 Z M7.23529412,31 C6.55306001,31 6,30.294026 6,29.423163 C6,22.8470453 10.3236345,16.0200488 16.5311943,16.0200488 C22.7351086,16.0200488 27,22.8237154 27,29.423163 C27,30.294026 26.44694,31 25.7647059,31 L7.23529412,31 Z M21.8122819,10.1666728 C21.6065623,9.06325578 21.3124569,7.97482346 20.9216422,6.97598498 C19.8251665,4.17362869 18.3274811,2.78242201 16.1853693,2.78242201 C13.9969903,2.78242201 12.5127951,4.10013521 11.4304465,6.7441776 C11.0017446,7.79144257 10.6908487,8.9453768 10.4870287,10.1070961 C12.9963869,11.3344377 18.1848553,11.4254612 21.8122819,10.1666728 Z M7.60187457,11.2004812 C7.60187457,11.2004812 8.17152895,0 16.2021379,0 C24.2327468,0 24.8027007,11.7189354 24.8027007,11.7189354 C20.1110506,14.5936578 10.6581917,14.5936578 7.60187457,11.2004812 Z"
                    id="comptoir"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M32,33 C32.0233425,32.1637439 32.0577773,31.5372382 32.1033044,31.120483 C32.8117383,24.6354649 37.1012872,18.4850543 43.0326797,18.4850543 C48.9639674,18.4850543 53.203467,24.6214356 53.8992341,31.1260538 C53.9436574,31.5413606 53.9772461,32.1660093 54,33 L32,33 Z M49.9347826,9.15160442 C49.9347826,13.1021992 46.7773043,16.3032088 42.8804348,16.3032088 C38.9854783,16.3032088 35.826087,13.1021992 35.826087,9.15160442 C35.826087,5.20294907 38.9854783,2 42.8804348,2 C46.7773043,2 49.9347826,5.20294907 49.9347826,9.15160442"
                    id="Combined-Shape"
                    opacity="0.5"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case "video-seating-plan":
      return (
        <svg
          width="44px"
          height="44px"
          viewBox="0 0 44 44"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="1---Formation-vidéo---Hover-Carte"
              transform="translate(-944.000000, -257.000000)"
              fill={color}
            >
              <g id="Group" transform="translate(200.000000, 186.000000)">
                <g
                  id="Icon-plan-de-salle"
                  transform="translate(744.000000, 71.000000)"
                >
                  <path
                    d="M12.4556774,11.2608696 C11.7958032,11.2608696 11.2608696,11.7958032 11.2608696,12.4556774 L11.2608696,31.5443226 C11.2608696,32.2041968 11.7958032,32.7391304 12.4556774,32.7391304 L31.5443226,32.7391304 C32.2041968,32.7391304 32.7391304,32.2041968 32.7391304,31.5443226 L32.7391304,12.4556774 C32.7391304,11.7958032 32.2041968,11.2608696 31.5443226,11.2608696 L12.4556774,11.2608696 Z M12.7142857,9 L31.2857143,9 C33.3370576,9 35,10.6629424 35,12.7142857 L35,31.2857143 C35,33.3370576 33.3370576,35 31.2857143,35 L12.7142857,35 C10.6629424,35 9,33.3370576 9,31.2857143 L9,12.7142857 C9,10.6629424 10.6629424,9 12.7142857,9 Z"
                    id="Rectangle-4"
                    stroke={color}
                  ></path>
                  <path
                    d="M27,5.36842105 L27,6 L17,6 L17,5.36842105 C17,2.40352397 19.2385763,0 22,0 C24.7614237,0 27,2.40352397 27,5.36842105 Z"
                    id="Oval-3"
                    opacity="0.5"
                  ></path>
                  <path
                    d="M46,24.3684211 L46,25 L36,25 L36,24.3684211 C36,21.403524 38.2385763,19 41,19 C43.7614237,19 46,21.403524 46,24.3684211 Z"
                    id="Oval-3-Copy"
                    opacity="0.5"
                    transform="translate(41.000000, 22.000000) rotate(90.000000) translate(-41.000000, -22.000000) "
                  ></path>
                  <path
                    d="M8,24.3684211 L8,25 L-2,25 L-2,24.3684211 C-2,21.403524 0.238576251,19 3,19 C5.76142375,19 8,21.403524 8,24.3684211 Z"
                    id="Oval-3-Copy-3"
                    opacity="0.5"
                    transform="translate(3.000000, 22.000000) rotate(270.000000) translate(-3.000000, -22.000000) "
                  ></path>
                  <path
                    d="M27,43.3684211 L27,44 L17,44 L17,43.3684211 C17,40.403524 19.2385763,38 22,38 C24.7614237,38 27,40.403524 27,43.3684211 Z"
                    id="Oval-3-Copy-2"
                    opacity="0.5"
                    transform="translate(22.000000, 41.000000) rotate(180.000000) translate(-22.000000, -41.000000) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case "video-users": 
        return (
          <svg
          width="44px"
          height="44px"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          >
          <title>{'Icone Gestion utilisateurs'}</title>
          <g
            transform="translate(8 5)"
            stroke={color}
            strokeWidth={1.5}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M1.282 20h10.436c.792 0 1.415-.624 1.257-1.362C12.54 16.602 11.116 13 6.5 13S.46 16.602.025 18.638C-.133 19.376.49 20 1.282 20Z" />
            <circle cx={6} cy={4} r={4} transform="translate(.25)" />
          </g>
        </svg>
      );
    case "video-order":
      return (
        <div className={`order ${status === "reading" ? "color-revert" : ""}`} />
      );
    default:
      return;
  }
}
