import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";
import TableIntervTech from "../components/Tables/TableIntervTech";
import Popover from "material-ui/Popover";
import * as fiscApiActions from "../actions/fiscApiActions";
import { withRouter } from "react-router";
import Header from "../../../components/Header/Header";

class TechnicalIntervention extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      comment: "",
      dataIntervTech: {},
      isLoading: false
    };
  }

  async componentDidMount() {
    //rowInfo = info de la cloture donc avec start date et end date au bon format
    this.setState({ isLoading: true });
    await this.props.actions.fetchTechnicalInterventions();
    this.setState({ isLoading: false });
  }

  openPopover = (event, rowInfo) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      open: true,
      comment: rowInfo.original.comment,
      anchorEl:
        event.currentTarget.childNodes[
          event.currentTarget.childNodes.length - 1
        ]
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataIntervTech: nextProps.entities.technicalInterventions
    });
  }

  render() {
    let { data, dataIntervTech } = this.state;
    let { pos, server } = this.props.entities;
    let { services, clotures } = this.state;
    let idServicesErrors = [];
    let idCloturesErrors = [];
    if (pos) {
      Object.keys(pos).map(service => {
        if (typeof service != "undefined") {
          idServicesErrors.push(pos[service].id_cash_register);
        }
      });
    }

    if (server) {
      Object.keys(server).map(cloture => {
        if (typeof cloture != "undefined") {
          idCloturesErrors.push(server[cloture].id);
        }
      });
    }

    if (services && (pos || server)) {
      Object.keys(services).map(service => {
        if (idServicesErrors.includes(service) === false) {
          delete services[service];
        }
      });
    }

    if (clotures && (pos || server)) {
      Object.keys(clotures).map(cloture => {
        if (idCloturesErrors.includes(clotures[cloture].id) === false) {
          delete clotures[cloture];
        }
      });
    }

    let dataIntervTechArray = [];

    if (dataIntervTech) {
      Object.keys(dataIntervTech).map(elem => {
        dataIntervTechArray.push(dataIntervTech[elem]);
      });
    }
    let { isLoading } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          <Header title={"Interventions Techniques"} />
          <div className="fiscContainer">
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
              onRequestClose={this.handleRequestClose}
              style={{ borderRadius: "15px" }}
            >
              <div
                style={{
                  minHeight: "30px",
                  padding: "20px",
                  color: "black",
                  backgroundColor: "white"
                }}
              >
                <span style={{ fontStyle: "italic" }}>Commentaire</span> :{" "}
                {this.state.comment}
              </div>
            </Popover>
            <div className="containerTable">
              <h1 className="titlePageInterventionTechnique">
                Liste des interventions techniques
              </h1>
              <TableIntervTech data={dataIntervTechArray} />
            </div>
          </div>
          <div
            id="fisc-loader-overlay"
            className={classnames({ hidden: !isLoading })}
          >
            <div className={classnames("pulse", { hidden: !isLoading })}>
              <img
                alt="loader L'addition"
                src={logoLoader}
                width="150px"
                height="150px"
              />
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TechnicalIntervention)
);
