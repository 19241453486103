import React from "react";
import Proptypes from "prop-types";
import classnames from "classnames";
import "./EmailHint.css";
import { I18n } from "react-redux-i18n";

const EmailHint = ({ isEmail, emailIsFree, processingEmailIsFree }) => {
  return (
    <div id={"email-hint-container"}>
      <section className={"email-hint-check"}>
        <h5>{I18n.t("emailHint.important")}</h5>
        <ul>
          <li
            className={classnames({
              success: isEmail,
              error: !isEmail
            })}
          >
            {I18n.t("emailHint.inputEmail")}
          </li>
          <li
            className={classnames({
              success: emailIsFree,
              loading: processingEmailIsFree,
              error: !emailIsFree
            })}
          >
            {I18n.t("emailHint.notAssociated")}
          </li>
        </ul>
      </section>
    </div>
  );
};

EmailHint.propTypes = {
  isEmail: Proptypes.bool.isRequired,
  emailIsFree: Proptypes.bool.isRequired,
  processingEmailIsFree: Proptypes.bool.isRequired
};

EmailHint.defaultProps = {
  isEmail: false,
  emailIsFree: false,
  processingEmailIsFree: true
};

export default EmailHint;
