import React, { Component } from 'react';
import { connect } from 'react-redux';

class A_B_Tester extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { featureFlag, FeatureFlagComponent, DefaultComponent } = this.props;
		let showable = false;
		if (this.props.auth && this.props.auth.user) {
			const { groups } = this.props.auth.user;
			if (groups) {
				const testGroup = groups.find(group => group.name === featureFlag);
				showable = testGroup ? true : false;
			}
		}
		return showable ? (
			<React.Fragment>{FeatureFlagComponent}</React.Fragment>
		) : (
			<React.Fragment>{DefaultComponent}</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default connect(mapStateToProps, null)(A_B_Tester);
