import React from 'react';  import PropTypes from 'prop-types';
import {map} from '../../../utils/utils';
import FaqItem from './FaqItem';
import '../css/faqItems.css';

const FaqItems = ({faqItems}) => {

  const getFaqItem = (value) => {
    const key = Object.keys(value)[0];
    return <FaqItem key={key} faqItem={value} />;
  };

  return (
    <div className='cd-faq-items'>
      {
        map(getFaqItem)(faqItems)
      }
    </div>
  );
};

FaqItems.propTypes = {
  faqItems: PropTypes.array.isRequired
};

export default FaqItems;
