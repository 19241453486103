const intialState = {
	openDrawer: false,
};

export default function variables(state = intialState, action) {
	switch (action.type) {
		case 'OPEN_DRAWER':
			return {
				openDrawer: action.openDrawer,
			};
		default:
			return state;
	}
}
