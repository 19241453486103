import React, { Component, useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ArrowDownSvg from './ArrowDownSvg';
import useClickAway from './useClickAway';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../sass/dataExport.css';
import { Button } from '@material-ui/core';
import { data } from 'jquery';
import { chunk } from 'lodash';

const styles = theme => ({
	selectRoot: {
		minHeight: '39px',
		backgroundColor: 'white',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.18)',
		paddingTop: '5px',
		paddingLeft: '10px',
	},
	select: {
		height: '27px',
		paddingRight: 0,
		minWidth: '200px',
		minHeight: '27px',
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},

	menu: {
		'&:focus': {
			backgroundColor: 'none',
			border: 'none',
		},
	},
	paper: {
		marginTop: '5px',
		height: '212px',
		borderRadius: '0',
		maxWidth: '209px',
		backgroundColor: 'white',
		boxShadow: '0 2px 38px rgba(0, 0, 0, 0.1)',
	},
	menuList: {
		paddingTop: '0px',
	},
	listItem: {
		fontSize: '14px',
		padding: '5px 11px',
	},
	menuItem_selected: {
		color: 'red!important',
		backgroundColor: 'yellow',
	},
});

function FiltersVat({
	datas,
	multiple,
	label,
	monoSelect,
	paramsValue,
	paramsLabel,
	isNumberData,
	onClose,
	classes,
	inputClassName,
	inputLabelClassName,
	selected = [],
}) {
	const [selectedValue, setSelectedValue] = useState(
		multiple
			? []
			: [
					{
						key: 1,
						label: datas[0][paramsLabel],
						[paramsValue]: datas[0][paramsValue],
					},
			  ]
	);
	const _allSelected = multiple && selected.length == datas.length;

	const [allSelected, setAllSelected] = useState(_allSelected);
	const [filterLabel, setFilterLabel] = useState(label);
	const [isMonoSelect, setIsMonoSelect] = useState(monoSelect);
	const [opened, setOpened] = useState(false);

	useEffect(() => {}, []);

	const refClick = useRef();
	useClickAway(refClick, () => {
		//_handleSelectClose();
	});
	const _getMenuItems = (items, params) => {
		if (items) {
			return items.map((el, idx) => {
				const itemsIsSelected = allSelected
					? true
					: multiple
					? selectedValue.some(e => e[params] === el[params])
					: selectedValue && selectedValue.length > 0
					? selectedValue[0][params] === el[params]
					: '';
				return (
					<MenuItem
						key={idx}
						disableGutters={true}
						value={el}
						classes={{
							root: classes.listItem,
							selected: classes.menuItem_selected,
						}}
						id={`filters_menu_item${isNumberData ? '_numbers' : ''}`}>
						<div
							className={`filter_menu_select_icon${
								itemsIsSelected ? '_selected' : ''
							}`}></div>
						<p key={`${Math.floor(Math.random() * 100)}`}>{el[paramsLabel]}</p>
					</MenuItem>
				);
			});
		}
	};

	const _handleSelectClose = (e, target) => {
		if (selectedValue.length > 0) {
			const formattedDatas = target;
			onClose(formattedDatas, paramsValue);
		} else {
			onClose([]);
		}
		setOpened(false);
	};

	const _handleSelectChange = (e, child) => {
		if (e.target && e.target.value) {
			const targetValue = monoSelect
				? e.target.value[paramsLabel]
				: e.target.value[1][paramsValue];
			const isValueSelected = selectedValue.some(el => {
				return el[paramsValue] === targetValue;
			});

			if (multiple && !monoSelect) {
				if (targetValue === 'select_all') {
					const allDatasFormatted = datas.map((d, idx) => ({
						key: idx,
						label: d[paramsLabel],
						[paramsValue]: d[paramsValue],
					}));
					setSelectedValue(allDatasFormatted);
					setAllSelected(true);
					setFilterLabel(label);
				} else {
					if (
						selectedValue.length === 1 &&
						targetValue === selectedValue[0][paramsValue]
					) {
						return false;
					}
					if (isValueSelected) {
						setSelectedValue(prevState => {
							prevState.selectedValue.filter(s => {
								return s[paramsValue] != targetValue;
							});
						});
						setAllSelected(false);
						setSelectedValue(selectedValue.map(el => el['label']).join(','));
					} else {
						setSelectedValue(prevState => {
							setAllSelected(
								prevState.selectedValue.length + 1 === datas.length
							);
							return [
								...selectedValue,
								{
									label: targetValue,
									key: child.key,
									[paramsValue]: targetValue,
								},
							];
						});

						setFilterLabel(
							allSelected
								? label
								: selectedValue.map(el => el['label']).join(',')
						);
					}
				}
			} else {
				if (isValueSelected) {
					return false;
				}

				setSelectedValue([
					{
						label: targetValue,
						key: child.key,
						[paramsValue]: e.target.value[paramsValue],
					},
				]);
				setFilterLabel(targetValue);

				if (isMonoSelect) {
					_handleSelectClose(e, e.target.value);
				}
			}
		}
	};

	return (
		<div ref={refClick}>
			<Select
				disableUnderline
				disabled={datas && datas.length > 1 ? false : true}
				className={inputClassName}
				value={[
					<p
						className={inputLabelClassName}
						key={`${Math.floor(Math.random() * 100)}`}>
						{filterLabel}
					</p>,
				]}
				onChange={_handleSelectChange}
				multiple={multiple}
				MenuProps={{
					getContentAnchorEl: null,

					classes: { paper: classes.paper },
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center',
					},
					transformOrigin: { horizontal: 'center', vertical: 'top' },
					MenuListProps: { classes: { root: classes.menuList } },
				}}
				classes={{
					root: classes.selectRoot,
					select: classes.select,
					selectMenu: classes.menu,
				}}
				renderValue={value => value}>
				{multiple && !monoSelect ? (
					<MenuItem
						key={Math.floor(Math.random() * 100).toString()}
						value={{
							[paramsValue]: 'select_all',
							[paramsLabel]: label,
						}}
						selected={allSelected}
						classes={{
							root: classes.listItem,
							selected: classes.menuItem_selected,
						}}
						id="filters_menu_item">
						<div
							className={`filter_menu_select_icon${
								allSelected ? '_selected' : ''
							}`}></div>
						<p>{label} </p>
					</MenuItem>
				) : null}
				{datas ? _getMenuItems(datas, paramsValue) : null}
			</Select>
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(FiltersVat);
