import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import NumberFormat from "react-number-format";

class TableErrors extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  openPopover(event, rowInfo) {
    this.props.openPopover(event, rowInfo);
  }

  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem]);
      });
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value),
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search
                  style={{
                    color: "white",
                    lineHeight: "17.4px",
                    verticalAlign: "middle"
                  }}
                />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      {
        Header: "Table",
        accessor: "table_name",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "ID element",
        accessor: "table_id",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Donnée",
        accessor: "key_",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Valeur",
        accessor: "value",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Date",
        accessor: "timestamp",
        pivot: true,
        resizable: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      { Header: "Source", accessor: "source", pivot: true },
      { Header: "Version", accessor: "app_version", pivot: true },
      {
        Header: "Commentaire",
        accessor: "comment",
        pivot: true,
        minWidth: 400,
        resizable: true
      }
    ];

    if (dataArray && dataArray.length > 0) {
      dataArray.forEach(element => {
        if (
          element.timestamp !=
            moment.unix(element.timestamp).format("DD-MM-YYYY H:mm:ss") &&
          moment.unix(element.timestamp).format("DD-MM-YYYY H:mm:ss") !=
            "Invalid date"
        ) {
          element.timestamp = moment
            .unix(element.timestamp)
            .format("DD-MM-YYYY H:mm:ss");
        }
        if (element.source === "pos") {
          element.source = "Application";
        }
        if (element.source === "server") {
          element.source = "Serveur";
          element.app_version = element.version || '';
        }
      });
    }

    return (
      <ReactTable
        className=" -highlight -striped clickAble"
        style={{ textAlign: "center", maxHeight: "85vh" }}
        data={dataArray}
        defaultPageSize={5}
        filterable
        columns={columnsTable}
        previousText={I18n.t("fisc.table.previousText")}
        nextText={I18n.t("fisc.table.nextText")}
        loadingText={I18n.t("fisc.table.loadingText")}
        noDataText={I18n.t("fisc.table.noDataText")}
        pageText={I18n.t("fisc.table.pageText")}
        ofText={I18n.t("fisc.table.ofText")}
        rowsText={I18n.t("fisc.table.rowsText")}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, t) => {
              this.openPopover(e, rowInfo);
            }
          };
        }}
      />
    );
  }
}

export default TableErrors;
