import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import './sass/style.scss';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const styles = theme => ({
	popover: {
		margin: '19% 5% 8% 3.3%',
		width: '590px',
		height: '250px',
		display: 'flex',
		flexDirection: 'row',
	},
});

const datePickerShortcutValue = [
	{
		id: 1,
		label: `Aujourd'hui`,
		from: moment().toDate(),
		to: moment().toDate(),
	},
	{
		id: 2,
		label: '7 derniers jours',
		from: moment().subtract(7, 'days').toDate(),
		to: moment().toDate(),
	},
	{
		id: 3,
		label: '30 derniers jours',
		from: moment().subtract(30, 'days').toDate(),
		to: moment().toDate(),
	},
	{
		id: 4,
		label: moment()
			.subtract(1, 'month')
			.startOf('month')
			.format('MMMM')
			.toUpperCase(),
		from: moment().subtract(1, 'month').startOf('month').toDate(),
		to: moment().subtract(1, 'month').endOf('month').toDate(),
	},
];

class DatePicker extends Component {
	constructor(props) {
		super(props);
		this._handleDayClick = this._handleDayClick.bind(this);
		this._handleResetClick = this._handleResetClick.bind(this);
		this._handerDayMouseEnter = this._handerDayMouseEnter.bind(this);
		this._handleSetDays = this._handleSetDays.bind(this);
		this._handleOnValidate = this._handleOnValidate.bind(this);
		this._getBtnShortcutList = this._getBtnShortcutList.bind(this);
		this.state = {
			from: null,
			to: null,
			enteredTo: null,
			openDatePicker: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.open != this.state.open) {
			this.setState({ openDatePicker: nextProps.open });
		}
	}

	componentWillMount() {
		this.setState({
			from: moment().subtract(7, 'days').toDate(), //moment('2019-06-01').toDate()
			enteredTo: moment().toDate(), //moment('2019-06-30').toDate()
		});
	}

	_isSelectingFirstDay = (from, to, day) => {
		const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
		const isRangeSelected = from && to;
		return !from || isBeforeFirstDay || isRangeSelected;
	};

	_getBtnShortcutList = fn => {
		return datePickerShortcutValue.map(date => {
			return (
				<Button
					key={date.id}
					className={`datepicker_shortcut_btn ${
						moment(this.state.from).isSame(date.from, 'day') &&
						moment(this.state.enteredTo).isSame(date.to, 'day')
							? 'selected'
							: ''
					}`}
					onClick={e => fn(e, date.from, date.to)}>
					{date.label}
				</Button>
			);
		});
	};

	static defaultProps = {
		numberOfMonths: 2,
	};

	_handleSetDays = (e, from, to) => {
		if (from && to) {
			this.setState({
				from: from,
				enteredTo: to,
			});
		}
	};

	_handleDayClick = day => {
		const { from, to } = this.state;
		if (from && to && day >= from && day <= to) {
			this._handleResetClick();
			return;
		}
		if (this._isSelectingFirstDay(from, to, day)) {
			this.setState({
				from: day,
				to: null,
				enteredTo: null,
			});
		} else {
			this.setState({
				to: day,
				enteredTo: day,
			});
		}
	};

	_handerDayMouseEnter = day => {
		const { from, to } = this.state;

		if (!this._isSelectingFirstDay(from, to, day)) {
			this.setState({
				enteredTo: day,
			});
		}
	};

	_handleResetClick = () => {
		console.log('Reset day');
		this.setState({
			from: null,
			to: null,
			enteredTo: null,
		});
	};

	_handleOnValidate = () => {
		this.setState({ openDatePicker: false });
		this.props.onValidate({
			from: this.state.from,
			fromLabel: moment(this.state.from).format('DD-MM-YYYY'),
			enteredTo: this.state.enteredTo,
			toLabel: moment(this.state.enteredTo).format('DD-MM-YYYY'),
		});
	};

	render() {
		const { from, to, enteredTo } = this.state;
		const modifiers = {
			start: from,
			end: enteredTo,
			thursdays: { daysOfWeek: [4] },
		};
		const selectedDays = [from, { from, to: enteredTo }];
		const { classes } = this.props;
		const disableBtnValidation =
			!this.state.from ||
			!this.state.enteredTo ||
			(!this.state.from && !this.state.enteredTo);

		const modifiersStyles = {
			selected: {
				backgroundColor: '#5ac8f5',
				color: 'white',
				fontFamily: 'Nunito-Sans',
				borderRadius: '0px',
			},
			start: {
				backgroundColor: '#e84249',
				color: 'white',
				fontFamily: 'Nunito-sans',
				backgroundColor: '#fffdee',
				borderRadius: '7px 0px 0px 7px',
			},
			end: {
				backgroundColor: '#e84249',
				color: 'white',
				fontFamily: 'Nunito-sans',
				backgroundColor: '#fffdee',
				borderRadius: '0px 7px 7px 0px',
			},
			outside: {
				backgroundColor: 'transparent',
			},

			root: {
				color: 'red',
			},
		};

		return (
			<div className="datepicker_container">
				<Popover
					classes={{ paper: classes.popover }}
					open={this.state.openDatePicker}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					onClose={this._handleOnValidate}>
					<div className="datepicker_shortcut">
						<p>Sélectionner une période : </p>
						{this._getBtnShortcutList(this._handleSetDays)}

						<Button
							className={`datepicker_btn_validate ${
								disableBtnValidation ? 'disabled' : ''
							}`}
							disabled={disableBtnValidation}
							onClick={this._handleOnValidate}>
							Valider
						</Button>
					</div>
					<div className="datepicker_main">
						<DayPicker
							className="Range"
							locale="fr"
							initialMonth={moment()
								.subtract(1, 'month')
								.startOf('month')
								.toDate()}
							localeUtils={MomentLocaleUtils}
							numberOfMonths={this.props.numberOfMonths}
							selectedDays={selectedDays}
							modifiers={modifiers}
							modifiersStyles={modifiersStyles}
							onDayClick={this._handleDayClick}
						/>
					</div>
				</Popover>
			</div>
		);
	}
}

export default withStyles(styles)(DatePicker);
