import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import checkedIcon from "../../../img/checked.png";
import pauseIcon from "../../../img/pause.png";
import nonInteIcon from "../../../img/nonintegrated.png";

class PartnerItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
    // this.setIsOpen = this.setIsOpen.bind(this);
    // this.changeReport = this.changeReport.bind(this);
  }

  render() {
    let { partner } = this.props;
    let partnerIcon = (
      <img className="checkPartner" src={nonInteIcon} alt="noninte" />
    );
    if (partner.access_token) {
      if (partner.partner_customer_id) {
        partnerIcon = (
          <img className="checkPartner" src={checkedIcon} alt="ok" />
        );
      } else {
        partnerIcon = (
          <img className="checkPartner" src={pauseIcon} alt="waiting" />
        );
      }
    }
    return (
      <div className="col-md-3 col-lg-2 col-sm-4">
        <Link
          onClick={e => this.props.handleDrawer(e, partner)}
          to="/reporting/"
        >
          <div className="partnerLogo">
            <img src={require(`../../../img/${partner.logo}`)} />
            {partner.with_action ? partnerIcon : null}
          </div>
        </Link>
      </div>
    );
  }
}

PartnerItem.propTypes = {
  partner: PropTypes.object.isRequired
};

export default PartnerItem;
