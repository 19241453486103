import React, { useState, useEffect } from 'react';

const CellEditable = ({ val, onBlurFct, isLabel, clsName, placeholder }) => {
	const rgxNb = /^[0-9\b]+$/;

	const onCellUpdate = e => {
		e.preventDefault();
		const checkedValue = rgxNb.test(e.target.value);
		if (!isLabel && !checkedValue) return false;
		onBlurFct(e.target.value, isLabel);
	};
	return (
		<input
			value={val}
			onChange={e => {
				onCellUpdate(e);
			}}
			className={clsName && clsName !== '' ? clsName : `vat-setting-input`}
			placeholder={placeholder || ''}
		/>
	);
};

export default CellEditable;
