import React, { Component } from 'react';
import programmerMale from './img/programmer-male.png';
import './style.css';
import { I18n } from 'react-redux-i18n';

class Maintenance extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className="maintenance-container center">
				<header>
					<h1>{I18n.t('maintenance.partenaireOuilink')}</h1>
				</header>
				<div className="img-container center">
					<img src={programmerMale} alt="maintenance" width={500} />
				</div>
			</div>
		);
	}
}

export default Maintenance;
