import React from "react";
import PropTypes from "prop-types";
import Valide from "../img/valide.png";
import Forme from "../img/forme.png";
import Play from "../img/play.png";
import Pause from "../img/pause.png";

class VideoItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasChild: false };
    this.updateVideo = this.updateVideo.bind(this);
  }

  updateVideo(e) {
    e.preventDefault();
    this.props.changeOpenedVideoUrl(this.props.video.link);
    this.props.changeAutoPlay(true);
    this.props.scrollTo();
  }

  render() {
    let { video } = this.props;
    if (typeof video.state === "undefined") {
      video.state = { state: "", time: "" };
    }
    const image = require(`../img/${video.icon}`).default;
    let icon;
    if (video.state) {
      if (video.state.state === "watched") {
        icon = <img className="checkStatus" src={Valide} alt="watched" />;
      }
      if (video.state.state === "inProgress") {
        icon = <img className="checkStatus" src={Forme} alt="inProgress" />;
      }
      if (video.state.state === "paused") {
        icon = <img className="checkStatus" src={Pause} alt="paused" />;
      }
    }

    let videoIconImage;
    if (video.icon === "commander.png") {
      videoIconImage = (
        <img
          src={image}
          className="videoIcon-image iconCommander"
          alt="image"
        />
      );
    } else if (video.icon === "timer.png") {
      videoIconImage = (
        <img
          src={image}
          className="videoIcon-image"
          style={{ marginTop: "-5px" }}
          alt="image"
        />
      );
    } else if (video.icon === "power.png") {
      videoIconImage = (
        <img
          src={image}
          className="videoIcon-image"
          style={{ marginTop: "5px" }}
          alt="image"
        />
      );
    } else {
      videoIconImage = (
        <img src={image} className="videoIcon-image" alt="image" />
      );
    }

    return (
      <div className="videoItem">
        <div
          className={"videoIcon-container " + video.state.state}
          onClick={this.updateVideo}
        >
          <img src={Play} className="videoIcon-play" alt="image" />
          {videoIconImage}
          {icon}
        </div>
        <p>
          <span className="item-title">{video.title}</span>
        </p>
      </div>
    );
  }
}

VideoItem.propTypes = {
  video: PropTypes.object.isRequired,
};

export default VideoItem;
