import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import config from './../../../utils/config';
import '../css/fisc.css';
import LogoLoader from './../../../components/LogoLoader';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import CloseModal from '../../CustomerAccount/svg/CloseModal';

class ModalCheckFiscal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			files: {},
			hidden: true,
			enablecheck: false,
			enableUpload: false,
			keyexisting: false,
			isValid: false,
			company: '',
			username: '',
			email: '',
			exportDate: '',
			openingExportDate: '',
			closingExportDate: '',
			fromDate: '',
			toDate: '',
		};
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
			this.setState({ open: nextProps.open });
		}
	}

	uploadAndcheckFilesUpload = async (filetype, e) => {
		const file = e.target.files[0];
		this.setState(
			prevState => ({
				files: { ...prevState.files, [filetype]: file },
			}),
			() => {
				if (!file) return false;
				const isFileTypeValid =
					(file.type !== 'text/plain' || file.type !== 'text/csv') &&
					this.state.files.hasOwnProperty('csv') &&
					this.state.files.hasOwnProperty('txt') &&
					this.state.files['txt'].type === 'text/plain';
				this.setState({ enableUpload: isFileTypeValid });
			}
		);
	};

	checkFiscalFiles = async e => {
		try {
			e.preventDefault();
			this.setState({ hidden: false });
			const { files } = this.state;
			const accessToken = window.localStorage.getItem('accessToken');
			const dataFiles = new FormData();
			Object.keys(files).map(function (key, index) {
				dataFiles.append('myFile', files[key]);
			});
			const checkFiles = await axios
				.post(
					`${config.reportingUrl}api/administration-fiscale/check-fiscal-file`,
					dataFiles,
					{
						headers: {
							Authorization: `accessToken ${accessToken}`,
							'Content-Type': 'multipart/form-data',
						},
					}
				)
				.then(res => {
					const formatExportDate = res.data.export_date
						? moment(res.data.export_date).format('DD-MM-YYYY HH:mm')
						: '';
					const formatOpeningExportDate = res.data.opening_export_date
						? moment(res.data.opening_export_date).format('DD-MM-YYYY')
						: '';
					const formatClosingExportDate = res.data.closing_export_date
						? moment(res.data.closing_export_date).format('DD-MM-YYYY')
						: '';

					this.setState({
						hidden: true,
						enablecheck: true,
						files: {},
						enableUpload: false,
						keyexisting: res.data.keyexisting,
						exportHistoryErrors: res.data.exportHistoryErrors,
						isValid: res.data.isValid,
						company: res.data.company,
						email: res.data.email,
						openingExportDate: formatOpeningExportDate,
						closingExportDate: formatClosingExportDate,
						exportdate: formatExportDate,
						username: res.data.username,
					});
				});
			return true;
		} catch (error) {
			return error;
		}
	};
	render() {
		const {
			enablecheck,
			isValid,
			enableUpload,
			hidden,
			company,
			username,
			email,
			exportdate,
			openingExportDate,
			closingExportDate,
			keyexisting,
			exportHistoryErrors,
		} = this.state;
		const labelValid = isValid
			? I18n.t('fisc.modalFisc.hashIsGood')
			: I18n.t('fisc.modalFisc.hashIsBad');
		return (
			<div>
				<Modal
					classNames={{ modal: 'fiscal_modal_check' }}
					open={this.state.open}
					showCloseIcon={false}
					closeIconSize={20}
					closeOnOverlayClick={hidden}
					onClose={() => {
						if (!hidden) return false;
						this.setState({ enablecheck: false });
						this.setState({ enableUpload: false });

						this.props.onClose();
					}}>
					{!hidden ? (
						<LogoLoader
							hidden={false}
							label={I18n.t('fisc.modalFisc.waitingCheckData')}
						/>
					) : (
						<div className="fiscal_check_content">
							{hidden ? (
								<div
									className="fiscal_close_icon"
									onClick={e => {
										if (this.state.loading) return false;
										this.setState({ enablecheck: false });
										this.setState({ enableUpload: false });
										this.props.onClose();
									}}>
									<CloseModal />
								</div>
							) : null}

							<h3>{I18n.t('fisc.modalFisc.checkingData')}</h3>
							{enablecheck ? (
								<div className="checked_infos_container">
									<p style={{ color: `${isValid ? 'green' : 'red'}` }}>
										{labelValid}
									</p>
									{exportHistoryErrors && exportHistoryErrors.length ? (
										<p style={{ color: 'orange' }}>
											{I18n.t('fisc.modalFisc.exportHistoryIsNotExact')}
										</p>
									) : null}
									<p>
										{I18n.t('fisc.modalFisc.company')}{' '}
										{keyexisting ? company : '-'}
									</p>
									<p>
										{I18n.t('fisc.modalFisc.user')}{' '}
										{keyexisting ? username : '-'}
									</p>
									<p>
										{I18n.t('fisc.modalFisc.email')} {keyexisting ? email : '-'}
									</p>
									<p>
										{I18n.t('fisc.modalFisc.exportDate')}{' '}
										{keyexisting ? exportdate : '-'}
									</p>
									<p>
										{I18n.t('fisc.modalFisc.dataFrom')}{' '}
										{keyexisting ? openingExportDate : '-/-/-'}{' '}
										{I18n.t('fisc.modalFisc.to')}{' '}
										{keyexisting ? closingExportDate : '-/-/-'}
									</p>
								</div>
							) : (
								<div className="fiscal_check_content">
									<div className="fisc_check_input">
										<p>{I18n.t('fisc.modalFisc.csvFile')}</p>
										<input
											type="file"
											className="upload_fisc_input"
											name="uploadcsv"
											accept=".csv"
											onChange={e => this.uploadAndcheckFilesUpload('csv', e)}
										/>
									</div>
									<div className="fisc_check_input">
										<p>{I18n.t('fisc.modalFisc.keyVerification')}</p>
										<input
											className="upload_fisc_input"
											type="file"
											name="uploadtxt"
											accept="text/plain, .txt"
											onChange={e => this.uploadAndcheckFilesUpload('txt', e)}
										/>
									</div>
									<Button
										onClick={this.checkFiscalFiles}
										disabled={!enableUpload}
										style={{
											color: 'white',
											backgroundColor: `${
												enableUpload ? '#262957' : 'rgba(0, 0, 0, 0.26)'
											}`,
											fontFamily: 'FuturaBook',
											borderRadius: '20px',
											fontSize: 'smaller',
											width: '261px',
											marginLeft: '33%',
										}}>
										{I18n.t('fisc.modalFisc.checkBtn')}
									</Button>
								</div>
							)}
						</div>
					)}
				</Modal>
			</div>
		);
	}
}

export default ModalCheckFiscal;
