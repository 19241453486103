import React, { useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Modal from 'react-responsive-modal';
import '../sass/modalExport.css';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import 'moment/locale/fr';
import Button from '@material-ui/core/Button';
import Input from '../../../components/Input/Input';
import Validator from 'validator';
import CloseModal from '../svg/CloseModal';

moment.locale('fr');

function ModalExport({ openModal, user, onClose, exportfct }) {
	const [startDate, setStartDate] = useState(moment().format('YYYYMMDD'));
	const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
	const [email, setEmail] = useState(user && user.email ? user.email : '');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const _areDatesValids = (start_Date, end_Date) => {
		if (!start_Date || !end_Date) return false;
		const start = moment(startDate);
		const end = moment(endDate);

		if (!end.isSameOrAfter(start)) {
			setErrorMessage(I18n.t('fisc.modalFisc.exportErrorReversedDates'));
			return false;
		}

		if (moment(end_Date).diff(start_Date, 'years') !== 0) {
			setErrorMessage(I18n.t('fisc.modalFisc.exportErrorOneYearMax'));
			return false;
		}

		setErrorMessage('');
		return true;
	};

	const _handleStartDayChange = day => {
		if (!day) return;
		let startDate = moment(day).format('YYYYMMDD');

		setStartDate(startDate);
		setErrorMessage('');
	};
	const _handleEndDayChange = day => {
		if (!day) return;
		const endDate = moment(day).format('YYYYMMDD');
		setEndDate(endDate);
		setErrorMessage('');
	};

	const onChange = e => {
		e.preventDefault();
		setEmail(e.target.value);
	};

	let userId = user && user.id ? user.id : '';

	const exportData = async (e, endPoint, method) => {
		e.preventDefault();
		if (!_areDatesValids(startDate, endDate)) return;
		setLoading(true);
		await exportfct(startDate, endDate, endPoint, userId, method, email);
		setLoading(false);
	};

	const btnIsDisabled = !Validator.isEmail(email || '')
		? {
				color: 'rgba(0, 0, 0, 0.26)',
				boxShadow: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0.12)',
		  }
		: {};

	return (
		<div>
			<Modal
				classNames={{
					modal: 'export_modal',
					overlay: 'modal_export_overlay',
				}}
				open={openModal}
				showCloseIcon={false}
				onClose={() => {
					if (loading) return false;
					onClose();
				}}
				closeOnOverlayClick={true}>
				<div className="export_modal_content">
					<div
						className="close_icon"
						onClick={e => {
							if (loading) return false;
							onClose();
						}}>
						<CloseModal />
					</div>
					<h3>{I18n.t('customerAccount.exportCustomerDatas')}</h3>
					<div className="export_modal_main">
						<div className="input_container">
							<div className="input_main">
								<p>{I18n.t('fisc.modalFisc.begin')} </p>
								<DayPickerInput
									local="fr"
									value={`${formatDate(startDate, 'L', 'fr')}`}
									formatDate={formatDate}
									parseDate={parseDate}
									placeholder={`${formatDate(startDate, 'L', 'fr')}`}
									onDayChange={_handleStartDayChange}
									dayPickerProps={{
										locale: 'fr',
										localeUtils: MomentLocaleUtils,
										modifiers: {},
									}}
								/>
							</div>
							<div className="input_main">
								<p>{I18n.t('fisc.modalFisc.end')}</p>
								<DayPickerInput
									local="fr"
									formatDate={formatDate}
									parseDate={parseDate}
									value={`${formatDate(endDate, 'L', 'fr')}`}
									placeholder={`${formatDate(endDate, 'L', 'fr')}`}
									onDayChange={_handleEndDayChange}
									dayPickerProps={{
										locale: 'fr',
										localeUtils: MomentLocaleUtils,
										fromMonth: moment(endDate).subtract(1, 'y').toDate(),
										toMonth: moment(startDate).add(1, 'y').toDate(),
										modifiers: {
											disabled: [
												{
													after: moment(startDate).add(1, 'y').toDate(),
												},
												{
													before: moment(endDate).subtract(1, 'y').toDate(),
												},
											],
										},
									}}
								/>
							</div>
						</div>
						<div className="error-export">
							<p>{errorMessage}</p>
						</div>
					</div>
					<div className="modal_input_mail">
						<Input
							label={I18n.t('lneExport.emailHint')}
							type="email"
							name="email"
							required="required"
							disableUnderline={true}
							idInput="email-input"
							onChange={onChange}
							value={email}
							colorInput={Validator.isEmail(email || '') ? '#7cc4ac' : null}
						/>
					</div>
					<div className="modal_btn_export">
						<Button
							classes={{ root: 'btn-export first_btn' }}
							id="first_btn"
							style={btnIsDisabled}
							disabled={!Validator.isEmail(email || '')}
							onClick={e => exportData(e, 'export-xls-all-customers', 'POST')}>
							{I18n.t('customerAccount.exportAllAccount')}
						</Button>
						<Button
							className="btn-export second_btn"
							id="second_btn"
							disabled={!Validator.isEmail(email || '')}
							style={btnIsDisabled}
							disabled={!Validator.isEmail(email || '')}
							onClick={e => exportData(e, 'export-xls-unpaid-sales', 'POST')}>
							{I18n.t('customerAccount.exportCustomerUnpaidSales')}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default ModalExport;
