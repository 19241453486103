import React, { Component } from "react";

import ReactTable from "react-table";

import "../../../TableData/sass/style.scss";
import "react-table/react-table.css";

class DataTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { columnsFirst, columnsSecond, labelNoData, wares } = this.props;
    return (
      <ReactTable
        data={wares}
        columns={columnsFirst}
        showPagination={false}
        resizable={false}
        sortable={false}
        noDataText={labelNoData}
        className="main_table"
        pageSize={wares.length}
        headerClassName="main_table_header"
        SubComponent={
          columnsSecond
            ? (row) => {
                return (
                  <div className="main_second_table_container">
                    <ReactTable
                      data={row.original.ware}
                      noDataText={labelNoData}
                      columns={columnsSecond}
                      getTrGroupProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            marginLeft: "22px",
                          },
                        };
                      }}
                      TheadComponent={(props) => null}
                      headerClassName
                      className="main_table_secondary"
                      showPagination={false}
                      pageSize={row.original.ware.length}
                    />
                  </div>
                );
              }
            : null
        }
      />
    );
  }
}

export default DataTable;
