import React, { Component } from 'react';
import { connect } from 'react-redux';

class FeatureFlag extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let showable = false;
		if (this.props.auth && this.props.auth.user) {
			const { groups } = this.props.auth.user;
			const testGroup = groups.find(group => group.name === 'reporting-test');
			showable = testGroup ? true : false;
		}
		return showable ? (
			<React.Fragment>{this.props.children}</React.Fragment>
		) : null;
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

export default connect(mapStateToProps, null)(FeatureFlag);
