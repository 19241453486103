import { makeStyles } from '@material-ui/core/styles';

export default function getFrontContainerStyle(theme) {
	return {
		root: {
			display: 'flex',
			paddingTop: '80px',
		},
		rootMobile: {
			display: 'flex',
			paddingTop: '70px',
		},
		drawPaper: {
			position: 'relative',
		},
		drawer: {
			width: '263px',
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: '263px',
			zIndex: 1000,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			'&::-webkit-scrollbar': {
				width: 5,
			},
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: '70px',
			zIndex: 1,
			borderTop: '0.5px solid #e7e7e7',
			'&::-webkit-scrollbar': {
				width: 5,
			},
		},

		mainContainer: {
			width: '100%',
			position: 'fixed',
			height: '100vh',
			//overflow: 'scroll',
		},

		mainContent: {
			marginLeft: '70px',
			height: 'calc(100vh - 85px)',
			border: 'solid 2px peach',
			backgroundColor: 'white',
			paddingTop: '17px',
			paddingLeft: '17px',
			fontSize: '14px',
			display: 'flex',
			position: 'relative',
			overflow: 'auto',
		},
		mainContentMobile: {
			marginLeft: '0px',
			height: '100vh',
			backgroundColor: '#f6f6f6',
			display: 'flex',
			position: 'relative',
			overflow: 'scroll',
		},
		arrowMenu: {
			height: '50px',
		},
		arrowMenuOpened: {
			marginLeft: 'auto',
			marginRight: '0',
		},

		arrowMenuClosed: {
			margin: '0 auto',
		},
		arrowMenuButton: {
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		divider: {
			backgroundColor: '#f6f6f6',
		},

		menuListItems: {
			display: 'flex',
			flexDirection: 'row',
			height: '50px',
			color: '#262957',
			position: 'relative',
			alignItems: 'center',
			padding: '35px 0px 35px 7px',
			'& p': {
				fontFamily: 'FuturaBook',
			},
		},
		menuIconsAnnotattion: {
			display: 'block',
			width: '56px',
			height: 'auto',
			whiteSpace: 'normal',
			textAlign: 'center',
			marginBottom: 'auto',
		},
		menuListIcons: {
			display: 'flex',
			flexDirection: 'column',
			fontSize: '1em',
			fontWeight: 'bold',
			fontFamily: 'FuturaHeavy',
			fontStyle: 'normal',
			letterSpacing: 'normal',
			lineHeight: 'normal',
			alignItems: 'center',
			color: '#262957',
			marginRight: '7px',
			maxWidth: '56px',
			'& img': {
				width: '30px',
				height: '30px',
			},
		},

		menuIconText: {
			'& span': {
				height: '100%',
				display: 'flex',
				alignItems: 'flex-end',
			},
		},
		menuIconPrimayText: {
			width: '99px',
			height: '18px',
			color: '#262957',
			fontFamily: 'FuturaHeavy',
			fontSize: '15px',
			fontWeight: '700',
			fontStyle: 'bold',
			letterSpacing: 'normal',
			lineHeight: 'normal',
			textAlign: 'left',
		},
		menuItemSelected: {
			color: '#e84249!important',
			backgroundColor: 'transparent!important',
			'& p': {
				color: '#e84249',
			},
			'& span': {
				color: '#e84249',
				height: '100%',
				display: 'flex',
				alignItems: 'flex-end',
			},
			'& img': {
				filter:
					'invert(44%) sepia(20%) saturate(3328%) hue-rotate(328deg) brightness(88%) contrast(100%)',
			},

			'& svg': {
				filter:
					'invert(44%) sepia(20%) saturate(3328%) hue-rotate(328deg) brightness(88%) contrast(100%)',
			},
		},
		menuDrawer: {
			display: 'flex',
			width: '100%',
			padding: '22px 0 22px 0',
		},
		subMenuItems: {
			height: '30px',
			color: '#262957',
			fontSize: '13px',
			fontFamily: 'FuturaBook',
			fontWeight: '300',
			fontStyle: 'normal',
			letterSpacing: 'normal',
			lineHeight: 'normal',
			textAlign: 'left',
			'&:hover': {
				backgroundColor: 'transparent',
			},
			'& a': {
				color: '#262957',
			},
		},
		menuListItemLink: {
			width: '100%',
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
		},

		subMenuItemsLabel: {
			fontFamily: 'FuturaBook',
			marginLeft: '54px',

			'& span': {
				fontFamily: 'FuturaBook',
				fontSize: '13px',
			},
			'&:hover': {
				textDecoration: 'underline',
				color: '#e84249',
				backgroundColor: 'transparent',
			},
		},
		subMenuItemSelected: {
			backgroundColor: 'rgba(232, 66, 73, 0.05)!important',
			color: '#e84249!important',
			'& a': {
				color: '#e84249',
			},
		},
	};
}
