export default {
	front: {
		appFront: {
			logins: 'Tus datos de acceso',
			warningLoginAccess:
				'Para aumentar la seguridad de tu cuenta, necesitas modificar tus datos de acceso.',
			warningOwner1: 'Atención:',
			warningOwner2: ' Si no eres el ',
			warningOwner3: 'TITULAR DE LA CUENTA',
			warningOwner4: ` (si eres contable o cualquier otra persona),
              no modifiques los datos. Pídele al titular
              que lo haga.`,
		},
		navigationBarFront: {
			connectAs: 'conectado como: ',
			connectAsFisc: 'Estás viendo los datos de: ',
			changeUserLogin: 'Cambiar nombre de usuario',
			changeUserPassword: 'Cambiar contraseña',
			logout: 'Cerrar sesiónES',
			login: 'Iniciar sesión',
			nameTool: 'Portal de Informes',
			nameCompany: "L'Addition",
			version: 'es v 1.2.1',
			sponsorship: 'Recomienda a un amigo y recibe 100 €',
			versionInfos: 'Información',
		},
		notFound: {
			notFoundMessage: 'LA PÁGINA QUE BUSCAS NO EXISTE.',
		},
	},
	emailEquals: {
		important: 'Importante',
		identical: 'Este email es idéntico al anterior',
	},
	emailHint: {
		important: 'Importante',
		inputEmail:
			'Introduce un email válido al que tengas acceso (se enviará un email de verificación a esta dirección)',
		notAssociated: "Este email no está vinculado a ninguna cuenta L'Addition",
	},
	passwordEquals: {
		important: 'Importante',
		identical: 'Esta contraseña es idéntica a la anterior',
	},
	passwordHint: {
		title: 'Tu contraseña debe contener:',
		nbCharacters: 'al menos 8 caracteres',
		uppercase: 'al menos 1 mayúscula',
		lowercase: 'al menos 1 minúscula',
		numerical: 'al menos 1 cifra',
		special: 'al menos 1 símbolo',
	},
	flashMessage: {
		readMore: 'Mostrar más',
		readLess: 'Mostrar menos',
	},
	notification: {
		noData: 'No tienes notificaciones',
		title: 'Notificaciones',
	},
	loader: {
		title: 'Cargando Informe. Por favor, espera',
		titleBr:
			'Si el informe no se ha cargado en pocos segundos, vuelve a cargar la página',
		title2: 'Cargando Informe.',
	},
	faq: {
		title: 'Preguntas frecuentes',
	},
	maintenance: {
		title: 'Página web en mantenimiento',
	},
	accountMigration: {
		emailValidation: {
			newLoginSucess: 'Datos de acceso validados',
			newLoginError: 'Error',
			newLoginLoading: 'Verificando...',
			pending: 'Verificando.',
			error1:
				'Se ha producido un error durante la modificación de tus datos. Por favor, inténtalo de nuevo.',
			error2: 'Este enlace ha caducado o ya ha sido utilizado.',
			successMessage:
				'Tus datos han sido modificados correctamente. En breves instantes, cerraremos tu sesión. Por favor, vuelve a iniciar sesión con tus nuevos datos.',
		},
		invalidPassword: 'Contraseña incorrecta.',
		error: 'Un error ha ocurrido',
		migration: {
			passwordMismatch: 'las contraseñas no coinciden',
			successMessage:
				'Casi has acabado. En breves instantes, recibirás un email de verificación. Para modificar tu contraseña, haz clic en el botón del email',
			errorMessage: 'Se ha producido un error.',
			title: 'Elige tus nuevos datos de acceso',
			actualEmail: 'Email actual',
			emailInput: 'Introduce tu email',
			emailConfirm: 'Confirma tu email',
			passwordInput: 'Introduce tu contraseña',
			passwordConfirm: 'Confirma tu contraseña',
			errorPassword:
				'Tu contraseña debe contener, al menos, 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo.',
			errorPasswordRequired: 'Campo requerido.',
		},
		btnValidate: 'validar',
		migrationStep1: {
			actualPassword: 'Introduce tu actual contraseña',
		},
	},
	category: {
		emptyCategory: 'No hay ninguna categoría disponible',
	},
	documentation: {
		userManual: {
			pdfName: 'Laddition-Manual-Usuario.pdf',
			description:
				"El manual de usuario recoge toda la información necesaria para utilizar la aplicación L'Addition.",
			title: 'Manual usuario',
		},
		installationManual: {
			pdfName: 'Laddition-Manual-Instalación.pdf',
			description:
				'Esta guía explica los pasos a seguir para descargar, instalar y activar la aplicación en tus dispositivos.',
			title: 'Guía - Instalar la aplicación',
		},
		printerLogo: {
			pdfName: 'LAddition-Logo-Impresora.pdf',
			description:
				'Esta guía explica el procedimiento a seguir para añadir un logo en tus tickets de compra y justificantes.',
			title: 'Guía – Añadir un logo en tus tickets',
		},
		usbScanner: {
			pdfName: 'LAddition-Escáner-USB.pdf',
			description:
				"Esta guía explica cómo conectar el escáner USB Zebra Symbol DS4308 a L'Addition. También incluye los problemas más comunes, con sus respectivas soluciones.",
			title: 'Guía - Escáner USB',
		},
		qrCodes: {
			pdfName: 'LAddition-código-QR.pdf',
			description:
				"Esta guía incluye 10 códigos QR válidos y te explica cómo integrarlos en tu aplicación L'Addition.",
			title: 'Guía – Añadir códigos QR',
		},
		zenChef: {
			pdfName: 'LAddition-Zenchef.pdf',
			description:
				"Esta guía explica el procedimiento a seguir para activar la sincronización de tus reservas entre Zenchef y la aplicación L'Addition.",
			title: 'Guía - Integrar Zenchef',
		},
		guestOnline: {
			pdfName: 'LAddition-Guestonline.pdf',
			description:
				"Esta guía explica el procedimiento a seguir para activar la sincronización de tus reservas entre Guestonline y la aplicación L'Addition.",
			title: 'Guía - Integrar Guestonline',
		},
		lneCertification: {
			pdfName: 'LAddition-certificado-0-lne.pdf',
			description:
				"L'Addition posee el certificado francés del LNE 2018. Descarga el certificado n°34277 - revisión 0, emitido por el LNE, a partir de la versión 4.0.0 de la aplicación.",
			title: 'Certificado LNE 2018',
		},
		title: "Biblioteca L'Addition",
		by: 'Por',
		download: 'Descargar',
	},
	faq: {
		emptyFaq: 'Ninguna FAQ disponible',
	},
	partnership: {
		generateKey: 'Generar clave',
		notActivatedPartner: 'No has activado a este socio',
		inActivationPartner: 'Esperando activación del asociado',
		keyCopied: 'Has copiado la clave en el portapapeles.',
		copyKey: 'Copiar clave de asociación',
		activatedPartner: 'Has activado a este socio',
		emptyCategory: 'Ninguna categoría disponible',
		activatePartnerModules: 'Activar módulos asociados',
		processTitle: '¿Cómo funciona?',
		process1: 'La activación de un socio se realiza en tres etapas:',
		process2: 'Haz clic en el nombre del socio',
		process3: 'Genera una clave',
		process4: 'Introduce dicha clave en la interfaz del socio',
		process5:
			'Cuando hayas introducido la clave en la interfaz del socio, este nos notificará la validación o rechazo de la activación.',
		process6:
			'Encontrarás el procedimiento completo en el apartado: Documentación',
		noIntegration: 'Ninguna integración',
		waitValidation: 'Esperando validación',
		integrationValidated: 'Integración validada',
		noTokenYet: 'Este socio aún no ha generado un Token.',
		update_version: 'Debes actualizar tu ipad para vincular a este socio',
	},
	video: {
		title: 'Módulo Vídeos',
		playing: 'Reproduciendo',
		alreadySeen: 'Ya visto',
		pause: 'En pausa',
		videoEmpty: 'Ningún vídeo disponible',
	},
	fisc: {
		table: {
			previousText: 'Anterior',
			nextText: 'Siguiente',
			loadingText: 'Cargando...',
			noDataText: 'Información no encontrada',
			pageText: 'Página',
			ofText: 'de',
			rowsText: 'líneas',
		},
		//TODO LATER
		// downloadExport: " Descargar archivo",
		// headersTable: {
		//   id: "ID",
		//   name: "Nombre"
		// },
		// actionName: {
		//   create: "Creación",
		//   update: "Actualización",
		//   delete: "Supresión"
		// },
		// index: {
		//   months: {
		//     january: "Enero",
		//     february: "Febrero",
		//     march: "Marzo",
		//     april: "Abril",
		//     may: "Mayo",
		//     june: "Junio",
		//     july: "Julio",
		//     august: "Agosto",
		//     september: "Septiembre",
		//     october: "Octubre",
		//     november: "Noviembre",
		//     december: "Diciembre"
		//   },
		//   loader: {
		//     timeLeft: "Quedan",
		//     calcul: "Calculando",
		//     minute: "minuto/s",
		//     seconde: "segundo/s"
		//   }
		// }
	},
	notFound: {
		title: 'Oups !',
		description: 'No se ha encontrado la página solicitada',
		backLogin: 'Volver al inicio',
	},
	lneExport: {
		noData: 'Pas de données pour la pèriode sélectionnée',
		exportProcessing:
			"Votre export est en cours, vous serez informé par e-mail lorsqu'il sera prêt !",
	},
	customerAccount: {
		cardNumber: 'N° de targeta',
		export: 'Exporter',
		exportCustomerDatas: 'EXPORT DES DONNÉES CLIENTS',
		exportAllAccount: 'Exporter liste des comptes',
		exportCustomerUnpaidSales: 'Exporter tickets non-soldés',
		customerAccountTitle: 'Cuentas cliente',
		noAccountFound: "No s'ha trobat cap compte",
		noCreditFound: 'Acucun crédit trouvé',
		firstAndLastName: 'Cognom i Nom',
		email: 'Correu electrònic',
		phoneNumber: 'Telèfon',
		address: 'Direcció',
		siren: 'CIF',
		companyName: 'Establiment',
		balance: 'Saldo',
		search: 'Cercar',
		allCompanies: 'Tots els establiments',
		accountsDetails: 'Detalls del compte',
		of: 'del',
		notOnSold: 'Sense vendes',
		sold: 'Vendes',
		credits: 'Crèdits',
		oneCredits: 'Crèdit',
		selectPeriod: 'Seleccioneu un període',
		resetPeriod: 'Restablir el calendari',
		noCreditFound: "No s'ha trobat crèdit",
		noTicketfound: 'No es van trobar tiquets',
		ticketDate: "Data de l'tiquet",
		paymentDate: 'Data de pagament',
		user: 'Usuari',
		selectAll: 'Selecciona-ho tot',
		mailSender: 'Remitent',
		mailRecipient: 'destinatari',
		mailSubject: 'objecte',
		mailContent: 'missatge',
		mailAttachement: 'arxiu (s) adjunt (s)',
		mailSend: 'Enviar a',
		noTicketSelected: 'Cap tiquet seleccionat',
		errorTicketGeneration:
			'Vaja ... Tenim un problema per a generar el tiquet.',
		customerCredit: 'crèdit',
		onTicket: '1 Ticket des',
		oneCredit: '1 Crèdits',
		amountUsed: 'quantitat utilitzada',
		creditLoadDataError:
			'Vaja ... tenim un problema amb la visualització de les dades crediticis. Torna a carregar la pàgina o torna a intentar-ho més tard.',
		noFoundCredit: 'No hi ha crèdit disponible',
		selectOneCredit: 'Seleccioneu a el menys un crèdit',
		pageFormat: 'Format A4',
		toPageFormat: 'a formato A4',
		defaultLabel: 'Etiqueta predeterminada :',
		sendTicketPDF: 'Enviar cada tiquet com un PDF separat',
		ticketDetails: 'Detallat',
		ticketNoDetails: 'No detallat',
		ticketDetailsMultiple: 'Detallat(s)',
		ticketNoDetailsMultiple: 'No detallat(s)',
		ticketSend: 'Enviar a',
		ticketSendCorrectly: "El missatge s'ha enviat correctament",
		sendMailError:
			'Vaja, hem trobat un problema en enviar el correu electrònic ...',
		amountCredit: 'Quantitat Crèdits',
		amountIvat: 'Quantitat Impuestos',
		paidAt: 'Pagat el ',
		a: 'a',
		and: ' y ',
		hello: 'Hola ',
		yourBill: 'La seva factura',
		labelDetailsDefault: 'Àpats complet(s)',
		defaultSendMail: ', adjuntareu les factures de l’establiment ',
	},
	globalLabels: {
		filters: 'Filtres',
		date: 'Date',
		companies: 'Établissement(s)',
		allCompanies: 'Tous les établissements',
		shifts: 'Service(s)',
		allShifts: 'Tous les services',
		validate: 'Valider',
		noData: 'Aucunes données',
		services: 'Afficher hors services',
	},

	barDistrib: {
		products: 'Produits',
		totalProductsCashed: 'Total produits encaissés',
		waresShift: 'Tirages services',
		waresOutShift: 'Tirages hors services',
		totalWares: 'Total tirages',
		difference: 'Différence',
		wares: 'Tirages',
	},

	barDistribDetail: {
		product: 'Produit',
		totalProductsCashed: 'Encaissés',
		user: 'Poste de tirage',
		wareHour: 'Heure de tirage',
		totalProductsNotCashed: 'Quantités non encaissées',
		difference: 'Différence',
		title: 'Rapport detaillé : ',
		titleNotCashed: 'Tirages non encaissés durant service',
		titleOutShift: 'Tirages hors service',
		titleProductsCashed: 'Produits encaissés',
	},
	exportReport: {
		exportDataTitle: 'Export des données',
		exportType: `Type d'export`,
		exportOrderData: 'Export lignes de ventes',
		exportAccountType: 'Type de compte',
		accounts: 'Comptes',
	},
};
