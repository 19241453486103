import {Component} from 'react';

class ConnectedAs extends Component {

  componentDidMount() {
    const {viewAsCustomerToken} = this.props;
    if (viewAsCustomerToken) {
      window.localStorage.viewAsCustomerToken = viewAsCustomerToken;
      window.location.replace('/');
    }
  }

  render() {
    return null;
  }
}

export default ConnectedAs;
