import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import NumberFormat from "react-number-format";
import { changeActionName } from "./utils";

class TableServiceHistorique extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      open: false,
      comment: "",
      activeRow: -1
    };

    this.handleClickDetails = this.handleClickDetails.bind(this);
  }

  handleClickDetails(rowInfo) {
    // this.props.goToDetails(rowInfo);
  }

  render() {
    let { data } = this.props;

    let creationObject = {};
    let updateObject = {};
    let dataArray = [];
    if (data) {
      Object.keys(data).map(elem => {
        if (data[elem].action == "create") {
          Object.keys(data[elem]).map(property => {
            creationObject[property] = data[elem][property];
            creationObject[data[elem].key_] = data[elem].value;
          });
        }
        if (data[elem].action == "update") {
          Object.keys(data[elem]).map(property => {
            updateObject[property] = data[elem][property];
            updateObject[data[elem].key_] = data[elem].value;
          });
        }
      });
      dataArray.push(creationObject);
      dataArray.push(updateObject);
    }

    if (dataArray && dataArray.length > 0) {
      dataArray.forEach(element => {
        element.user_fullname = String(element.lastname + element.firstname);
        if (["create", "update", "delete"].includes(element.action)) {
          element.action = changeActionName(element.action);
        }
        if (element.opening_date && element.opening_date != "0") {
          element.formatOpen = moment
            .unix(element.opening_date)
            .format("DD-MM-YYYY H:mm:ss");
        } else {
          element.formatOpen = "";
        }
        if (element.closing_date && element.closing_date != "0") {
          element.formatClose = moment
            .unix(element.closing_date)
            .format("DD-MM-YYYY H:mm:ss");
        } else {
          element.formatClose = "";
        }
      });
    }

    let columnsTable = [
      {
        Header: "Action",
        accessor: "action",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search
                  style={{
                    color: "white",
                    lineHeight: "17.4px",
                    verticalAlign: "middle"
                  }}
                />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "ID tracking", accessor: "id", pivot: true },
      { Header: "Ouverture", accessor: "formatOpen", pivot: true },
      { Header: "Fermeture", accessor: "formatClose", pivot: true },
      {
        Header: "Fond de caisse",
        accessor: "cash_float",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      {
        Header: "CA",
        accessor: "turnover",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      { Header: "Commentaire", accessor: "comment", pivot: true },
      { Header: "Caisse", accessor: "device", pivot: true },
      { Header: "Version", accessor: "app_version", pivot: true },
      { Header: "Utilisateur", accessor: "user_fullname", pivot: true }
    ];

    let columnsTableExport = [
      { label: "Action", key: "action" },
      { label: "ID tracking", key: "id" },
      { label: "Ouverture", key: "formatOpen" },
      { label: "Fermeture", key: "formatClose" },
      { label: "Fond de caisse", key: "cash_float" },
      { label: "CA", key: "turnover" },
      { label: "Commentaire", key: "comment" },
      { label: "Caisse", key: "device" },
      { label: "Version", key: "app_version" },
      { label: "Utilisateur", key: "userName" }
    ];

    return (
      <div>
        <ReactTable
          className=" -highlight -striped"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo);
              }
            };
          }}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-servicesHistorique.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload
                    style={{ lineHeight: "20px", verticalAlign: "middle" }}
                  />{" "}
                  Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableServiceHistorique;
