import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ArrowDownSvg from '../Filters/ArrowDownSvg';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../sass/dataExport.css';
import { Button } from '@material-ui/core';
import { data } from 'jquery';
import { chunk } from 'lodash';

const styles = theme => ({
	selectRoot: {
		minHeight: '39px',
		backgroundColor: 'white',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.18)',
		paddingTop: '5px',
		paddingLeft: '10px',
	},
	select: {
		height: '27px',
		paddingRight: 0,
		minHeight: '27px',
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},

	menu: {
		'&:focus': {
			backgroundColor: 'none',
			border: 'none',
		},
	},
	paper: {
		marginTop: '5px',
		height: '212px',
		borderRadius: '0',
		maxWidth: '209px',
		backgroundColor: 'white',
		boxShadow: '0 2px 38px rgba(0, 0, 0, 0.1)',
	},
	menuList: {
		paddingTop: '0px',
	},
	listItem: {
		fontSize: '14px',
		padding: '5px 11px',
	},
	menuItem_selected: {
		color: 'red!important',
		backgroundColor: 'yellow',
	},
});
class Filters extends Component {
	constructor(props) {
		super(props);
		this._getMenuItems = this._getMenuItems.bind(this);
		this._handleSelectChange = this._handleSelectChange.bind(this);
		this._handleSelectClose = this._handleSelectClose.bind(this);

		this.state = {
			selectedValue: this.props.multiple
				? []
				: [
						{
							key: 1,
							label: this.props.datas[0][this.props.paramsLabel],
							[this.props.paramsValue]:
								this.props.datas[0][this.props.paramsValue],
						},
				  ],
			allSelected: this.props.multiple,
			filterLabel: this.props.label,
		};
	}

	componentDidMount() {
		// const allDatasFormatted = this.props.datas.map((d, idx) => ({
		// 	key: idx,
		// 	label: d[this.props.paramsLabel],
		// 	[this.props.paramsValue]: d[this.props.paramsValue],
		// }));
	}

	_getMenuItems = (items, params) => {
		if (items) {
			return items.map((el, idx) => {
				const itemsIsSelected = this.state.allSelected
					? true
					: this.props.multiple
					? this.state.selectedValue.some(e => e[params] === el[params])
					: this.state.selectedValue && this.state.selectedValue.length > 0
					? this.state.selectedValue[0][params] === el[params]
					: '';
				return (
					<MenuItem
						key={idx}
						disableGutters={true}
						value={el}
						classes={{
							root: this.props.classes.listItem,
							selected: this.props.classes.menuItem_selected,
						}}
						id={`filters_menu_item${
							this.props.isNumberData ? '_numbers' : ''
						}`}>
						<div
							className={`filter_menu_select_icon${
								itemsIsSelected ? '_selected' : ''
							}`}></div>
						<p key={`${Math.floor(Math.random() * 100)}`}>
							{el[this.props.paramsLabel]}
						</p>
					</MenuItem>
				);
			});
		}
	};

	_handleSelectClose = e => {
		if (this.state.selectedValue.length > 0) {
			const formattedDatas = this.state.selectedValue.map(el => {
				return { [this.props.paramsValue]: el[this.props.paramsValue] };
			});
			this.props.onClose(formattedDatas);
		} else {
			this.props.onClose([]);
		}
	};

	_handleSelectChange = (e, child) => {
		if (e.target && e.target.value) {
			const isValueSelected = this.state.selectedValue.some(
				el =>
					el[this.props.paramsValue] ===
					e.target.value[1][this.props.paramsValue]
			);
			if (this.props.multiple) {
				if (e.target.value[1][this.props.paramsValue] === 'select_all') {
					const allDatasFormatted = this.props.datas.map((d, idx) => ({
						key: idx,
						label: d[this.props.paramsLabel],
						[this.props.paramsValue]: d[this.props.paramsValue],
					}));
					this.setState({
						selectedValue: allDatasFormatted,
						allSelected: true,
						filterLabel: this.props.label,
					});
				} else {
					if (
						this.state.selectedValue.length === 1 &&
						e.target.value[1][this.props.paramsValue] ===
							this.state.selectedValue[0][this.props.paramsValue]
					) {
						return false;
					}

					if (isValueSelected) {
						this.setState(
							prevState => ({
								selectedValue: prevState.selectedValue.filter(s => {
									return (
										s[this.props.paramsValue] !=
										e.target.value[1][this.props.paramsValue]
									);
								}),
								allSelected: false,
							}),
							() => {
								this.setState({
									filterLabel: this.state.selectedValue
										.map(el => el['label'])
										.join(','),
								});
							}
						);
					} else {
						console.log(e.target.value[1][this.props.paramsLabel]);
						this.setState(
							prevState => ({
								selectedValue: [
									...this.state.selectedValue,
									{
										label: e.target.value[1][this.props.paramsLabel],
										key: child.key,
										[this.props.paramsValue]:
											e.target.value[1][this.props.paramsValue],
									},
								],
								allSelected:
									prevState.selectedValue.length + 1 ===
									this.props.datas.length,
							}),
							() => {
								this.setState(prevState => {
									return {
										filterLabel: this.state.allSelected
											? this.props.label
											: this.state.selectedValue
													.map(el => el['label'])
													.join(','),
									};
								});
							}
						);
					}
				}
			} else {
				if (isValueSelected) {
					return false;
				}

				this.setState({
					selectedValue: [
						{
							label: e.target.value[this.props.paramsLabel],
							key: child.key,
							[this.props.paramsValue]: e.target.value[this.props.paramsValue],
						},
					],
					filterLabel: e.target.value[this.props.paramsLabel],
				});
			}
		}
	};

	render() {
		const { classes, inputClassName, inputLabelClassName } = this.props;
		return (
			<ClickAwayListener onClickAway={e => this._handleSelectClose(e)}>
				<Select
					disableUnderline
					onClose={e => this._handleSelectClose(e)}
					disabled={
						this.props.datas && this.props.datas.length > 1 ? false : true
					}
					className={inputClassName}
					value={[
						<p
							className={inputLabelClassName}
							key={`${Math.floor(Math.random() * 100)}`}>
							{this.state.filterLabel}
						</p>,
					]}
					onChange={this._handleSelectChange}
					multiple={this.props.multiple}
					MenuProps={{
						getContentAnchorEl: null,
						classes: { paper: classes.paper },
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						transformOrigin: { horizontal: 'center', vertical: 'top' },
						MenuListProps: { classes: { root: classes.menuList } },
					}}
					classes={{
						root: classes.selectRoot,
						select: classes.select,
						selectMenu: classes.menu,
					}}
					renderValue={value => value}>
					{!this.state.multiple ? (
						<MenuItem
							key={Math.floor(Math.random() * 100).toString()}
							value={{
								[this.props.paramsValue]: 'select_all',
								[this.props.paramsLabel]: this.props.label,
							}}
							selected={this.state.allSelected}
							classes={{
								root: classes.listItem,
								selected: classes.menuItem_selected,
							}}
							id="filters_menu_item">
							<div
								className={`filter_menu_select_icon${
									this.state.allSelected ? '_selected' : ''
								}`}></div>
							<p>{this.props.label} </p>
						</MenuItem>
					) : null}
					{this.props.datas
						? this._getMenuItems(this.props.datas, this.props.paramsValue)
						: null}
				</Select>
			</ClickAwayListener>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Filters);
