import React from 'react';

const InfosSvg = props => (
	<svg
		width="35"
		height="35"
		viewBox="0 0 200 200"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>icon / info</title>
		<desc>Created with Sketch.</desc>
		<defs></defs>
		<g
			id="icon-/-info"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd">
			<rect id="Rectangle" x="0" y="0" width="200" height="200"></rect>
			<g
				id="icon-/-info-filaire"
				transform="translate(55.000000, 55.000000)"
				fill="#4A4A4A">
				<path
					d="M45,84.1935484 C66.6459991,84.1935484 84.1935484,66.6459991 84.1935484,45 C84.1935484,23.3540009 66.6459991,5.80645161 45,5.80645161 C23.3540009,5.80645161 5.80645161,23.3540009 5.80645161,45 C5.80645161,66.6459991 23.3540009,84.1935484 45,84.1935484 Z M45,90 C20.1471863,90 0,69.8528137 0,45 C0,20.1471863 20.1471863,0 45,0 C69.8528137,0 90,20.1471863 90,45 C90,69.8528137 69.8528137,90 45,90 Z"
					id="Oval-6"
					fillRule="nonzero"></path>
				<circle id="Oval-26" cx="45" cy="26" r="6"></circle>
				<rect
					id="Rectangle-29"
					x="39"
					y="38"
					width="12"
					height="35"
					rx="6"></rect>
			</g>
		</g>
	</svg>
);

export default InfosSvg;
