import React, { Component } from "react";

class DocSlite extends Component {
  render() {
    return (
      <div
        style={{
          height: "calc(100vh - 140px)"
        }}
      >
        <iframe
          src="https://9f654e8f.ngrok.io/p/channel/5ks2aJh7253BdLmUJYhgf2"
          width="100%"
          style={{ height: "100vh", border: "none", marginTop: "-120px" }}
        />
      </div>
    );
  }
}

export default DocSlite;
