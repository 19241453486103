import { CALL_API } from 'redux-api-middleware';
import detectIe from './detectIe';
import axios from 'axios';

export default function setAuthorizationToken() {
	return ({ dispatch }) => (next) => (action) => {
		if (detectIe()) {
			const token = window.localStorage.accessToken;
			if (token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			} else {
				delete axios.defaults.headers.common['Authorization'];
			}
			return next(action);
		} else {
			const callApi = action[CALL_API];
			const token = window.localStorage.accessToken;
			if (!callApi) {
				return next(action);
			}

			if (callApi && callApi.types[0].indexOf('TOKEN') >= 0) {
				callApi.headers = Object.assign({}, callApi.headers, {
					Authorization: ''
				});
				return next(action);
			}

			if (
				(callApi && !callApi.types[0].indexOf('TOKEN') >= 0 && callApi.types[0].indexOf('REQUEST') >= 0) ||
				(callApi && !callApi.types[0].indexOf('TOKEN') >= 0 && callApi.types[0].indexOf('FETCH') >= 0)
			) {
				callApi.headers = Object.assign({}, callApi.headers, {
					Authorization: `Bearer ${token}` || ''
				});
			}

			return next(action);
		}
	};
}
