import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import '../css/fisc.css';
import LogoLoader from './../../../components/LogoLoader';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fr';
import { I18n } from 'react-redux-i18n';
import Input from '../../../components/Input/Input';
import Validator from 'validator';
import CloseModal from '../../CustomerAccount/svg/CloseModal';
//import Input from "../../components/Input/Input";
moment.locale('fr');

const btnStyle = {
	color: 'white',
	fontFamily: 'FuturaBook',
	fontSize: 'smaller',
	marginLeft: '23px',
	borderRadius: '20px',
	textTransform: 'none',
	width: '261px',
	fontSize: '15px',
	letterSpacing: '0',
};

class ModalFisc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: props.open,
			loading: false,
			startDate: moment().subtract(1, 'y').add(1, 'd').format('YYYYMMDD'),
			email: props.user.email || '',
			endDate: moment().format('YYYYMMDD'),
		};
	}

	onChange = e => {
		e.preventDefault();
		this.setState({ [e.target.name]: e.target.value });
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.open != this.state.open) {
			this.setState({ open: nextProps.open });
		}
	}

	_areDatesValids = (startDate, endDate) => {
		if (!startDate || !endDate) return false;
		const start = moment(this.state.startDate);
		const end = moment(this.state.endDate);

		if (!end.isSameOrAfter(start)) {
			this.setState({
				errorMessage: I18n.t('fisc.modalFisc.exportErrorReversedDates'),
			});
			return false;
		}

		if (moment(endDate).diff(startDate, 'years') !== 0) {
			this.setState({
				errorMessage: I18n.t('fisc.modalFisc.exportErrorOneYearMax'),
			});
			return false;
		}

		this.setState({ errorMessage: '' });
		return true;
	};

	_handleStartDayChange = day => {
		if (!day) return;
		let startDate = moment(day).format('YYYYMMDD');
		this.setState({ startDate, errorMessage: '' });
	};
	_handleEndDayChange = day => {
		if (!day) return;
		const endDate = moment(day).format('YYYYMMDD');
		this.setState({ endDate, errorMessage: '' });
	};

	exportData = async (e, endPoint, userId) => {
		e.preventDefault();

		if (!this._areDatesValids(this.state.startDate, this.state.endDate)) return;

		this.setState({ loading: true });
		await this.props.exportCashing(
			this.state.startDate,
			this.state.endDate,
			endPoint,
			userId,
			this.state.email
		);
		this.setState({ loading: false });
	};

	render() {
		const userId =
			this.props && this.props.user && this.props.user.id
				? this.props.user.id
				: '';
		let stylebtn = { ...btnStyle };
		if (!Validator.isEmail(this.state.email)) {
			stylebtn = {
				...btnStyle,
				color: 'rgba(0, 0, 0, 0.26)',
				boxShadow: 'none',
				backgroundColor: 'rgba(0, 0, 0, 0.12)!important',
			};
		}
		let emailInputStyle = {
			margin: '8px 8px 8px 15px',
		};
		return (
			<div>
				<Modal
					showCloseIcon={false}
					closeOnOverlayClick={!this.state.loading}
					classNames={{ modal: 'fiscal_modal_export' }}
					open={this.state.open}
					onClose={() => {
						if (this.state.loading) return false;
						this.props.onClose();
					}}>
					{this.state.loading ? (
						<LogoLoader
							hidden={false}
							label={I18n.t('fisc.modalFisc.waitingDownloadData')}
						/>
					) : (
						<div className="fiscal_modal_content">
							<div
								className="fiscal_close_icon"
								onClick={e => {
									if (this.state.loading) return false;
									this.props.onClose();
								}}>
								<CloseModal />
							</div>
							<h3>{I18n.t('fisc.modalFisc.exportSavedData')}</h3>
							<div>
								<div className="input_container">
									<div className="input_main">
										<p>{I18n.t('fisc.modalFisc.begin')}</p>
										<DayPickerInput
											local="fr"
											value={`${formatDate(this.state.startDate, 'L', 'fr')}`}
											formatDate={formatDate}
											parseDate={parseDate}
											placeholder={`${formatDate(
												this.state.startDate,
												'L',
												'fr'
											)}`}
											onDayChange={this._handleStartDayChange}
											dayPickerProps={{
												locale: 'fr',
												localeUtils: MomentLocaleUtils,
												modifiers: {},
											}}
										/>
									</div>
									<div>
										<p>{I18n.t('fisc.modalFisc.end')}</p>
										<DayPickerInput
											local="fr"
											formatDate={formatDate}
											parseDate={parseDate}
											value={`${formatDate(this.state.endDate, 'L', 'fr')}`}
											placeholder={`${formatDate(
												this.state.endDate,
												'L',
												'fr'
											)}`}
											onDayChange={this._handleEndDayChange}
											dayPickerProps={{
												locale: 'fr',
												localeUtils: MomentLocaleUtils,
												fromMonth: moment(this.state.endDate)
													.subtract(1, 'y')
													.toDate(),
												toMonth: moment(this.state.startDate)
													.add(1, 'y')
													.toDate(),
												modifiers: {
													disabled: [
														{
															after: moment(this.state.startDate)
																.add(1, 'y')
																.toDate(),
														},
														{
															before: moment(this.state.endDate)
																.subtract(1, 'y')
																.toDate(),
														},
													],
												},
											}}
										/>
									</div>
								</div>
								<div className="error-export-fisc">
									<p>{this.state.errorMessage}</p>
								</div>
							</div>
							<div className="fisc_email_input">
								<Input
									label={I18n.t('lneExport.emailHint')}
									type="email"
									name="email"
									required="required"
									idInput="email-input"
									//onFocus={this.onFocus}
									onChange={this.onChange}
									//onBlur={this.onBlur}
									value={this.state.email}
									colorInput={
										Validator.isEmail(this.state.email || '')
											? '#7cc4ac'
											: '#e84249'
									}
								/>
							</div>
							<div className="fiscal_btn_container">
								<Button
									className={
										Validator.isEmail(this.state.email)
											? 'btn-export-fisc-first'
											: ''
									}
									style={stylebtn}
									disabled={!Validator.isEmail(this.state.email || '')}
									variant="contained"
									onClick={e => this.exportData(e, 'export-xls', userId)}>
									{I18n.t('fisc.modalFisc.exportCashing')}
								</Button>
								<Button
									className={
										Validator.isEmail(this.state.email)
											? 'btn-export-fisc-second'
											: ''
									}
									style={stylebtn}
									variant="contained"
									disabled={!Validator.isEmail(this.state.email || '')}
									onClick={e =>
										this.exportData(e, 'export-xls-cloture', userId)
									}>
									{I18n.t('fisc.modalFisc.exportClosing')}
								</Button>
							</div>
						</div>
					)}
				</Modal>
			</div>
		);
	}
}

export default ModalFisc;
