import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { I18n } from "react-redux-i18n";

function DocumentationItem({
  backgroundUrl,
  pdfUrl,
  pdfName,
  description,
  title,
  author
}) {
  let active = false;
  const touchStart = e => {
    active = true;
  };

  const touchEnd = e => {
    active = false;
  };
  return (
    <li
      className={classNames("book-item col-xs-12 col-md-6 col-lg-4 columns", {
        "book-item-active": active
      })}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
    >
      <div className="bk-img col-xs-12 col-md-4">
        <div className="bk-wrapper">
          <div className="bk-book bk-bookdefault">
            <div className="bk-front">
              <div
                className="bk-cover"
                style={{ backgroundImage: `url(${backgroundUrl})` }}
              />
            </div>
            <div className="bk-back" />
            <div className="bk-left" />
          </div>
        </div>
      </div>
      <div className="item-details col-xs-12 col-md-6">
        <h3 className="book-item_title">{title}</h3>
        <p className="author">
          {I18n.t("documentation.by")} {author}
        </p>
        <p>{description}</p>
        <a className="button " target="_blank" href={pdfUrl} download={pdfName}>
          {I18n.t("documentation.download")}
        </a>
      </div>
    </li>
  );
}

DocumentationItem.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,
  pdfUrl: PropTypes.string.isRequired,
  pdfName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired
};

export default DocumentationItem;
