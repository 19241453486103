import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";
import NumberFormat from "react-number-format";

class TableCommandes extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  handleClickDetails(rowInfo, columns) {
    this.props.goToDetails(rowInfo, columns);
  }

  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem]);
      });
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value),
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Ouverture", accessor: "formatOpen", pivot: true },
      { Header: "Fermeture", accessor: "formatClose", pivot: true },
      {
        Header: "Total TTC",
        accessor: "amount_total_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total HT",
        accessor: "amount_total_evat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total TVA",
        accessor: "amount_tax",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Remises",
        accessor: "amount_discount_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Offerts",
        accessor: "amount_offered_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total TTC Brut",
        accessor: "amount_total_wo_discount_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Utilisateur",
        accessor: "user_fullname",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value)
      },
      { Header: "Version", accessor: "pos_device_app_version", pivot: true }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id" },
      { label: "Ouverture", key: "formatOpen" },
      { label: "Fermeture", key: "formatClose" },
      { label: "Total TTC", key: "amount_total_ivat" },
      { label: "Total HT", key: "amount_total_evat" },
      { label: "Total TVA", key: "amount_tax" },
      { label: "Remises", key: "amount_discount_ivat" },
      { label: "Offerts", key: "amount_offered_ivat" },
      { label: "Total TTC Brut", key: "amount_total_wo_discount_ivat" },
      { label: "Utilisateur", key: "user_fullname" },
      { label: "Version", key: "pos_device_app_version" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        if (typeof element != "undefined") {
          element.amount_total_ivat = Number(element.amount_total_ivat).toFixed(2);
          element.amount_total_evat = Number(element.amount_total_evat).toFixed(2);
          element.amount_tax = Number(element.amount_tax).toFixed(2);
          element.amount_discount_ivat = Number(element.amount_discount_ivat).toFixed(2);
          element.amount_offered_ivat = Number(element.amount_offered_ivat).toFixed(2);
          element.amount_total_wo_discount_ivat = Number(element.amount_total_wo_discount_ivat).toFixed(2);
          element.user_fullname = element.user_firstname + " " + element.user_lastname;
          element.formatClose = moment(element.closing_date).format("DD-MM-YYYY H:mm:ss");
          element.formatOpen = moment(element.opening_date).format("DD-MM-YYYY H:mm:ss");
        }
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped clickAble"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            let { columns } = state;
            return {
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo, columns);
              }
            };
          }}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-commandes.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                {" "}
                <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau{" "}
              </button>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableCommandes;
