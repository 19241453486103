import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";
import NumberFormat from "react-number-format";

//prend donnees directement en tableau
class TableCloture extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClickDetails = this.handleClickDetails.bind(this);
  }

  handleClickDetails(rowInfo, columns) {
    this.props.goToDetails(rowInfo, columns);
  }

  render() {
    let { data, columns } = this.props;

    let selectType = [
      { label: "Clôture journalière" },
      { label: "Grand total perpétuel (journalière)" },
      { label: "Clôture mensuelle" },
      { label: "Grand total perpétuel (mensuel)" },
      { label: "Clôture annuelle" },
      { label: "Grand total perpétuel (annuel)" }
    ];

    let columnsTable = [
      {
        Header: "Type",
        accessor: "typeName",
        pivot: true,
        headerStyle: { minWidth: "150px" },
        resizable: true,
        style: { minWidth: "150px" },
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value),
        Filter: ({ filter, onChange }) => {
          return (
            <div>
              <MuiThemeProvider>
                <div style={{ zIndex: "13921" }}>
                  <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />

                  {/* <input
                                    onChange={event => onChange(event.target.value)}
                                    value={filter ? filter.value : ""}
                                    style={{
                                        color: "black",
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        width: "80%"
                                    }}
                                /> */}
                  <Autocomplete
                    getItemValue={item => item.label}
                    items={selectType}
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          color: "black",
                          background: isHighlighted ? "lightgray" : "white"
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                    // shouldItemRender={(item, filter) =>
                    //     filter ? item.label.toLowerCase().startsWith(filter.toLowerCase()) : false
                    // }
                    value={filter ? filter.value : ""}
                    onChange={e => onChange(e.target.value)}
                    onSelect={(val, filter) => onChange(val)}
                  />
                </div>
              </MuiThemeProvider>
            </div>
          );
        }
      },
      {
        Header: "Ouverture",
        accessor: "formatStart",
        pivot: true,
        headerStyle: { minWidth: "150px" },
        resizable: true,
        style: { minWidth: "150px" }
      },
      {
        Header: "Fermeture",
        accessor: "formatEnd",
        pivot: true,
        headerStyle: { minWidth: "150px" },
        resizable: true,
        style: { minWidth: "150px" }
      },
      {
        Header: "Total TTC",
        accessor: "amount_total_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total HT",
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        accessor: "amount_total_evat",
        pivot: true,
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total TVA",
        accessor: "amount_tax",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Offerts TTC",
        accessor: "amount_offered_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Remises TTC",
        accessor: "amount_discount_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "TTC Brut",
        accessor: "amount_total_wo_discount_ivat",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Total Règlements",
        accessor: "amount_payment_shift",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      }
    ];

    let headersExport = [
      { label: "Type", key: "typeName" },
      { label: "Ouverture", key: "formatStart" },
      { label: "Fermeture", key: "formatEnd" },
      { label: "Total TTC", key: "amount_total_ivat" },
      { label: "Total HT", key: "amount_total_evat" },
      { label: "Total TVA", key: "amount_tax" },
      { label: "Offerts TTC", key: "amount_offered_ivat" },
      { label: "Remises TTC", key: "amount_discount_ivat" },
      { label: "TTC Brut", key: "amount_total_wo_discount_ivat" },
      { label: "Total Règlements", key: "amount_payment_shift" }
    ];

    //nouvelle propriété dans l'objet comme ça on ne change jamais les données initiales et
    //pas d'erreur possible en cas de multiples transformations
    if (data && data.length > 0) {
      data.forEach(element => {
        let typeName = "";
        if (Number.isInteger(element.type)) {
          switch (element.type) {
            case 1:
              typeName = "Clôture journalière";
              break;
            case 2:
              typeName = "Grand total perpétuel (journalière)";
              break;
            case 3:
              typeName = "Clôture mensuelle";
              break;
            case 4:
              typeName = "Grand total perpétuel (mensuel)";
              break;
            case 5:
              typeName = "Clôture annuelle";
              break;
            case 6:
              typeName = "Grand total perpétuel (annuel)";
              break;
            default:
              typeName = "";
              break;
          }
          element.typeName = typeName;
        }
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped clickAble"
          style={{ textAlign: "center", borderRadius: "10px", border: 0, maxHeight: "85vh" }}
          data={data}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            let { columns } = state;
            return {
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo, columns);
              }
            };
          }}
        />
        {data ? (
          <CSVLink
            style={{ float: "right" }}
            data={data}
            filename="export-clotures.csv"
            headers={headersExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau
              </button>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableCloture;
