import React, { Component, useEffect, useState } from 'react';
import '../sass/dataExport.css';
import HeaderExport from './HeaderExport';
import { I18n } from 'react-redux-i18n';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Filters from '../components/Filters/Filters';
import DatePicker from '../../BarDistrib/components/DatePicker/DatePicker';
import ReactTable from 'react-table';
import Input from '../../../components/Input/Input';
import { Button } from '@material-ui/core';
import ImgCalendar from '../components/Filters/calendar.svg';

import { connect } from 'react-redux';
import CallApi from '../../../utils/CallApi';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';

import { dataExportLabels } from '../constant';
import moment from 'moment';

const groupingOrders = [
	{
		id: 'shift',
		label: 'Services',
	},
	{
		id: 'day',
		label: 'Jours',
	},
	{
		id: 'week',
		label: 'Semaine',
	},
	{
		id: 'month',
		label: 'Mois',
	},
	{
		id: 'year',
		label: 'Années',
	},
];

const exportTypes = [
	{ label: 'Lignes de ventes', route: 'dataExport/getOrderLines' },
	// {
	// 	label: 'Lignes de ventes à emporter',
	// 	route: 'dataExport/getDeliveryOrderLines',
	// },
];
const orderTypes = [
	{ label: 'Sur place', type: 1 },
	{
		label: 'À emporter',
		type: 2,
	},
	{
		label: 'Livraison',
		type: 3,
	},
];

function DataExport({ companies, user, addFlashMessage }) {
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [selectedOrderTypeAway, setSelectedOrderTypeAway] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [dateBegin, setDateBegin] = useState(
		moment().subtract(7, 'days').toDate()
	);
	const [dateBeginLabel, setDateBeginLabel] = useState(
		moment().subtract(7, 'days').format('DD-MM-YYYY')
	);
	const [dateEnd, setDateEnd] = useState(moment().toDate());
	const [dateEndLabel, setDateEndLabel] = useState(
		moment().format('DD-MM-YYYY')
	);
	const [datas, setDatas] = useState([]);
	const [orderLabels, setOrderLabels] = useState('orderLines');
	const [userEmail, setUserEmail] = useState(user.email);
	const [dataLoading, setDataLoading] = useState(false);
	const [exportRoute, setExportRoute] = useState('dataExport/getOrderLines');

	useEffect(() => {
		getDataExport();
	}, [
		dateBegin,
		dateEnd,
		exportRoute,
		selectedOrderTypeAway,
		selectedCompanies,
	]);

	const onDatePickerValidate = params => {
		if (
			moment(dateBegin).format('DD-MM-YYYY') !==
				moment(params.from).format('DD-MM-YYYY') ||
			moment(dateEnd).format('DD-MM-YYYY') !==
				moment(params.enteredTo).format('DD-MM-YYYY')
		) {
			setDateBeginLabel(params.fromLabel);
			setDateBegin(params.from);
			setDateEndLabel(params.toLabel);
			setDateEnd(params.enteredTo);
			setOpenDatePicker(false);
		}
	};

	const formateDate = (data, formatDisplay) => {
		return moment(data).format(formatDisplay);
	};

	const getDataExport = async () => {
		const accessToken = window.localStorage.getItem('accessToken');

		const tmpOrderTk =
			selectedOrderTypeAway && selectedOrderTypeAway.length > 0
				? selectedOrderTypeAway
				: orderTypes;
		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;
		setDataLoading(true);
		try {
			const dataToExport = await CallApi(
				'POST',
				exportRoute,
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),

					idCompanies: JSON.stringify(tmpCompanies.map(c => c['id_company'])),
					orderTypeAway: JSON.stringify(tmpOrderTk.map(o => o['type'])),
					limit: true,
				},
				`accessToken ${accessToken}`
			);

			if (dataToExport && dataToExport.length > 0 && dataToExport[0].success) {
				setDatas(dataToExport[0].data);
				setDataLoading(false);
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const exportDataCsv = async () => {
		const accessToken = window.localStorage.getItem('accessToken');

		const tmpOrderTk =
			selectedOrderTypeAway && selectedOrderTypeAway.length > 0
				? selectedOrderTypeAway
				: orderTypes;
		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;

		try {
			const exportCsv = await CallApi(
				'POST',
				'dataExport/exportData',
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: JSON.stringify(tmpCompanies.map(c => c['id_company'])),
					email: userEmail,
					orderTypeAway: JSON.stringify(tmpOrderTk.map(o => o['type'])),
					limit: false,
				},
				`accessToken ${accessToken}`
			);

			if (exportCsv && exportCsv.length > 0 && exportCsv[0].success) {
				addFlashMessage({
					type: 'success',
					text: I18n.t('lneExport.exportProcessing'),
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const onEmailChange = e => {
		e.preventDefault();
		setUserEmail(e.target.value);
	};

	const handleTakeAway = t => {
		if (JSON.stringify(t) !== JSON.stringify(selectedOrderTypeAway)) {
			setSelectedOrderTypeAway([...t]);
		}
	};

	const handleOpenDatePicker = () => {
		setOpenDatePicker(!openDatePicker);
	};

	const handleCompanyChanges = async c => {
		if (JSON.stringify(c) !== JSON.stringify(selectedCompanies)) {
			setSelectedCompanies(c);
		}
	};

	const handleRouteChanges = r => {
		if (r !== exportRoute) {
			setExportRoute(r);
		}
	};

	const columnsFirstLevel = dataExportLabels[orderLabels];

	return (
		<div className="data-export-main">
			<div className="export-header">
				<h3>{I18n.t('exportReport.exportDataTitle')}</h3>
			</div>
			<HeaderExport
				companies={companies}
				selectedCompanies={selectedCompanies}
				orderTypes={orderTypes}
				onTakeAwaySelect={t => handleTakeAway(t)}
				onDatePickerClick={handleOpenDatePicker}
				onCompaniesChange={c => handleCompanyChanges(c)}
				onRouteChanges={r => handleRouteChanges(r)}
				dateBegin={dateBegin}
				dateBeginLabel={dateBeginLabel}
				dateEnd={dateEnd}
				dateEndLabel={dateEndLabel}
			/>
			<div className="export-visualisation-container">
				<span className="export-visualisation-title"> Prévisualisation </span>
				<ReactTable
					columns={columnsFirstLevel}
					showPagination={false}
					data={datas && datas.length > 0 ? datas : []}
					loadingText={I18n.t('fisc.table.loadingText')}
					noDataText={dataLoading ? '' : I18n.t('fisc.table.noDataText')}
					loading={dataLoading}
					resizable={false}
					sortable={false}
					className="visualisation-table"
				/>
				<DatePicker
					open={openDatePicker}
					anchorEl={anchorEl}
					onValidate={params => onDatePickerValidate(params)}
				/>
			</div>
			<div className="export-sender-container">
				<Input
					label={I18n.t('lneExport.emailHint')}
					type="email"
					name="email"
					required="required"
					idInput="email-input"
					value={userEmail}
					onChange={onEmailChange}
				/>
				<Button
					disabled={datas && datas.length > 0 ? false : true}
					variant="contained"
					className={
						datas && datas.length > 0 ? 'btn-export' : 'btn-export disabled'
					}
					onClick={() => {
						exportDataCsv();
					}}>
					Exporter
				</Button>
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		companies: state.entities.informations.undefined.companies,
		user: state.auth.user,
	};
}
export default connect(mapStateToProps, { addFlashMessage })(DataExport);
