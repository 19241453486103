import getAllUrlParams from "./getUrlParams";

export const map = f => x => Array.prototype.map.call(x, f);

export const getOffset = element => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
};

export const deepClone = object => {
  return JSON.parse(JSON.stringify(object));
};


export const getCookieValue = (key) => {
  const cookie = document.cookie.split(';').find(cookie => cookie.includes(key));
  return cookie ? cookie.split('=')[1] : null;
}


export const getRefreshToken = () => {
  const cookieValue = getCookieValue('refreshToken');
  const paramsValue = getAllUrlParams("refreshToken");
  return cookieValue || paramsValue || localStorage.getItem('refreshToken');
}
