import React, { Component } from "react";
import getUrlParams from "../../utils/getUrlParams";
import axios from "axios";
import config from "../../utils/config";
import "./migration.css";
import Card from "../../components/Card/Card";
import successImg from "../../components/Password/img/success.svg";
import errorImg from "../../components/Password/img/error.svg";
import refreshImg from "../../components/Password/img/refresh.svg";
import classnames from "classnames";
import { browserHistory } from "react-router";
import { I18n } from "react-redux-i18n";

const validationText = {
  pending: I18n.t("accountMigration.emailValidation.pending"),
  error1: I18n.t("accountMigration.emailValidation.error1"),
  error2: I18n.t("accountMigration.emailValidation.error2"),
  success: I18n.t("accountMigration.emailValidation.success")
};

class EmailValidation extends Component {
  constructor(props) {
    super();

    this.state = {
      pending: true,
      success: false,
      imgSrc: refreshImg,
      statusText: validationText.pending
    };
  }

  componentDidMount() {
    const token = getUrlParams("verificationToken");
    if (token && token !== undefined) {
      axios
        .post(config.reportingUrl + "api/account/migration/email/verify", {
          token: token
        })
        .then(res => {
          this.setState({
            pending: false,
            success: res.data.success,
            imgSrc: res.data.success ? successImg : errorImg,
            statusText: res.data.success
              ? validationText.success
              : validationText.error2
          });
          window.setTimeout(() => {
            if (res.data.success) {
              browserHistory.push("/");
            }
          }, 5000);
        })
        .catch(err => {
          this.setState({
            pending: false,
            imgSrc: errorImg,
            statusText: validationText.error1
          });
        });
    }
  }

  render() {
    let title = "";
    if (this.state.success && !this.state.pending) {
      title = I18n.t("accountMigration.emailValidation.newLoginSuccess");
    } else if (!this.state.success && !this.state.pending) {
      title = I18n.t("accountMigration.emailValidation.newLoginError");
    } else {
      title = I18n.t("accountMigration.emailValidation.newLoginLoading");
    }
    return (
      <div className="check-email-container">
        {/* <Card title={title}> */}
        <h1>{title}</h1>
        <div className={"email-validation-container"}>
          <div className={"email-validation-img-container"}>
            <img src={this.state.imgSrc} alt="" />
          </div>
          <div
            className={classnames("alert", {
              "alert-danger": !this.state.success && !this.state.pending,
              "alert-success": this.state.success && !this.state.pending,
              "alert-info": this.state.pending
            })}
          >
            {this.state.statusText}
          </div>
        </div>
        {/* </Card> */}
      </div>
    );
  }
}

EmailValidation.propTypes = {};
EmailValidation.defaultProps = {};

export default EmailValidation;
