export const SET_CURRENT_USER                   = 'SET_CURRENT_USER';
export const SET_UNAUTHORIZED_USER              = 'SET_UNAUTHORIZED_USER';
export const SET_SUBUSER_SERVICES               = 'SET_SUBUSER_SERVICES';
export const CHECK_AUTH                         = 'CHECK_AUTH';
export const FETCH_ACCESS_TOKEN_REQUEST         = 'FETCH_ACCESS_TOKEN_REQUEST';
export const FETCH_ACCESS_TOKEN_SUCCESS         = 'FETCH_ACCESS_TOKEN_SUCCESS';
export const FETCH_ACCESS_TOKEN_FAILURE         = 'FETCH_ACCESS_TOKEN_FAILURE';
export const FETCH_CHECK_ACCESS_TOKEN_REQUEST   = 'FETCH_CHECK_ACCESS_TOKEN_REQUEST';
export const FETCH_CHECK_ACCESS_TOKEN_SUCCESS   = 'FETCH_CHECK_ACCESS_TOKEN_SUCCESS';
export const FETCH_CHECK_ACCESS_TOKEN_FAILURE   = 'FETCH_CHECK_ACCESS_TOKEN_FAILURE';
