export const ADD_FISC_ACCESS_REQUEST          = 'ADD_FISC_ACCESS_REQUEST';
export const ADD_FISC_ACCESS_SUCCESS          = 'ADD_FISC_ACCESS_SUCCESS';
export const ADD_FISC_ACCESS_FAILURE          = 'ADD_FISC_ACCESS_FAILURE';
export const ADD_FISC_CHECK_ACCESS_REQUEST    = 'ADD_FISC_CHECK_ACCESS_REQUEST';
export const ADD_FISC_CHECK_ACCESS_SUCCESS    = 'ADD_FISC_CHECK_ACCESS_SUCCESS';
export const ADD_FISC_CHECK_ACCESS_FAILURE    = 'ADD_FISC_CHECK_ACCESS_FAILURE';
export const ADD_FISC_CHECK_ACCESS_SIGNATURE_REQUEST    = 'ADD_FISC_CHECK_ACCESS_SIGNATURE_REQUEST';
export const ADD_FISC_CHECK_ACCESS_SIGNATURE_SUCCESS    = 'ADD_FISC_CHECK_ACCESS_SIGNATURE_SUCCESS';
export const ADD_FISC_CHECK_ACCESS_SIGNATURE_FAILURE    = 'ADD_FISC_CHECK_ACCESS_SIGNATURE_FAILURE';
export const FETCH_TRACKED_CASH_REGISTER_REQUEST = 'FETCH_TRACKED_CASH_REGISTER_REQUEST';
export const FETCH_TRACKED_CASH_REGISTER_SUCCESS = 'FETCH_TRACKED_CASH_REGISTER_SUCCESS';
export const FETCH_TRACKED_CASH_REGISTER_FAILURE = 'FETCH_TRACKED_CASH_REGISTER_FAILURE';
export const RESET_CASH_REGISTER = 'RESET_CASH_REGISTER';
export const FETCH_TRACKED_ORDER_REQUEST = 'FETCH_TRACKED_ORDER_REQUEST';
export const FETCH_TRACKED_ORDER_SUCCESS = 'FETCH_TRACKED_ORDER_SUCCESS';
export const FETCH_TRACKED_ORDER_FAILURE = 'FETCH_TRACKED_ORDER_FAILURE';
export const RESET_ORDERS = 'RESET_ORDERS';
export const FETCH_TRACKED_ORDER_LINE_REQUEST = 'FETCH_TRACKED_ORDER_LINE_REQUEST';
export const FETCH_TRACKED_ORDER_LINE_SUCCESS = 'FETCH_TRACKED_ORDER_LINE_SUCCESS';
export const FETCH_TRACKED_ORDER_LINE_FAILURE = 'FETCH_TRACKED_ORDER_LINE_FAILURE';
export const RESET_ORDERS_LINES = 'RESET_ORDERS_LINES';
export const FETCH_TRACKED_PAYMENT_LINE_REQUEST = 'FETCH_TRACKED_PAYMENT_LINE_REQUEST';
export const FETCH_TRACKED_PAYMENT_LINE_SUCCESS = 'FETCH_TRACKED_PAYMENT_LINE_SUCCESS';
export const FETCH_TRACKED_PAYMENT_LINE_FAILURE = 'FETCH_TRACKED_PAYMENT_LINE_FAILURE';
export const RESET_PAYMENT_LINES = 'RESET_PAYMENT_LINES';
export const FETCH_TRACKED_RECEIPT_REQUEST = 'FETCH_TRACKED_RECEIPT_REQUEST';
export const FETCH_TRACKED_RECEIPT_SUCCESS = 'FETCH_TRACKED_RECEIPT_SUCCESS';
export const FETCH_TRACKED_RECEIPT_FAILURE = 'FETCH_TRACKED_RECEIPT_FAILURE';
export const RESET_RECEIPT = 'RESET_RECEIPT';
export const FETCH_TRACKED_RECEIPT_DETAIL_REQUEST = 'FETCH_TRACKED_RECEIPT_DETAIL_REQUEST';
export const FETCH_TRACKED_RECEIPT_DETAIL_SUCCESS = 'FETCH_TRACKED_RECEIPT_DETAIL_SUCCESS';
export const FETCH_TRACKED_RECEIPT_DETAIL_FAILURE = 'FETCH_TRACKED_RECEIPT_DETAIL_FAILURE';
export const RESET_RECEIPT_DETAIL = 'RESET_RECEIPT_DETAIL';
export const FETCH_TRACKED_RECEIPT_DETAIL_TAX_REQUEST = 'FETCH_TRACKED_RECEIPT_DETAIL_TAX_REQUEST';
export const FETCH_TRACKED_RECEIPT_DETAIL_TAX_SUCCESS = 'FETCH_TRACKED_RECEIPT_DETAIL_TAX_SUCCESS';
export const FETCH_TRACKED_RECEIPT_DETAIL_TAX_FAILURE = 'FETCH_TRACKED_RECEIPT_DETAIL_TAX_FAILURE';
export const RESET_RECEIPT_DETAIL_TAX = 'RESET_RECEIPT_DETAIL_TAX';
export const FETCH_TRACKED_CREDIT_REQUEST = 'FETCH_TRACKED_CREDIT_REQUEST';
export const FETCH_TRACKED_CREDIT_SUCCESS = 'FETCH_TRACKED_CREDIT_SUCCESS';
export const FETCH_TRACKED_CREDIT_FAILURE = 'FETCH_TRACKED_CREDIT_FAILURE';
export const RESET_CREDIT = 'RESET_CREDIT';
export const FETCH_TRACKED_CASH_REGISTER_VARIATION_REQUEST = 'FETCH_TRACKED_CASH_REGISTER_VARIATION_REQUEST';
export const FETCH_TRACKED_CASH_REGISTER_VARIATION_SUCCESS = 'FETCH_TRACKED_CASH_REGISTER_VARIATION_SUCCESS';
export const FETCH_TRACKED_CASH_REGISTER_VARIATION_FAILURE = 'FETCH_TRACKED_CASH_REGISTER_VARIATION_FAILURE';
export const RESET_CASH_REGISTER_VARIATION = 'RESET_CASH_REGISTER_VARIATION';
export const FETCH_TRACKED_CLOTURES_REQUEST = 'FETCH_TRACKED_CLOTURES_REQUEST';
export const FETCH_TRACKED_CLOTURES_SUCCESS = 'FETCH_TRACKED_CLOTURES_SUCCESS';
export const FETCH_TRACKED_CLOTURES_FAILURE = 'FETCH_TRACKED_CLOTURES_FAILURE';
export const RESET_CLOTURES = 'RESET_CLOTURES';
export const FETCH_TRACKED_TRACKING_SERVER_REQUEST = 'FETCH_TRACKED_TRACKING_SERVER_REQUEST';
export const FETCH_TRACKED_TRACKING_SERVER_SUCCESS = 'FETCH_TRACKED_TRACKING_SERVER_SUCCESS';
export const FETCH_TRACKED_TRACKING_SERVER_FAILURE = 'FETCH_TRACKED_TRACKING_SERVER_FAILURE';
export const RESET_TRACKING_SERVER = 'RESET_TRACKING_SERVER';
export const FETCH_SIGNATURE_ERRORS_REQUEST = 'FETCH_SIGNATURE_ERRORS_REQUEST';
export const FETCH_SIGNATURE_ERRORS_SUCCESS = 'FETCH_SIGNATURE_ERRORS_SUCCESS';
export const FETCH_SIGNATURE_ERRORS_FAILURE = 'FETCH_SIGNATURE_ERRORS_FAILURE';
export const RESET_SIGNATURE_ERRORS = 'RESET_SIGNATURE_ERRORS';
export const FETCH_TECHNICAL_INTERVENTIONS_REQUEST = 'FETCH_TECHNICAL_INTERVENTIONS_REQUEST';
export const FETCH_TECHNICAL_INTERVENTIONS_SUCCESS = 'FETCH_TECHNICAL_INTERVENTIONS_SUCCESS';
export const FETCH_TECHNICAL_INTERVENTIONS_FAILURE = 'FETCH_TECHNICAL_INTERVENTIONS_FAILURE';
export const RESET_TECHNICAL_INTERVENTIONS = 'RESET_TECHNICAL_INTERVENTIONS';
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const FETCH_COMMANDES_REQUEST = 'FETCH_COMMANDES_REQUEST';
export const FETCH_COMMANDES_SUCCESS = 'FETCH_COMMANDES_SUCCESS';
export const FETCH_COMMANDES_FAILURE = 'FETCH_COMMANDES_FAILURE';
export const FETCH_TRACKED_HISTORY_REQUEST = "FETCH_TRACKED_HISTORY_REQUEST";
export const FETCH_TRACKED_HISTORY_SUCCESS = "FETCH_TRACKED_HISTORY_SUCCESS";
export const FETCH_TRACKED_HISTORY_FAILURE = "FETCH_TRACKED_HISTORY_FAILURE";
export const RESET_TRACKED_HISTORY = "RESET_TRACKED_HISTORY";
export const FETCH_TRACKED_FULL_HISTORY_REQUEST = "FETCH_TRACKED_FULL_HISTORY_REQUEST";
export const FETCH_TRACKED_FULL_HISTORY_SUCCESS = "FETCH_TRACKED_FULL_HISTORY_SUCCESS";
export const FETCH_TRACKED_FULL_HISTORY_FAILURE = "FETCH_TRACKED_FULL_HISTORY_FAILURE";
export const RESET_TRACKED_FULL_HISTORY = "RESET_TRACKED_FULL_HISTORY";
export const FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_REQUEST = "FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_REQUEST";
export const FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_SUCCESS = "FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_SUCCESS";
export const FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_FAILURE = "FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_FAILURE";
export const FETCH_CONTROL_STRIP_SALES_DOCUMENT_REQUEST = "FETCH_CONTROL_STRIP_SALES_DOCUMENT_REQUEST";
export const FETCH_CONTROL_STRIP_SALES_DOCUMENT_SUCCESS = "FETCH_CONTROL_STRIP_SALES_DOCUMENT_SUCCESS";
export const FETCH_CONTROL_STRIP_SALES_DOCUMENT_FAILURE = "FETCH_CONTROL_STRIP_SALES_DOCUMENT_FAILURE";