import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './card.css';

class Card extends Component {
  render() {
    const {title, message, messageType} = this.props;
    return (
      <div className="card-row">
        <div className="card-container">
          {
            message !== '' ?
              (<div className={'card-message ' + messageType}>
                {message}
              </div>) :
              null
          }
          <div className="card">
            <h1 className="title">{title || ''}</h1>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  messageType: PropTypes.string
};
Card.defaultProps = {
  title: '',
  message: '',
  messageType: 'alert alert-info'
};

export default Card;
