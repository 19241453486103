import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './header.css';

class Header extends Component {
  render() {
    const {title} = this.props;
    return (
      <header className="header-container">
        <h1>{this.props.title}</h1>
      </header>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
};
Header.defaultProps = {
  title: '',
};

export default Header;