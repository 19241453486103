import React from "react";
import Proptypes from "prop-types";
import classnames from "classnames";
import "./PasswordEgal.css";
import { I18n } from "react-redux-i18n";

const PasswordEgal = ({ passwordEgal }) => {
  return (
    <div id={"password-egal-container"}>
      <section className={"password-egal-check"}>
        <h5>{I18n.t("passwordEquals.important")}</h5>
        <ul>
          <li
            className={classnames({
              success: passwordEgal,
              error: !passwordEgal
            })}
          >
            {I18n.t("passwordEquals.identical")}
          </li>
        </ul>
      </section>
    </div>
  );
};

PasswordEgal.propTypes = {
  passwordEgal: Proptypes.bool.isRequired
};

PasswordEgal.defaultProps = {
  passwordEgal: false
};

export default PasswordEgal;
