export default {
    table: {
        previousText:'Previous',
        nextText:'Next',
        loadingText:'Loading...',
        noDataText:'No data found',
        pageText:'Page',
        ofText:'of',
        rowsText:'lines'
    }
}