import React from "react";

export const HelpImg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="47"
    viewBox="50 50 100 100"
    className="iconSvg"
  >
    <path
      id="Forme_1"
      data-name="Forme 1"
      d="M130,49.993H70A20.006,20.006,0,0,0,49.993,70V130a20.007,20.007,0,0,0,20.006,20h60a20,20,0,0,0,20-20V70A20,20,0,0,0,130,49.993ZM98.833,137a5.993,5.993,0,1,1,5.993-5.993A5.994,5.994,0,0,1,98.833,137Zm5.257-28.806v7.1a5.224,5.224,0,0,1-5.351,5.346,5.356,5.356,0,0,1-5.346-5.346V103.936c0-.106.109-0.106,0.109-0.217a0.222,0.222,0,0,1-.109-0.218,5.5,5.5,0,0,1,5.346-5.35,12.229,12.229,0,1,0,0-24.454,12.071,12.071,0,0,0-10.48,6,5.384,5.384,0,0,1-7.423,1.964,5.316,5.316,0,0,1-1.852-7.312A22.942,22.942,0,1,1,104.09,108.193Z"
    />
  </svg>
);

export const BellImg = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="27"
    viewBox="300 300 420 420"
    className="iconSvg"
  >
    <g>
      <path
        d="M485.6,705.6c-9-2.6-17-6.7-23-14.3c-4.6-5.8-7.5-12.3-8.2-19.6c-0.3-3.6-1.7-4.3-4.9-4.3c-32.1,0.1-64.3,0-96.4,0.1
       c-9.4,0-17.8-2.6-25.2-8.5c-12.1-9.8-16.1-22.8-14.2-37.8c1.9-15.2,8.5-28.3,19.9-38.6c14.9-13.6,23.2-31,29.4-49.7
       c7.3-21.8,11.1-44.4,12.9-67.3c0.9-11,1-22.1,1.4-33.1c1.1-27.1,10.6-50.9,28.3-71.4c12.5-14.5,27.8-25.2,45.8-31.9
       c2.4-0.9,3.6-2.3,4.2-4.7c4.8-17.4,19.5-28.7,37.4-28.6c17.6,0,32.3,11.3,36.9,28.6c0.8,3.1,2.8,4.1,5.4,5.1
       c36.6,15.4,60.1,42.3,70,80.8c4,15.5,2.8,31.5,3.8,47.3c2.2,34,7.7,67.2,23,98.1c4.9,9.9,11.2,18.7,19.4,26.2
       c11.4,10.4,17.9,23.2,20.4,38.4c4.2,25.5-13.5,47-39.3,47.1c-32,0.1-64,0.1-96-0.1c-3.6,0-4.8,0.8-5.4,4.7
       c-2.3,15.7-11.2,26.4-26.2,31.9c-1.6,0.6-3.3,1.1-4.9,1.7C495.2,705.6,490.4,705.6,485.6,705.6z M492.5,628.5
       c37.6,0,75.2,0,112.8,0c8.1,0,16.3-0.1,24.4,0c2.4,0,3.3-0.6,3-3.2c-0.6-4.8-2.5-9-5.8-12.4c-3.3-3.5-6.8-6.7-10-10.3
       c-16.2-18-25.9-39.6-33.1-62.4c-10.1-32.3-14.3-65.5-14.5-99.3c-0.1-9.9-1.1-19.6-4.4-29.1c-10.8-31.1-41.9-53.8-77.6-51
       c-22.4,1.8-40.5,11.6-54.5,28.9c-12.1,15-16.6,32.5-16.6,51.4c0.1,22.7-2,45.3-6.5,67.5c-8,39.4-20.7,76.5-51.4,104.7
       c-2.8,2.6-4.2,6.1-5.1,9.8c-1.2,4.8-0.7,5.3,4.1,5.3C402.4,628.5,447.4,628.5,492.5,628.5z"
      />
    </g>
  </svg>
);

export const GiftImg = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="300 290 470 470"
    width="25"
    height="27"
    className="iconSvg"
  >
    <g>
      <path
        d="M499.5,747.5c-48.3,0-96.5,0-144.8,0c-27.5,0-46.5-19.1-46.5-46.5c0-68.4-0.1-136.7,0.1-205.1c0-5.1-1-8.8-5.3-12.4
		c-6.6-5.4-10.4-12.9-10.4-21.8c-0.1-16.4-0.2-32.8,0-49.1c0.2-16.6,14-30.1,30.8-30.4c13.8-0.2,27.5-0.2,41.3,0
		c4.8,0.1,6.9-0.2,4.2-6c-15.9-34.4,3.7-73.9,40.8-81.1c22.5-4.4,42,2.6,56.5,20.9c3.3,4.2,5.4,4.4,10,1.9c16.1-9,32.6-8.9,48.6,0.4
		c4.2,2.4,6.1,1.9,8.9-1.8c18.4-23.8,50.2-29.3,76.3-13.6c24.3,14.6,33.9,46.6,21.6,72.9c-2.5,5.3-1.8,6.7,4.1,6.5
		c13.5-0.3,27.1-0.2,40.6-0.1c17.5,0.2,31.2,13.7,31.3,31.1c0.2,15.7,0,31.5,0,47.2c0,9.3-3.5,17.3-10.6,23
		c-4.1,3.4-5.2,6.8-5.2,11.8c0.1,68.8,0.1,137.6,0,206.4c0,21.9-13.4,39-34.4,44.6c-4.1,1.1-8.2,1-12.4,1
		C596.5,747.5,548,747.5,499.5,747.5z M449.7,510.8c-0.4-0.1-0.9-0.2-1.3-0.3c-1.2,2.1-2.4,4.1-3.6,6.2c-3.3,6.1-7.7,11-15.3,10.2
		c-7.8-0.8-11.4-6.4-13.1-13.6c-1.5-5.9-3.3-11.8-4.7-17.7c-0.9-3.9-2.7-4.8-6.5-3.7c-6.7,2-13.5,3.5-20.2,5.5
		c-4.3,1.3-8.6,0.9-11.8-1.8c-4.1-3.4-8.6-3.8-13.4-3.6c-5.7,0.1-11.4,0.3-17-0.1c-5.6-0.4-7.6,1.4-7.6,7.3
		c0.2,66.2,0.1,132.3,0.1,198.5c0,15.4,7.2,22.7,22.4,22.7c52.9,0.1,105.7,0,158.6,0.1c5,0,6.9-1.2,6.9-6.6
		c-0.2-71.4-0.1-142.8,0-214.2c0-4.7-1.4-6.7-6.3-6.5c-10.7,0.3-21.4,0.4-32.1-0.1c-6.5-0.3-8,2.1-8,8.3
		c0.2,39.3,0.1,78.6,0.1,117.9c0,6.6,0.2,13.1-0.1,19.7c-0.4,8-6.4,13.3-14.3,12.9c-7.9-0.4-12.7-5.9-12.7-15.2
		c-0.1-30.6,0-61.1,0-91.7C449.7,533.5,449.7,522.2,449.7,510.8z M551.3,509.6c-0.4,0.1-0.7,0.3-1.1,0.4c0,68.1,0,136.2-0.1,204.3
		c0,5.2,2.3,6.1,6.7,6.1c28.6-0.1,57.2,0,85.8-0.1c14.5-0.1,21.8-7.1,21.9-21.8c0.1-66.8,0-133.6,0.1-200.4c0-4.4-1.2-6.5-6-6.2
		c-3.9,0.3-7.9,0.3-11.8,0c-4.6-0.4-5.8,1-5.2,5.9c1.3,10.6-6.7,17.5-17.2,15.2c-7.2-1.6-14.4-3.5-21.5-5.6c-3.3-0.9-4.8,0-5.7,3.3
		c-1.6,6.3-3.5,12.6-5,19c-1.7,7.1-5.6,12.1-13,12.9c-7.2,0.8-11.7-3.6-14.9-9.6C560.1,525.1,555.7,517.4,551.3,509.6z M365.1,409.3
		c-12,0-24-0.1-36,0c-7.3,0.1-9.5,2.2-9.5,9.3c-0.1,12.2-0.1,24.4,0,36.6c0.1,7.2,2.2,9.4,9.4,9.4c13.7,0.1,27.5-0.1,41.2,0.1
		c4,0.1,6.4-1.5,8.3-5c8.4-15,16.8-30,25.6-44.9c3-5,1.2-5.8-3.6-5.7C388.6,409.5,376.8,409.3,365.1,409.3z M635.1,409.3
		c-12,0-24,0.1-36,0c-4.2,0-5.8,0.6-3.2,5c8.9,15.3,17.5,30.7,26.1,46.1c1.8,3.3,4.3,4.4,8,4.4c13.7-0.1,27.5,0,41.2-0.1
		c6.8-0.1,9-2.2,9.1-9.1c0.1-12.2,0.1-24.4,0-36.7c-0.1-7.6-2.2-9.6-9.9-9.7C658.6,409.3,646.8,409.3,635.1,409.3z M428.8,381.3
		c7.1,0,12.1,0,17.1,0c3,0,5.2-1,5-4.5c-0.3-10.2,1-20.5-1-30.7c-2.6-13.5-13.7-23.4-27.8-24.6c-12.5-1-25.6,8-29.7,20.4
		c-4.4,13.3,0.5,26.8,11.8,34.6C412.6,382.3,421.8,381.2,428.8,381.3z M568.2,381.3c0,0,0-0.1,0-0.1c4.1,0,8.3,0.2,12.4,0
		c15.8-0.8,27.4-12.5,27.9-28.1c0.6-16.7-10.1-29.1-27-31.4c-14-1.9-28.5,9-31.6,23.7c-2.3,10.6-0.8,21.3-1,32
		c-0.1,3.2,2.2,3.9,4.9,3.9C558.6,381.4,563.4,381.3,568.2,381.3z M599.4,476.9c-12.7-22.3-24.9-43.7-37-65
		c-1.4-2.5-3.8-2.6-6.3-2.6c-7.6,0-15.3,0.2-22.9-0.1c-5.5-0.2-5.8,1.2-3.2,5.6c10.9,19.1,21.6,38.4,32.4,57.6
		c3.4,6,6.8,11.9,10.5,18.3C580.6,475.2,582,474.4,599.4,476.9z M435.2,475c2.7-1.7,3.5-3.7,4.6-5.6c9.3-16.3,18.6-32.5,27.9-48.8
		c6.4-11.2,6.2-9.9-6.4-11.6c-16.7-2.1-26.7,3.5-33.1,19c-4.7,11.3-11.9,21.6-18.8,33.8C423.5,457,432,461.3,435.2,475z
		 M520.7,360.3c0-12.2-8.8-20.8-21.1-20.7c-12,0.1-20.4,8.6-20.5,20.7c-0.1,12.4,8.4,21,20.8,21
		C512.3,381.2,520.8,372.7,520.7,360.3z M499.7,464.9c7.2,0,14.4,0,21.6,0c3.5,0,4.3-1.2,2.5-4.3c-7.1-12.5-14.1-25-21.1-37.6
		c-1.6-3-2.9-2.7-4.4,0.1c-7.2,12.7-14.4,25.4-21.7,38c-1.6,2.8-1,3.9,2.2,3.9C485.8,464.9,492.7,465,499.7,464.9z"
      />
      <path
        d="M476.8,686.2c0,7.5-6.5,13.9-13.8,13.7c-7.2-0.2-13.1-6.3-13.2-13.6c-0.1-7.7,5.9-13.9,13.5-13.9
		C470.7,672.3,476.8,678.5,476.8,686.2z"
      />
    </g>
  </svg>
);

export const HelpImgMobile = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="23"
    viewBox="50 50 100 100"
    className="iconSvg"
  >
    <path
      id="Forme_1"
      data-name="Forme 1"
      d="M130,49.993H70A20.006,20.006,0,0,0,49.993,70V130a20.007,20.007,0,0,0,20.006,20h60a20,20,0,0,0,20-20V70A20,20,0,0,0,130,49.993ZM98.833,137a5.993,5.993,0,1,1,5.993-5.993A5.994,5.994,0,0,1,98.833,137Zm5.257-28.806v7.1a5.224,5.224,0,0,1-5.351,5.346,5.356,5.356,0,0,1-5.346-5.346V103.936c0-.106.109-0.106,0.109-0.217a0.222,0.222,0,0,1-.109-0.218,5.5,5.5,0,0,1,5.346-5.35,12.229,12.229,0,1,0,0-24.454,12.071,12.071,0,0,0-10.48,6,5.384,5.384,0,0,1-7.423,1.964,5.316,5.316,0,0,1-1.852-7.312A22.942,22.942,0,1,1,104.09,108.193Z"
    />
  </svg>
);

export const BellImgMobile = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="23"
    viewBox="300 300 420 420"
    className="iconSvg"
  >
    <g>
      <path
        d="M485.6,705.6c-9-2.6-17-6.7-23-14.3c-4.6-5.8-7.5-12.3-8.2-19.6c-0.3-3.6-1.7-4.3-4.9-4.3c-32.1,0.1-64.3,0-96.4,0.1
         c-9.4,0-17.8-2.6-25.2-8.5c-12.1-9.8-16.1-22.8-14.2-37.8c1.9-15.2,8.5-28.3,19.9-38.6c14.9-13.6,23.2-31,29.4-49.7
         c7.3-21.8,11.1-44.4,12.9-67.3c0.9-11,1-22.1,1.4-33.1c1.1-27.1,10.6-50.9,28.3-71.4c12.5-14.5,27.8-25.2,45.8-31.9
         c2.4-0.9,3.6-2.3,4.2-4.7c4.8-17.4,19.5-28.7,37.4-28.6c17.6,0,32.3,11.3,36.9,28.6c0.8,3.1,2.8,4.1,5.4,5.1
         c36.6,15.4,60.1,42.3,70,80.8c4,15.5,2.8,31.5,3.8,47.3c2.2,34,7.7,67.2,23,98.1c4.9,9.9,11.2,18.7,19.4,26.2
         c11.4,10.4,17.9,23.2,20.4,38.4c4.2,25.5-13.5,47-39.3,47.1c-32,0.1-64,0.1-96-0.1c-3.6,0-4.8,0.8-5.4,4.7
         c-2.3,15.7-11.2,26.4-26.2,31.9c-1.6,0.6-3.3,1.1-4.9,1.7C495.2,705.6,490.4,705.6,485.6,705.6z M492.5,628.5
         c37.6,0,75.2,0,112.8,0c8.1,0,16.3-0.1,24.4,0c2.4,0,3.3-0.6,3-3.2c-0.6-4.8-2.5-9-5.8-12.4c-3.3-3.5-6.8-6.7-10-10.3
         c-16.2-18-25.9-39.6-33.1-62.4c-10.1-32.3-14.3-65.5-14.5-99.3c-0.1-9.9-1.1-19.6-4.4-29.1c-10.8-31.1-41.9-53.8-77.6-51
         c-22.4,1.8-40.5,11.6-54.5,28.9c-12.1,15-16.6,32.5-16.6,51.4c0.1,22.7-2,45.3-6.5,67.5c-8,39.4-20.7,76.5-51.4,104.7
         c-2.8,2.6-4.2,6.1-5.1,9.8c-1.2,4.8-0.7,5.3,4.1,5.3C402.4,628.5,447.4,628.5,492.5,628.5z"
      />
    </g>
  </svg>
);

export const GiftImgMobile = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="300 290 470 470"
    width="21"
    height="23"
    className="iconSvg"
  >
    <g>
      <path
        d="M499.5,747.5c-48.3,0-96.5,0-144.8,0c-27.5,0-46.5-19.1-46.5-46.5c0-68.4-0.1-136.7,0.1-205.1c0-5.1-1-8.8-5.3-12.4
          c-6.6-5.4-10.4-12.9-10.4-21.8c-0.1-16.4-0.2-32.8,0-49.1c0.2-16.6,14-30.1,30.8-30.4c13.8-0.2,27.5-0.2,41.3,0
          c4.8,0.1,6.9-0.2,4.2-6c-15.9-34.4,3.7-73.9,40.8-81.1c22.5-4.4,42,2.6,56.5,20.9c3.3,4.2,5.4,4.4,10,1.9c16.1-9,32.6-8.9,48.6,0.4
          c4.2,2.4,6.1,1.9,8.9-1.8c18.4-23.8,50.2-29.3,76.3-13.6c24.3,14.6,33.9,46.6,21.6,72.9c-2.5,5.3-1.8,6.7,4.1,6.5
          c13.5-0.3,27.1-0.2,40.6-0.1c17.5,0.2,31.2,13.7,31.3,31.1c0.2,15.7,0,31.5,0,47.2c0,9.3-3.5,17.3-10.6,23
          c-4.1,3.4-5.2,6.8-5.2,11.8c0.1,68.8,0.1,137.6,0,206.4c0,21.9-13.4,39-34.4,44.6c-4.1,1.1-8.2,1-12.4,1
          C596.5,747.5,548,747.5,499.5,747.5z M449.7,510.8c-0.4-0.1-0.9-0.2-1.3-0.3c-1.2,2.1-2.4,4.1-3.6,6.2c-3.3,6.1-7.7,11-15.3,10.2
          c-7.8-0.8-11.4-6.4-13.1-13.6c-1.5-5.9-3.3-11.8-4.7-17.7c-0.9-3.9-2.7-4.8-6.5-3.7c-6.7,2-13.5,3.5-20.2,5.5
          c-4.3,1.3-8.6,0.9-11.8-1.8c-4.1-3.4-8.6-3.8-13.4-3.6c-5.7,0.1-11.4,0.3-17-0.1c-5.6-0.4-7.6,1.4-7.6,7.3
          c0.2,66.2,0.1,132.3,0.1,198.5c0,15.4,7.2,22.7,22.4,22.7c52.9,0.1,105.7,0,158.6,0.1c5,0,6.9-1.2,6.9-6.6
          c-0.2-71.4-0.1-142.8,0-214.2c0-4.7-1.4-6.7-6.3-6.5c-10.7,0.3-21.4,0.4-32.1-0.1c-6.5-0.3-8,2.1-8,8.3
          c0.2,39.3,0.1,78.6,0.1,117.9c0,6.6,0.2,13.1-0.1,19.7c-0.4,8-6.4,13.3-14.3,12.9c-7.9-0.4-12.7-5.9-12.7-15.2
          c-0.1-30.6,0-61.1,0-91.7C449.7,533.5,449.7,522.2,449.7,510.8z M551.3,509.6c-0.4,0.1-0.7,0.3-1.1,0.4c0,68.1,0,136.2-0.1,204.3
          c0,5.2,2.3,6.1,6.7,6.1c28.6-0.1,57.2,0,85.8-0.1c14.5-0.1,21.8-7.1,21.9-21.8c0.1-66.8,0-133.6,0.1-200.4c0-4.4-1.2-6.5-6-6.2
          c-3.9,0.3-7.9,0.3-11.8,0c-4.6-0.4-5.8,1-5.2,5.9c1.3,10.6-6.7,17.5-17.2,15.2c-7.2-1.6-14.4-3.5-21.5-5.6c-3.3-0.9-4.8,0-5.7,3.3
          c-1.6,6.3-3.5,12.6-5,19c-1.7,7.1-5.6,12.1-13,12.9c-7.2,0.8-11.7-3.6-14.9-9.6C560.1,525.1,555.7,517.4,551.3,509.6z M365.1,409.3
          c-12,0-24-0.1-36,0c-7.3,0.1-9.5,2.2-9.5,9.3c-0.1,12.2-0.1,24.4,0,36.6c0.1,7.2,2.2,9.4,9.4,9.4c13.7,0.1,27.5-0.1,41.2,0.1
          c4,0.1,6.4-1.5,8.3-5c8.4-15,16.8-30,25.6-44.9c3-5,1.2-5.8-3.6-5.7C388.6,409.5,376.8,409.3,365.1,409.3z M635.1,409.3
          c-12,0-24,0.1-36,0c-4.2,0-5.8,0.6-3.2,5c8.9,15.3,17.5,30.7,26.1,46.1c1.8,3.3,4.3,4.4,8,4.4c13.7-0.1,27.5,0,41.2-0.1
          c6.8-0.1,9-2.2,9.1-9.1c0.1-12.2,0.1-24.4,0-36.7c-0.1-7.6-2.2-9.6-9.9-9.7C658.6,409.3,646.8,409.3,635.1,409.3z M428.8,381.3
          c7.1,0,12.1,0,17.1,0c3,0,5.2-1,5-4.5c-0.3-10.2,1-20.5-1-30.7c-2.6-13.5-13.7-23.4-27.8-24.6c-12.5-1-25.6,8-29.7,20.4
          c-4.4,13.3,0.5,26.8,11.8,34.6C412.6,382.3,421.8,381.2,428.8,381.3z M568.2,381.3c0,0,0-0.1,0-0.1c4.1,0,8.3,0.2,12.4,0
          c15.8-0.8,27.4-12.5,27.9-28.1c0.6-16.7-10.1-29.1-27-31.4c-14-1.9-28.5,9-31.6,23.7c-2.3,10.6-0.8,21.3-1,32
          c-0.1,3.2,2.2,3.9,4.9,3.9C558.6,381.4,563.4,381.3,568.2,381.3z M599.4,476.9c-12.7-22.3-24.9-43.7-37-65
          c-1.4-2.5-3.8-2.6-6.3-2.6c-7.6,0-15.3,0.2-22.9-0.1c-5.5-0.2-5.8,1.2-3.2,5.6c10.9,19.1,21.6,38.4,32.4,57.6
          c3.4,6,6.8,11.9,10.5,18.3C580.6,475.2,582,474.4,599.4,476.9z M435.2,475c2.7-1.7,3.5-3.7,4.6-5.6c9.3-16.3,18.6-32.5,27.9-48.8
          c6.4-11.2,6.2-9.9-6.4-11.6c-16.7-2.1-26.7,3.5-33.1,19c-4.7,11.3-11.9,21.6-18.8,33.8C423.5,457,432,461.3,435.2,475z
           M520.7,360.3c0-12.2-8.8-20.8-21.1-20.7c-12,0.1-20.4,8.6-20.5,20.7c-0.1,12.4,8.4,21,20.8,21
          C512.3,381.2,520.8,372.7,520.7,360.3z M499.7,464.9c7.2,0,14.4,0,21.6,0c3.5,0,4.3-1.2,2.5-4.3c-7.1-12.5-14.1-25-21.1-37.6
          c-1.6-3-2.9-2.7-4.4,0.1c-7.2,12.7-14.4,25.4-21.7,38c-1.6,2.8-1,3.9,2.2,3.9C485.8,464.9,492.7,465,499.7,464.9z"
      />
      <path
        d="M476.8,686.2c0,7.5-6.5,13.9-13.8,13.7c-7.2-0.2-13.1-6.3-13.2-13.6c-0.1-7.7,5.9-13.9,13.5-13.9
          C470.7,672.3,476.8,678.5,476.8,686.2z"
      />
    </g>
  </svg>
);

export const Home = props => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    version="1.1"
    id="Layer_1"
    width="21"
    height="23"
    className="iconSvg"
  >
    <g>
      <path d="M280.4 148.3L96 300.1V464a16 16 0 0 0 16 16l112.1-.3a16 16 0 0 0 15.9-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.6a16 16 0 0 0 16 16.1L464 480a16 16 0 0 0 16-16V300L295.7 148.3a12.2 12.2 0 0 0 -15.3 0zM571.6 251.5L488 182.6V44.1a12 12 0 0 0 -12-12h-56a12 12 0 0 0 -12 12v72.6L318.5 43a48 48 0 0 0 -61 0L4.3 251.5a12 12 0 0 0 -1.6 16.9l25.5 31A12 12 0 0 0 45.2 301l235.2-193.7a12.2 12.2 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0 -1.7-16.9z"/>
    </g>
  </svg>
);
