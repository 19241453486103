import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import Popover from "material-ui/Popover";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";

//prend donnees directement en tableau
class TableIntervTech extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  openPopover = (event, rowInfo) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      open: true,
      comment: rowInfo.original.comment,
      anchorEl: event.currentTarget.childNodes[event.currentTarget.childNodes.length - 1]
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    let { data, columns } = this.props;

    let columnsTable = [
      {
        Header: "ID",
        accessor: "table_id",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Table", accessor: "table_name", pivot: true },
      { Header: "Date", accessor: "formatTimestamp", pivot: true },
      { Header: "Cas", accessor: "case_id", pivot: true },
      { Header: "Utilisateur", accessor: "user_fullname", pivot: true },
      { Header: "Commentaire", accessor: "comment", pivot: true, minWidth: 400 }
    ];

    if (data && data.length > 0) {
      data.forEach(element => {
        element.user_fullname = element.user_firstname + " " + element.user_lasttname;
        element.formatTimestamp = moment.unix(element.timestamp).format("DD-MM-YYYY H:mm:ss");
      });
    }

    return (
      <div>
        <MuiThemeProvider>
          <Popover
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
            onRequestClose={this.handleRequestClose}
            style={{ borderRadius: "15px" }}
          >
            <div style={{ minHeight: "30px", padding: "20px", color: "black", backgroundColor: "white" }}>
              <span style={{ fontStyle: "italic" }}>Commentaire</span> : {this.state.comment}
            </div>
          </Popover>
        </MuiThemeProvider>
        <ReactTable
          className=" -highlight -striped clickAble"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={data}
          defaultPageSize={5}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, t) => {
                this.openPopover(e, rowInfo);
              }
            };
          }}
        />
      </div>
    );
  }
}

export default TableIntervTech;
