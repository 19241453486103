import fr from "./fr";
import en from "./en";
import es from "./es";
import ca from "./ca";
import caES from "./ca-ES";

export default {
  fr,
  en,
  es,
  ca,
  "ca-ES": caES
};
