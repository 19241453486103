import { CALL_API } from 'redux-api-middleware';
import { schema, normalize } from 'normalizr';
import * as types from './constant';
import config from '../utils/config';
import detectIe from '../utils/detectIe';
import axios from 'axios';

const categorySchema = new schema.Entity('categories');
const categoryListSchema = [categorySchema];

const videoSchema = new schema.Entity('videos');
const videoListSchema = [videoSchema];

const documentationSchema = new schema.Entity('documentation');
const documentationListSchema = [documentationSchema];

const helperVideoSchema = new schema.Entity('helperVideo');
const helperVideoListSchema = [helperVideoSchema];

const messageSchema = new schema.Entity('messages');
const messageListSchema = [messageSchema];

const notificationSchema = new schema.Entity('notifications');
const notificationListSchema = [notificationSchema];

const partnersSchema = new schema.Entity('partners');
const partnersListSchema = [partnersSchema];

const partnersCategoriesSchema = new schema.Entity('partnersCategories', {
	partners: partnersListSchema
});
const partnersCategoriesListSchema = [partnersCategoriesSchema];

const companiesSchema = new schema.Entity('companies');
const companiesListSchema = [companiesSchema];

const informationsSchema = new schema.Entity('informations', {
	categories: categoryListSchema,
	messages: messageListSchema,
	notifications: notificationListSchema,
	partnersCategories: partnersCategoriesListSchema,
	videos: videoListSchema,
	documentations: documentationListSchema,
	helperVideos: helperVideoListSchema,
	companines: companiesListSchema
});

export function setCurrentInformation(informations) {
	return {
		type: types.SET_INFORMATIONS,
		informations
	};
}

export function getInformations() {
	let fullEndpoint = config.reportingUrl + 'api/informations';
	const viewAsCustomerToken = window.localStorage.viewAsCustomerToken;

	if (viewAsCustomerToken) {
		fullEndpoint += '/?view-as-customer=' + viewAsCustomerToken;
	}

	if (detectIe()) {
		return (dispatch) => {
			axios.get(fullEndpoint).then((res) => {
				return dispatch(setCurrentInformation(normalize(res.data, informationsSchema)));
			});
		};
	} else {
		return {
			[CALL_API]: {
				endpoint: fullEndpoint,
				method: 'GET',
				types: [
					types.FETCH_INFORMATIONS_REQUEST,
					{
						type: types.FETCH_INFORMATIONS_SUCCESS,
						payload: (action, state, res) => {
							const contentType = res.headers.get('Content-Type');
							if (contentType && contentType.indexOf('json') > -1) {
								return res.json().then((json) => normalize(json, informationsSchema));
							}
						}
					},
					types.FETCH_INFORMATIONS_FAILURE
				]
			}
		};
	}
}
