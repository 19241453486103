import React from "react";
import { I18n } from "react-redux-i18n";
import { Button } from "@material-ui/core";
import { Link } from "react-router";
import svgCircle from "../img/ronds-confirmation-formulaire.svg";

class NotFound extends React.Component {
  render() {
    return (
      <div className="container-404">
        <div className="">
          <h1 className="title-404">{I18n.t("notFound.title")}</h1>
          <hr />
          <p className="description-404">{I18n.t("notFound.description")}</p>
          <Link to="/">
            <Button variant="contained" className="cta-red">
              {I18n.t("notFound.backLogin")}
            </Button>
          </Link>
        </div>
        <div className="blocs-ronds-wrapper">
          <div className="ronds-wrapper">
            <img src={svgCircle} alt="svg-circle" />
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
