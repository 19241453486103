import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import config from '../../../utils/config';
import ReactGA from 'react-ga';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismiss: false
    };
    this.onClick = this.onClick.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    if (config.useReactGa) {
      ReactGA.event({
        category: 'Notification',
        action: 'Deleted Notification',
        label: 'Notification ' + this.props.message.id
      });
    }
    this.setState({dismiss: !this.state.dismiss}, this.removeNotification());
  }

  removeNotification() {
    window.setTimeout(() => {
      this.props.deleteNotification(this.props.message.id)
    }, 500);
  }

  render() {
    const {type, text} = this.props.message;

    function getNotificationText() {
      return {__html: text};
    }

    return (
      <li className={classnames('item js-item', {
        'notification-success': type === 'success',
        'notification-warning': type === 'warning',
        'notification-error': type === 'error',
        'notification-info': type === 'info',
        'dismissed': this.state.dismiss
      })}>
        <div className="details">
          <div className="title"
               dangerouslySetInnerHTML={getNotificationText()}/>
        </div>
        <button type="button" onClick={this.onClick}
                className="button-default button-dismiss js-dismiss">×
        </button>
      </li>
    );
  }
}

Notification.propTypes = {
  message: PropTypes.object.isRequired,
  deleteNotification: PropTypes.func.isRequired
};

export default Notification;
