import React, {Component} from 'react';  import PropTypes from 'prop-types';
import '../css/item.css';

class Item extends Component {
  constructor() {
    super();

    this.state = {
      display: false
    };

    this.showContent = this.showContent.bind(this);
  }

  showContent(e) {
    e.preventDefault();
    this.setState({
      display: !this.state.display
    });
  }

  render() {
    const isDisplay = this.state.display ?
      {display: 'block'}
      :
      {display: 'none'};
    const {item} = this.props;

    return (
      <li key={item.trigger}>
        <a className='cd-faq-trigger' href='#0' onClick={this.showContent}>
          {item.trigger}
        </a>
        <div className='cd-faq-content' style={isDisplay}>
          <p>{item.content}</p>
        </div>
      </li>
    );
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired
};

export default Item;
