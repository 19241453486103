import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import NumberFormat from "react-number-format";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";

class TableMvtCaisse extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  handleClickDetails(rowInfo, columns) {
    this.props.goToDetails(rowInfo, columns);
  }

  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem].creation);
      });
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Date", accessor: "formatTimestamp", pivot: true },
      {
        Header: "Montant",
        accessor: "amount",
        pivot: true,
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },

      { Header: "Caisse", accessor: "device", pivot: true },
      { Header: "Version", accessor: "app_version", pivot: true },
      { Header: "Utilisateur", accessor: "userName", pivot: true },
      { Header: "Supprimé", accessor: "removed", pivot: true }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id" },
      { label: "Date", key: "formatTimestamp" },
      { label: "Montant", key: "amount" },

      { label: "Caisse", key: "device" },
      { label: "Version", key: "app_version" },
      { label: "Utilisateur", key: "userName" },
      { label: "Supprimé", key: "removed" }
    ];

    if (dataArray && dataArray.length > 0) {
      dataArray.forEach(element => {
        element.amount = Number(element.amount).toFixed(2);

        if (element.removed == 1) {
          element.removed = "oui";
        } else if (element.removed == 0) {
          element.removed = "non";
        }
        element.formatTimestamp = moment.unix(element.timestamp).format("DD-MM-YYYY H:mm:ss");
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped clickAble"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            let { columns } = state;
            return {
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo, columns);
              }
            };
          }}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-mouvementCaisse.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableMvtCaisse;
