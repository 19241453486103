import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import {
	MenuArrowOpenSvg,
	MenuArrowCollapseSvg,
	Comptability,
} from './assets/MenuSvg';
import useWindowSize from './hooks/useWindowSize';

import getFrontContainerStyle from './FrontContainer.style';
import { setOpenDrawer } from './actions/index';
import { subUserTools } from '@capps/subuser-tools';

const useStyles = makeStyles(theme => getFrontContainerStyle(theme));

function FrontContainer({ categories, groups, childrens, openDrawer, setOpenDrawer, auth }) {
	const classes = useStyles();
	const theme = useTheme();
	const [menuItemSelected, setItemSelected] = useState({
		Dashboard: true,
		id: 0,
	});
	const [subItemSelected, setSubItemSelected] = useState({});

	const [width, height] = useWindowSize();

	const isMobile = width < 764;

	const [open, setOpen] = useState(false);
	const isSubUser = subUserTools.isSubUserAccount(auth.user);

	const handleDrawerChange = (e, item) => {
		if (item && item.childrens && item.childrens.length === 0) {
			setSubItemSelected({});
			if (!open) {
				return;
			}
		}

		setOpen(!open);
		setOpenDrawer(false);
	};

	const menuItemClicked = (e, item, index) => {
		if (menuItemSelected.id !== index) {
			setItemSelected({
				[item.title]: true,
				id: index,
			});
			if (item.childrens.length > 0) {
				const openDrawerValueForItemWithChildren =
					menuItemSelected.id === index && item.childrens.length > 0
						? false
						: true;
				setOpenDrawer(openDrawerValueForItemWithChildren);
				setOpen(openDrawerValueForItemWithChildren);
			}
		}

		const drawerIsOpenend = open || openDrawer;
		if (item.childrens.length == 0 && drawerIsOpenend) {
			setOpen(isMobile ? !openDrawer : !open);
			setOpenDrawer(false);
			setSubItemSelected({});
		}
	};

	const subMenuItemClicked = (e, subItem, idx, parentIdx) => {
		setOpen(false);
		setOpenDrawer(false);

		if (menuItemSelected.id !== parentIdx && !openDrawerProps) {
			setItemSelected({
				[subItem.title]: true,
				id: parentIdx,
			});
		}
		setSubItemSelected({
			title: subItem.title,
			[subItem.title]: true,
			id: idx,
		});
	};

	function getSvg(iconLink) {
		return { __html: iconLink };
	}

	useEffect(() => {}, [menuItemSelected, subItemSelected, openDrawer]);
	useEffect(() => {
		if (isMobile) {
			setOpen(openDrawer);
		}
	}, []);

	let openDrawerProps = isMobile ? openDrawer : open;


	let newVideoModule = false;

	if(groups && Array.isArray(groups)) {
		const isSuiteClient = groups.some(group => group.name === "laddition-suite");
		newVideoModule = isSuiteClient &&  window.location.pathname === "/videos-suite";
	}

	//deplacer le code dans des composants plus petits

	return (
		<React.Fragment>
			<div
				className={isMobile ? classes.rootMobile : classes.root}
				id={newVideoModule ? "espace-client-style" : ""}>
				<CssBaseline />
				<Drawer
					variant={isMobile ? 'persistent' : 'permanent'}
					anchor="left"
					open={openDrawerProps}
					className={classnames(classes.drawer, {
						[classes.drawerOpen]: openDrawerProps,
						[classes.drawerClose]: !openDrawerProps,
					})}
					classes={{
						paper: classnames(classes.drawPaper, {
							[classes.drawerOpen]: openDrawerProps,
							[classes.drawerClose]: !openDrawerProps,
						}),
					}}>
					{!isMobile ? (
						<React.Fragment>
							<div
								className={classnames(classes.arrowMenu, {
									[classes.arrowMenuOpened]: openDrawerProps,
									[classes.arrowMenuClosed]: !openDrawerProps,
								})}>
								<IconButton
									disableRipple={true}
									onClick={e => {
										handleDrawerChange(e);
									}}
									className={classes.arrowMenuButton}>
									{!openDrawerProps ? (
										<MenuArrowOpenSvg />
									) : (
										<MenuArrowCollapseSvg />
									)}
								</IconButton>
							</div>

							<Divider variant="middle" className={classes.divider} />
						</React.Fragment>
					) : null}
					<List>
						{categories &&
							categories.length > 0 &&
							categories.map((item, index) => {
							  if (isSubUser && item.service_name && !auth.subUserServices.includes(item.service_name)) {
									return <React.Fragment></React.Fragment>;
								}
								return (
									<React.Fragment key={index}>
										<ListItem
											disableRipple={true}
											button
											alignItems="center"
											selected={menuItemSelected.id === index}
											className={classnames(classes.menuListItems, {
												[classes.menuItemSelected]: item.id === index,
											})}
											key={index}
											classes={{
												root: classes.menuListItems,
												selected: classes.menuItemSelected,
											}}
											onClick={e => {
												menuItemClicked(e, item, index);
											}}>
											{item.childrens.length === 0 && item.link ? (
												<React.Fragment>
													{' '}
													<Link
														className={classes.menuListItemLink}
														href={
															item.link.indexOf('http') > -1 ? item.link : false
														}
														to={
															item.link.indexOf('http') > -1
																? null
																: item.title === 'Dashboard'
																? '/'
																: item.is_react
																? `${item.link}`
																: item.type === 0
																? '/reporting/' + item.id
																: '/reporting-sales/' + item.id
														}>
														<ListItemIcon
															className={classes.menuListIcons}
															onClick={e => {
																handleDrawerChange(e, item);
															}}>
															<div
																dangerouslySetInnerHTML={getSvg(
																	item.new_icon
																)}></div>

															{openDrawerProps ? null : (
																<p className={classes.menuIconsAnnotattion}>
																	{item.title}
																</p>
															)}
														</ListItemIcon>
														<ListItemText
															classes={{
																root: classes.menuIconText,
																primary: classes.menuIconPrimayText,
															}}
															className={classnames(classes.menuIconText)}
															primary={item.title}
														/>
													</Link>
												</React.Fragment>
											) : (
												<div
													className={classes.menuDrawer}
													onClick={e => {
														handleDrawerChange(e, item);
													}}>
													<ListItemIcon className={classes.menuListIcons}>
														<div
															dangerouslySetInnerHTML={getSvg(
																item.new_icon
															)}></div>
														{openDrawerProps ? null : (
															<p className={classes.menuIconsAnnotattion}>
																{item.title}
															</p>
														)}
													</ListItemIcon>
													<ListItemText
														classes={{
															root: classes.menuIconText,
															primary: classes.menuIconPrimayText,
														}}
														className={classnames(classes.menuIconText)}
														primary={item.title}
													/>
												</div>
											)}
										</ListItem>
										{openDrawerProps ? (
											<React.Fragment>
												<Collapse
													in={menuItemSelected[item.title]}
													timeout="auto"
													unmountOnExit
													key={index}>
													<List component="div" disablePadding>
														{item.childrens &&
															item.childrens.length > 0 &&
															item.childrens.map((subItem, idx) => {
															  if (isSubUser && subItem.service_name && !auth.subUserServices.includes(subItem.service_name)) {
   															  return <React.Fragment></React.Fragment>;
    														}
																const isHttpLink =
																	subItem && subItem.link.indexOf('http') > -1;
																return (
																	<ListItem
																		button
																		disableRipple={true}
																		key={idx}
																		className={classes.subMenuItems}
																		selected={
																			subItemSelected[subItem.title] &&
																			subItemSelected.id === idx
																		}
																		classes={{
																			root: classes.subMenuItems,
																			selected: classes.subMenuItemSelected,
																		}}
																		onClick={e => {
																			subMenuItemClicked(
																				e,
																				subItem,
																				idx,
																				index
																			);
																		}}>
																		<Link
																			href={isHttpLink ? subItem.link : false}
																			to={
																				isHttpLink
																					? null
																					: subItem.is_react
																					? `${subItem.link}`
																					: subItem.type === 0
																					? '/reporting/' + subItem.id
																					: '/reporting-sales/' + subItem.id
																			}>
																			<ListItemText
																				key={idx}
																				primary={subItem.title}
																				className={classes.subMenuItemsLabel}
																			/>
																		</Link>
																	</ListItem>
																);
															})}
													</List>
												</Collapse>
												<Divider variant="middle" className={classes.divider} />
											</React.Fragment>
										) : null}
									</React.Fragment>
								);
							})}
					</List>
				</Drawer>
			</div>
			<div className={classes.mainContainer}>
				<main id={newVideoModule ? "new_navigation_main_hidden" : "new_navigation_main"}>
					<div
						id={newVideoModule ? "espace-client-style" : ""}
						className={classnames(classes.mainContent, {
							[classes.mainContentMobile]: isMobile,
						})}>
						{childrens}
					</div>
				</main>
			</div>
		</React.Fragment>
	);
}
function mapStateToProps(state) {
	return {
		categories: Object.values(state.entities.categories),
		groups: state.auth.user.groups,
		auth: state.auth,
		openDrawer: state.variables.openDrawer,
	};
}
export default connect(mapStateToProps, { setOpenDrawer })(FrontContainer);
