export const vatExportLabels = {
  orderLines: [
    /*{
      Header: `Société`,
      accessor: "society",
      headerClassName: "main_header_title",
      className: "table_string_field",
    },*/
    {
      Header: `Date`,
      accessor: "date",
      headerClassName: "main_header_title",
    },
    {
      Header: `N°`,
      accessor: "numberCaisse",
      headerClassName: "main_header_title",
    },
    {
      Header: `Type`,
      accessor: "type",
      headerClassName: "main_header_title",
    },
    {
      Header: `Journal`,
      accessor: "journal",
      headerClassName: "main_header_title",
    },
    {
      Header: `Etab`,
      accessor: "etab",
      headerClassName: "main_header_title",
    },

    {
      Header: `Général`,
      accessor: "account",
      headerClassName: "main_header_title",
    },
    {
      Header: `Tiers`,
      accessor: "tiers",
      headerClassName: "main_header_title",
    },
    /*{
      Header: `Axe analytique`,
      accessor: "axeAnal",
      headerClassName: "main_header_title",
    },*/
    /*{
      Header: `Section analytique`,
      accessor: "sectionAnal",
      headerClassName: "main_header_title",
    },*/
    {
      Header: `Référence`,
      accessor: "numberCaisse",
      headerClassName: "main_header_title",
    },
    {
      Header: `Libellé`,
      accessor: "lib",
      headerClassName: "main_header_title",
    },
    {
      Header: `Montant EUR`,
      accessor: "amount",
      headerClassName: "main_header_title",
    },
    {
      Header: `Sens`,
      accessor: "sens",
      headerClassName: "main_header_title",
    },
    {
      Header: `Devise`,
      accessor: "devise",
      headerClassName: "main_header_title",
    },
    {
      Header: `Montant en Devise`,
      accessor: "amountD",
      headerClassName: "main_header_title",
    },
    {
      Header: `Echéance`,
      accessor: "echeance",
      headerClassName: "main_header_title",
    },
    {
      Header: `Mode Règlement`,
      accessor: "reglMode",
      headerClassName: "main_header_title",
    },
    {
      Header: `Profil TVA`,
      accessor: "profilVat",
      headerClassName: "main_header_title",
    },
    {
      Header: `Intraco`,
      accessor: "intraco",
      headerClassName: "main_header_title",
    },
    {
      Header: `Nature`,
      accessor: "nature",
      headerClassName: "main_header_title",
    },
    {
      Header: `Type de pièce`,
      accessor: "pieceType",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 1`,
      accessor: "sectionAxe1",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 2`,
      accessor: "sectionAxe2",
      headerClassName: "main_header_title",
    },
    {
      Header: `Section Axe 3`,
      accessor: "sectionAxe3",
      headerClassName: "main_header_title",
    },
  ],
};
