import {ADD_NOTIFICATION, DELETE_NOTIFICATION, FETCH_NOTIFICATION_REQUEST, FETCH_NOTIFICATION_SUCCESS, FETCH_NOTIFICATION_FAILURE} from '../constant';
import {CALL_API} from 'redux-api-middleware';
import {schema, normalize} from 'normalizr';
import config from '../../../utils/config';

export function addNotification(message) {
    return {
        type: ADD_NOTIFICATION,
        message
    }
}

export function deleteNotification(id) {
    return {
        type: DELETE_NOTIFICATION,
        id
    }
}

const notificationSchema = new schema.Entity('notifications');
const notificationListSchema = [notificationSchema];

export function getNotifications() {
    return {
        [CALL_API]: {
            endpoint: config.reportingUrl + 'api/messages/notification',
            method: 'GET',
            types: [
                FETCH_NOTIFICATION_REQUEST,
                {
                    type: FETCH_NOTIFICATION_SUCCESS,
                    payload: (action, state, res) => {
                        const contentType = res.headers.get('Content-Type');
                        if (contentType && contentType.indexOf('json') > -1) {
                            return res.json().then((json) =>  normalize(json.notifications, notificationListSchema));
                        }
                    }
                },
                FETCH_NOTIFICATION_FAILURE
            ]
        }
    }
}

