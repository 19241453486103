import CheckBoxEmptyIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckboxIndeterIcon from '@material-ui/icons/IndeterminateCheckBox';
const IconCheckbox = ({ checked, indeterminate, onClick = () => {} }) => {
	return indeterminate ? (
		<CheckboxIndeterIcon onClick={onClick} />
	) : checked ? (
		<CheckBoxIcon onClick={onClick} />
	) : (
		<CheckBoxEmptyIcon onClick={onClick} />
	);
};

export default IconCheckbox;
