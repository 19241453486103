import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "../../components/Card/Card";
import Btn from "../../components/Button/Btn";
import config from "../../utils/config";
import axios from "axios/index";
import { I18n } from "react-redux-i18n";
import Input from "../../components/Input/Input";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { addFlashMessage } from "../../common/flashMessage/actions/flashMessages";
import { getRefreshToken } from "../../utils/utils";

class MigrationStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      errors: "",
      isLoading: false,
      disabled: true,
      isActive: false,
      successMessage: "",
      messageType: "alert alert-success"
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const newState = { [e.target.name]: e.target.value };
    if (e.target.value !== "") {
      newState.disabled = false;
      newState.isActive = true;
    } else {
      newState.disabled = true;
      newState.isActive = false;
    }
    this.setState(newState);
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      window.localStorage.accessToken
    }`;
    axios
      .post(config.reportingUrl + "api/account/migration/password/verify", {
        password: this.state.password,
        refreshToken: getRefreshToken(),
      })
      .then(res => {
        if (res.data.success === true || res.data.success === "true") {
          window.localStorage.checkToken = res.data.token;
          this.context.router.push("/account/migration");
        } else {
          this.setState({
            isLoading: false
          });
          this.props.addFlashMessage({
            type: "error",
            text: I18n.t("accountMigration.invalidPassword")
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false
        });
        this.props.addFlashMessage({
          type: "error",
          text: I18n.t("accountMigration.error")
        });
      });
  }

  render() {
    const {
      errors,
      messageType,
      password,
      disabled,
      isLoading,
      isActive
    } = this.state;
    let option = {};
    if (errors !== "") {
      option.message = errors;
      option.messageType = messageType;
    }
    return (
      <div id={"migration-container"}>
        <h1>{I18n.t("accountMigration.migrationStep1.actualPassword")}</h1>
        <form onSubmit={this.onSubmit}>
          <Input
            label={I18n.t("accountMigration.migrationStep1.actualPassword")}
            type="password"
            onFocus={this.onFocus}
            onChange={this.onChange}
            value={password}
            name="password"
            required="required"
            idInput="password-input"
            colorInput={!(password !== "") ? null : "#7cc4ac"}
          />
          <Button
            variant="contained"
            className="submitButton"
            disabled={isLoading || disabled}
            type="submit"
          >
            {I18n.t("accountMigration.btnValidate")}
          </Button>
        </form>
      </div>
    );
  }
}

MigrationStep1.propTypes = {};
MigrationStep1.defaultProps = {};
MigrationStep1.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(
  null,
  { addFlashMessage }
)(MigrationStep1);
