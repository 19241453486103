import map from 'lodash/map';
import moment from 'moment';

export function cleanData(clotures){
    const objToArray = (value) => {
        return value;
      };
      const clotureArr = map(clotures, objToArray);
      const clotureFullArr = clotureArr.map((cloture) => {
        if (cloture.value) {
          const clotureValueObj = JSON.parse(cloture.value);
          const clotureValueKeys = Object.keys(clotureValueObj);
          clotureValueKeys.forEach((element) => {
            cloture[element] = clotureValueObj[element];
          });
          delete cloture.value;
        }
        delete cloture.timestamp;
        delete cloture.type_value;
        
        cloture.formatStart = cloture.start_date ? moment(cloture.start_date).format("DD-MM-YYYY HH:mm:ss") : '';
        cloture.formatEnd = cloture.end_date ? moment(cloture.end_date).format("DD-MM-YYYY HH:mm:ss") : '';

        return cloture;
      });
      let clotureKeys;
      if (clotureFullArr.length > 0) {
        const firstCreation = clotureArr[0];
        clotureKeys = Object.keys(firstCreation);
        let indexOfChildren = clotureKeys.indexOf('children');
        clotureKeys.splice(indexOfChildren, 1);
      }
      return {cloturesRows:clotureFullArr,clotureKeys:clotureKeys};

}