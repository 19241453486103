import React from 'react';
import ReactDOM from 'react-dom';
import useWindowSize from '../../../front/NewFrontNavigation/hooks/useWindowSize';

import DashboardWidget from './DashboardWidget';
import { getRefreshToken } from '../../../utils/utils';

let MyDashboardWidget = DashboardWidget.driver('react', {
	React: React,
	ReactDOM: ReactDOM,
});

function Dashboard() {
	const [width, height] = useWindowSize();
	const isMobile = width < 764;
	return getRefreshToken() ? (
		<MyDashboardWidget
			refreshToken={getRefreshToken()}
			isMobile={isMobile}
		/>
	) : null;
}

export default Dashboard;
