import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ArrowDownSvg from '../../svg/ArrowDownSvg';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../../sass/style.scss';
import { Button } from '@material-ui/core';
import { data } from 'jquery';
import { chunk } from 'lodash';

const styles = theme => ({
	selectRoot: {
		minHeight: '39px',
		backgroundColor: 'white',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.18)',
		paddingTop: '5px',
		paddingLeft: '10px',
	},
	select: {
		paddingRight: 0,
		minHeight: '27px',
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},

	menu: {
		'&:focus': {
			backgroundColor: 'none',
			border: 'none',
		},
	},
	paper: {
		marginTop: '5px',
		height: '212px',
		borderRadius: '0',
		maxWidth: '209px',
		backgroundColor: 'white',
		boxShadow: '0 2px 38px rgba(0, 0, 0, 0.1)',
	},
	menuList: {
		paddingTop: '0px',
	},
	listItem: {
		fontSize: '14px',
		padding: '5px 11px',
	},
	menuItem_selected: {
		color: 'red!important',
		backgroundColor: 'yellow',
	},
});

class Filters extends Component {
	constructor(props) {
		super(props);
		this._getMenuItems = this._getMenuItems.bind(this);
		this._handleSelectChange = this._handleSelectChange.bind(this);
		this._handleSelectClose = this._handleSelectClose.bind(this);

		this.state = {
			selectedValue: [],
			allSelected: true,
			filterLabel: this.props.label,
		};
	}

	_getMenuItems = (items, params) => {
		if (items) {
			return items.map((el, idx) => {
				const itemsIsSelected = this.state.selectedValue.some(
					e => e[params] === el[params]
				);
				return (
					<MenuItem
						key={idx + 1}
						disableGutters={true}
						value={el}
						classes={{
							root: this.props.classes.listItem,
							selected: this.props.classes.menuItem_selected,
						}}
						id={`filters_menu_item${
							this.props.isNumberData ? '_numbers' : ''
						}`}>
						<div
							className={`filter_menu_select_icon${
								itemsIsSelected ? '_selected' : ''
							}`}></div>
						<p>{el[this.props.paramsLabel]}</p>
					</MenuItem>
				);
			});
		}
	};

	_handleSelectClose = e => {
		if (this.state.selectedValue.length > 0) {
			const formattedDatas = this.state.selectedValue.map(el => {
				return { [this.props.paramsValue]: el[this.props.paramsValue] };
			});
			this.props.onClose(formattedDatas);
		} else {
			this.props.onClose([]);
		}
	};

	_handleSelectChange = (e, child) => {
		if (e.target && e.target.value) {
			if (this.state.selectedValue.length > 0)
				this.setState({ filterLabel: this.state.selectedValue[0].label });

			if (
				e.target.value[1][this.props.paramsValue] !== 'select_all' &&
				this.state.selectedValue.some(
					el =>
						el[this.props.paramsValue] ===
						e.target.value[1][this.props.paramsValue]
				)
			) {
				this.setState(
					prevState => ({
						selectedValue: prevState.selectedValue
							.filter(
								el =>
									el[this.props.paramsValue] !==
									e.target.value[1][this.props.paramsValue]
							)
							.sort(function (a, b) {
								return a.key - b.key;
							}),
					}),
					() => {
						this.setState({
							filterLabel: this.state.selectedValue[0]
								? this.state.selectedValue[0].label
								: this.props.label,
						});
					}
				);
			} else {
				this.setState(
					{
						selectedValue:
							e.target.value[1][this.props.paramsValue] === 'select_all'
								? []
								: [
										...this.state.selectedValue,
										{
											key: child.key,
											label: e.target.value[1][this.props.paramsLabel],
											[this.props.paramsValue]:
												e.target.value[1][this.props.paramsValue],
										},
								  ].sort(function (a, b) {
										return a.key - b.key;
								  }),

						allSelected:
							e.target.value[1][this.props.paramsValue] === 'select_all',
					},
					() => {
						this.setState({
							filterLabel: this.state.selectedValue[0]
								? this.state.selectedValue[0].label
								: this.props.label,
						});
					}
				);
			}
		}
	};

	render() {
		const { classes, inputClassName, inputLabelClassName } = this.props;
		return (
			<ClickAwayListener onClickAway={e => this._handleSelectClose(e)}>
				<Select
					disableUnderline
					disabled={
						this.props.datas && this.props.datas.length > 0 ? false : true
					}
					className="warelist_filter_input"
					value={[
						<p className="warelist_input_label">{this.state.filterLabel}</p>,
					]}
					defaultValue={3}
					onChange={this._handleSelectChange}
					multiple
					MenuProps={{
						getContentAnchorEl: null,
						classes: { paper: classes.paper },
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
						transformOrigin: { horizontal: 'center', vertical: 'top' },
						MenuListProps: { classes: { root: classes.menuList } },
					}}
					classes={{
						root: classes.selectRoot,
						select: classes.select,
						selectMenu: classes.menu,
					}}
					renderValue={value => value}>
					<MenuItem
						key={0}
						value={{
							[this.props.paramsValue]: 'select_all',
							[this.props.paramsLabel]: this.props.label,
						}}
						selected={this.state.allSelected}
						classes={{
							root: classes.listItem,
							selected: classes.menuItem_selected,
						}}
						id="filters_menu_item">
						<div
							className={`filter_menu_select_icon${
								this.state.allSelected ? '_selected' : ''
							}`}></div>
						<p>{this.props.label} </p>
					</MenuItem>
					{this.props.datas
						? this._getMenuItems(this.props.datas, this.props.paramsValue)
						: null}
				</Select>
			</ClickAwayListener>
		);
	}
}

export default withStyles(styles)(Filters);
