import React, { useState, useEffect } from 'react';
import CellEditable from '../containers/CellEditable';

export default function GlobalSettings({
	accountVatList,
	paramsLabel,
	selectKeysValues,
	setValChanged,
	setAccountVatList,
}) {
	useEffect(() => {
		console.log(accountVatList['vatKeys']);
	}, []);

	let newKeysValue = { ...accountVatList['vatKeys'] };
	return (
		<div className="vat-settings-keys-configuration">
			{accountVatList &&
				accountVatList['vatKeys'] &&
				Object.keys(accountVatList['vatKeys']).map(k => {
					if (k !== 'sectionAnal') {
						return (
							<div className="keys-container" key={k}>
								<span>{paramsLabel[k]} : </span>
								<CellEditable
									val={accountVatList['vatKeys'][k]}
									clsName="key-value"
									onBlurFct={val => {
										console.log(newKeysValue);
										console.log(val);

										newKeysValue[k] = val;
										setValChanged(
											JSON.stringify(newKeysValue) !==
												JSON.stringify(accountVatList['vatKeys'])
										);

										setAccountVatList({
											...accountVatList,
											vatKeys: newKeysValue,
										});
									}}
									isLabel={true}
								/>
							</div>
						);
					} else {
						return null;
					}
				})}
		</div>
	);
}
