import React from "react";
import { MuiThemeProvider } from "material-ui/styles";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

const FilterPrice = ({ filter, onChange }) => {
  return (
    <div>
      <MuiThemeProvider>
        <div style={{ display: "flex" }}>
          <SelectField
            autoWidth={true}
            fullWidth={true}
            value={filter ? filter.value.type : 3}
            onChange={(event, index, value) => {
              filter ? onChange({ ...filter.value, type: value }) : onChange({ type: value });
            }}
            style={{
              width: "40%",
              backgroundColor: "white",
              borderRadius: "5px",
              textAlign: "center",
              height: "30px"
            }}
            labelStyle={{ paddingRight: "0", lineHeight: "35px", height: "30px" }}
          >
            <MenuItem value={1} label=">" primaryText="Supérieur à" />
            <MenuItem value={2} label="<" primaryText="Inférieur à" />
            <MenuItem value={3} label="=" primaryText="Egale à" />
          </SelectField>
          <input
            onChange={event => {
              filter
                ? filter.value.type
                  ? onChange({ ...filter.value, value: event.target.value })
                  : onChange({ type: 3, value: event.target.value })
                : onChange({ type: 3, value: event.target.value });
            }}
            value={filter ? filter.value.value : ""}
            style={{
              color: "black",
              backgroundColor: "white",
              borderRadius: "5px",
              width: "50%",
              marginLeft: "3px",
              height: "30px"
            }}
          />
        </div>
      </MuiThemeProvider>
    </div>
  );
};

export default FilterPrice;
