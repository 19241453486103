import React from "react";
import VideoBlock from "./VideoBlock";
import classNames from "classnames";

const VideoGrid = props => {
  let { videos, hasChild = false } = props;
  let classObject = { "grid-3": true, "grid-child": hasChild };
  let grid3Class = classNames(classObject);
  return (
    <div className={grid3Class}>
      {Object.keys(videos).map(function(key) {
        return (
          <VideoBlock
            key={key}
            video={videos[key]}
            openVideoUrl={props.openVideoUrl}
            changeOpenedVideoUrl={props.changeOpenedVideoUrl}
            autoPlay={props.autoPlay}
            changeAutoPlay={props.changeAutoPlay}
            scrollTo={props.scrollTo}
          />
        );
      })}
    </div>
  );
};

export default VideoGrid;
