let authServerUrl = 'http://localhost:3000/';
let urlEspaceClient = "http://localhost:3300/";
let adminServerUrl = 'http://localhost:8181/';
let reportingUrl = '/';
let dashboardUrl = 'http://localhost:1620/';
let dataUrl = 'https://data-dev.laddition.com/';
let scope = 'bo-client';
let useReactGa = false;
let reportingServerVersion = '2.1.9';
let reportingClientVersion = '3.0.1';
let fiscalModuleVersion = '2.5';
let fiscalModuleServerVersion = '2.5';
let onboardingUrl = 'https://localhost:3200/';

if (
	window.location &&
	window.location.hostname === 'dev.reporting.laddition.com'
) {
	authServerUrl = 'https://auth.laddit.io/';
	urlEspaceClient = "https://espace-client.laddit.io/";
	adminServerUrl = 'https://dev.admin-api.laddition.com/';
	reportingUrl = 'https://dev.reporting-api.laddition.io/';
	dataUrl = 'https://data-dev.laddition.com/';
	scope = 'bo-client';
	useReactGa = false;
	dashboardUrl = 'https://board-reporting.laddit.io/';
} else if (
	window.location &&
	window.location.hostname === 'reporting.laddit.io'
) {
	authServerUrl = 'https://auth.laddit.io/';
	urlEspaceClient = "https://espace-client.laddit.io/";
	adminServerUrl = 'https://reporting-back.laddit.io/';
	reportingUrl = 'https://reporting.laddit.io/';
	dataUrl = 'https://data-dev.laddition.com/';
	onboardingUrl = 'https://hello.laddit.io/';
	scope = 'bo-client-laddit';
	useReactGa = false;
	dashboardUrl = 'https://board-reporting.laddit.io/';
} else if (
	window.location &&
	window.location.hostname === 'reporting.laddition.com'
) {
	authServerUrl = 'https://auth.laddition.com/';
	urlEspaceClient = "https://espace-client.laddition.com/";
	adminServerUrl = 'https://admin.laddition.com/';
	reportingUrl = 'https://reporting-api.laddition.com/';
	dataUrl = 'https://data.laddition.com/';
	onboardingUrl = 'https://hello.laddition.com/';
	scope = 'bo-client';
	useReactGa = true;
	dashboardUrl = 'https://board-reporting.laddition.com/';
} else if (
	window.location &&
	window.location.hostname === 'preprod.reporting.laddition.com'
) {
	authServerUrl = 'https://auth.laddition.com/';
	urlEspaceClient = "https://espace-client.laddition.com/";
	adminServerUrl = 'https://admin.laddition.com/';
	reportingUrl = 'https://preprod.reporting-api.laddition.com/';
	dataUrl = 'https://data.laddition.com/';
	scope = 'bo-client-preprod';
	useReactGa = true;
	dashboardUrl = 'https://board-reporting.laddition.com/';
}

export default {
	authServerUrl,
	urlEspaceClient,
	adminServerUrl,
	reportingUrl,
	dataUrl,
	scope,
	useReactGa,
	dashboardUrl,
	reportingServerVersion,
	reportingClientVersion,
	fiscalModuleVersion,
	fiscalModuleServerVersion,
	onboardingUrl,
	fiscalErrorPdfUrl:
		'https://cdn.laddition.com/7/adsladenrincidentsdonneesfiscales.pdf',
};
