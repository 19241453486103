import React, { Component } from "react";
import "../css/faq.css";
import "../css/searchBar.css";
import FaqCategories from "../components/FaqCategories";
import FaqItems from "../components/FaqItems";
import { map } from "../../../utils/utils";
import classNames from "classnames";
import Fuse from "fuse.js";
import { connect } from "react-redux";
import LadditionLoader from "../../../components/LadditionLoader";
import { I18n } from "react-redux-i18n";

class FAQ extends Component {
  constructor(props) {
    super(props);
    const { faqItems } = this.props;
    this.state = {
      searchBarClosed: true,
      searchBarValue: "",
      faqItems,
      faqItemsInitial: faqItems
    };

    this.toggleSearchBar = this.toggleSearchBar.bind(this);
    this.onSearchBarChangeValue = this.onSearchBarChangeValue.bind(this);
  }

  toggleSearchBar(e) {
    e.preventDefault();
    this.onSearchBarChangeValue(e);
    this.state.searchBarClosed ? this.searchInput.focus() : this.searchInput.blur();
    this.setState({
      searchBarClosed: !this.state.searchBarClosed,
      searchBarValue: ""
    });
  }

  onSearchBarChangeValue(e) {
    this.setState({
      searchBarValue: e.target.value
    });

    const options = {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["trigger"]
    };
    const { faqItemsInitial } = this.state;
    let searchResult = [];
    for (const id in faqItemsInitial) {
      for (const title in faqItemsInitial[id]) {
        const fuse = new Fuse(faqItemsInitial[id][title], options);
        const result = fuse.search(e.target.value);
        searchResult.push({});
        searchResult[id][title] = result;
      }
    }
    e.target.value
      ? this.setState({ faqItems: searchResult })
      : this.setState({ faqItems: this.state.faqItemsInitial });
  }

  componentWillReceiveProps(nextProps) {
    const { faqItems } = nextProps;
    this.setState({ faqItems, faqItemsInitial: faqItems });
  }

  render() {
    const { faqItems } = this.state;
    if (faqItems) {
      const getCategories = value => Object.keys(value)[0];
      const faqCategories = map(getCategories)(faqItems);

      return (
        <div id="faq-container">
          <header>
            <h1>{I18n.t("faq.title")}</h1>
          </header>
          <div
            id="search-bar"
            className={classNames("search-bar", {
              closed: this.state.searchBarClosed,
              "search-bar-open": !this.state.searchBarClosed
            })}
          >
            <input
              type="text"
              value={this.state.searchBarValue}
              ref={input => {
                this.searchInput = input;
              }}
              onChange={this.onSearchBarChangeValue}
            />
            <div id="close" className="toggle" onClick={this.toggleSearchBar}>
              {}
            </div>
          </div>
          <section className={classNames("cd-faq", { "search-mode": !this.state.searchBarClosed })}>
            <FaqCategories faqCategories={faqCategories} />
            <FaqItems faqItems={faqItems} />
            <a href="#0" className="cd-close-panel">
              Close
            </a>
          </section>
        </div>
      );
    } else {
      return (
        <div>
          <LadditionLoader hidden={this.state.faqItemsInitial !== undefined} />
          <h1>{I18n.t("faq.emptyFaq")}</h1>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    faqItems: state.entities.informations["undefined"].faq,
    entities: state.entities
  };
}

export default connect(
  mapStateToProps,
  null
)(FAQ);
