import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { I18n } from 'react-redux-i18n';
import logoImg from '../img/logo_symbol.png';
import config from '../utils/config';
import CloseModal from '../module/CustomerAccount/svg/CloseModal';

class ModalInformations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.state.open) {
			this.setState({ open: nextProps.open });
		}
	}
	render() {
		return (
			<div>
				<Modal
					classNames={{ modal: 'informations_modal' }}
					open={this.state.open}
					showCloseIcon={false}
					onClose={() => {
						this.props.onClose();
					}}>
					<div className="informations_modal_content">
						<div
							className="fiscal_close_icon"
							onClick={e => {
								if (this.state.loading) return false;
								this.props.onClose();
							}}>
							<CloseModal />
						</div>
						<h3>{I18n.t('informations.title')}</h3>
						<div className="informations_modal_container">
							<img
								alt="loader L'addition"
								src={logoImg}
								width="70px"
								height="70px"
							/>
							<div>
								<h5>
									{I18n.t('informations.reportingVersion')}{' '}
									{config.reportingClientVersion}
								</h5>
								<h5>
									{I18n.t('informations.reportingServerVersion')}{' '}
									{config.reportingServerVersion}
								</h5>
								<h5>
									{I18n.t('informations.fiscModuleVersion')}{' '}
									{config.fiscalModuleVersion}
								</h5>
								<h5>
									{I18n.t('informations.fiscModuleServerVersion')}{' '}
									{config.fiscalModuleServerVersion}
								</h5>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default ModalInformations;
