import React from 'react';

// GLOBAL MENU ICONS

export const MenuArrowCollapseSvg = props => (
	<svg
		width="27px"
		height="20px"
		viewBox="0 0 27 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group 4</title>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="Dekstop---Flat-(favoris)"
				transform="translate(-20.000000, -123.000000)">
				<g
					id="Group-2"
					transform="translate(0.000000, 106.000000)"
					fill="#FFFFFF">
					<rect id="Rectangle" x="0" y="0" width="70" height="918"></rect>
				</g>
				<g
					id="Group-4"
					transform="translate(34.000000, 133.000000) rotate(180.000000) translate(-34.000000, -133.000000) translate(22.000000, 124.000000)"
					stroke="#E84249"
					strokeLinecap="round"
					strokeWidth="2">
					<polyline id="Path-2" points="8 0 17 9 8 18"></polyline>
					<polyline
						id="Path-2-Copy"
						strokeOpacity="0.3"
						points="15 0 24 9 15 18"></polyline>
					<polyline
						id="Path-2-Copy-2"
						strokeOpacity="0.3"
						points="0 0 9 9 0 18"></polyline>
				</g>
			</g>
		</g>
	</svg>
);

export const MenuArrowOpenSvg = props => (
	<svg
		width="27px"
		height="20px"
		viewBox="0 0 27 20"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group 4</title>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="Dekstop---Flat-(favoris)"
				transform="translate(-21.000000, -123.000000)">
				<g
					id="Group-2"
					transform="translate(0.000000, 106.000000)"
					fill="#FFFFFF">
					<rect id="Rectangle" x="0" y="0" width="70" height="918"></rect>
				</g>
				<g
					id="Group-4"
					transform="translate(22.000000, 124.000000)"
					stroke="#E84249"
					strokeLinecap="round"
					strokeWidth="2">
					<polyline id="Path-2" points="8 0 17 9 8 18"></polyline>
					<polyline
						id="Path-2-Copy"
						strokeOpacity="0.3"
						points="15 0 24 9 15 18"></polyline>
					<polyline
						id="Path-2-Copy-2"
						strokeOpacity="0.3"
						points="0 0 9 9 0 18"></polyline>
				</g>
			</g>
		</g>
	</svg>
);

//CONTEXTUAL MENU SVG

export const Comptability = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17"
		height="20"
		viewBox="0 0 17 20">
		<g>
			<g>
				<g>
					<g opacity=".3">
						<path
							fill={props.fill}
							d="M8.014 16.938c0-.31.248-.56.555-.56.306 0 .555.25.555.56 0 .31-.249.561-.555.561a.558.558 0 0 1-.555-.56zm2.428.35a.279.279 0 0 1-.277-.28c0-.155.124-.28.277-.28h4.323c.153 0 .277.125.277.28 0 .155-.124.28-.277.28zm0-1.963a.279.279 0 0 1-.277-.28c0-.155.124-.28.277-.28h4.323c.153 0 .277.125.277.28 0 .155-.124.28-.277.28zm.698-9.466v3.015h3.625c.153 0 .277.126.277.28 0 .155-.124.281-.277.281H11.14v1.402h3.625c.153 0 .277.126.277.281 0 .155-.124.28-.277.28H11.14v1.403h3.625c.153 0 .277.125.277.28 0 .155-.124.28-.277.28H11.14v1.056H8.625c.28.028.499.267.499.558 0 .31-.249.56-.555.56a.558.558 0 0 1-.555-.56c0-.29.218-.53.498-.558H6.209v4.456c0 .171.14.31.31.31h10.206a.31.31 0 0 0 .31-.31V6.014a.155.155 0 0 0-.155-.155h-1.3v1.156a.433.433 0 1 1-.866 0V5.859h-.004V4.645a.433.433 0 0 0-.867 0v1.214h-.814v1.156a.433.433 0 0 1-.866 0V5.859h-.003V4.645a.433.433 0 0 0-.867 0v1.214z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M1.088 1.549h10.108c.32 0 .578.258.578.577v11.698c0 .797-.647 1.444-1.444 1.444H1.954A1.444 1.444 0 0 1 .51 13.824V2.126c0-.319.259-.577.578-.577zm.288.866v11.409c0 .319.26.577.578.577h8.376a.578.578 0 0 0 .578-.577V2.415z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M4.914 5.52a.289.289 0 0 1 0-.578h4.498a.289.289 0 0 1 0 .577z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M4.914 7.83a.289.289 0 0 1 0-.577h4.498a.289.289 0 0 1 0 .577z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M4.914 10.141a.289.289 0 0 1 0-.577h4.498a.289.289 0 0 1 0 .577z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M4.914 12.45a.289.289 0 0 1 0-.577h4.498a.289.289 0 0 1 0 .578z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M3.399 5.736a.578.578 0 1 0 0-1.155.578.578 0 0 0 0 1.155z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M3.399 8.047a.578.578 0 1 0 0-1.155.578.578 0 0 0 0 1.155z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M3.399 10.358a.578.578 0 1 0 0-1.155.578.578 0 0 0 0 1.155z"
						/>
					</g>
					<g>
						<path
							fill={props.fill}
							d="M3.399 12.667a.578.578 0 1 0 0-1.155.578.578 0 0 0 0 1.155z"
						/>
					</g>
					<g>
						<g>
							<path fill={props.fill} d="M4.449 1.873h.866V3.46h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M4.024-.04h.866v2.455h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M2.091 1.873h.866V3.46h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M1.665-.04h.866v2.455h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M6.808 1.873h.866V3.46h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M6.383-.04h.866v2.455h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M9.167 1.873h.866V3.46h-.866z" />
						</g>
						<g>
							<path fill={props.fill} d="M8.742-.04h.867v2.455h-.867z" />
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const MenuSandwichIcon = props => (
	<svg
		width="24px"
		height="16px"
		viewBox="0 0 24 16"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg">
		<title>Group Copy</title>
		<g
			id="Page-1"
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd">
			<g id="iPhone-8---2-Nav" transform="translate(-17.000000, -101.000000)">
				<rect
					id="Rectangle"
					fill="#FFFFFF"
					x="0"
					y="86"
					width="375"
					height="45"></rect>
				<g
					id="Group-Copy"
					transform="translate(17.000000, 101.000000)"
					fill="#E84249">
					<rect id="Rectangle" x="0" y="0" width="24" height="2" rx="1"></rect>
					<rect
						id="Rectangle-Copy"
						x="0"
						y="7"
						width="24"
						height="2"
						rx="1"></rect>
					<rect
						id="Rectangle-Copy-2"
						x="0"
						y="14"
						width="24"
						height="2"
						rx="1"></rect>
				</g>
			</g>
		</g>
	</svg>
);
