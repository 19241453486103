import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import NumberFormat from "react-number-format";
import { changeActionName } from "./utils";

class TablePaymentLinesHistorique extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  handleClickDetails(rowInfo, columns) {
    this.props.goToDetails(rowInfo, columns);
  }

  render() {
    let { data, columns } = this.props;

    //element[element.key_]=element.value;
    let dataArray = [];
    let creationObject = {};
    if (data) {
      Object.keys(data).map(elem => {
        if (data[elem].action == "create") {
          Object.keys(data[elem]).map(property => {
            creationObject[property] = data[elem][property];
            creationObject[data[elem].key_] = data[elem].value;
          });
        } else {
          dataArray.push(data[elem]);
        }
      });
      dataArray.unshift(creationObject);
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search
                  style={{
                    color: "white",
                    lineHeight: "17.4px",
                    verticalAlign: "middle"
                  }}
                />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Action", accessor: "action", pivot: true },
      {
        Header: "Montant",
        accessor: "amount",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        )
      },
      { Header: "Type", accessor: "id_payment_type", pivot: true },
      { Header: "Supprimé", accessor: "removed", pivot: true }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id" },
      { label: "Action", key: "action" },
      { label: "Montant", key: "amount" },
      { label: "Supprimé", key: "removed" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        if (typeof element != "undefined") {
          element[element.key_] = element.value;
        }
        if (["create", "update", "delete"].includes(element.action)) {
          element.action = changeActionName(element.action);
        }
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-lignesPaiementHistorique.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload
                    style={{ lineHeight: "20px", verticalAlign: "middle" }}
                  />{" "}
                  Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TablePaymentLinesHistorique;
