import React from "react";
import PropTypes from "prop-types";
import CategoryGrid from "./CategoryGrid";
import { VelocityTransitionGroup } from "velocity-react";
import Vivus from "vivus";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router";
import MediaQuery from "react-responsive";
import detectIe from "../../../utils/detectIe";
import arrowLeft from "../../../img/Forme_3.png";

class CategoryItem extends React.Component {
  constructor(props) {
    super(props);
    const random = new Date().getTime();
    this.state = {
      isOpen: false,
      hasChild: false,
      randomId: random.toString() + Math.random()
    };
    this.setIsOpen = this.setIsOpen.bind(this);
    this.changeReport = this.changeReport.bind(this);
  }

  componentDidMount() {
    if (this.props.category.hasOwnProperty("childrens")) {
      this.setState({ hasChild: true });
    }
    if (this.props.category.hasOwnProperty("icon")) {
      let svgElement = document.getElementById(this.state.randomId).children[0];
      if (detectIe()) {
        let nodes = svgElement.getElementsByTagName("path");
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].style.fillOpacity = 1;
          nodes[i].style.strokeOpacity = 0;
        }
      } else {
        new Vivus(
          svgElement,
          { type: "sync", duration: 50, start: "autostart" },
          () => {
            let nodes = svgElement.getElementsByTagName("path");
            for (let i = 0; i < nodes.length; i++) {
              nodes[i].style.fillOpacity = 1;
              nodes[i].style.strokeOpacity = 0;
            }
          }
        );
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.openedCategoryId === this.props.category.id) {
      this.setState({ isOpen: true });
    } else if (
      nextProps.openedCategoryId !== this.props.category.id &&
      this.state.isOpen === true
    ) {
      this.setState({ isOpen: false });
    }
  }

  setIsOpen(e) {
    e.preventDefault();
    if (this.props.openedCategoryId === this.props.category.id) {
      document.getElementsByClassName("parent-grid")[0].className =
        "grid-3 parent-grid";
      this.props.changeOpenedCategoryId(0);
    } else {
      document.getElementsByClassName("parent-grid")[0].className =
        "grid-3 parent-grid display-none";
      this.props.changeOpenedCategoryId(this.props.category.id);
    }
  }

  changeReport() {
    if (this.props.closeMenu) {
      this.props.closeMenu();
    }
  }

  render() {
    let { category } = this.props;
    function getSvg() {
      return { __html: category.icon };
    }
    if (category.hasOwnProperty("childrens") && !isEmpty(category.childrens)) {
      return (
        <div className={this.state.isOpen ? "parent-open display-block" : null}>
          <a href="#" onClick={this.setIsOpen}>
            <div className="box">
              <div className="svg-container">
                <div
                  id={this.state.randomId}
                  dangerouslySetInnerHTML={getSvg()}
                />
              </div>

              <div className="text-container">
                {this.state.isOpen ? (
                  <span
                    href="#"
                    id="category-parent-back"
                    className="previous round"
                  >
                    {" "}
                  </span>
                ) : (
                  ""
                )}
                <div className="item-title">{category.title}</div>
              </div>
            </div>
          </a>
          <MediaQuery minDeviceWidth={764}>
            <VelocityTransitionGroup enter={{ animation: "fadeIn" }}>
              {this.state.isOpen ? (
                <div className="childContainerOpen">
                  <div className="ronds">
                    <svg>
                      {/* cercle bleu */}
                      <circle
                        id="oval1"
                        cx="100%"
                        cy="10%"
                        r="120"
                        fill="#03CBFA"
                      />{" "}
                      {/* cercle bleu clair*/}
                      <circle
                        id="oval2"
                        cx="95%"
                        cy="25%"
                        r="10"
                        fill="rgba(3,203,250, 0.5)"
                      />{" "}
                      {/* cercle rouge*/}
                      <circle
                        id="oval5"
                        cx="-10"
                        cy="60%"
                        r="50"
                        fill="#e84249"
                      />{" "}
                      {/* cercle rouge clair*/}
                      <circle
                        id="oval4"
                        cx="5%"
                        cy="70%"
                        r="15"
                        fill="rgba(232,66,73, 0.5)"
                      />{" "}
                      <circle
                        className="circleDesktop"
                        id="oval7"
                        cx="70%"
                        cy="105%"
                        r="30"
                        fill="rgba(249,184,104, 0.75)"
                      />{" "}
                    </svg>
                  </div>
                  <div className="back-button">
                    <a href="#" onClick={this.setIsOpen}>
                      <img
                        src={arrowLeft}
                        alt={arrowLeft}
                        width="24px"
                        height="19px"
                      />
                      <div className="back-button-title">{category.title}</div>
                    </a>
                  </div>
                  <CategoryGrid
                    hasChild={this.state.hasChild}
                    isOpen={true}
                    categories={category.childrens}
                    closeMenu={this.props.closeMenu}
                  />
                </div>
              ) : (
                undefined
              )}
            </VelocityTransitionGroup>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={764}>
            {this.state.isOpen ? (
              <div className="childContainerOpen">
                <div className="ronds">
                  <svg>
                    {/* cercle bleu */}
                    <circle
                      id="oval1"
                      cx="100%"
                      cy="10%"
                      r="120"
                      fill="#03CBFA"
                    />{" "}
                    {/* cercle bleu clair*/}
                    <circle
                      id="oval2"
                      cx="95%"
                      cy="25%"
                      r="10"
                      fill="rgba(3,203,250, 0.5)"
                    />{" "}
                    {/* cercle rouge*/}
                    <circle
                      id="oval5"
                      cx="-10"
                      cy="60%"
                      r="50"
                      fill="#e84249"
                    />{" "}
                    {/* cercle rouge clair*/}
                    <circle
                      id="oval4"
                      cx="5%"
                      cy="70%"
                      r="15"
                      fill="rgba(232,66,73, 0.5)"
                    />{" "}
                    <circle
                      className="circleDesktop"
                      id="oval7"
                      cx="70%"
                      cy="105%"
                      r="30"
                      fill="rgba(249,184,104, 0.75)"
                    />{" "}
                  </svg>
                </div>
                <div className="back-button">
                  <a href="#" onClick={this.setIsOpen}>
                    <img
                      src={arrowLeft}
                      alt={arrowLeft}
                      width="24px"
                      height="19px"
                    />
                    <div className="back-button-title">{category.title}</div>
                  </a>
                </div>
                <CategoryGrid
                  hasChild={this.state.hasChild}
                  isOpen={true}
                  categories={category.childrens}
                  closeMenu={this.props.closeMenu}
                />
              </div>
            ) : (
              undefined
            )}
          </MediaQuery>
        </div>
      );
    } else {
      return category.link.indexOf("http") > -1 ? (
        <div>
          <a href={category.link}>
            <div className="box">
              <div className="svg-container">
                <div
                  id={this.state.randomId}
                  dangerouslySetInnerHTML={getSvg()}
                />
              </div>

              <div className="text-container">
                <div className="item-title">{category.title}</div>
                {category.description ? (
                  <span className="item-description">
                    {category.description}
                  </span>
                ) : null}
              </div>
            </div>
          </a>
        </div>
      ) : (
        <div>
          <Link
            to={
              category.is_react
                ? `/${category.link}`
                : category.type === 0
                ? "/reporting/" + category.id
                : "/reporting-sales/" + category.id
            }
            onClick={this.changeReport}
            state={category}
          >
            <div className="box">
              <div className="svg-container">
                <div
                  id={this.state.randomId}
                  dangerouslySetInnerHTML={getSvg()}
                />
              </div>
              <div className="text-container">
                <div className="item-title">{category.title}</div>
                {category.description ? (
                  <span className="item-description">
                    {category.description}
                  </span>
                ) : null}
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }
}

CategoryItem.propTypes = {
  category: PropTypes.object.isRequired
};

export default CategoryItem;
