import React, { Component } from 'react';
import FeedItem from './FeedItem';
import axios from 'axios';
import config from '../../utils/config';
import './style.css';

class Feed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			feedContent: null,
		};
	}
	componentDidMount() {
		if (this.props.name) {
			const userLang = navigator.language || navigator.userLanguage;
			axios
				.get(
					`${config.reportingUrl}api/feed/${this.props.name}/?locale=${userLang}`
				)
				.then(data => {
					if (data.data.success) {
						this.setState({ feedContent: data.data.formatedFeed });
					}
				});
		}
	}

	render() {
		return this.state.feedContent &&
			this.state.feedContent.feeds &&
			this.state.feedContent.feeds.length ? (
			<section id="cd-timeline" className="cd-container">
				{this.state.feedContent.feeds.map(item => {
					return <FeedItem key={item.id} item={item} />;
				})}
			</section>
		) : null;
	}
}

export default Feed;
