import React from 'react';
import 'react-tippy/dist/tippy.css';
import NavigationBarFront from './NavigationBarFront';
import FlashMessagesList from '../common/flashMessage/containers/FlashMessagesList';
import Category from '../module/Category/containers/Category';
import { addFlashMessage } from '../common/flashMessage/actions/flashMessages';
import { addNotification } from '../common/notification/actions/notification';
import { connect } from 'react-redux';
import forEach from 'lodash/forEach';
import ModalVideo from 'react-modal-video';
import ReactGA from 'react-ga';
import config from '../utils/config';
import Modal from 'react-responsive-modal';
import Btn from '../components/Button/Btn';
import checkImg from '../components/Password/img/success.svg';
import errorImg from '../components/Password/img/error.svg';
import PropTypes, { checkPropTypes } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Button from '@material-ui/core/Button';
import ModalFisc from '../module/Fisc/components/ModalFisc';
import ModalCheckFiscal from '../module/Fisc/components/ModalCheckFiscal';
import ModalInformations from '../components/ModalInformations';
import axios from 'axios';
import FrontContainer from './NewFrontNavigation/FrontContainer';
import Dashboard from '../module/Dashboard/containers/Dashboard';
import A_B_Tester from '../components/A_B_Tester';
import ModalProductsExport from '../module/ModalExport';
import { CGV } from '@capps/cgv';
import getUrlParams from '../utils/getUrlParams';
import { getRefreshToken } from '../utils/utils';
import { SubUserHeader, UnauthorizedServicePage } from '@capps/subuser-tools';

class AppFront extends React.Component {
	constructor(props) {
		super(props);
		if (window.sessionStorage.accountMigrationModalSeen === undefined) {
			window.sessionStorage.accountMigrationModalSeen = false;
		}
		if (
			props.auth &&
			props.auth.isAuthenticated &&
			props.auth.user.email !== undefined &&
			props.auth.user.hasMigratedEmail
		) {
			window.sessionStorage.accountMigrationModalSeen = true;
		}
		if (
			props.auth &&
			props.auth.isAuthenticated &&
			props.auth.user.email !== undefined &&
			props.auth.user.email.indexOf('@laddition.com') === -1
		) {
			window.sessionStorage.accountMigrationModalSeen = true;
		}
		if (
			window.location.pathname === '/account/migration' ||
			window.location.pathname === '/check-new-email' ||
			window.location.pathname === '/fisc-connect-as' ||
			window.localStorage.fiscAccessToken ||
			window.localStorage.viewAsCustomerToken
		) {
			window.sessionStorage.accountMigrationModalSeen = true;
		}
		this.state = {
			modalVideoOpen: false,
			modalVideoChannel: 'youtube',
			modalVideoId: 'cttiSXasFZ4',
			openAccounMigrationModal:
				window.sessionStorage.accountMigrationModalSeen === 'false',
			openFiscModal: false,
			openCheckFiscal: false,
			openVersionModal: false,
			openPaymentModal: false,
		};

		this.openHelpModal = this.openHelpModal.bind(this);
		this.openFiscalModal = this.openFiscalModal.bind(this);
		this.openCheckFiscal = this.openCheckFiscal.bind(this);
		this.openPaymentExportModal = this.openPaymentExportModal.bind(this);

		this.linkTo = this.linkTo.bind(this);
		this.onOpenAccountMigrationModal =
			this.onOpenAccountMigrationModal.bind(this);
		this.onCloseAccountMigrationModal =
			this.onCloseAccountMigrationModal.bind(this);
		this.onCloseAccountMigrationModal2 =
			this.onCloseAccountMigrationModal2.bind(this);
	}

	componentWillMount() {
		if (!window.localStorage.idVideo) {
			window.localStorage.helpVideoSeen = false;
		} else {
			if (window.localStorage.idVideo !== this.state.modalVideoId) {
				window.localStorage.helpVideoSeen = false;
			}
		}
		window.localStorage.idVideo = this.state.modalVideoId;
		forEach(this.props.messages, message => {
			this.props.addFlashMessage({
				type: message.type,
				text: message.message,
				persistent: message.persistent,
			});
		});

		forEach(this.props.notifications, notification => {
			let deletedNotification = window.localStorage.removedNotification;
			let skip = false;
			if (deletedNotification) {
				deletedNotification = deletedNotification.split(',');
				let deletedNotificationNumber = deletedNotification.length;
				for (let i = 0; i < deletedNotificationNumber - 1; ++i) {
					if (+deletedNotification[i] === +notification.id) {
						skip = true;
					}
				}
			}
			if (!skip) {
				this.props.addNotification({
					type: notification.type,
					text: notification.message,
					id: notification.id,
				});
			}
		});
	}

	componentWillReceiveProps(nextProps, nextState) {
		const el = document.getElementsByClassName('content-container');
		if (el && el[0]) {
			el[0].className = 'content-container';
		}
	}

	openHelpModal(e) {
		e.preventDefault();
		this.setState({
			modalVideoOpen: true,
		});
	}

	openFiscalModal(e) {
		e.preventDefault();
		this.setState({
			openFiscModal: true,
		});
	}
	openCheckFiscal(e) {
		e.preventDefault();
		this.setState({
			openCheckFiscal: true,
		});
	}

	openVersionModal = e => {
		e.preventDefault();
		this.setState({
			openVersionModal: true,
		});
	};
	openPaymentExportModal = e => {
		e.preventDefault();
		this.setState({
			openPaymentModal: true,
		});
	};

	exportCashing = async (startDate, endDate, route, userId = '', email) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const fiscFile = await axios({
				method: 'GET',
				headers: {
					Authorization: `accessToken ${accessToken}`,
				},
				url: `${config.reportingUrl}api/administration-fiscale/${route}/?startDate=${startDate}&endDate=${endDate}&email=${email}`,
				//responseType: "blob"
			}).then(res => {
				if (res.data.success) {
					let message = I18n.t('lneExport.noData');
					if (res.data.hasData) {
						message = I18n.t('lneExport.exportProcessing');
					}
					this.props.addFlashMessage({
						type: message.type,
						text: message,
						persistent: false,
					});
				}

				/* const url = window.URL.createObjectURL(new Blob([res.data]));
        const fileName = route + "-" + userId + "-" + Date.now() + ".zip";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click(); */
			});
		} catch (error) {
			return error;
		}
	};

	exportProductLines = async (startDate, endDate, userId = '', email) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const productLines = await axios({
				method: 'GET',
				headers: {
					Authorization: `accessToken ${accessToken}`,
				},
				params: {
					email: email,
					startDate: startDate,
					endDate: endDate,
				},
				url: `${config.reportingUrl}api/dataExport/exportProductLines`,
			}).then(res => {});
		} catch (error) {
			return error;
		}
	};

	componentDidMount() {
		if (
			!window.localStorage.helpVideoSeen ||
			window.localStorage.helpVideoSeen === 'false'
		) {
			this.setState({
				modalVideoOpen: true,
			});
			window.localStorage.helpVideoSeen = true;
		}
	}

	onOpenAccountMigrationModal() {
		this.setState({ openAccounMigrationModal: true });
	}

	onCloseAccountMigrationModal() {
		//this.setState({ openAccounMigrationModal: false });
		//window.sessionStorage.accountMigrationModalSeen = true;
	}

	onCloseAccountMigrationModal2() {
		this.setState({ openAccounMigrationModal: false });
		//window.sessionStorage.accountMigrationModalSeen = true;
	}

	linkTo(e, to, func) {
		e.preventDefault();
		func();
		this.context.router.push(to);
	}

	render() {
		if (config.useReactGa && this.state.modalVideoOpen) {
			ReactGA.modalview('/video/' + this.state.modalVideoId);
		}
		const { openAccounMigrationModal } = this.state;
		let { helperVideo, auth } = this.props;
		if (helperVideo && Object.keys(helperVideo).length > 0) {
			helperVideo = helperVideo[Object.keys(helperVideo)[0]];
		} else {
			helperVideo = {};
		}

		let newVideoModule = false;

		if (auth && !auth.isAuthenticated && auth.isNotAuthorized) {
		  return <UnauthorizedServicePage />;
		}
		if (
			auth &&
			auth.user &&
			auth.user.groups &&
			Array.isArray(auth.user.groups)
		) {
			const isSuiteClient = auth.user.groups.some(
				group => group.name === 'laddition-suite'
			);
			newVideoModule =
				isSuiteClient && window.location.pathname === '/videos-suite';
		}

		return (
			<div>
        <SubUserHeader user={auth.user} />
				<NavigationBarFront
					showMenuIcon={
						window.location.pathname !== '/' &&
						window.location.pathname !== '/fisc-connect-as'
					}
					openHelpModal={this.openHelpModal}
					openFiscalModal={this.openFiscalModal}
					openCheckFiscal={this.openCheckFiscal}
					openVersionModal={this.openVersionModal}
					openPaymentExportModal={this.openPaymentExportModal}
				/>
				<Modal
					open={openAccounMigrationModal}
					onClose={this.onCloseAccountMigrationModal}
					classNames={{
						modal: 'account-migration-modal',
						overlay: 'account-migration-overlay',
						closeIcon: 'account-migration-modal-close',
					}}
					little>
					<div>
						<h3>{I18n.t('front.appFront.logins')}</h3>
						<p>{I18n.t('front.appFront.warningLoginAccess')}</p>
						<p>
							<u>
								<b>{I18n.t('front.appFront.warningOwner1')}</b>
							</u>{' '}
							{I18n.t('front.appFront.warningOwner2')}{' '}
							<b>{I18n.t('front.appFront.warningOwner3')}</b>{' '}
							{I18n.t('front.appFront.warningOwner4')}
						</p>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="contained"
								className="submitButton"
								//type="submit"
								onClick={e =>
									this.linkTo(
										e,
										'/account/migration',
										this.onCloseAccountMigrationModal2
									)
								}>
								{I18n.t('accountMigration.btnValidate')}
							</Button>
						</div>
					</div>
				</Modal>
				<ModalVideo
					channel={helperVideo.platform}
					isOpen={
						this.state.modalVideoOpen && Object.values(helperVideo).length
					}
					videoId={helperVideo.video_id}
					onClose={() => this.setState({ modalVideoOpen: false })}
				/>
				<ModalFisc
					open={this.state.openFiscModal}
					exportCashing={this.exportCashing.bind(this)}
					user={this.props.auth.user}
					onClose={() => {
						return this.setState({
							openFiscModal: false,
						});
					}}
				/>
				<ModalCheckFiscal
					open={this.state.openCheckFiscal}
					onClose={() => this.setState({ openCheckFiscal: false })}
				/>
				<ModalInformations
					open={this.state.openVersionModal}
					onClose={() => {
						this.setState({ openVersionModal: false });
					}}
				/>
				<CGV refreshToken={getRefreshToken()} />
				<ModalProductsExport
					open={this.state.openPaymentModal}
					exportFct={this.exportProductLines.bind(this)}
					buttonLabel={I18n.t('globalLabels.validate')}
					user={this.props.auth.user}
					fctOnClose={() => {
						this.setState({ openPaymentModal: false });
					}}
				/>

				<div className="content-container-new-nav">
					<FrontContainer childrens={this.props.children || <Dashboard />} />
				</div>

				<FlashMessagesList />
			</div>
		);
	}
}

// TO-DO Creer un composant avec la feature flag en HOC avec Feature flag.

AppFront.contextTypes = {
	router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		messages: state.entities.messages,
		categories: state.entities.categories,
		notifications: state.entities.notifications,
		helperVideo: state.entities.helperVideo,
		auth: state.auth,
	};
}

export default connect(mapStateToProps, {
	addFlashMessage,
	addNotification,
})(AppFront);
