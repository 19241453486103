import React from "react";

const SearchSvg = props => (
  <svg
    width="20px"
    height="75%"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon / search</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-64.000000, -846.000000)" id="02">
        <g transform="translate(44.000000, 781.000000)">
          <g id="linéaire" transform="translate(20.000000, 0.000000)">
            <g id="icon-/-search" transform="translate(0.000000, 65.000000)">
              <g id="icon-/-search-border">
                <path
                  d="M32.4081169,63 C15.0111473,63 0.908116908,48.8969696 0.908116908,31.5 C0.908116908,14.1030304 15.0111473,0 32.4081169,0 C49.8050865,0 63.9081169,14.1030304 63.9081169,31.5 C63.9081169,48.8969696 49.8050865,63 32.4081169,63 Z M32.4081169,56.875 C46.4223424,56.875 57.7831169,45.5142255 57.7831169,31.5 C57.7831169,17.4857745 46.4223424,6.125 32.4081169,6.125 C18.3938914,6.125 7.03311691,17.4857745 7.03311691,31.5 C7.03311691,45.5142255 18.3938914,56.875 32.4081169,56.875 Z"
                  id="Oval-19"
                  fill="#acacac"
                  fillRule="nonzero"
                />
                <path
                  d="M49.9286385,57.3471565 C50.2051809,59.5330409 50.3010845,61.6693687 50.214948,63.755224 C50.1286901,65.8440152 49.853313,68.0409665 49.3908075,70.3471565 L72.0316446,70.3471565 C75.6214954,70.3471565 78.5316446,67.4370073 78.5316446,63.8471565 C78.5316446,60.2573056 75.6214954,57.3471565 72.0316446,57.3471565 L49.9286385,57.3471565 Z"
                  id="Rectangle-26"
                  stroke="#acacac"
                  strokeWidth="6"
                  transform="translate(63.563557, 63.847156) rotate(-315.000000) translate(-63.563557, -63.847156) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SearchSvg;
