import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";

import TableCommandesLinesHistorique from "../components/Tables/TableCommandesLinesHistorique";

import * as fiscApiActions from "../actions/fiscApiActions";

import { browserHistory } from "react-router";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";
import DetailedInformations from "../components/DetailedInformations";

class DetailsOrderLines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataOrderLinesHistory: {},
      isLoading: false
    };
  }

  goBack() {
    browserHistory.goBack();
  }

  goHome() {
    browserHistory.push("/administration-fiscale");
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.props.actions.resetHistory(this.props.entities);
    await this.props.actions.fetchTrackingHistory(
      "order_line",
      this.props.location.state.cashRegisterId,
      this.props.location.state.rowInfo.original.id
    );
    this.setState({ isLoading: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ dataOrderLinesHistory: nextProps.entities.history });
  }

  render() {
    let rowInfo = this.props.location.state.rowInfo;
    let rowInfoData = this.props.location.state.rowInfo.row._original;
    let columns = this.props.location.state.columns;

    const { isLoading } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={this.goBack}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={this.goHome}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>
          <div className="fiscContainer">
            <div className="fiscHeader">
              <div className="fiscHeaderLeft">
                <h1 className="titlePage">Détails de ligne de commande</h1>
                <h3 className="fiscPath">
                  {" "}
                  <span style={{ color: "#9A9A9A" }}>
                    cloture/service/commande/ligne de commande/
                  </span>
                  historique
                </h3>
              </div>
              <div
                style={{ marginLeft: "auto", marginRight: "5%", width: "55%" }}
              >
                <h3 className="rowInfoTitle">Informations détaillées</h3>
                <div className="gridRowInfo">
                  <DetailedInformations
                    rowInfo={rowInfoData}
                    columns={columns}
                  />
                </div>
              </div>
            </div>

            <div className="tabsContainer">
              <div className="containerTable">
                <TableCommandesLinesHistorique
                  data={this.state.dataOrderLinesHistory}
                />
              </div>
            </div>
            <div
              id="fisc-loader-overlay"
              className={classnames({ hidden: !isLoading })}
            >
              <div class={classnames("pulse", { hidden: !isLoading })}>
                <img
                  alt="loader L'addition"
                  src={logoLoader}
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsOrderLines);
