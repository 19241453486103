import { setCurrentUser, generateAccessToken, setUnauthorizedUser, getSubUserServices, setSubUserServices } from '../common/login/actions/authActions';
import config from './config';
import jwtDecode from 'jwt-decode';
import { getInformations } from '../actions/information';
import axios from 'axios';
import { setLocale } from 'react-redux-i18n';
import { subUserTools } from '@capps/subuser-tools';
import { getRefreshToken } from './utils';

/**
 * generate accessToken
 *
 * @param {object} store
 * @param {function} next
 * @param {string} token
 * @param {string} type
 */
const dispatchGenerateAccessToken = (store, next, token, type = 'refreshToken') => {
	if (type === 'fiscAccessToken') {
		return store
			.dispatch(generateAccessToken(token, 'fiscAccessToken'))
			.then((result) => {
				dispatchSetUserAndGetData(store, next);
			})
			.catch((err) => {
				console.log(err);
			});
	} else {
		return store
			.dispatch(generateAccessToken(token))
			.then((result) => {
			  if (result.payload.type && result.payload.type === "SUB_USER_NO_ACCESS") {
					return dispatchUnauthorizedService(store, next);
				}
				dispatchSetUserAndGetData(store, next);
			})
			.catch((err) => {
				window.localStorage.removeItem('accessToken');
				window.localStorage.removeItem('refreshToken');
				window.location.replace(config.authServerUrl + '?scope=' + config.scope);
				console.log(err);
			});
	}
};

/**
 * set current user then fetch all data for the reporting
 *
 * @param {object} store
 * @param {function} next
 */
const dispatchSetUserAndGetData = (store, next) => {
	const { accessToken, viewAsCustomerToken } = window.localStorage;
  const user = jwtDecode(viewAsCustomerToken || accessToken);
	store.dispatch(setCurrentUser(user)).then(() => {
		const isSubUser = subUserTools.isSubUserAccount(user);
		if (isSubUser) {
		  dispatchSubUserServices(store, next);
    }

		store.dispatch(getInformations()).then(() => {
			document.getElementById('laddition-loader-container').classList.add('laddition-loader-container__hidden');
			next();
		});
	});
	if (window.localStorage.accessToken || getRefreshToken()) {
		axios
			.get(config.reportingUrl + 'api/user/local', {
				headers: { Authorization: `Bearer ${window.localStorage.accessToken}` }
			})
			.then((res) => {
				store.dispatch(setLocale(res.data || 'fr'));
			});
	}
};

/**
 * check if current url require auth
 *
 * @param {object} nextState
 * @returns {boolean}
 */
const urlUnderAuthRequire = (nextState) => {
	return nextState.location.pathname !== '/fisc-connect-as' && nextState.location.pathname !== '/check-new-email';
};

const dispatchUnauthorizedService = (store, next) => {
  store.dispatch(setUnauthorizedUser());
  document.getElementById('laddition-loader-container').classList.add('laddition-loader-container__hidden');
  next();
}

const dispatchSubUserServices = async (store, next) => {
  const refreshToken = window.localStorage.getItem('refreshToken');
  const subUserServices = await getSubUserServices(refreshToken);
  store.dispatch(setSubUserServices(subUserServices));
}

/**
 * generate access token or redirect
 *
 * @param {object} store
 * @param {object} nextState
 * @param {object} replace
 * @param {function} next
 */
const requireAuth = (store, nextState, replace, next) => {
	document.getElementById('laddition-loader-container').classList.remove('laddition-loader-container__hidden');
	if (urlUnderAuthRequire(nextState)) {
		const { fiscAccessToken } = window.localStorage;
    const refreshToken = getRefreshToken();
		if (refreshToken !== undefined) {
			dispatchGenerateAccessToken(store, next, refreshToken);
		} else if (fiscAccessToken !== undefined) {
			dispatchGenerateAccessToken(store, next, fiscAccessToken, 'fiscAccessToken');
		} else {
			window.location.replace(config.authServerUrl + '?scope=' + config.scope);
		}
	} else {
		document.getElementById('laddition-loader-container').classList.add('laddition-loader-container__hidden');
		next();
	}
};

export default requireAuth;
