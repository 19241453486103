import {CALL_API} from 'redux-api-middleware';
import {schema, normalize} from 'normalizr';
import * as types from '../constant';
import config from '../../../utils/config';

const fiscAccessSchema = new schema.Entity('fiscAccess');
const fiscAccessListSchema = [fiscAccessSchema];

const crCreation = new schema.Entity('cr-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const crUpdate = new schema.Entity('cr-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const oCreation = new schema.Entity('o-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const oUpdate = new schema.Entity('o-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const olCreation = new schema.Entity('ol-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const olUpdate = new schema.Entity('ol-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const plCreation = new schema.Entity('pl-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const plUpdate = new schema.Entity('pl-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const creCreation = new schema.Entity('cr-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const creUpdate = new schema.Entity('cr-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const crvCreation = new schema.Entity('crv-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const crvUpdate = new schema.Entity('crv-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reCreation = new schema.Entity('re-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reUpdate = new schema.Entity('re-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reDCreation = new schema.Entity('re-d-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reDUpdate = new schema.Entity('re-d-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reDTCreation = new schema.Entity('re-d-t-creation', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});
const reDTUpdate = new schema.Entity('re-d-t-update', undefined, {
  idAttribute: value => `${value.id}-${value.timestamp}-${value.action}`
});

const cashRegister = new schema.Entity('cashRegisters', {
  crCreation: crCreation,
  crUpdate: [crUpdate]
});

const order = new schema.Entity('orders', {
  oCreation: oCreation,
  oUpdate: [oUpdate]
});

const pos = new schema.Entity('pos');
const server = new schema.Entity('server');

const listError = new schema.Entity('listErrors', {
  pos: [pos],
  server: [server]
});


const cloture = new schema.Entity('clotures');
const clotureListSchema = [cloture];

const service = new schema.Entity('services');
const serviceListSchema = [service];

const commande = new schema.Entity('commandes');
const commandeListSchema = [commande];

const history = new schema.Entity('history');
const historyListSchema = [history];

const fullHistory = new schema.Entity('fullHistory');
const fullHistoryListSchema = [fullHistory];

const technicalInterventionsSchema = new schema.Entity('technicalInterventions');
const technicalInterventionsListSchema = [technicalInterventionsSchema];

const controlStripShiftDocumentSchema = new schema.Entity('controlStripShiftDocument', {},{idAttribute:'id_control_strip'});
const controlStripShiftDocumentListSchema = [controlStripShiftDocumentSchema];

const controlStripSalesDocumentSchema = new schema.Entity('controlStripSalesDocument',{},{idAttribute:'id_control_strip'});
const controlStripSalesDocumentListSchema = [controlStripSalesDocumentSchema];

const trackingServer = new schema.Entity('trackingsServer');
const trackingserverListSchema = [trackingServer];

const orderLine = new schema.Entity('ordersLines', {
  olCreation: olCreation,
  olUpdate: [olUpdate]
});
const paymentLine = new schema.Entity('paymentLines', {
  plCreation: plCreation,
  plUpdate: [plUpdate]
});
const credit = new schema.Entity('credits', {
  creCreation: creCreation,
  creUpdate: [creUpdate]
});
const cashRegisterVariation = new schema.Entity('cashRegistersVariation', {
  crvCreation: crvCreation,
  crvUpdate: [crvUpdate]
});
const receipt = new schema.Entity('receipts', {
  reCreation: reCreation,
  reUpdate: [reUpdate]
});
const receiptDetail = new schema.Entity('receiptDetails', {
  reDCreation: reDCreation,
  reDUpdate: [reDUpdate]
});
const receiptDetailTax = new schema.Entity('receiptDetailTaxs', {
  reDTCreation: reDTCreation,
  reDTUpdate: [reDTUpdate]
});
const responseSchema = {cashRegisters: new schema.Array(cashRegister)};
const responseSchemaServices = {services: new schema.Array(service)};
// const responseCloturesSchema = {clotures: new schema.Array(clotures)};
const responseSchemaOrder = {orders: new schema.Array(order)};
const responseSchemaOrderLine = {ordersLines: new schema.Array(orderLine)};
const responseSchemaPaymentLine = {paymentLines: new schema.Array(paymentLine)};
const responseSchemaCredit = {credits: new schema.Array(credit)};
const responseSchemaCashRegisterVariation = {cashRegistersVariation: new schema.Array(cashRegisterVariation)};
const responseSchemaReceipt = {receipts: new schema.Array(receipt)};
const responseSchemaReceiptDetail = {receiptDetails: new schema.Array(receiptDetail)};
const responseSchemaReceiptDetailTax = {receiptDetailTaxs: new schema.Array(receiptDetailTax)};

export function addFiscAccess(fiscAccess) {
  return {
    [CALL_API]: {
      endpoint: config.reportingUrl + 'api/administration-fiscale',
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fiscAccess),
      types: [
        types.ADD_FISC_ACCESS_REQUEST,
        {
          type: types.ADD_FISC_ACCESS_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json.fiscAccess, fiscAccessListSchema)
                );
            }
          }
        },
        types.ADD_FISC_ACCESS_FAILURE
      ]
    }
  };
}

export function fetchTrackingOrdersLines(orderId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/order-line';
  fullEndPoint += orderId !== 0 && orderId !== undefined ?
    '?orderId=' + orderId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_ORDER_LINE_REQUEST,
        {
          type: types.FETCH_TRACKED_ORDER_LINE_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaOrderLine)
                );
            }
          }
        },
        types.FETCH_TRACKED_ORDER_LINE_FAILURE
      ]
    }
  };
}

export function fetchTrackingCredits(cashRegisterId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/credit';
  fullEndPoint += cashRegisterId !== 0 && cashRegisterId !== undefined ?
    '?cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_CREDIT_REQUEST,
        {
          type: types.FETCH_TRACKED_CREDIT_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaCredit)
                );
            }
          }
        },
        types.FETCH_TRACKED_CREDIT_FAILURE
      ]
    }
  };
}

export function fetchTrackingCashRegistersVariation(cashRegisterId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/cash-register-variation';
  fullEndPoint += cashRegisterId !== 0 && cashRegisterId !== undefined ?
    '?cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_CASH_REGISTER_VARIATION_REQUEST,
        {
          type: types.FETCH_TRACKED_CASH_REGISTER_VARIATION_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaCashRegisterVariation)
                );
            }
          }
        },
        types.FETCH_TRACKED_CASH_REGISTER_VARIATION_FAILURE
      ]
    }
  };
}

export function fetchTrackingReceipt(orderId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/receipt';
  fullEndPoint += orderId !== 0 && orderId !== undefined ?
    '?orderId=' + orderId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_RECEIPT_REQUEST,
        {
          type: types.FETCH_TRACKED_RECEIPT_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaReceipt)
                );
            }
          }
        },
        types.FETCH_TRACKED_RECEIPT_FAILURE
      ]
    }
  };
}

export function fetchTrackingReceiptDetail(receiptId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/receipt-detail';
  fullEndPoint += receiptId !== 0 && receiptId !== undefined ?
    '?receiptId=' + receiptId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_RECEIPT_DETAIL_REQUEST,
        {
          type: types.FETCH_TRACKED_RECEIPT_DETAIL_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaReceiptDetail)
                );
            }
          }
        },
        types.FETCH_TRACKED_RECEIPT_DETAIL_FAILURE
      ]
    }
  };
}

export function fetchTrackingReceiptDetailTax(receiptDetailId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/receipt-detail-tax';
  fullEndPoint += receiptDetailId !== 0 && receiptDetailId !== undefined ?
    '?receiptDetailId=' + receiptDetailId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_RECEIPT_DETAIL_TAX_REQUEST,
        {
          type: types.FETCH_TRACKED_RECEIPT_DETAIL_TAX_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaReceiptDetailTax)
                );
            }
          }
        },
        types.FETCH_TRACKED_RECEIPT_DETAIL_TAX_FAILURE
      ]
    }
  };
}

export function fetchTrackingPaymentLines(orderId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/payment-line';
  fullEndPoint += orderId !== 0 && orderId !== undefined ?
    '?orderId=' + orderId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_PAYMENT_LINE_REQUEST,
        {
          type: types.FETCH_TRACKED_PAYMENT_LINE_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaPaymentLine)
                );
            }
          }
        },
        types.FETCH_TRACKED_PAYMENT_LINE_FAILURE
      ]
    }
  };
}

export function fetchTrackingHistory(tableName,cashRegisterId,objectId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/history';
  fullEndPoint += objectId !== '' && objectId !== undefined ?
    '?objectId=' + objectId : '';
    fullEndPoint += tableName !== '' && tableName !== undefined ?
    '&tableName=' + tableName : '';
    fullEndPoint += cashRegisterId !== '' && cashRegisterId !== undefined ?
    '&cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_HISTORY_REQUEST,
        {
          type: types.FETCH_TRACKED_HISTORY_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, historyListSchema)
                );
            }
          }
        },
        types.FETCH_TRACKED_HISTORY_FAILURE
      ]
    }
  };
}

export function fetchTrackingFullHistory(cashRegisterId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/fullhistory';
  fullEndPoint += cashRegisterId !== '' && cashRegisterId !== undefined ?
    '?cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_FULL_HISTORY_REQUEST,
        {
          type: types.FETCH_TRACKED_FULL_HISTORY_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, fullHistoryListSchema)
                );
            }
          }
        },
        types.FETCH_TRACKED_FULL_HISTORY_FAILURE
      ]
    }
  };
}


export function fetchTrackingOrders(cashRegisterId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/order';
  fullEndPoint += cashRegisterId !== '' && cashRegisterId !== undefined ?
    '?cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_ORDER_REQUEST,
        {
          type: types.FETCH_TRACKED_ORDER_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, responseSchemaOrder)
                );
            }
          }
        },
        types.FETCH_TRACKED_ORDER_FAILURE
      ]
    }
  };
}

export function fetchTrackingServer(startDate, endDate) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking-server';
  fullEndPoint += '?startDate=' + startDate + '&endDate=' + endDate;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_TRACKING_SERVER_REQUEST,
        {
          type: types.FETCH_TRACKED_TRACKING_SERVER_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json.trackingsServer, trackingserverListSchema)
                );
            }
          }
        },
        types.FETCH_TRACKED_TRACKING_SERVER_FAILURE
      ]
    }
  };
}

export function fetchClotures(startDate, endDate) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/cloture';
  fullEndPoint += '?startDate=' + startDate + '&endDate=' + endDate;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_CLOTURES_REQUEST,
        {
          type: types.FETCH_TRACKED_CLOTURES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json.clotures, clotureListSchema)
                );
            }
          }
        },
        types.FETCH_TRACKED_CLOTURES_FAILURE
      ]
    }
  };
}

export function fetchCloturesById(cloturesIds) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/clotureById';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      body:JSON.stringify({cloturesIds:cloturesIds}),
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_CLOTURES_REQUEST,
        {
          type: types.FETCH_TRACKED_CLOTURES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json.clotures, clotureListSchema)
                );
            }
          }
        },
        types.FETCH_TRACKED_CLOTURES_FAILURE
      ]
    }
  };
}

export function fetchTrackingCashRegisters(startDate, endDate) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/cash-register';
  fullEndPoint += '?startDate=' + startDate + '&endDate=' + endDate;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TRACKED_CASH_REGISTER_REQUEST,
        {
          type: types.FETCH_TRACKED_CASH_REGISTER_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {return normalize(json, responseSchema)}
                );
            }
          }
        },
        types.FETCH_TRACKED_CASH_REGISTER_FAILURE
      ]
    }
  };
}



export function fetchServices(startDate, endDate) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/services';
  fullEndPoint += '?startDate=' + startDate + '&endDate=' + endDate;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_SERVICES_REQUEST,
        {
          type: types.FETCH_SERVICES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {return normalize(json, serviceListSchema)}
                );
            }
          }
        },
        types.FETCH_SERVICES_FAILURE
      ]
    }
  };
}

export function fetchCommandes(cashRegisterId) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/tracking/commandes';
  fullEndPoint += cashRegisterId !== '' && cashRegisterId !== undefined ?
    '?cashRegisterId=' + cashRegisterId : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_COMMANDES_FAILURE,
        {
          type: types.FETCH_COMMANDES_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => normalize(json, commandeListSchema)
                );
            }
          }
        },
        types.FETCH_COMMANDES_FAILURE
      ]
    }
  };
}


export function checkSignatureServer(startDate, endDate) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/check/signatureServer';
  fullEndPoint += '?startDate=' + startDate + '&endDate=' + endDate;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.ADD_FISC_CHECK_ACCESS_SIGNATURE_REQUEST,
        {
          type: types.ADD_FISC_CHECK_ACCESS_SIGNATURE_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return json;
                  }
                );
            }
          }
        },
        types.ADD_FISC_CHECK_ACCESS_SIGNATURE_FAILURE
      ]
    }
  };
}


export function checkSignaturePos(idCashRegister,lastSignatures) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/check/signaturePos';
  fullEndPoint += '?idCashRegister=' + idCashRegister;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({lastSignatures:lastSignatures}),
      types: [
        types.ADD_FISC_CHECK_ACCESS_SIGNATURE_REQUEST,
        {
          type: types.ADD_FISC_CHECK_ACCESS_SIGNATURE_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return json;
                  }
                );
            }
          }
        },
        types.ADD_FISC_CHECK_ACCESS_SIGNATURE_FAILURE
      ]
    }
  };
}

export function fetchTechnicalInterventions() {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/technical-intervention';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TECHNICAL_INTERVENTIONS_REQUEST,
        {
          type: types.FETCH_TECHNICAL_INTERVENTIONS_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return normalize(json.technicalInterventions, technicalInterventionsListSchema);
                  }
                );
            }
          }
        },
        types.FETCH_TECHNICAL_INTERVENTIONS_FAILURE
      ]
    }
  };
}

export function fetchTechnicalInterventionsByServiceId(serviceId) {
  let fullEndPoint = config.reportingUrl + `api/administration-fiscale/technical-intervention/${serviceId}`;
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_TECHNICAL_INTERVENTIONS_REQUEST,
        {
          type: types.FETCH_TECHNICAL_INTERVENTIONS_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return normalize(json.technicalInterventions, technicalInterventionsListSchema);
                  }
                );
            }
          }
        },
        types.FETCH_TECHNICAL_INTERVENTIONS_FAILURE
      ]
    }
  };
}

export function fetchControlStripShiftDocument(idShiftDocument) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/controlStripShiftDocument';
  fullEndPoint += idShiftDocument !== '' && idShiftDocument !== undefined ?
    '?idShiftDocument=' + idShiftDocument : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_REQUEST,
        {
          type: types.FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return normalize(json, controlStripShiftDocumentListSchema);
                  }
                );
            }
          }
        },
        types.FETCH_CONTROL_STRIP_SHIFT_DOCUMENT_FAILURE
      ]
    }
  };
}

export function fetchControlStripSalesDocument(idSalesDocument) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/controlStripSalesDocument';
  fullEndPoint += idSalesDocument !== '' && idSalesDocument !== undefined ?
    '?idSalesDocument=' + idSalesDocument : '';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      types: [
        types.FETCH_CONTROL_STRIP_SALES_DOCUMENT_REQUEST,
        {
          type: types.FETCH_CONTROL_STRIP_SALES_DOCUMENT_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return normalize(json, controlStripSalesDocumentListSchema);
                  }
                );
            }
          }
        },
        types.FETCH_CONTROL_STRIP_SALES_DOCUMENT_FAILURE
      ]
    }
  };
}

export function fetchErrors(errors) {
  let fullEndPoint = config.reportingUrl + 'api/administration-fiscale/error';
  return {
    [CALL_API]: {
      endpoint: fullEndPoint,
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({errors: errors}),
      types: [
        types.FETCH_SIGNATURE_ERRORS_REQUEST,
        {
          type: types.FETCH_SIGNATURE_ERRORS_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    return normalize(json.listErrors, listError);
                  }
                );
            }
          }
        },
        types.FETCH_SIGNATURE_ERRORS_FAILURE
      ]
    }
  };
}

export function checkFiscAccess(fiscAccess) {
  return {
    [CALL_API]: {
      endpoint: config.reportingUrl + 'api/administration-fiscale/check',
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fiscAccess),
      types: [
        types.ADD_FISC_CHECK_ACCESS_REQUEST,
        {
          type: types.ADD_FISC_CHECK_ACCESS_SUCCESS,
          payload: (action, state, res) => {
            const contentType = res.headers.get('Content-Type');
            if (contentType && contentType.indexOf('json') > -1) {
              return res.json()
                .then(
                  (json) => {
                    window.localStorage
                      .setItem('fiscAccessToken', json.fiscAccess[0].token);
                    return normalize(json.fiscAccess, fiscAccessListSchema);
                  }
                );
            }
          }
        },
        types.ADD_FISC_CHECK_ACCESS_FAILURE
      ]
    }
  };
}

export function resetOrdersLines(entities) {
  return {
    type: types.RESET_ORDERS_LINES,
    entities: entities
  };
}

export function resetClotures(entities) {
  return {
    type: types.RESET_CLOTURES,
    entities: entities
  };
}

export function resetOrders(entities) {
  return {
    type: types.RESET_ORDERS,
    entities: entities
  };
}

export function resetPaymentLines(entities) {
  return {
    type: types.RESET_PAYMENT_LINES,
    entities: entities
  };
}

export function resetCredit(entities) {
  return {
    type: types.RESET_CREDIT,
    entities: entities
  };
}

export function resetCashRegisterVariation(entities) {
  return {
    type: types.RESET_CASH_REGISTER_VARIATION,
    entities: entities
  };
}

export function resetCashRegister(entities) {
  return {
    type: types.RESET_CASH_REGISTER,
    entities: entities
  };
}

export function resetReceipt(entities) {
  return {
    type: types.RESET_RECEIPT,
    entities: entities
  };
}

export function resetReceiptDetail(entities) {
  return {
    type: types.RESET_RECEIPT_DETAIL,
    entities: entities
  };
}

export function resetTrackingserver(entities) {
  return {
    type: types.RESET_TRACKING_SERVER,
    entities: entities
  };
}

export function resetReceiptDetailTax(entities) {
  return {
    type: types.RESET_RECEIPT_DETAIL_TAX,
    entities: entities
  };
}

export function resetTechnicalInterventions(entities) {
  return {
    type: types.RESET_TECHNICAL_INTERVENTIONS,
    entities: entities
  };
}

export function resetHistory(entities) {
  return {
    type: types.RESET_TRACKED_HISTORY,
    entities: entities
  };
}

export function resetFullHistory(entities) {
  return {
    type: types.RESET_TRACKED_FULL_HISTORY,
    entities: entities
  };
}

