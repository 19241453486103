import React, { Component } from 'react';
import '../css/OuiLink.css';
import CallApi from '../../../utils/CallApi';
import Loader from '../../../components/Loader';
import { connect } from 'react-redux';
import { getRefreshToken } from '../../../utils/utils';

class OuiLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: '',
			noToken: false,
			userInfo: '',
		};
	}

	componentDidMount() {
		const accessToken = window.localStorage.getItem('accessToken');
		CallApi(
			'POST',
			`ouilink`,
			'',
			{ refreshToken: getRefreshToken() },
			`accessToken ${accessToken}`
		)
			.then(response => {
				if (response[0]) {
					if (response[0].ouiLinkAccessToken) {
						this.setState({ token: response[0].ouiLinkAccessToken });
					}
					if (response[0].noToken) {
						const userInfo = response[0].userInfo;
						let buff = new Buffer(JSON.stringify(userInfo));
						let base64data = buff.toString('base64');
						this.setState({ noToken: true, userInfo: base64data });
					}
				}
			})
			.catch(err => {
				console.log(err);
			});
	}

	render() {
		const { token, noToken } = this.state;

		return token ? (
			<div id="main-container" className="main-container nav-effect-1">
				<iframe
					style={{ top: '0px' }}
					id="ouilink-iframe"
					src={`https://ouilink.laddition.com/partners/direct?k=${token}`}
				/>
			</div>
		) : !noToken ? (
			<Loader show={true} />
		) : (
			<div id="main-container" className="main-container nav-effect-1">
				<iframe
					style={{ top: '0px' }}
					id="ouilink-iframe"
					src={`https://ouilink.laddition.com/onboard?c=${this.state.userInfo}`}
				/>
			</div>
		);
	}
}

OuiLink.propTypes = {};
OuiLink.defaultProps = {};

function mapStateToProps(state) {
	return {
		groups: state.auth.user.groups,
	};
}

export default connect(mapStateToProps, {})(OuiLink);
