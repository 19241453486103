import React from "react";
import CategoryBlock from "./CategoryBlock";
import classNames from "classnames";

const CategoryGrid = props => {
  let { categories, hasChild = false, isOpen = false, closeMenu } = props;

  let classObject = {
    "grid-3": true,
    "grid-child": hasChild,
    isOpen: isOpen,
    "parent-grid": !isOpen
  };
  let grid3Class = classNames(classObject);
  return (
    <div className={grid3Class}>
      {Object.keys(categories).map(function(key) {
        return (
          <CategoryBlock
            key={key}
            category={categories[key]}
            openedCategoryId={props.openedCategoryId}
            changeOpenedCategoryId={props.changeOpenedCategoryId}
            closeMenu={closeMenu}
          />
        );
      })}
    </div>
  );
};

export default CategoryGrid;
