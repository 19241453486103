import merge from 'lodash/merge';

const initialState = {
	categories: {},
	videos: {},
	notifications: {},
	messages: {},
	informations: {
		undefined: [],
	},
	cashRegisters: {},
	services: {},
	commandes: {},
	history: {},
	controlStripShiftDocument: {},
	controlStripSalesDocument: {},
	fullHistory: {},
	cashRegistersVariation: {},
	credits: {},
	receipts: {},
	receiptDetails: {},
	receiptDetailTaxs: {},
	orders: {},
	ordersLines: {},
	paymentLines: {},
	clotures: {},
	listErrors: {},
	trackingsServer: {},
	technicalInterventions: {},
	partnersCategories: {},
	companies: [],
};

export default function entities(state = initialState, action) {
	if (action.payload && action.payload.entities) {
		return merge({}, state, action.payload.entities);
	}
	if (action.type === 'SET_INFORMATIONS') {
		return merge({}, state, action.informations.entities);
	}

	if (action.type === 'RESET_ORDERS_LINES') {
		const newAction = { ...action.entities };
		newAction.ordersLines = {};
		return newAction;
	}
	if (action.type === 'RESET_TRACKED_HISTORY') {
		const newAction = { ...action.entities };
		newAction.history = {};
		return newAction;
	}
	if (action.type === 'RESET_TRACKED_FULL_HISTORY') {
		const newAction = { ...action.entities };
		newAction.fullHistory = {};
		return newAction;
	}
	if (action.type === 'RESET_PAYMENT_LINES') {
		const newAction = { ...action.entities };
		newAction.paymentLines = {};
		return newAction;
	}
	if (action.type === 'RESET_ORDERS') {
		const newAction = { ...action.entities };
		newAction.orders = {};
		return newAction;
	}
	if (action.type === 'RESET_CASH_REGISTER_VARIATION') {
		const newAction = { ...action.entities };
		newAction.cashRegistersVariation = {};
		return newAction;
	}
	if (action.type === 'RESET_CASH_REGISTER') {
		const newAction = { ...action.entities };
		newAction.cashRegisters = {};
		return newAction;
	}
	if (action.type === 'RESET_CREDIT') {
		const newAction = { ...action.entities };
		newAction.credits = {};
		return newAction;
	}
	if (action.type === 'RESET_CLOTURES') {
		const newAction = { ...action.entities };
		newAction.clotures = {};
		return newAction;
	}
	if (action.type === 'RESET_RECEIPT') {
		const newAction = { ...action.entities };
		newAction.receipt = {};
		return newAction;
	}
	if (action.type === 'RESET_RECEIPT_DETAIL') {
		const newAction = { ...action.entities };
		newAction.receiptDetails = {};
		return newAction;
	}
	if (action.type === 'RESET_RECEIPT_DETAIL_TAX') {
		const newAction = { ...action.entities };
		newAction.receiptDetailTaxs = {};
		return newAction;
	}
	if (action.type === 'RESET_TRACKING_SERVER') {
		const newAction = { ...action.entities };
		newAction.trackingsServer = {};
		return newAction;
	}
	if (action.type === 'RESET_SIGNATURE_ERRORS') {
		const newAction = { ...action.entities };
		newAction.listErrors = {};
		return newAction;
	}
	if (action.type === 'RESET_TECHNICAL_INTERVENTIONS') {
		const newAction = { ...action.entities };
		newAction.technicalInterventions = {};
		return newAction;
	}

	return state;
}
