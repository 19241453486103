const getPaymentName = (listPayment, idPayment) => {
  for (let payments of listPayment) {
    if (payments.id_payment_type === idPayment) {
      return `(payments.payment_name)`;
    } else {
      return "";
    }
  }
};

export default getPaymentName;
