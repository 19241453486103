import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { Tabs, Tab } from "material-ui/Tabs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import config from "../../../utils/config";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";
import TableMvtCaisse from "../components/Tables/TableMvtCaisse";
import TableServiceHistorique from "../components/Tables/TableServiceHistorique";
import TableServiceFullHistorique from "../components/Tables/TableServiceFullHistorique";
import TableCommandes from "../components/Tables/TableCommandes";
import TableIntervTech from "../components/Tables/TableIntervTech";
import * as fiscApiActions from "../actions/fiscApiActions";
import { browserHistory } from "react-router";
import ReportProblem from "material-ui/svg-icons/action/report-problem";
import TableControlStrip from "../components/Tables/TableControlStrip";

import Modal from "react-responsive-modal";
import axios from "axios";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";
import Print from "material-ui/svg-icons/action/print";
import DetailedInformations from "../components/DetailedInformations";

class DetailsService extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataMvtCaisse: [],
      dataCredits: [],
      dataHistorique: [],
      dataServices: [],
      dataIntervTech: {},
      dataFullHistorique: [],
      open: false,
      dbName: "",
      pdfUrl: "",
      timestamp: "",
      signature: "",
      modal: "",
      isLoading: false,
      tab: 4
    };

    this.onCloseModal = this.onCloseModal.bind(this);

    this.navigate = this.navigate.bind(this);
  }

  // === MODAL ===
  getPdfData = async () => {
    const res = await axios.get(
      config.reportingUrl + "api/administration-fiscale/pdf-data",
      {
        headers: { Authorization: `Bearer ${window.localStorage.accessToken}` }
      }
    );
    await this.setState({
      dbName: res.data.dbName,
      timestamp: res.data.timestamp,
      signature: res.data.signature
    });
  };

  onOpenModal = () => {
    if (this.state.pdfUrl !== "") {
      this.setState({ open: true, isLoading: false });
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  navigate(entity, rowInfo, columns) {
    //purge des donnees trop importantes sinon dépassement de la limite et bug
    //TODO: utilisation de react-router-redux
    let sessionStorage = window.sessionStorage;
    sessionStorage.setItem("serviceTab", this.state.tab);
    if (entity === "back") {
      browserHistory.goBack();
      return;
    } else if (entity === "home") {
      browserHistory.push("/administration-fiscale");
    } else {
      Object.keys(window.sessionStorage).map(elem => {
        if (elem.startsWith("@@History")) {
          let row = window.sessionStorage.getItem(elem);
          row = JSON.parse(row);
          delete row.fullHistory;
          row = JSON.stringify(row);
          window.sessionStorage.removeItem(elem);
          window.sessionStorage.setItem(elem, row);
        }
      });
      browserHistory.push({
        pathname: `/administration-fiscale/${entity}/details/${
          rowInfo.original.id
        }`,
        state: {
          rowInfo,
          columns,
          cashRegisterId: this.props.location.state.rowInfo.original.id
        }
      });
    }
  }

  async componentDidMount() {
    let sessionStorage = window.sessionStorage;
    let lastTab = parseInt(sessionStorage.getItem("serviceTab"));
    if (lastTab) {
      this.setState({ tab: lastTab });
    }
    this.setState({ isLoading: true });
    await Promise.all([
      this.props.actions.fetchTrackingCashRegistersVariation(
        this.props.location.state.rowInfo.row._original.id
      ),
      this.props.actions.fetchTrackingCredits(
        this.props.location.state.rowInfo.row._original.id
      ),
      this.props.actions.fetchTechnicalInterventionsByServiceId(
        this.props.location.state.rowInfo.row._original.id
      ),

      this.props.actions.fetchCommandes(
        this.props.location.state.rowInfo.original.id
      ),
      this.props.actions.resetHistory(this.props.entities).then(() => {
        this.props.actions.fetchTrackingHistory(
          "cash_register",
          this.props.location.state.rowInfo.original.id,
          this.props.location.state.rowInfo.original.id
        );
      })
    ]);
    await this.getPdfData();
    await this.setState({
      pdfUrl:
        config.dataUrl +
        "scripts/reporting.php?db=" +
        this.state.dbName +
        "&cash_register_id=" +
        this.props.location.state.rowInfo.original.id +
        "&action=z&format=pdf&ts=" +
        this.state.timestamp +
        "&signature=" +
        this.state.signature,
      isLoading: false
    });
    this.props.actions.fetchControlStripShiftDocument(
      this.props.location.state.rowInfo.original.id
    );
    this.props.actions.fetchTrackingFullHistory(
      this.props.location.state.rowInfo.row._original.id
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataControlStrip: nextProps.entities.controlStripShiftDocument
    });
    this.setState({ dataMvtCaisse: nextProps.entities.cashRegistersVariation });
    this.setState({ dataCredits: nextProps.entities.credits });
    this.setState({ dataCommandes: nextProps.entities.commandes });
    this.setState({
      dataIntervTech: nextProps.entities.technicalInterventions
    });
    this.setState({ dataHistorique: nextProps.entities.history });
    this.setState({ dataFullHistorique: nextProps.entities.fullHistory });
  }

  render() {
    let rowInfo = this.props.location.state.rowInfo;
    let rowInfoData = this.props.location.state.rowInfo.row._original;
    let columns = this.props.location.state.columns;

    let modal = this.state.modal;
    let open = this.state.open;
    let dataIntervTechArray = [];
    if (this.state.dataIntervTech) {
      Object.keys(this.state.dataIntervTech).map(elem => {
        if (typeof elem != "undefined") {
          dataIntervTechArray.push(this.state.dataIntervTech[elem]);
        }
      });
    }
    const { isLoading } = this.state;
    const labelInterTech =
      dataIntervTechArray.length > 0 ? (
        <div>
          Interventions <ReportProblem /> {dataIntervTechArray.length}
        </div>
      ) : (
        "Interventions "
      );

    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={() => this.navigate("back")}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={() => this.navigate("home")}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>
          <div className="fiscContainer">
            <div className="tabsContainer">
              <Tabs
                value={this.state.tab}
                onChange={value => this.setState({ tab: value })}
                inkBarStyle={{ backgroundColor: "#ab2221", height: "3px" }}
              >
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"commandes"}
                  label="Commandes"
                  value={4}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        commandes
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfoButton"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableCommandes
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("commandes", rowInfo, columns)
                      }
                      data={this.state.dataCommandes}
                    />
                  </div>
                </Tab>
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"histo"}
                  label="Historique"
                  value={1}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        historique
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Modal
                            open={open}
                            showCloseIcon={false}
                            onClose={this.onCloseModal}
                            classNames={{
                              closeIcon: "close-icon-fisc-json-data",
                              modal: "modal-fisc-json-data"
                            }}
                          >
                            <iframe
                              src={this.state.pdfUrl}
                              style={{ width: "750px", minHeight: "90vh" }}
                              type="application/pdf"
                            />
                          </Modal>
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableServiceHistorique data={this.state.dataHistorique} />
                  </div>
                </Tab>
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"histo"}
                  label="Bande de contrôle"
                  value={2}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        bande de contrôle
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableControlStrip data={this.state.dataControlStrip} />
                  </div>
                </Tab>
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"fullHisto"}
                  label="Historique Complet"
                  value={3}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        historique complet
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableServiceFullHistorique
                      data={this.state.dataFullHistorique}
                    />
                  </div>
                </Tab>

                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"mvtCaisse"}
                  label="Mouvements de caisse"
                  value={5}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        mouvement de caisse
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableMvtCaisse
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("mvtcaisse", rowInfo, columns)
                      }
                      data={this.state.dataMvtCaisse}
                    />
                  </div>
                </Tab>
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"credits"}
                  label="Crédits"
                  value={6}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        crédits
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableMvtCaisse
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("credit", rowInfo, columns)
                      }
                      data={this.state.dataCredits}
                    />
                  </div>
                </Tab>
                <Tab
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"intervTech"}
                  label={labelInterTech}
                  value={7}
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de service</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/
                        </span>
                        interventions
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {modal}
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&cash_register_id=" +
                                  rowInfo.original.id +
                                  "&action=z&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression Rapport Z
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableIntervTech data={dataIntervTechArray} />
                  </div>
                </Tab>
              </Tabs>
              <div
                id="fisc-loader-overlay"
                className={classnames({ hidden: !isLoading })}
              >
                <div class={classnames("pulse", { hidden: !isLoading })}>
                  <img
                    alt="loader L'addition"
                    src={logoLoader}
                    width="150px"
                    height="150px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsService);
