import React, { useState, useEffect } from 'react';
import GlobalSettings from '../components/GlobalSettings';

export default function AdminAccounts({
	accountVatList,
	paramsLabel,
	selectKeysValues,
	setValChanged,
	setAccountVatList,
}) {
	useEffect(() => {}, []);
	return (
		<div className="vat-admin-main">
			<GlobalSettings
				accountVatList={accountVatList}
				paramsLabel={paramsLabel}
				setValChanged={val => setValChanged(val)}
				setAccountVatList={accounts => setAccountVatList(accounts)}
				selectKeysValues={selectKeysValues}
			/>
		</div>
	);
}
