import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as fiscApiActions from '../actions/fiscApiActions';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import imgClock from '../img/clock.png';
import imgArrow from '../img/ARROW.png';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import ModalCheckFiscal from '../components/ModalCheckFiscal';
import ModalFisc from '../components/ModalFisc';
import config from '../../../utils/config';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';

// import PropTypes from 'prop-types';

function TransitionUp(props) {
	return <Slide {...props} direction="up" />;
}

class GenerateFiscTokenPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedInput: '1week',
			fiscEmail: '',
			customerEmail: '',
			disabled: true,
			openCheckFiscal: false,
			openFiscModal: false,
		};
		this.sendDemand = this.sendDemand.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleRadioChange = this.handleRadioChange.bind(this);
		//this.exportCashing = this.exportCashing.bind(this);
	}

	async sendDemand(e) {
		e.preventDefault();
		const { selectedInput, fiscEmail, customerEmail } = this.state;
		if (fiscEmail.includes('@')) {
			this.props.addFlashMessage({
				type: 'error',
				text: 'l\'Email de l\'administration fiscale ne doit pas contenir le symbole "@".',
				persistent: true,
			});
		} else {
			const res = await this.props.actions.addFiscAccess({
				askerEmail: fiscEmail + '@dgfip.finances.gouv.fr',
				customerEmail: customerEmail,
				expireType: selectedInput,
			});
			if (res.type === 'ADD_FISC_ACCESS_SUCCESS' && !res.error) {
				this.props.addFlashMessage({
					type: 'success',
					text: 'Succès, vous recevrez un email lorsque la demande sera validée',
					persistent: true,
				});
				this.setState({ disabled: true });
			} else {
				this.props.addFlashMessage({
					type: 'error',
					text: "Une erreur s'est produite",
					persistent: true,
				});
			}
		}
	}

	handleRadioChange(changeEvent) {
		this.setState({
			selectedInput: changeEvent.target.value,
		});
	}

	handleChange(changeEvent) {
		changeEvent.preventDefault();
		this.setState({
			[changeEvent.target.name]: changeEvent.target.value,
		});
	}

	exportCashing = async (startDate, endDate, route, userId = '', email) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');

			console.log(
				`Fisc route :  ${config.reportingUrl}api/administration-fiscale/${route}/?startDate=${startDate}&endDate=${endDate}&email=${email}`
			);
			const fiscFile = await axios({
				method: 'GET',
				headers: {
					Authorization: `accessToken ${accessToken}`,
				},
				url: `${config.reportingUrl}api/administration-fiscale/${route}/?startDate=${startDate}&endDate=${endDate}&email=${email}`,
				//responseType: "blob"
			}).then(res => {
				if (res.data.success) {
					let message = I18n.t('lneExport.noData');
					if (res.data.hasData) {
						message = I18n.t('lneExport.exportProcessing');
					}
					this.props.addFlashMessage({
						type: message.type,
						text: message,
						persistent: false,
					});
				}

				/* const url = window.URL.createObjectURL(new Blob([res.data]));
        const fileName = route + "-" + userId + "-" + Date.now() + ".zip";
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click(); */
			});
		} catch (error) {
			console.log(error);
			return error;
		}
	};

	componentDidUpdate() {}

	render() {
		return (
			<div
				id="main-container"
				className="main-container nav-effect-1"
				style={{
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
				}}>
				<header style={{ height: '95px', paddingBottom: 0 }}>
					<h1 className="generate-connexion-header">
						Voulez-vous générer un accès pour l'administration fiscale ?
					</h1>
				</header>

				<div
					style={{ backgroundColor: '#fff', minHeight: 'calc(100vh - 70px)' }}>
					<div>
						<Snackbar
							open={true}
							TransitionComponent={TransitionUp}
							ContentProps={{
								'aria-describedby':
									"Votre demande sera traitée par nos équipes;nous validerons par mail la bonne transmission des accès à l'administration fiscale",
							}}
							message={
								<span>
									Votre demande sera traitée par nos équipes ; nous validerons
									par mail la bonne transmission des accès à l'administration
									fiscale
								</span>
							}
							className={'informationMessage'}
						/>
						<div
							className="row"
							style={{ marginTop: '30px', marginLeft: 0, marginRight: 0 }}>
							<form>
								<div className="col-xs-12 col-md-5" style={{ height: '300px' }}>
									<div
										className={'col-md-12'}
										style={{ textAlign: 'center', padding: 0 }}>
										<h3
											style={{ color: 'rgb(232, 66, 73)', textAlign: 'left' }}>
											Choisissez la durée d'accès
										</h3>
									</div>
									<div>
										<div className="col-md-6">
											<img src={imgClock} alt="clock" width="300px" />
										</div>
										<div
											className={'col-md-6'}
											style={{
												marginTop: '90px',
												paddingLeft: '120px',
											}}>
											<div className="radio">
												<label>
													<input
														type="radio"
														value="1week"
														checked={this.state.selectedInput === '1week'}
														onChange={this.handleRadioChange}
													/>
													1 semaine
												</label>
											</div>
											<div className="radio">
												<label>
													<input
														type="radio"
														value="2week"
														checked={this.state.selectedInput === '2week'}
														onChange={this.handleRadioChange}
													/>
													2 semaine
												</label>
											</div>
											<div className="radio">
												<label>
													<input
														type="radio"
														value="1month"
														checked={this.state.selectedInput === '1month'}
														onChange={this.handleRadioChange}
													/>
													1 mois
												</label>
											</div>
											<div className="radio">
												<label>
													<input
														type="radio"
														value="2month"
														checked={this.state.selectedInput === '2month'}
														onChange={this.handleRadioChange}
													/>
													2 mois
												</label>
											</div>
										</div>
									</div>
								</div>
								<div
									className="col-xs-12 col-md-2"
									style={{
										padding: '30px 0px',
										display: 'flex',
										justifyContent: 'center',
									}}>
									<img src={imgArrow} alt=">" width="300px" />
								</div>
								<div className="col-xs-12 col-md-5" style={{ height: '300px' }}>
									<div
										className={'col-md-12'}
										style={{ textAlign: 'center', padding: 0 }}>
										<h3
											style={{
												color: 'rgb(232, 66, 73)',
												textAlign: 'left',
												padding: 0,
											}}>
											Saisissez les emails
										</h3>
									</div>
									<div style={{ height: '300px' }}>
										<div className="form-group">
											<label htmlFor="yourEmail">Votre Email</label>
											<input
												type="email"
												className="form-control"
												id="yourEmail"
												placeholder="Email"
												name="customerEmail"
												onChange={this.handleChange}
											/>
										</div>
										<div className="form-group">
											<label htmlFor="fiscEmail" style={{ display: 'block' }}>
												Email administration fiscale
											</label>
											<input
												style={{
													display: 'inline-block',
													width: '30%',
													borderTopRightRadius: 0,
													borderBottomRightRadius: 0,
													borderRight: 0,
												}}
												pattern="/^[^@]*$/"
												className="form-control"
												id="fiscEmail"
												placeholder="Email admin fiscale"
												name="fiscEmail"
												onChange={this.handleChange}
											/>
											<input
												style={{
													display: 'inline-block',
													width: '70%',
													borderTopLeftRadius: 0,
													borderBottomLeftRadius: 0,
													borderLeft: 0,
													paddingLeft: 1,
												}}
												className="form-control"
												id="fiscEmailDomain"
												name="fiscEmailDomain"
												readOnly={true}
												value={'@dgfip.finances.gouv.fr'}
											/>
										</div>
										<button
											disabled={
												this.state.fiscEmail === '' ||
												this.state.customerEmail === ''
													? true
													: false
											}
											style={{
												backgroundColor:
													this.state.fiscEmail === '' ||
													this.state.customerEmail === ''
														? 'rgba(232, 66, 73, 0.5)'
														: 'rgba(232, 66, 73, 1)',
												padding: '10px',
												color: 'white',
												border: '0',
												borderRadius: '10px',
											}}
											onClick={this.sendDemand}>
											Envoyer
										</button>
									</div>
								</div>
							</form>
						</div>
						<Divider className="divider" />
						<div
							style={{
								width: '100%',
								height: '100px',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								gap: '17px',
							}}>
							<button
								style={{
									backgroundColor: '#262957',
									minWidth: '300px',
									padding: '10px',
									color: 'white',
									border: '0',
									borderRadius: '10px',
								}}
								onClick={() => this.setState({ openFiscModal: true })}>
								Exporter les données fiscales
							</button>
							<button
								style={{
									backgroundColor: 'rgba(232, 66, 73, 1)',
									minWidth: '300px',
									padding: '10px',
									color: 'white',
									border: '0',
									borderRadius: '10px',
								}}
								onClick={() => this.setState({ openCheckFiscal: true })}>
								Vérifier les données exportées
							</button>
						</div>
					</div>
					<ModalFisc
						open={this.state.openFiscModal}
						exportCashing={this.exportCashing.bind(this)}
						user={this.props.auth.user}
						onClose={() => {
							return this.setState({
								openFiscModal: false,
							});
						}}
					/>
					<ModalCheckFiscal
						open={this.state.openCheckFiscal}
						onClose={() => this.setState({ openCheckFiscal: false })}
					/>
				</div>
			</div>
		);
	}
}

GenerateFiscTokenPage.propTypes = {};
GenerateFiscTokenPage.defaultProps = {};

function mapStateToProps(state) {
	return {
		auth: state.auth,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(fiscApiActions, dispatch),
		addFlashMessage: message => {
			dispatch(addFlashMessage(message));
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GenerateFiscTokenPage);
