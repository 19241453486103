export function filterPrice(filter, row) {
  if (!filter) {
    return true;
  }
  if (!filter.value.type || !filter.value.value) {
    return true;
  }
  if (filter.value.type === 1) {
    return parseInt(row[filter.id]) >= parseInt(filter.value.value);
  }
  if (filter.value.type === 2) {
    return parseInt(row[filter.id]) <= parseInt(filter.value.value);
  }
  if (filter.value.type === 3) {
    return parseInt(row[filter.id]) == parseInt(filter.value.value);
  }
}

export function changeActionName(value) {
  if (value == "create") {
    return "Création";
  }
  if (value == "update") {
    return "Mise à jour";
  }
  if (value == "delete") {
    return "Suppression";
  }
}
