import React, { useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import './style/modal.scss';
import LogoLoader from '../../components/LogoLoader';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import { I18n } from 'react-redux-i18n';

import Validator from 'validator';
import CloseModal from '../CustomerAccount/svg/CloseModal';
import Input from '../../components/Input/Input';
import * as dayjs from 'dayjs';

const btnStyle = {
	color: 'white',
	fontFamily: 'FuturaBook',
	fontSize: 'smaller',
	marginLeft: '23px',
	borderRadius: '20px',
	textTransform: 'none',
	width: '261px',
	fontSize: '15px',
	letterSpacing: '0',
};

function ModalExport({ open, user, fctOnClose, exportFct, buttonLabel }) {
	const today = dayjs();
	const oneMonthBefore = dayjs().subtract(1, 'month').toString();

	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(oneMonthBefore);
	const [endDate, setEndDate] = useState(today);
	const [email, setEmail] = useState(user.email || '');
	const [errorMessage, setErrorMessage] = useState('');

	useEffect(() => {}, []);

	const _areDatesValids = (sDate, eDate) => {
		if (!sDate || !eDate) return false;
		const start = dayjs(startDate);
		const end = dayjs(endDate);
		if (!end.isSameOrAfiter(start)) {
			setErrorMessage(I18n.t('fisc.modalFisc.exportErrorReversedDates'));
			return;
		}
		if (endDate.diff(start, 'years') !== 0) {
			setErrorMessage(I18n.t('fisc.modalFisc.exportErrorOneYearMax'));
			return;
		}
		setErrorMessage('');
		return;
	};

	const _handleStartDayChange = day => {
		if (!day) return;
		setStartDate(dayjs(day).format('YYYYMMDD'));
		setErrorMessage('');
	};
	const _handleEndDayChange = day => {
		if (!day) return;
		setEndDate(dayjs(day).format('YYYYMMDD'));
		setErrorMessage('');
	};

	const exportDate = async (e, endPoint, userId) => {
		e.preventDefault();

		if (!_areDatesValids(startDate, endDate)) return;

		setLoading(true);
		await exportProductLines(startDate, endDate, userId, email);
		setLoading(false);
	};

	const userId = user && user.id ? user.id : '';
	let styleBtn = { ...btnStyle };
	if (!Validator.isEmail(email)) {
		styleBtn = {
			...btnStyle,
			color: 'rgba(0, 0, 0, 0.26)',
			boxShadow: 'none',
			backgroundColor: 'rgba(0, 0, 0, 0.12)!important',
		};
	}
	let emailInputStyle = {
		margin: '8px 8px 8px 15px',
	};

	return (
		<div>
			<Modal
				open={open}
				showCloseIcon={false}
				classNames={{ modal: 'modal_export' }}
				onClose={() => {
					if (loading) return false;
					fctOnClose();
				}}>
				{loading ? (
					<LogoLoader
						hidden={false}
						label={I18n.t('fisc.modalFisc.waitingDownloadData')}
					/>
				) : (
					<div className="modal_content">
						<div
							className="close_icon"
							onClick={e => {
								if (loading) return false;
								fctOnClose();
							}}>
							<CloseModal />
						</div>
						<h3>{I18n.t('front.navigationBarFront.exporPaymentProduct')}</h3>
						<div>
							<div className="modal_input_container">
								<div className="modal_input_main">
									<p>{I18n.t('fisc.modalFisc.begin')}</p>
									<DayPickerInput
										local="fr"
										value={`${formatDate(startDate, 'L', 'fr')}`}
										formatDate={formatDate}
										parseDate={parseDate}
										placeholder={`${formatDate(startDate, 'L', 'fr')}`}
										onDayChange={_handleStartDayChange}
										dayPickerProps={{
											locale: 'fr',
											localeUtils: MomentLocaleUtils,
											modifiers: {},
										}}
									/>
								</div>
								<div>
									<p>{I18n.t('fisc.modalFisc.end')}</p>
									<DayPickerInput
										local="fr"
										formatDate={formatDate}
										parseDate={parseDate}
										value={`${formatDate(endDate, 'L', 'fr')}`}
										placeholder={`${formatDate(endDate, 'L', 'fr')}`}
										onDayChange={_handleEndDayChange}
										dayPickerProps={{
											locale: 'fr',
											localeUtils: MomentLocaleUtils,
											fromMonth: moment(endDate).subtract(1, 'y').toDate(),
											toMonth: moment(startDate).add(1, 'y').toDate(),
											modifiers: {
												disabled: [
													{
														after: moment(startDate).add(1, 'y').toDate(),
													},
													{
														before: moment(endDate).subtract(1, 'y').toDate(),
													},
												],
											},
										}}
									/>
								</div>
							</div>
							<div className="error-export">
								<p>{errorMessage}</p>
							</div>
							<div className="email_input">
								<Input
									label={I18n.t('lneExport.emailHint')}
									type="email"
									name="email"
									required="required"
									idInput="email-input"
									onChange={e => {
										setEmail(e.target.value);
									}}
									value={email}
									colorInput={
										Validator.isEmail(email || '') ? '#7cc4ac' : '#e84249'
									}
								/>
							</div>
							<div className="btn_container">
								<Button
									className={Validator.isEmail(email) ? 'btn-export-first' : ''}
									style={styleBtn}
									disabled={!Validator.isEmail(email || '')}
									variant="contained"
									onClick={e =>
										exportFct(
											dayjs(startDate).format('YYYY-MM-DD 00:00:00'),
											dayjs(endDate).format('YYYY-MM-DD 23:59:59'),
											userId,
											email
										)
									}>
									{buttonLabel}
								</Button>
							</div>
						</div>
					</div>
				)}
			</Modal>
		</div>
	);
}

export default ModalExport;
