import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";
import NumberFormat from "react-number-format";

class TablePaiements extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem]);
      });
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id_payment",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Nom", accessor: "payment_name", pivot: true },
      {
        Header: "Règlements du service",
        accessor: "amount_shift",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Trop-perçus",
        accessor: "overpayment",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      },
      {
        Header: "Crédits clients",
        accessor: "credit",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => <FilterPrice filter={filter} onChange={onChange} />
      }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id_payment" },
      { label: "Nom", key: "payment_name" },
      { label: "Règlements du service", key: "amount_shift" },
      { label: "Trop-perçus", key: "overpayment" },
      { label: "Crédits clients", key: "credit" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        element.amount = Number(element.amount).toFixed(2);
        element.overpayment = Number(element.overpayment).toFixed(2);
        element.credit = Number(element.credit).toFixed(2);
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-cloturesPaiements.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                {" "}
                <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau{" "}
              </button>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TablePaiements;
