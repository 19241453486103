import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import Filters from '../components/Filters/Filters';
import ImgCalendar from '../components/Filters/calendar.svg';
import moment from 'moment';
const exportTypes = [
	{ label: 'Lignes de ventes', route: 'dataExport/getOrderLines' },
	// {
	// 	label: 'Lignes de ventes à emporter',
	// 	route: 'dataExport/getDeliveryOrderLines',
	// },
];

const orderTypes = [
	{ label: 'Sur place', type: 1 },
	{
		label: 'À emporter',
		type: 2,
	},
	{
		label: 'Livraison',
		type: 3,
	},
];

function HeaderExport({
	companies,
	onTakeAwaySelect,
	orderTypes,
	onDatePickerClick,
	selectedCompanies,
	onCompaniesChange,
	onRouteChanges,
	dateBegin,
	dateBeginLabel,
	dateEnd,
	dateEndLabel,
}) {
	return (
		<div className="export-filters">
			<div className="export-type">
				<span className="export-filter-title">
					{I18n.t('exportReport.exportType')}
				</span>

				<div className="export-filters-main">
					<div className="export-filter-container">
						<Filters
							label={exportTypes[0].label}
							datas={exportTypes}
							paramsLabel="label"
							isNumberData={false}
							multiple={false}
							paramsValue="route"
							inputClassName="dataExport_filter_input"
							inputLabelClassName="dataExport_input_label"
							onClose={data => {
								if (data && data.length > 0) {
									const { route } = data[0];
									onRouteChanges(route);
								}
							}}
						/>
					</div>
				</div>
			</div>
			<div className="export-filters-main">
				<div className="export-filter-container">
					<span className="export-filter-title">
						{I18n.t('globalLabels.date')}
					</span>
					<div
						className="datepicker-input"
						onClick={() => {
							onDatePickerClick();
						}}>
						<div className="datepicker-label">
							<span>{`${dateBegin ? dateBeginLabel : '-- -- --'} / ${
								dateEnd ? dateEndLabel : '-- -- --'
							}`}</span>
						</div>
						<img src={ImgCalendar} />
					</div>
				</div>
				<div className="export-filter-container">
					<span className="export-filter-title">
						{I18n.t('globalLabels.companies')}
					</span>
					<Filters
						label={
							companies && companies.length == 1
								? companies[0].company_name
								: I18n.t('globalLabels.allCompanies')
						}
						datas={companies}
						paramsLabel="company_name"
						isNumberData={false}
						multiple={companies && companies.length > 1 ? true : false}
						paramsValue="id_company"
						inputClassName="dataExport_filter_input"
						inputLabelClassName="dataExport_input_label"
						onClose={data => {
							if (data && data.length > 0) {
								onCompaniesChange(data);
							}
						}}
					/>
				</div>
				<div className="export-filter-container">
					<span className="export-filter-title">Type d'activité</span>
					<Filters
						label={'Tous les types'}
						datas={orderTypes}
						paramsLabel="label"
						isNumberData={false}
						multiple={true}
						paramsValue="type"
						inputClassName="dataExport_filter_input"
						inputLabelClassName="dataExport_input_label"
						onClose={data => {
							if (data && data.length > 0) {
								onTakeAwaySelect(data);
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default HeaderExport;
