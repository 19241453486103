import React from 'react';  import PropTypes from 'prop-types';

const FaqCategory = ({faqCategory}) => {
  const anchor = '#' + faqCategory;
  return (
    <li>
      <a href={anchor}>
        {faqCategory}
      </a>
    </li>
  );
};

FaqCategory.propTypes = {
  faqCategory: PropTypes.string.isRequired,
};

export default FaqCategory;
