import React, { useState, useEffect } from 'react';
import CellEditable from '../containers/CellEditable';
import { Divider } from '@material-ui/core';
import FiltersVat from './Filters/FiltersVat';
import ReactTable from 'react-table';
import '../sass/dataExport.css';
import Filters from './Filters/Filters';
import CallApi from '../../../utils/CallApi';
import { I18n } from 'react-redux-i18n';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';

export default function AccountsHandler({ floors, refreshAccounts }) {
	console.log({ floors });
	const [floorsSelected, setFloors] = useState(floors.map(f => f.id));
	const [newAccValue, setAccValue] = useState({
		analytics: 'Défaut',
		label: '',
		accNumber: '',
	});
	const [selectedAccType, setSelectedAccType] = useState('product');

	const onSelectFloors = d => {
		const formattedFloors = d.map(f => f['id_floor']);

		setFloors(formattedFloors);
	};

	const onAccTypeSelected = d => {
		setSelectedAccType(d['value']);
	};
	const accType = [
		{
			id: 1,
			label: 'Produit',
			value: 'product',
		},
		{
			id: 2,
			label: 'Paiement',
			value: 'payment',
		},
		{
			id: 3,
			label: 'TVA',
			value: 'vat',
		},
	];

	const handleCreateAcc = async () => {
		const accessToken = window.localStorage.getItem('accessToken');

		try {
			const addVatAccount = await CallApi(
				'POST',
				'exportVat/createVatAccounts',
				'',
				{
					type: selectedAccType,
					account_number: newAccValue.accNumber,
					label: newAccValue.label,
					analytics: newAccValue.analytics,
					all_floors:
						selectedAccType != 'product'
							? true
							: floorsSelected.length > 0 &&
							  floorsSelected.length === floors.length,
					floors: selectedAccType == 'product' ? floorsSelected : [],
				},
				`accessToken ${accessToken}`
			)
				.then(results => {
					refreshAccounts();
					alert('Votre compte comptable à bien été ajouté');
					setAccValue({
						analytics: 'Défaut',
						label: '',
						accNumber: '',
					});
					addFlashMessage({
						type: 'success',
						text: 'Votre compte comptable à bien été ajouté',
						persistent: false,
					});
				})
				.catch(err => {
					console.log(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
				});
		} catch (error) {
			console.log(error);
		}
	};

	// useEffect(() => {
	// 	onSelectFloors(floors);
	// }, []);

	console.log({ floorsSelected });
	return (
		<div className="vat-settings-handler-container">
			<div className="vat-filter-list">
				<div className="keys-container">
					{' '}
					<span>Type : </span>
					<FiltersVat
						multiple={false}
						monoSelect={true}
						label={accType[0]['label']}
						datas={accType}
						selected={floorsSelected}
						paramsLabel="label"
						paramsValue="value"
						inputClassName="dataExport_filter_input"
						inputLabelClassName="dataExport_input_label"
						onClose={(data, pValue) => {
							if (!!data) {
								onAccTypeSelected(data);
							}

							return;
						}}
					/>
				</div>
			</div>

			{selectedAccType == 'product' ? (
				<div className="vat-filter-list">
					<div className="keys-container">
						<span>Salles : </span>
						<Filters
							multiple={true}
							monoSelect={false}
							label="Toutes les salles"
							datas={JSON.parse(JSON.stringify(floors))}
							paramsLabel="name"
							paramsValue="id"
							selected={floorsSelected}
							inputClassName="dataExport_filter_input"
							inputLabelClassName="dataExport_input_label"
							onClose={(data, pValue) => {
								if (!!data) {
									onSelectFloors(data);
								}

								return;
							}}
						/>
					</div>
				</div>
			) : null}

			<div className="vat-settings-acc-product">
				<div className="keys-container">
					<span>Section analytique : </span>
					<CellEditable
						val={'Défaut'}
						clsName="key-value"
						onBlurFct={val => {
							const newVal = { analytics: val };
							setAccValue(newAccValue => ({
								...newAccValue,
								...newVal,
							}));
						}}
						isLabel={true}
					/>
				</div>
				<div className="keys-container">
					<span>Libellé : </span>
					<CellEditable
						val={newAccValue.label}
						clsName="key-value"
						onBlurFct={val => {
							const newVal = { label: val };
							setAccValue(newAccValue => ({
								...newAccValue,
								...newVal,
							}));
						}}
						isLabel={true}
					/>
				</div>
				<div className="keys-container">
					<span>N° de compte : </span>
					<CellEditable
						val={newAccValue.accNumber}
						clsName="key-value"
						onBlurFct={val => {
							const newVal = { accNumber: val };
							setAccValue(newAccValue => ({
								...newAccValue,
								...newVal,
							}));
						}}
						isLabel={true}
					/>
				</div>
				<div className="vat-account-handler-btn">
					<button
						className={`btn-vat-settings`}
						onClick={() => {
							handleCreateAcc();
						}}>
						Ajouter
					</button>
				</div>
				<Divider />

				{/*	<ReactTable
					columns={tHeaders}
					showPagination={false}
					data={tData}
					loadingText={I18n.t('fisc.table.loadingText')}
					noDataText={false ? '' : I18n.t('fisc.table.noDataText')}
					resizable={false}
					sortable={false}
					className="vat-settings-table"
					nextText={'Suivant'}
					previousText={'Précédent'}
					rowsText={'lignes'}
					pageSize={[100]}
					/>*/}
			</div>
		</div>
	);
}
