import React, { useEffect, useState } from 'react';
import moment from 'moment';
import priceFormatting from '../../../utils/priceFormatting';
import memoize from 'lodash/memoize';

const language = window.navigator.language;
const matchingHeaderName = [
	{
		id: 'company_name',
		cssClass: 'history_table_header _big',
		field: '',
		chieldField: null,
		label: 'Établissement',
	},
	{
		id: 'operation_name',
		cssClass: 'history_table_header _mid',
		field: '',
		chieldField: null,
		label: 'Action',
	},
	{
		id: 'operation_date',
		cssClass: 'history_table_header _mid',
		field: '',
		chieldField: null,
		label: 'Date',
	},

	{
		id: 'customer_firstname',
		cssClass: 'history_table_header _mid',
		field: '',
		chieldField: null,
		label: 'Nom/Prénom',
	},

	{
		id: 'user_firstname',
		cssClass: 'history_table_header _mid',
		field: '',
		chieldField: null,
		label: 'Utilisateur',
	},

	{
		id: 'payment_name',
		cssClass: 'history_table_header _small',
		field: '',
		chieldField: null,
		label: 'Paiement',
	},

	{
		id: 'amount',
		cssClass: 'history_table_header _small',
		field: '',
		chieldField: null,
		label: 'Montant',
	},
	{
		id: 'balance',
		cssClass: 'history_table_header _small',
		field: '',
		chieldField: null,
		label: 'Solde',
	},
];

const getTableHeaders = () => {
	return matchingHeaderName.map(h => {
		return (
			<div
				id="table_header"
				key={`${Math.floor(Math.random() * 100000)}`}
				className={`${h.cssClass}`}>
				<span key={h.id} role="columnheader">
					{h.label}
				</span>
			</div>
		);
	});
};

const getTablesRows = memoize(datas => {
	if (datas && datas.length > 0) {
		const dataRows = datas.map((r, idx) => {
			const formattedDate = moment(r.operation_date).format('DD/MM/YYYY HH:mm');
			return (
				<tr
					className="table_row"
					key={`${r.amount}-${Math.floor(Math.random() * 100000000)}`}>
					<div className="test-cell big-cell">
						<span>{r.company_name}</span>
					</div>

					<div className="test-mid-cell mid-cell">
						<span>{r.operation_name}</span>
					</div>
					<div className="mid-cell">
						<span>{formattedDate}</span>
					</div>
					<div className="mid-cell">
						<span>{`${r.customer_firstname} ${r.customer_lastname}`}</span>
					</div>
					<div className="mid-cell">
						<span>{` ${r.user_lastname} ${r.user_firstname}`}</span>
					</div>
					<div className="small-cell">
						<span>{r.payment_name}</span>
					</div>
					<div className="small-cell">
						<span>{priceFormatting(r.amount, language, 'EUR')}</span>
					</div>
					<div className="small-cell">
						<span>{priceFormatting(r.balance, language, 'EUR')}</span>
					</div>
				</tr>
			);
		});

		return dataRows;
	}
});

function TableHistory({ historyDatas }) {
	return (
		<div className="history_table_wrapper">
			<div className="export_table_header_container">{getTableHeaders()}</div>
			<div className="export_table_container">
				{historyDatas && historyDatas.length > 0 ? (
					<table>
						<tbody>{getTablesRows(historyDatas)}</tbody>
					</table>
				) : null}
			</div>
		</div>
	);
}

export default TableHistory;
