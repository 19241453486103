import React, { Component } from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import Divider from "@material-ui/core/Divider";
import { I18n } from "react-redux-i18n";

import DataTable from "./components/DataTable";
import ArrowDown from "../../svg/ArrowDownSvg";
import ArrowUp from "../../svg/ArrowUpSvg";

import "../TableData/sass/style.scss";
import "react-table/react-table.css";
import "./sass/styles.scss";

class WareDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wareNotCash: [],
      wareCashOut: [],
      wareCash: [],
      columnsFirstLevel: [
        {
          expander: true,
          width: 35,
          Expander: ({ isExpanded, ...rest }) => {
            {
              return <div>{isExpanded ? <ArrowUp /> : <ArrowDown />}</div>;
            }
          },
        },
        {
          Header: I18n.t("barDistribDetail.product"),
          accessor: "ware_name",
          headerClassName: "main_header_title",
          className: "table_string_field",
        },
        {
          Header: I18n.t("barDistribDetail.user"),
          accessor: "user_name",
          headerClassName: "main_header_title",
        },
        {
          Header: I18n.t("barDistribDetail.wareHour"),
          accessor: "time",
          headerClassName: "main_header_title",
        },
        {
          Header: I18n.t("barDistribDetail.totalProductsNotCashed"),
          accessor: "total",
          headerClassName: "main_header_title",
        },
      ],
      columnsFirstLevelCash: [
        {
          width: 35,
        },
        {
          Header: I18n.t("barDistribDetail.product"),
          accessor: "ware_name",
          headerClassName: "main_header_title",
          className: "table_string_field",
        },
        {
          Header: I18n.t("barDistribDetail.totalProductsCashed"),
          accessor: "total",
          headerClassName: "main_header_title",
        },
      ],
      columnsSecondLevel: [
        {
          width: 35,
        },
        {
          Header: I18n.t("barDistribDetail.product"),
          accessor: "product_name",
          headerClassName: "main_header_title",
          className: "table_string_field",
        },
        {
          Header: I18n.t("barDistribDetail.user"),
          accessor: "user_name",
          headerClassName: "main_header_title",
        },
        {
          Header: I18n.t("barDistribDetail.wareHour"),
          accessor: "time",
          headerClassName: "main_header_title",
        },
        {
          Header: I18n.t("barDistribDetail.totalProductsCashed"),
          accessor: "total",
          headerClassName: "main_header_title",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.wareGlobal.length) {
      const { wareNotCashed, wareOut, wareCash } = this.props.wareGlobal[0];
      this.setState({
        wareNotCash: wareNotCashed,
        wareCashOut: wareOut,
        wareCash: wareCash,
      });
    }
  }

  render() {
    const { handleCloseModal, labelNoData, wareName } = this.props;
    const {
      columnsFirstLevelCash,
      columnsSecondLevel,
      columnsFirstLevel,
      wareNotCash,
      wareCashOut,
      wareCash,
    } = this.state;

    return (
      <div className="main_container_details">
        <div className="header_container_details">
          <h3>
            {I18n.t("barDistribDetail.title")} {wareName}
          </h3>
          <CancelIcon
            onClick={(e) => handleCloseModal()}
            style={{ fontSize: 30, cursor: "pointer" }}
          />
        </div>
        <div className="main_table_container container_tables">
          <div className="container_table">
            <h4>{I18n.t("barDistribDetail.titleNotCashed")}</h4>
            {wareNotCash.length && wareNotCash[0].total ? (
              <DataTable
                columnsSecond={columnsSecondLevel}
                columnsFirst={columnsFirstLevel}
                labelNoData={labelNoData}
                wares={wareNotCash}
              />
            ) : (
              <p>{labelNoData}</p>
            )}
            <Divider className="divider" />
          </div>
          <div className="container_table">
            <h4>{I18n.t("barDistribDetail.titleOutShift")}</h4>
            {wareCashOut.length && wareCashOut[0].total ? (
              <DataTable
                columnsSecond={columnsSecondLevel}
                columnsFirst={columnsFirstLevel}
                labelNoData={labelNoData}
                wares={wareCashOut}
              />
            ) : (
              <p>{labelNoData}</p>
            )}
            <Divider className="divider" />
          </div>
          <div className="container_table">
            <h4>{I18n.t("barDistribDetail.titleProductsCashed")}</h4>
            {wareCash.length && wareCash[0].total ? (
              <DataTable
                columnsFirst={columnsFirstLevelCash}
                labelNoData={labelNoData}
                wares={wareCash}
              />
            ) : (
              <p>{labelNoData}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default WareDetails;
