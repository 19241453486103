import React, { Component } from "react";
import PropTypes from "prop-types";

import "./tableloader.css";

class TableLoader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <table className="loader-table">
          <tbody id="loader-table">
            <tr className="datagrid_row">
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 40}%` }}
                />
              </td>

              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 20}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 30}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 10}%` }}
                />
              </td>
            </tr>
            <tr className="datagrid_row">
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 40}%` }}
                />
              </td>

              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 20}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 30}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 10}%` }}
                />
              </td>
            </tr>
            <tr className="datagrid_row">
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 40}%` }}
                />
              </td>

              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 20}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 30}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 10}%` }}
                />
              </td>
            </tr>
            <tr className="datagrid_row">
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 40}%` }}
                />
              </td>

              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 20}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 30}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 10}%` }}
                />
              </td>
            </tr>
            <tr className="datagrid_row">
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 40}%` }}
                />
              </td>

              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 20}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 30}%` }}
                />
              </td>
              <td className="loaderow">
                <div
                  className="datagrid-loader"
                  style={{ width: `${Math.floor(Math.random() * 51) + 10}%` }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

TableLoader.propTypes = {};

export default TableLoader;
