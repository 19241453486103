import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { I18n } from "react-redux-i18n";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

class FlashMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  componentDidMount() {
    this.handleOpen();
    if (!this.props.message.persistent) {
      window.setTimeout(() => {
        this.handleClose();
      }, 4500);
    }
  }

  render() {
    const { type, text } = this.props.message;

    function getPersistentMessageText() {
      return { __html: text };
    }

    return (
      <Snackbar
        classes={{ root: "persistentFlashRoot" }}
        open={this.state.open}
        TransitionComponent={TransitionUp}
        //onRequestClose={this.handleClose}
        ContentProps={{
          "aria-describedby": this.props.message.id,
          classes: {
            root: this.props.message.persistent ? "persistentFlash" : ""
          }
        }}
        action={
          <span style={{ cursor: "pointer" }} onClick={this.handleClose}>
            <CloseIcon />
          </span>
        }
        //onActionClick={this.handleClose}
        message={
          <span
            id={this.props.message.id}
            dangerouslySetInnerHTML={getPersistentMessageText()}
          />
        }
        //className={type === "success" ? "successMessage" : "errorMessage"}
        className={classnames({
          successMessage: type === "success",
          errorMessage: type === "error" || type === "warning",
          informationMessage: type === "info"
        })}
      />
    );
  }
}

FlashMessage.propTypes = {
  message: PropTypes.object.isRequired
  //deleteFlashMessage: PropTypes.func.isRequired
};

export default FlashMessage;
