import React, { Component } from "react";
import Drawer from "../../components/Drawer";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import PartnerItem from "./components/PartnerItem";
import "./css/partnership.css";
import { addFlashMessage } from "../../common/flashMessage/actions/flashMessages";
import copyIcon from "../../img/copy.png";
import copiedIcon from "../../img/copied.png";
import checkedIcon from "../../img/checked.png";
import nonInteIcon from "../../img/nonintegrated.png";
import infoIcon from "../../img/info.png";
import pauseIcon from "../../img/pause.png";
import map from "lodash/map";
import config from "../../utils/config";
import { I18n } from "react-redux-i18n";
import { getRefreshToken } from "../../utils/utils";

class LinkPartners extends Component {
  constructor(props) {
    super(props);
    const { partnersCategories } = this.props;
    const { partners } = this.props;
    this.state = {
      open: false,
      noOverlay: false,
      openedCategoryId: 0,
      partnersCategories,
      partners,
      selectedPartner: null,
      copied: false
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.generateToken = this.generateToken.bind(this);
  }

  toggleDrawer(e, partner) {
    e.preventDefault();
    let newState = true;
    if (partner === this.state.selectedPartner) {
      newState = !this.state.open;
    } else {
      newState = true;
      this.setState({ selectedPartner: partner });
    }
    this.setState({ open: newState });
    this.setState({ copied: false });
  }

  closeDrawer(e) {
    e.preventDefault();
    this.setState({ open: false });
    this.setState({ copied: false });
  }

  generateToken(e) {
    e.preventDefault();
    const partner = this.state.selectedPartner;
    axios
      .post(config.reportingUrl + "api/partnership/ipadToken", {
        refreshToken: getRefreshToken(),
      })
      .then(result => {
        const { ipadToken } = result.data;
        axios
          .post(config.reportingUrl + "api/partnership/generate-link", {
            ipadToken: ipadToken,
            partnerId: partner.auth_partner_id
          })
          .then(reqresult => {
            const { token } = reqresult.data;
            partner.access_token = token;
            this.setState({ selectedPartner: partner });
          });
      });
  }

  renderDrawer() {
    if (!this.state.selectedPartner) {
      return (
        <Drawer
          isOpened={this.state.open}
          position="right"
          clickCloseBtn={this.closeDrawer}
        />
      );
    }
    const isWaiting = !!this.state.selectedPartner.access_token;
    const isConfirmed =
      isWaiting && !!this.state.selectedPartner.partner_customer_id;
    let partnerIcon = (
      <img className="imgChecked" src={nonInteIcon} alt="noninte" />
    );
    let tokenBtn = (
      <button onClick={e => this.generateToken(e)}>
        {I18n.t("partnership.generateKey")}
      </button>
    );
    let status = (
      <p className="bg-info">{I18n.t("partnership.noActivatedPartner")}</p>
    );

    // WAITING
    if (isWaiting) {
      partnerIcon = (
        <img className="imgChecked" src={pauseIcon} alt="waiting" />
      );
      status = (
        <p className="bg-info">{I18n.t("partnership.inActivationPartner")}</p>
      );
      tokenBtn = (
        <CopyToClipboard
          text={this.state.selectedPartner.access_token}
          onCopy={() => {
            this.setState({ copied: true });
            this.props.addFlashMessage({
              type: "info",
              text: I18n.t("partnership.keyCopied")
            });
          }}
        >
          <button
            onClick={e => {
              e.preventDefault();
            }}
          >
            <img
              src={this.state.copied ? copiedIcon : copyIcon}
              alt="copier"
              style={{ height: 21 }}
            />{" "}
            {I18n.t("partnership.copyKey")}
          </button>
        </CopyToClipboard>
      );
    }
    // CONFIRMED
    if (isConfirmed) {
      partnerIcon = <img className="imgChecked" src={checkedIcon} alt="ok" />;
      status = (
        <p className="bg-success">{I18n.t("partnership.activatedKey")}</p>
      );
    }

    return (
      <Drawer
        isOpened={this.state.open}
        position="right"
        clickCloseBtn={this.closeDrawer}
      >
        <div
          style={{
            width: "50%",
            height: "45%",
            maxHeight: "215px",
            maxWidth: "215px"
          }}
          className="img-container"
        >
          <img
            alt="Selected Partner"
            style={{ width: "100%", height: "100%" }}
            src={require(`../../img/${this.state.selectedPartner.logo}`)}
          />

          {this.state.selectedPartner.with_action ? partnerIcon : null}
        </div>
        {/*<h2>{this.state.selectedPartner.name}</h2>*/}
        {/*<hr/>*/}
        <p
          style={{ minHeight: "130px", overflowY: "scroll", maxHeight: "15%" }}
        >
          {this.state.selectedPartner.description}
        </p>
        {/*<hr/>*/}
        {/*{status}*/}
        <br />
        <br />
        {this.state.selectedPartner.with_action ? (
          <form>
            {this.state.selectedPartner.name === "LyfPay" ? (
              <div>
                <p>
                  {this.state.selectedPartner.access_token
                    ? this.state.selectedPartner.access_token
                    : I18n.t("partnership.noTokenYet")}
                </p>
                <br />
              </div>
            ) : this.state.selectedPartner.canActivate ? (
              <div>
                <input
                  type="text"
                  name=""
                  value={this.state.selectedPartner.access_token}
                  disabled
                />
                <br />
                <br />
                {tokenBtn}
              </div>
            ) : (
              <div>{I18n.t("partnership.update_version")}</div>
            )}
          </form>
        ) : null}
      </Drawer>
    );
  }

  render() {
    let { partnersCategories, partners } = this.props;
    if (isEmpty(partnersCategories)) {
      return (
        <div>
          <h1>{I18n.t("partnership.emptyCategory")}</h1>
        </div>
      );
    }

    return (
      <div>
        {this.renderDrawer()}
        <div id="main-container">
          <header>
            <h1 className="documentation-header">
              {I18n.t("partnership.activatePartnerModules")}
            </h1>
          </header>
          <div id="linkPartnersGrid">
            <div className="blocinfo">
              <img src={infoIcon} alt="info" />
              <div className="description">
                <h3>{I18n.t("partnership.processTitle")}</h3>
                <p>{I18n.t("partnership.process1")}</p>
                <ul>
                  <li>{I18n.t("partnership.process2")}</li>
                  <li>{I18n.t("partnership.process3")}</li>
                  <li>{I18n.t("partnership.process4")}</li>
                </ul>
                <p>{I18n.t("partnership.process5")}</p>
                <p>{I18n.t("partnership.process6")}</p>
              </div>
            </div>
            {map(partnersCategories, (value, key) => {
              if (value.partners && value.partners.length) {
                return (
                  <div key={key} className="partnersGrid">
                    <h2 className="partnerCatTitle">{value.name}</h2>
                    <hr />
                    <div className="row">
                      {value.partners.map((value, key) => {
                        return (
                          <PartnerItem
                            key={key}
                            partner={partners[value]}
                            handleDrawer={this.toggleDrawer}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div id="stateLegend">
            <div>
              <ul>
                <li>
                  <img src={nonInteIcon} alt="noninte" />
                  <span>{I18n.t("partnership.noIntegration")}</span>
                  <span className="helper" />
                </li>
                <li>
                  <img src={pauseIcon} alt="paused" />
                  <span>{I18n.t("partnership.waitValidation")}</span>
                  <span className="helper" />
                </li>
                <li>
                  <img src={checkedIcon} alt="validated" />
                  <span>{I18n.t("partnership.integrationValidated")}</span>
                  <span className="helper" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    partnersCategories: state.entities.partnersCategories,
    partners: state.entities.partners
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addFlashMessage: message => {
      dispatch(addFlashMessage(message));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkPartners);
