import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Filters from '../components/Filters/Filters';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import DatePicker from '../components/DatePicker/DatePicker';
import Popover from '@material-ui/core/Popover';
import moment from 'moment';
import ImgCalendar from '../svg/calendar.svg';
import TableData from '../components/TableData/TableData';
import '../sass/style.scss';

import { connect } from 'react-redux';
import CallApi from '../../../utils/CallApi';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import { I18n } from 'react-redux-i18n';
import Dialog from '@material-ui/core/Dialog';
import WareDetails from '../components/WareDetails';

const styles = theme => ({
	selectRoot: {
		minWidth: '304px',
		minHeight: '39px',
		backgroundColor: 'white',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.18)',
	},
	select: {
		minHeight: '23px',
		'&:focus': {
			backgroundColor: 'transparent',
		},
	},

	menu: {
		'&:focus': {
			backgroundColor: 'none',
			border: 'none',
		},
		'&:before': {
			borderColor: 'orange',
		},
		'&:after': {
			borderColor: 'green',
		},
	},
	paper: {
		marginTop: '40px',
		borderRadius: '0',
	},
});

class BarDistrib extends Component {
	constructor(props) {
		super(props);
		this.onDatePickerValidate = this.onDatePickerValidate.bind(this);
		this.onValidateFilters = this.onValidateFilters.bind(this);
		this.state = {
			openDatePicker: false,
			anchorEl: null,
			dateBegin: moment().subtract(7, 'days').toDate(),
			dateBeginLabel: moment().subtract(7, 'days').format('DD-MM-YYYY'),
			dateEnd: moment().toDate(),
			dateEndLabel: moment().format('DD-MM-YYYY'),
			shifts: [],
			idCompanies: this.props.companies,
			idShifts: [],
			wares: [],
			tmpIdShifts: [],
			tmpIdCompanies: [],
			tableHeight: 10,
			modalOpen: false,
			wareDetails: [],
			wareName: '',
		};
	}

	onDatePickerValidate = params => {
		this.setState({
			dateBeginLabel: params.fromLabel,
			dateBegin: params.from,
			dateEndLabel: params.toLabel,
			dateEnd: params.enteredTo,
			openDatePicker: false,
		});
	};

	componentDidMount() {
		this.getShiftDatas(this.state.idShifts);
	}

	componentWillMount() {
		this.getShiftDatas(this.state.idShifts);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.dateBegin &&
			this.state.dateEnd &&
			this.state.dateBegin !== prevState.dateBegin &&
			this.state.dateEnd !== prevState.dateEnd
		) {
			this.getShiftDatas(this.state.idShifts);
		}
	}

	onValidateFilters = e => {
		this.setState(
			{
				idShifts: this.state.tmpIdShifts,
				idCompanies:
					this.state.tmpIdCompanies.length > 0
						? this.state.tmpIdCompanies
						: this.props.companies,
			},
			() => {
				this.getShiftDatas(this.state.tmpIdShifts);
			}
		);
	};

	getShiftDatas = async idShifts => {
		try {
			const accessToken = window.localStorage.getItem('accessToken');
			let idShifts = [];
			const shiftDatas = await CallApi(
				'GET',
				'bardistrib/shifts',
				'',
				{
					fromDate: moment(this.state.dateBegin).format('YYYY-MM-DD 00:00:00'),
					toDate: moment(this.state.dateEnd).format('YYYY-MM-DD 23:59:59'),
					id_companies: JSON.stringify(this.state.idCompanies),
				},
				`accessToken ${accessToken}`
			)
				.then(result => {
					if (result && result.length > 0) {
						const formattedShift = result[0].map(shift => {
							return {
								...shift,
								labelDate: `${shift.opening_date_label}- ${shift.closing_date_label}`,
							};
						});

						idShifts = result[0].map(shift => {
							return { id_shift_document: shift.id_shift_document };
						});
						this.setState({ shifts: formattedShift, idShifts: idShifts });
					}
				})
				.catch(err => {
					console.log(err);
				});

			const wares = await CallApi(
				'GET',
				'bardistrib/ware-datas',
				'',
				{
					fromDate: this.formateDate(
						this.state.dateBegin,
						'YYYY-MM-DD 00:00:00'
					),
					toDate: this.formateDate(this.state.dateEnd, 'YYYY-MM-DD 23:59:59'),
					shifts: JSON.stringify(
						this.state.tmpIdShifts && this.state.tmpIdShifts.length > 0
							? this.state.tmpIdShifts
							: idShifts
					),
					shiftsSelected: this.state.tmpIdShifts.length,
					id_companies: JSON.stringify(this.state.idCompanies),
				},
				`accessToken ${accessToken}`
			)
				.then(result => {
					if (result && result.length > 0) {
						const newHeight =
							result[0] && result[0].length ? result[0].length : 10;
						this.setState({ wares: result, tableHeight: newHeight });
					}
				})
				.catch(err => {
					console.log(err);
				});
		} catch (err) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	formateDate = (data, formatDisplay) => {
		return moment(data).format(formatDisplay);
	};

	getWareDetailsData = async wareInfo => {
		const accessToken = window.localStorage.getItem('accessToken');
		const resWareDetails = await CallApi(
			'GET',
			'bardistrib/ware-data/details',
			'',
			{
				fromDate: this.formateDate(this.state.dateBegin, 'YYYY-MM-DD 00:00:00'),
				toDate: this.formateDate(this.state.dateEnd, 'YYYY-MM-DD 23:59:59'),
				shifts: JSON.stringify(
					this.state.tmpIdShifts && this.state.tmpIdShifts.length > 0
						? this.state.tmpIdShifts
						: this.state.idShifts
				),
				shiftsSelected: this.state.tmpIdShifts.length,
				id_companies: JSON.stringify(this.state.idCompanies),
				wareInfo,
			},
			`accessToken ${accessToken}`
		);
		if (resWareDetails && resWareDetails.length) {
			const formateResDetails = this.formateResponseDetails(resWareDetails);
			this.setState(
				{ wareDetails: formateResDetails, wareName: wareInfo.ware_name },
				() => this.handleOpenModal()
			);
		}
	};

	handleClickWareDetails = wareInfo => {
		const { id_ware } = wareInfo.original;
		if (id_ware) {
			this.getWareDetailsData(wareInfo.original);
		}
	};
	formateResponseDetails = data => {
		data.map(item => {
			item.wareOut[0].ware.map(item2 => {
				item2.time = formateTime(item2.time);
				return item2;
			});
			item.wareNotCashed[0].ware.map(item3 => {
				item3.time = formateTime(item3.time);
				return item3;
			});
			return item;
		});

		function formateTime(time) {
			return moment(time).utc().format('DD/MM/YYYY HH:mm:ss');
		}
		return data;
	};

	handleOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	handleCloseModal = () => {
		this.setState({
			modalOpen: false,
			wareDetails: [],
			wareName: '',
		});
	};

	render() {
		const { classes, companies } = this.props;
		return (
			<>
				<div className="warelist_container">
					<header className="warelist_header">
						<div className="warelist_title">
							<span className="warelist_title_content">
								<p>Rapport d'analyse Bardistrib</p>
								<div className="warelist_title_shape"></div>
							</span>
						</div>
						<div className="warelist_filter">
							<div className="warelist_filter_header">
								<p>{I18n.t('globalLabels.filters')}</p>
							</div>
							<div className="warelist_filter_container">
								<div className="warelist_filter_main">
									<p>{I18n.t('globalLabels.date')}</p>
									<div className="warelist_filter_selector">
										<div
											className="warelist_filter_datepicker"
											onClick={e => this.setState({ openDatePicker: true })}>
											<div className="datepicker_label_container">
												<p>{`${
													this.state.dateBegin
														? this.state.dateBeginLabel
														: '-- -- --'
												} / ${
													this.state.dateEnd
														? this.state.dateEndLabel
														: '-- -- --'
												}`}</p>
											</div>
											<img src={ImgCalendar} />
										</div>
									</div>
								</div>
								<div className="warelist_filter_main">
									<p>{I18n.t('globalLabels.companies')}</p>
									<div className="warelist_filter_selector">
										{' '}
										<Filters
											label={
												companies && companies.length == 1
													? companies[0].company_name
													: I18n.t('globalLabels.allCompanies')
											}
											datas={companies}
											paramsLabel="company_name"
											isNumberData={false}
											multiple={true}
											paramsValue="id_company"
											onClose={data => {
												this.setState({ tmpIdCompanies: data });
											}}
										/>
									</div>
								</div>
								<div className="warelist_filter_main">
									<p>{I18n.t('globalLabels.shifts')}</p>
									<div className="warelist_filter_selector">
										<Filters
											label={I18n.t('globalLabels.allShifts')}
											datas={this.state.shifts}
											paramsLabel="labelDate"
											multiple={true}
											isNumberData={true}
											onClose={data => {
												this.setState({ tmpIdShifts: data });
											}}
											paramsValue="id_shift_document"
										/>
									</div>
								</div>
								<div className="warelist_filter_validate_btn">
									<Button
										onClick={this.onValidateFilters}
										className="warelist_btn_validate">
										{I18n.t('globalLabels.validate')}
									</Button>
								</div>
							</div>
							{/* <div className="warelist_filter_btn">
              <Button
                className={`warelist_btn_validate ${
                  this.state.tmpIdShifts.length ? "not-allowed" : ""
                }`}
              >
                {I18n.t("globalLabels.services")}
              </Button>
            </div> */}
						</div>
					</header>
					<main className="warelist_main">
						<div>
							<TableData
								handleClickWareDetails={this.handleClickWareDetails}
								labelNoData={I18n.t('globalLabels.noData')}
								datas={this.state.wares}
								tableHeight={this.state.tableHeight}
							/>
							<DatePicker
								open={this.state.openDatePicker}
								onValidate={params => this.onDatePickerValidate(params)}
							/>
						</div>
					</main>
				</div>
				<Dialog
					onClose={this.handleCloseModal}
					aria-labelledby="simple-dialog-title"
					open={this.state.modalOpen}
					fullScreen={true}>
					<WareDetails
						labelNoData={I18n.t('globalLabels.noData')}
						handleCloseModal={this.handleCloseModal}
						wareGlobal={this.state.wareDetails}
						wareName={this.state.wareName}
					/>
				</Dialog>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		companies: state.entities.informations.undefined.companies,
	};
}

export default connect(mapStateToProps, { addFlashMessage })(
	withStyles(styles)(BarDistrib)
);
