import React from 'react';

const ArrowUpSvg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13">
		<g>
			<g>
				<path
					fill="#e84249"
					d="M-.012 11.18c0-.462.17-.925.507-1.276L10.003.008l9.507 9.896c.673.7.677 1.839.009 2.544a1.661 1.661 0 0 1-2.428.009l-7.088-7.378-7.088 7.378c-.673.7-1.76.696-2.428-.009a1.84 1.84 0 0 1-.499-1.268z"
				/>
			</g>
		</g>
	</svg>
);

export default ArrowUpSvg;
