import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import Popover from "material-ui/Popover";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import Autocomplete from "react-autocomplete";
import NumberFormat from "react-number-format";

class TableControlStrip extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      description: "",
      activeRow: -1
    };
  }

  openPopover = (event, rowInfo) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      open: true,
      description: rowInfo.original.description,
      anchorEl: event.currentTarget.childNodes[event.currentTarget.childNodes.length - 3]
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    let { data, columns } = this.props;

    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        dataArray.push(data[elem]);
      });
    }

    let columnsTable = [
      {
        Header: "Date",
        accessor: "formatTimestamp",
        pivot: true,
        maxWidth: 300,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search style={{ color: "white", lineHeight: "17.4px", verticalAlign: "middle" }} />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      {
        Header: "Nom Commande",
        accessor: "sales_document_name",
        pivot: true,
        maxWidth: 300,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Action",
        accessor: "action",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value),
        Filter: ({ filter, onChange }) => (
          <div style={{ width: "100%" }}>
            <MuiThemeProvider>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  inputProps={{ style: { width: "100%" } }}
                  wrapperStyle={{ width: "100%" }}
                  getItemValue={item => item}
                  items={uniqueAction}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item}
                      style={{
                        color: "black",
                        background: isHighlighted ? "lightgray" : "white"
                      }}
                    >
                      {item}
                    </div>
                  )}
                  shouldItemRender={(item, filter) =>
                    filter ? item.includes(filter) || item.toLowerCase().includes(filter) : true
                  }
                  value={filter ? filter.value : ""}
                  onChange={e => onChange(e.target.value)}
                  onSelect={(val, filter) => onChange(val)}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      {
        Header: "Description",
        accessor: "description",
        pivot: true,
        minWidth: 275,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "ID Appareil",
        accessor: "id_device",
        pivot: true,
        maxWidth: 150,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Utilisateur",
        accessor: "user_fullname",
        pivot: true,
        maxWidth: 250,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) || row[filter.id].toLowerCase().includes(filter.value)
      }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id_shift_document" },
      { label: "Date", key: "formatTimestamp" },
      { label: "Nom Commande", key: "sales_document_name" },
      { label: "Action", key: "action" },
      { label: "Description", key: "description" },
      { label: "ID Appareil", key: "id_device" },
      { label: "Utilisateur", key: "user_fullname" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        if (typeof element != "undefined") {
          element.user_fullname = element.user_firstname + " " + element.user_lastname;
          element.formatTimestamp = moment(element.timestamp).format("DD-MM-YYYY H:mm:ss");
        }
      });
    }

    const uniqueAction = [...new Set(dataArray.map(item => item.action))];

    return (
      <div>
        <MuiThemeProvider>
          <div>
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
              onRequestClose={this.handleRequestClose}
              style={{ borderRadius: "15px" }}
            >
              <div style={{ minHeight: "30px", padding: "20px", color: "black", backgroundColor: "white" }}>
                <span style={{ fontStyle: "italic" }}>Description</span> : {this.state.description}
              </div>
            </Popover>
            <ReactTable
              className=" -highlight -striped clickAble"
              style={{ textAlign: "center", maxHeight: "85vh" }}
              data={dataArray}
              defaultPageSize={25}
              filterable
              columns={columnsTable}
              previousText={I18n.t("fisc.table.previousText")}
              nextText={I18n.t("fisc.table.nextText")}
              loadingText={I18n.t("fisc.table.loadingText")}
              noDataText={I18n.t("fisc.table.noDataText")}
              pageText={I18n.t("fisc.table.pageText")}
              ofText={I18n.t("fisc.table.ofText")}
              rowsText={I18n.t("fisc.table.rowsText")}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, t) => {
                    this.openPopover(e, rowInfo);
                  }
                };
              }}
            />
            {dataArray ? (
              <CSVLink
                style={{ float: "right" }}
                data={dataArray}
                filename="export-bandeControleService.csv"
                headers={columnsTableExport}
                separator={";"}
              >
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload style={{ lineHeight: "20px", verticalAlign: "middle" }} /> Extraire le tableau{" "}
                </button>
              </CSVLink>
            ) : (
              ""
            )}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default TableControlStrip;
