import React, { Component } from 'react';
import logo from '../img/logo_symbol.png';
import '../css/ladditionLoader.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class LadditionLoader extends Component {
	render() {
		return (
			<div
				className={classNames('laddition-loader-container', {
					'laddition-loader-container__hidden': this.props.hidden,
				})}>
				<div className="pulse">
					<img
						alt="loader L'addition"
						src={logo}
						width="150px"
						height="150px"
					/>
				</div>
			</div>
		);
	}
}

LadditionLoader.propTypes = {
	hidden: PropTypes.bool,
};
LadditionLoader.defaultProps = {
	hidden: true,
};

export default LadditionLoader;
