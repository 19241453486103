import React, { Component } from 'react';

import memoize from 'lodash/memoize';
import '../sass/customerAccount.css';
import CallApi from './../../../utils/CallApi';
import TableLoader from './../../../common/tableloader/TableLoader';
import searchIcon from '../img/search.svg';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchSvg from '../svg/SearchSvg';
import { browserHistory } from 'react-router';
import CarteImg from '../img/solde_plein@2x.png';
import ImgRemove from '../img/delete.png';
import removeAccents from 'remove-accents';
import { connect } from 'react-redux';
import config from '../../../utils/config';
import priceFormatting from '../../../utils/priceFormatting';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import Clusterize from '../components/Clusterize';
import ModalExport from '../components/ModalExport';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import A_B_Tester from '../../../components/A_B_Tester';
import ModalHistory from '../components/ModalHistory';

const language = window.navigator.language;

class CustomerAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listAccounts: [],
			listAccountsFiltered: [],
			searchValue: '',
			loading: true,
			resetSearch: false,
			idOrderedBy: '',
			orderDirection: 'ASC',
			openExportModal: false,
			openExportHistory: false,
			allUsers: [],
			paymentTypes: [],
			isSuite: false
		};
	}

	componentDidMount() {
		this.getListCustomerAccounts();
		this.getAllUsers();
		this.getAllPaymentTypes();
		this.checkEspaceClientGroup();
		this.checkIfSuiteAccount();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.searchValue !== this.state.searchValue) {
			window.sessionStorage.setItem('searchValue', this.state.searchValue);
		}

		if (
			prevState.listAccounts.length !== this.state.listAccounts.length &&
			window.sessionStorage.getItem('searchValue') !== 'null'
		) {
			const searchValueStorage =
				window.sessionStorage.getItem('searchValue') !== 'null'
					? window.sessionStorage.getItem('searchValue')
					: '';
			this.setState(
				{
					searchValue: searchValueStorage,
					loading: true,
				},
				() => {
					this.filterAccounts(searchValueStorage);
				}
			);
		}

		if (prevProps.auth.user !== this.props.auth.user) {
			this.checkEspaceClientGroup();
		}
	}

	getListCustomerAccounts = async () => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const accountsList = await CallApi(
				'GET',
				'customer-account',
				'',
				'',
				`accessToken ${accessToken}`
			);
			this.setState({
				listAccounts: accountsList,
				listAccountsFiltered: accountsList,
				loading: false,
			});
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	getAllUsers = async () => {
		try {
			const accesToken = await window.localStorage.getItem('accessToken');
			const getUsers = await CallApi(
				'GET',
				'user/users',
				'',
				'',
				`accessToken ${accesToken}`
			);

			this.setState({ allUsers: getUsers[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	checkEspaceClientGroup() {
		const user = this.props.auth.user;
		if (user && user.groups) {
			const hasEspaceClientGroup = user.groups.some(group => group.name === 'espace_client');
			this.setState({ hasEspaceClientGroup });
		}
	}

	checkIfSuiteAccount() {
		const { user } = this.props.auth;
		if (user && user.groups && Array.isArray(user.groups)) {
			const isSuiteClient = user.groups.some(
				group => group.name === 'laddition-suite'
			);
			this.setState({ isSuite: isSuiteClient })
		}
	}

	getAllPaymentTypes = async () => {
		try {
			const accesToken = await window.localStorage.getItem('accessToken');
			const getPaymentTypes = await CallApi(
				'GET',
				'user/paymentTypes',
				'',
				'',
				`accessToken ${accesToken}`
			);
			this.setState({ paymentTypes: getPaymentTypes[0] });
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	searchAccounts = e => {
		e.preventDefault();
		this.setState({ searchValue: e.target.value, loading: true }, () => {
			setTimeout(() => this.filterAccounts(this.state.searchValue), 500);
		});
	};

	setOrderTable = (e, field, childField, id) => {
		e.preventDefault();
		this.setState(
			(prevState, props) => ({
				loading: true,
				idOrderedBy: id,
				orderDirection:
					prevState.idOrderedBy === id && prevState.orderDirection === 'ASC'
						? 'DESC'
						: 'ASC',
			}),
			() => {
				setTimeout(
					() => this.orderTable(this.state.listAccounts, field, childField),
					500
				);
			}
		);
	};

	orderTable = (accountList, field, childField) => {
		const sort_by = (field, fieldInObject, reverse = 'ASC', primer) => {
			const key = function (x) {
				const val =
					fieldInObject !== null && x[field.toString()]
						? field === 'companies'
							? x[field.toString()].length > 1
								? 'ALL'
								: x[field.toString()][0][fieldInObject]
							: x[field.toString()][fieldInObject]
						: x[field];
				return primer ? primer(val) : val;
			};

			const order = reverse === 'ASC' ? 1 : -1;
			return function (a, b) {
				return (
					(a = key(a) === null ? '' : key(a)),
					(b = key(b) === null ? '' : key(b)),
					order * ((a > b) - (b > a))
				);
			};
		};

		const sortedData = accountList[0].sort(
			sort_by(field, childField, this.state.orderDirection, a =>
				typeof a === 'string' ? a.toUpperCase() : a
			)
		);

		this.setState({
			loading: false,
			listAccountsFiltered: [sortedData],
		});
	};

	filterAccounts = searchValue => {
		const accountsList = [...this.state.listAccounts];

		const accountsListFilter = [];
		if (searchValue) {
			accountsList[0].filter(account => {
				const search =
					account && account.search ? account.search.toLowerCase() : '';
				const searchValueLower = searchValue ? searchValue.toLowerCase() : '';
				if (search && search.includes(searchValueLower)) {
					accountsListFilter.push(account);
				}
			});
			this.setState({
				loading: false,
				listAccountsFiltered: [accountsListFilter],
			});
		} else {
			this.setState({
				loading: false,
				listAccountsFiltered: [...this.state.listAccounts],
			});
		}
	};

	customerAcccountDetails = (e, idCustomer) => {
		e.preventDefault();
	};

	handleModalExport = e => {
		this.setState({ openExportModal: true });
	};

	handleResetClick = e => {
		e.preventDefault();
		this.setState(
			{
				searchValue: '',
				resetSearch: true,
				loading: true,
				listAccountsFiltered: this.state.listAccounts,
			},
			() => {
				setTimeout(() => {
					this.setState({
						loading: false,
					});
				}, 100);
			}
		);
	};

	exportCustomerData = async (
		startDate = '',
		endDate = '',
		route,
		userId = '',
		method,
		email
	) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');

			const customerAccountExport = await CallApi(
				method,
				`customer-account/${route}`,
				'',
				{
					startDate,
					endDate,
					email,
				},
				`accessToken ${accessToken}`
			);

			if (customerAccountExport && customerAccountExport.length > 0) {
				let message =
					customerAccountExport[0].ok && customerAccountExport[0].data
						? I18n.t('lneExport.exportProcessing')
						: I18n.t('lneExport.noData');
				this.props.addFlashMessage({
					type: message.type,
					text: message,
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			this.props.addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
			return error;
		}
	};
	render() {
		const renderCompanies = companies => {
			return companies.length > 1 ? 'Tous' : companies[0].company_name;
		};

		const headersLabels = [
			{
				id: 'card_number',
				cssClass: 'small',
				field: 'card_number',
				childField: null,
				label: `${I18n.t('customerAccount.cardNumber')}`,
			},
			{
				id: 'lastname',
				cssClass: 'big',
				field: 'lastname',
				childField: null,
				label: `${I18n.t('customerAccount.firstAndLastName')}`,
			},
			{
				id: 'details_email',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_email',
				label: `${I18n.t('customerAccount.email')}`,
			},
			{
				id: 'details_phone',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'details_phone',
				label: `${I18n.t('customerAccount.phoneNumber')}`,
			},
			{
				id: 'adress',
				cssClass: 'big',
				field: 'customer_infos',
				childField: 'adress',
				label: `${I18n.t('customerAccount.address')}`,
			},
			{
				id: 'customer_company_id',
				cssClass: 'small',
				field: 'customer_infos',
				childField: 'customer_company_id',
				label: `${I18n.t('customerAccount.siren')}`,
			},
			{
				id: 'company_name',
				cssClass: 'big',
				field: 'companies',
				childField: 'company_name',
				label: `${I18n.t('customerAccount.companyName')}`,
			},
			{
				id: 'balance',
				cssClass: 'small',
				field: 'account_details',
				childField: 'balance',
				label: `${I18n.t('customerAccount.balance')}`,
			},
		];

		// let isTestGroup = false;
		// if (this.props.auth && this.props.auth.user) {
		// 	//TO-DO : remove du padding supplémentaire après la mise en place de la navigation sans groupe
		// 	const { groups } = this.props.auth.user;
		// 	const testGroup = groups.find(group => group.name === 'reporting-test');
		// 	isTestGroup = testGroup ? true : false;
		// }

		const createTableHeader = () => {
			return headersLabels
			.filter(head => {
				if (this.state.isSuite && head.id === 'card_number') {
				  return false;
				}
				return true;
			})
			.map(head => {
				return (
					<div
						id="header_label"
						key={`${Math.floor(Math.random() * 100000)}`}
						className={`flex-row ${head.cssClass}`}>
						<p
							key={head.id}
							role="columnheader"
							onClick={e =>
								this.setOrderTable(e, head.field, head.childField, head.id)
							}>
							{head.label}
						</p>
						{head.id !== this.state.idOrderedBy ? (
							<RemoveIcon />
						) : this.state.orderDirection === 'ASC' ? (
							<ArrowUpwardIcon />
						) : (
							<ArrowDownwardIcon />
						)}
					</div>
				);
			});
		};

		const createAccountRow = memoize(() => {
			const { listAccountsFiltered } = this.state;
			if (listAccountsFiltered[0] && listAccountsFiltered[0].length > 0) {
				const toRender = listAccountsFiltered.map(accounts => {
					return accounts.map((account, i) => {
						const accountIds = [];
						const accountIdCompanies = [];

						account.companies.map(company =>
							accountIds.push(company.uniq_id_customer)
						);
						account.companies.map(company =>
							accountIdCompanies.push(company.id)
						);

						const nameRestaurantTmp =
							account.companies.length === 1
								? account.companies[0].company_name.split(' ').join('-')
								: '';
						const nameRestaurant = removeAccents(nameRestaurantTmp);

						return `<tr
                class="flex-table"
                role="rowgroup"
								key="${accountIdCompanies}-${Math.floor(Math.random() * 100000)}"
                data-url=
                  ${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}
              >
			  ${!this.state.isSuite ? `
				<td class="flex-row small" role="cell">
						<a href=${
							account.companies.length === 1
								? `customer-account/${accountIds}?id=${accountIdCompanies}`
								: `customer-account/${accountIds}`
						}>
						<span class="">
							${account && account.card_number ? account.card_number : ''}
						</span>
					</a>
				</td>`
				: 
				''
				}
                <td class="flex-row big" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										${
											account && account.card_number
												? `<span
														class="info-card tooltip-td card-span"
														data-tooltip="carte n° ${account.card_number}"
													>
														<img src=${CarteImg} alt="card icon" />
													</span>`
												: `<span class="card-span" />`
										}
										<span class="">
											${account && account.lastname ? account.lastname : ''} ${
							account && account.firstname ? account.firstname : ''
						}
										</span>
									</a>
                </td>
                <td class="flex-row big" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">
											${
												account &&
												account.customer_infos &&
												account.customer_infos.details_email
													? account.customer_infos.details_email
													: ''
											}
										</span>
									</a>
                </td>
                <td class="flex-row big" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">
											${
												account &&
												account.customer_infos &&
												account.customer_infos.details_phone
													? account.customer_infos.details_phone
													: ''
											}
										</span>
									</a>
                </td>
                <td class="flex-row big" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">
											${
												account &&
												account.customer_infos &&
												account.customer_infos.address
													? account.customer_infos.address
													: ''
											}
										</span>
									</a>
                </td>
                <td class="flex-row small" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">
											${
												account &&
												account.customer_infos &&
												account.customer_infos.customer_company_id
													? account.customer_infos.customer_company_id
													: ''
											}
										</span>
									</a>
                </td>
                <td class="flex-row big" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">
											${account && account.companies ? renderCompanies(account.companies) : ''}
										</span>
									</a>
                </td>
                <td class="flex-row small" role="cell">
									<a href=${
										account.companies.length === 1
											? `customer-account/${accountIds}?id=${accountIdCompanies}`
											: `customer-account/${accountIds}`
									}>
										<span class="">

											${
												account &&
												account.account_details &&
												account.account_details.balance
													? priceFormatting(
															account.account_details.balance,
															language,
															'EUR'
													  )
													: ''
											}
										</span>
									</a>
                </td>
              </tr>`;
					});
				});
				return toRender[0];
			} else {
				return (
					<div id="empty-contact">
						<div id="error-box">
							<div className="face">
								<div className="eye" />
								<div className="eye right" />
								<div className="mouth sad" />
							</div>
							<div className="shadow move" />
							<p className="message">
								{I18n.t('customerAccount.noAccountFound')}
							</p>
						</div>
					</div>
				);
			}
		});
		return (
			<div id="customer-account">
				<header>
					<div className="header-main">
						<h3>{I18n.t('customerAccount.customerAccountTitle')}</h3>
						<div className="customer-header-action">
							{ !this.state.isSuite && 
								<button
									className="btn-history"
									onClick={() => this.setState({ openExportHistory: true })}>
									Historique
								</button>
							}
							<A_B_Tester
								featureFlag={'export-customer-account'}
								FeatureFlagComponent={
									<button
										className="header-export-btn"
										onClick={this.handleModalExport}>
										{I18n.t('customerAccount.export')}
									</button>
								}
								DefaultComponent={null}
							/>

							<div className="search-bar-account">
								{this.state.searchValue ? (
									<img
										src={ImgRemove}
										onClick={this.handleResetClick}
										className="remove"
										alt="img remove"
									/>
								) : (
									''
								)}
								<input
									type="text"
									placeholder={I18n.t('customerAccount.search')}
									value={this.state.searchValue ? this.state.searchValue : ''}
									onChange={this.searchAccounts}
								/>
								<SearchSvg />
							</div>
						</div>
					</div>
					{/*  <div className="border filter">
            <div className="btn-add-filter">
              <button>Ajouter un filtre</button>
            </div>
            <div className="list-filter">
              <p>Filter list</p>
            </div>
          </div> */}
				</header>
				<div className="table-header" role="rowgroup">
					{createTableHeader()}
				</div>

				<main className="main_customer_account">
					{/* <div>
            <p>Selection</p>
         </div>*/}

					<section>
						{this.state.loading ? (
							<TableLoader />
						) : this.state.listAccountsFiltered[0] &&
						  this.state.listAccountsFiltered[0].length > 0 ? (
							<Clusterize rows={createAccountRow()} />
						) : (
							<div id="empty-contact">
								<div id="error-box">
									<div className="face">
										¨
										<div className="eye" />
										<div className="eye right" />
										<div className="mouth sad" />
									</div>
									<div className="shadow move" />
									<p className="message">
										{I18n.t('customerAccount.noAccountFound')}
									</p>
								</div>
							</div>
						)}
					</section>
				</main>

				<ModalExport
					openModal={this.state.openExportModal}
					user={this.props.auth.user}
					onClose={() => {
						this.setState({ openExportModal: false });
					}}
					exportfct={this.exportCustomerData.bind(this)}
				/>

				<ModalHistory
					openModal={this.state.openExportHistory}
					user={this.props.auth.user}
					companies={this.props.companies}
					allUsers={this.state.allUsers}
					paymentTypes={this.state.paymentTypes}
					onDisplayMessage={this.props.addFlashMessage}
					onClose={() => {
						this.setState({ openExportHistory: false });
					}}
					onError={() => {
						this.props.addFlashMessage({
							type: 'danger',
							text: 'Une erreur est survenue',
							persistent: false,
						});
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		companies: state.entities.informations.undefined.companies,
	};
}

export default connect(mapStateToProps, { addFlashMessage })(CustomerAccount);
