import React, { Component } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import { Translate, I18n } from "react-redux-i18n";
import { CSVLink } from "react-csv";
import FileDownload from "material-ui/svg-icons/file/file-download";
import Search from "material-ui/svg-icons/action/search";
import { MuiThemeProvider } from "material-ui/styles";
import { filterPrice } from "./utils";
import FilterPrice from "./FilterPrice";
import NumberFormat from "react-number-format";

class TableCommandesLines extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      details: "",
      stats: {},
      activeRow: -1
    };
  }

  handleClickDetails(rowInfo, columns) {
    this.props.goToDetails(rowInfo, columns);
  }

  render() {
    let { data, columns } = this.props;
    let dataArray = [];

    if (data) {
      Object.keys(data).map(elem => {
        if (data[elem].creation.id) {
          dataArray.push(data[elem].creation);
        } else {
          dataArray.push({ id: data[elem].id, error: true });
        }

        if (data[elem].update.length > 0) {
          Object.keys(dataArray[dataArray.length - 1]).map(property => {
            if (
              dataArray[dataArray.length - 1][property] !=
                data[elem].update[data[elem].update.length - 1][property] &&
              data[elem].update[data[elem].update.length - 1][property]
            ) {
              dataArray[dataArray.length - 1][property] =
                data[elem].update[data[elem].update.length - 1][property];
            }
          });
        }
      });
    }

    let columnsTable = [
      {
        Header: "ID",
        accessor: "id",
        pivot: true,
        Filter: ({ filter, onChange }) => (
          <div>
            <MuiThemeProvider>
              <div>
                <Search
                  style={{
                    color: "white",
                    lineHeight: "17.4px",
                    verticalAlign: "middle"
                  }}
                />
                <input
                  onChange={event => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "80%"
                  }}
                />
              </div>
            </MuiThemeProvider>
          </div>
        )
      },
      { Header: "Date", accessor: "formatTimestamp", pivot: true },
      {
        Header: "Prix TTC",
        accessor: "price",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => (
          <FilterPrice filter={filter} onChange={onChange} />
        )
      },
      { Header: "Nom", accessor: "product_name", pivot: true },
      {
        Header: "Remise",
        accessor: "discount",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => (
          <FilterPrice filter={filter} onChange={onChange} />
        )
      },
      {
        Header: "Offert",
        accessor: "offered",
        pivot: true,
        Cell: row => (
          <NumberFormat
            style={{ float: "right", marginRight: "15%" }}
            value={row.value}
            displayType={"text"}
            thousandSeparator={" "}
            decimalSeparator={"."}
          />
        ),
        filterMethod: (filter, row) => filterPrice(filter, row),
        Filter: ({ filter, onChange }) => (
          <FilterPrice filter={filter} onChange={onChange} />
        )
      },
      {
        Header: "Taux de TVA",
        accessor: "tax_rate",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      {
        Header: "Utilisateur",
        accessor: "userName",
        pivot: true,
        filterMethod: (filter, row) =>
          row[filter.id].includes(filter.value) ||
          row[filter.id].toLowerCase().includes(filter.value)
      },
      { Header: "Version", accessor: "app_version", pivot: true },

      { Header: "Supprimé", accessor: "removed", pivot: true }
    ];

    let columnsTableExport = [
      { label: "ID", key: "id" },
      { label: "Date", key: "formatTimestamp" },
      { label: "Prix TTC", key: "price" },
      { label: "Nom", key: "product_name" },
      { label: "Remise", key: "discount" },
      { label: "Offert", key: "offered" },
      { label: "Taux de TVA", key: "tax_rate" },
      { label: "Utilisateur", key: "userName" },
      { label: "Version", key: "app_version" },

      { label: "Supprimé", key: "removed" }
    ];

    if (dataArray.length > 0) {
      dataArray.forEach(element => {
        if (element.error) {
          element.timestamp = "Erreur tracking";
          element.dateHour = "Erreur tracking";
          element.opening_date = "Erreur tracking";
        }

        if (element.tax_rate * 100 + "%" != "NaN%") {
          element.tax_rate = element.tax_rate * 100 + "%";
        }

        if (!isNaN(Number(element.price).toFixed(2))) {
          element.price = Number(element.price).toFixed(2);
        }
        if (!isNaN(Number(element.discount).toFixed(2))) {
          element.discount = Number(element.discount).toFixed(2);
        }
        if (!isNaN(Number(element.offered).toFixed(2))) {
          element.offered = Number(element.offered).toFixed(2);
        }

        if (element.removed == 1) {
          element.removed = "oui";
        } else if (element.removed == 0) {
          element.removed = "non";
        }
        element.formatTimestamp = moment
          .unix(element.timestamp)
          .format("DD-MM-YYYY H:mm:ss");
      });
    }

    return (
      <div>
        <ReactTable
          className=" -highlight -striped clickAble"
          style={{ textAlign: "center", maxHeight: "85vh" }}
          data={dataArray}
          defaultPageSize={10}
          filterable
          columns={columnsTable}
          previousText={I18n.t("fisc.table.previousText")}
          nextText={I18n.t("fisc.table.nextText")}
          loadingText={I18n.t("fisc.table.loadingText")}
          noDataText={I18n.t("fisc.table.noDataText")}
          pageText={I18n.t("fisc.table.pageText")}
          ofText={I18n.t("fisc.table.ofText")}
          rowsText={I18n.t("fisc.table.rowsText")}
          getTrProps={(state, rowInfo, column, instance) => {
            let { columns } = state;

            return {
              style: {
                backgroundColor:
                  rowInfo && rowInfo.original.error
                    ? "rgba(222,0, 10, 0.8)"
                    : ""
              },
              onClick: (e, t) => {
                this.handleClickDetails(rowInfo, columns);
              }
            };
          }}
        />
        {dataArray ? (
          <CSVLink
            style={{ float: "right" }}
            data={dataArray}
            filename="export-lignesCommande.csv"
            headers={columnsTableExport}
            separator={";"}
          >
            <MuiThemeProvider>
              <div>
                {" "}
                <button className="btn btn-fisc" style={{ marginRight: "0px" }}>
                  {" "}
                  <FileDownload
                    style={{ lineHeight: "20px", verticalAlign: "middle" }}
                  />{" "}
                  Extraire le tableau{" "}
                </button>{" "}
              </div>
            </MuiThemeProvider>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TableCommandesLines;
