import React from "react";
import Proptypes from "prop-types";
import classnames from "classnames";
import "./EmailEgal.css";
import { I18n } from "react-redux-i18n";

const EmailEgal = ({ emailEgal }) => {
  return (
    <div id={"email-egal-container"}>
      <section className={"email-egal-check"}>
        <h5>{I18n.t("emailEquals.important")}</h5>
        <ul>
          <li
            className={classnames({
              success: emailEgal,
              error: !emailEgal
            })}
          >
            {I18n.t("emailEquals.identical")}
          </li>
        </ul>
      </section>
    </div>
  );
};

EmailEgal.propTypes = {
  emailEgal: Proptypes.bool.isRequired
};

EmailEgal.defaultProps = {
  emailEgal: false
};

export default EmailEgal;
