import React, { Component } from "react";
import PropTypes from "prop-types";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import config from "../../utils/config";
import PasswordHint from "../../components/Password/PasswordHint/PasswordHint";
import { Tooltip } from "react-tippy";
import Card from "../../components/Card/Card";
import Btn from "../../components/Button/Btn";
import PasswordEgal from "../../components/Password/PasswordEgal/PasswordEgal";
import EmailEgal from "../../components/Password/EmailEgal/EmailEgal";
import EmailHint from "../../components/Password/EmailHint/EmailHint";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { I18n } from "react-redux-i18n";
import Input from "../../components/Input/Input";
import Button from "@material-ui/core/Button";
import { addFlashMessage } from "../../common/flashMessage/actions/flashMessages";
import { getRefreshToken } from "../../utils/utils";

class Migration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailRepeat: "",
      password: "",
      passwordVerification: "",
      errors: {},
      isLoading: false,
      disabled: true,
      isActive: false,
      passwordTooltipOpen: false,
      passwordEgalTooltipOpen: false,
      emailTooltipOpen: false,
      emailEgalTooltipOpen: false,
      emailIsFree: false,
      processingEmailIsFree: true,
      successMessage: "",
      messageType: "alert alert-success"
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  isValid() {
    let { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    if (isValid && this.state.password !== this.state.passwordVerification) {
      isValid = false;
      this.props.addFlashMessage({
        type: "error",
        text: I18n.t("accountMigration.migration.passwordMismatch")
      });
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    const decodedJwt = jwtDecode(window.localStorage.checkToken);
    const currentDate = moment().unix();
    if (decodedJwt.exp < currentDate) {
      this.context.router.push("/account/migration/step-1");
    }
    if (this.isValid()) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        window.localStorage.accessToken
      }`;
      axios
        .post(config.reportingUrl + "api/account/migration/email/update", {
          password: this.state.password,
          email: this.state.email,
          oldEmail: this.props.auth.user.email,
          refreshToken: getRefreshToken(),
          checkToken: window.localStorage.checkToken
        })
        .then(res => {
          document.getElementById("app").scrollTo(0, 0);
          this.setState({
            disabled: true,
            isActive: false
          });
          this.props.addFlashMessage({
            type: "success",
            text: I18n.t("accountMigration.migration.successMessage")
          });
        })
        .catch(err => {
          this.setState({
            disabled: false,
            isActive: true,
            isLoading: false
          });
          this.props.addFlashMessage({
            type: "success",
            text: I18n.t("accountMigration.migration.errorMessage")
          });
        });
      this.setState({ errors: {}, isLoading: true });
    }
  }

  onFocus(e) {
    if (e.target.name === "password") {
      this.setState({
        passwordTooltipOpen: true,
        passwordEgalTooltipOpen: false,
        emailTooltipOpen: false,
        emailEgalTooltipOpen: false
      });
    } else if (e.target.name === "passwordVerification") {
      this.setState({
        passwordEgalTooltipOpen: true,
        passwordTooltipOpen: false,
        emailTooltipOpen: false,
        emailEgalTooltipOpen: false
      });
    } else if (e.target.name === "emailRepeat") {
      this.setState({
        passwordEgalTooltipOpen: false,
        passwordTooltipOpen: false,
        emailTooltipOpen: false,
        emailEgalTooltipOpen: true
      });
    } else if (e.target.name === "email") {
      this.setState({
        passwordEgalTooltipOpen: false,
        passwordTooltipOpen: false,
        emailTooltipOpen: true,
        emailEgalTooltipOpen: false
      });
    }
  }

  onChange(e) {
    const newState = { [e.target.name]: e.target.value };
    const targetName = e.target.name;
    const targetValue = e.target.value;
    if (targetName === "email") {
      newState.processingEmailIsFree = true;
    }
    this.setState(newState, () => {
      const { email, emailRepeat, password, passwordVerification } = this.state;
      if (targetName === "email" && Validator.isEmail(targetValue)) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${
          window.localStorage.accessToken
        }`;
        axios
          .get(
            config.reportingUrl +
              "api/account/migration/email/exist?email=" +
              email
          )
          .then(res => {
            this.setState({
              emailIsFree: !res.data,
              processingEmailIsFree: false
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
      if (
        email === emailRepeat &&
        Validator.isEmail(email) &&
        email !== "" &&
        emailRepeat !== "" &&
        (password === passwordVerification &&
          password !== "" &&
          passwordVerification !== "")
      ) {
        this.setState({ disabled: false, isActive: true });
      } else {
        this.setState({ disabled: true, isActive: false });
      }
    });
  }

  onBlur() {
    this.setState({
      passwordEgalTooltipOpen: false,
      passwordTooltipOpen: false,
      emailTooltipOpen: false,
      emailEgalTooltipOpen: false
    });
  }

  componentWillMount() {
    if (!window.localStorage.checkToken) {
      this.context.router.push("/account/migration/step-1");
    } else {
      try {
        const decodedJwt = jwtDecode(window.localStorage.checkToken);
        const currentDate = moment().unix();
        if (decodedJwt.exp < currentDate) {
          this.context.router.push("/account/migration/step-1");
        }
      } catch (err) {
        this.context.router.push("/account/migration/step-1");
      }
    }
  }

  render() {
    const {
      errors,
      password,
      passwordVerification,
      email,
      emailRepeat,
      disabled,
      isLoading,
      isActive,
      successMessage,
      messageType
    } = this.state;
    return (
      <div id={"migration-container"}>
        <h1>{I18n.t("accountMigration.migration.title")}</h1>
        <form onSubmit={this.onSubmit}>
          <div className="input-container" style={{ marginTop: 40 }}>
            <Input
              label={I18n.t("accountMigration.migration.actualEmail")}
              onChange={this.onChange}
              value={this.props.auth.user.email}
              name="old_email"
              required="required"
              idInput="old-email-input"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              colorInput={
                !(this.props.auth.user.email !== "") ? null : "#7cc4ac"
              }
            />
          </div>
          <div className="input-container">
            <Tooltip
              title="email hints"
              arrow={true}
              html={
                <EmailHint
                  emailIsFree={this.state.emailIsFree}
                  isEmail={Validator.isEmail(email)}
                  processingEmailIsFree={this.state.processingEmailIsFree}
                />
              }
              theme={"light"}
              position={"right"}
              open={this.state.emailTooltipOpen}
            >
              <Input
                label={I18n.t("accountMigration.migration.emailInput")}
                type="email"
                name="email"
                required="required"
                idInput="email-input"
                onFocus={this.onFocus}
                onChange={this.onChange}
                onBlur={this.onBlur}
                value={email}
                colorInput={
                  this.state.emailIsFree && Validator.isEmail(email)
                    ? "#7cc4ac"
                    : null
                }
              />
            </Tooltip>
          </div>
          {this.state.emailTooltipOpen ? (
            <div className={"hint-help-container"}>
              <EmailHint
                emailIsFree={this.state.emailIsFree}
                isEmail={Validator.isEmail(email)}
                processingEmailIsFree={this.state.processingEmailIsFree}
              />
            </div>
          ) : null}

          <div className="input-container">
            <Tooltip
              title="email egal"
              arrow={true}
              html={<EmailEgal emailEgal={email === emailRepeat} />}
              theme={"light"}
              position={"right"}
              open={this.state.emailEgalTooltipOpen}
            >
              <Input
                label={I18n.t("accountMigration.migration.emailConfirm")}
                type="email"
                name="emailRepeat"
                required="required"
                idInput="email-repeat-input"
                onChange={this.onChange}
                value={emailRepeat}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                colorInput={
                  emailRepeat && email === emailRepeat ? "#7cc4ac" : null
                }
              />
            </Tooltip>
          </div>

          {this.state.emailEgalTooltipOpen ? (
            <div className={"hint-help-container"}>
              <EmailEgal emailEgal={email === emailRepeat} />
            </div>
          ) : null}

          <div className="input-container">
            <Tooltip
              title="password hints"
              arrow={true}
              html={
                <PasswordHint
                  nbCharIsOk={password.length >= 8}
                  nbUpperIsOk={hasUpperCase(password)}
                  nbLowerIsOk={hasLowerCase(password)}
                  nbNumericIsOk={hasNumeric(password)}
                  nbSpecialCharIsOk={hasSpecialChar(password)}
                />
              }
              theme={"light"}
              position={"right"}
              open={this.state.passwordTooltipOpen}
            >
              <Input
                label={I18n.t("accountMigration.migration.passwordInput")}
                type="password"
                name="password"
                required="required"
                idInput="password-input"
                onFocus={this.onFocus}
                onChange={this.onChange}
                onBlur={this.onBlur}
                value={password}
                colorInput={
                  password.length >= 8 &&
                  hasUpperCase(password) &&
                  hasLowerCase(password) &&
                  hasNumeric(password) &&
                  hasSpecialChar(password)
                    ? "#7cc4ac"
                    : null
                }
              />
            </Tooltip>
          </div>

          {this.state.passwordTooltipOpen ? (
            <div className={"hint-help-container"}>
              <PasswordHint
                nbCharIsOk={password.length >= 8}
                nbUpperIsOk={hasUpperCase(password)}
                nbLowerIsOk={hasLowerCase(password)}
                nbNumericIsOk={hasNumeric(password)}
                nbSpecialCharIsOk={hasSpecialChar(password)}
              />
            </div>
          ) : null}

          <div className="input-container">
            <Tooltip
              title="password egal"
              arrow={true}
              html={
                <PasswordEgal
                  passwordEgal={password === passwordVerification}
                />
              }
              theme={"light"}
              position={"right"}
              open={this.state.passwordEgalTooltipOpen}
            >
              <Input
                label={I18n.t("accountMigration.migration.passwordConfirm")}
                type="password"
                name="passwordVerification"
                required="required"
                idInput="password-input-repeat"
                onFocus={this.onFocus}
                onChange={this.onChange}
                onBlur={this.onBlur}
                value={passwordVerification}
                colorInput={
                  passwordVerification && password === passwordVerification
                    ? "#7cc4ac"
                    : null
                }
              />
            </Tooltip>
          </div>

          {this.state.passwordEgalTooltipOpen ? (
            <div className={"hint-help-container"}>
              <PasswordEgal passwordEgal={password === passwordVerification} />
            </div>
          ) : null}
          <Button
            variant="contained"
            className="submitButton"
            disabled={isLoading || disabled}
            type="submit"
          >
            {I18n.t("accountMigration.btnValidate")}
          </Button>
        </form>
      </div>
    );
  }
}

Migration.propTypes = {};
Migration.defaultProps = {
  router: PropTypes.object.isRequired
};

function validateInput(data) {
  let errors = {};

  if (Validator.isEmpty(data.password)) {
    errors.password = I18n.t(
      "accountMigration.migration.errorPasswordRequired"
    );
  }

  if (Validator.isEmpty(data.passwordVerification)) {
    errors.passwordVerification = I18n.t(
      "accountMigration.migration.errorPasswordRequired"
    );
  }

  if (
    !(
      data.password.length >= 8 &&
      hasLowerCase(data.password) &&
      hasUpperCase(data.password) &&
      hasNumeric(data.password) &&
      hasSpecialChar(data.password)
    )
  ) {
    errors.password = I18n.t("accountMigration.migration.errorPassword");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

function hasSpecialChar(str) {
  return /^[a-zA-Z0-9]*$/.test(str) === false;
}

function hasNumeric(str) {
  return str.match(/\d+/g) !== null;
}

function hasLowerCase(str) {
  return str.toUpperCase() !== str;
}

function hasUpperCase(str) {
  return str.toLowerCase() !== str;
}

Migration.contextTypes = {
  router: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(
  mapStateToProps,
  { addFlashMessage }
)(Migration);
