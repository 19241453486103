import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { Tabs, Tab } from "material-ui/Tabs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import config from "../../../utils/config";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";

import TableCommandesHistorique from "../components/Tables/TableCommandesHistorique";
import TableReceipts from "../components/Tables/TableReceipts";
import TableCommandesLines from "../components/Tables/TableCommandesLines";
import TablePaymentLines from "../components/Tables/TablePaymentLines";
import TableControlStrip from "../components/Tables/TableControlStrip";
import * as fiscApiActions from "../actions/fiscApiActions";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";
import Print from "material-ui/svg-icons/action/print";

import { browserHistory } from "react-router";
import Modal from "react-responsive-modal";
import axios from "axios";
import DetailedInformations from "../components/DetailedInformations";

class DetailsCommande extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataOrderLines: [],
      dataPayments: [],
      dataReceipt: [],
      dataCommandesHistorique: {},
      open: false,
      isLoading: false,
      dbName: "",
      pdfUrl: "",
      timestamp: "",
      signature: "",
      tab: 1
    };
    this.navigate = this.navigate.bind(this);
  }

  // === MODAL ===
  getPdfData = async () => {
    const res = await axios.get(
      config.reportingUrl + "api/administration-fiscale/pdf-data",
      {
        headers: { Authorization: `Bearer ${window.localStorage.accessToken}` }
      }
    );
    await this.setState({
      dbName: res.data.dbName,
      timestamp: res.data.timestamp,
      signature: res.data.signature
    });
  };

  onOpenModal = () => {
    if (this.state.pdfUrl !== "") {
      this.setState({ open: true, isLoading: false });
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  renderRowInfo() {
    let rowInfo = this.props.location.state.rowInfo.row._original;
    let columns = this.props.location.state.columns;
    return Object.keys(columns).map(elem => {
      //si objet imbriqué provoque une erreur
      if (typeof rowInfo[elem] !== "object") {
        return (
          <div className="rowInfo" key={elem}>
            <span className="keyRowInfo">{columns[elem].Header} :</span>{" "}
            {rowInfo[columns[elem].accessor]}
          </div>
        );
      }
    });
  }

  navigate(entity, rowInfo, columns) {
    //purge des donnees trop importantes sinon dépassement de la limite et bug
    //TODO: utilisation de react-router-redux
    let sessionStorage = window.sessionStorage;
    sessionStorage.setItem("commandeTab", this.state.tab);
    if (entity === "back") {
      browserHistory.goBack();
      return;
    } else if (entity === "home") {
      browserHistory.push("/administration-fiscale");
    } else {
      Object.keys(window.sessionStorage).map(elem => {
        if (elem.startsWith("@@History")) {
          let row = window.sessionStorage.getItem(elem);
          row = JSON.parse(row);
          delete row.fullHistory;
          row = JSON.stringify(row);
          window.sessionStorage.removeItem(elem);
          window.sessionStorage.setItem(elem, row);
        }
      });
      browserHistory.push({
        pathname: `/administration-fiscale/${entity}/details/${
          rowInfo.original.id
        }`,
        state: {
          rowInfo,
          columns,
          cashRegisterId: this.props.location.state.cashRegisterId
        }
      });
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    let sessionStorage = window.sessionStorage;
    let lastTab = parseInt(sessionStorage.getItem("commandeTab"));
    if (lastTab) {
      this.setState({ tab: lastTab });
    }
    await Promise.all([
      this.props.actions.fetchTrackingOrdersLines(
        this.props.location.state.rowInfo.row._original.id
      ),
      this.props.actions.fetchTrackingPaymentLines(
        this.props.location.state.rowInfo.row._original.id
      ),
      this.props.actions.fetchTrackingReceipt(
        this.props.location.state.rowInfo.row._original.id
      ),

      this.props.actions.resetHistory(this.props.entities).then(() => {
        this.props.actions.fetchTrackingHistory(
          "order_",
          this.props.location.state.cashRegisterId,
          this.props.location.state.rowInfo.original.id
        );
      })
    ]);

    await this.getPdfData();
    this.setState({ isLoading: false });
    this.props.actions.fetchControlStripSalesDocument(
      this.props.location.state.rowInfo.original.id
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dataControlStrip: nextProps.entities.controlStripSalesDocument
    });
    this.setState({ dataOrderLines: nextProps.entities.ordersLines });
    this.setState({ dataPayments: nextProps.entities.paymentLines });
    this.setState({ dataReceipt: nextProps.entities.receipts });
    this.setState({ dataCommandesHistorique: nextProps.entities.history });
  }

  pdfReceipt = async id => {
    this.setState({ isLoading: true });
    await this.getPdfData();
    await this.setState({
      pdfUrl:
        config.dataUrl +
        "scripts/reporting.php?db=" +
        this.state.dbName +
        "&receipt_id=" +
        id +
        "&action=receipt&format=pdf&ts=" +
        this.state.timestamp +
        "&signature=" +
        this.state.signature
    });
    this.onOpenModal();
  };

  render() {
    let rowInfo = this.props.location.state.rowInfo;
    let rowInfoData = this.props.location.state.rowInfo.row._original;
    let columns = this.props.location.state.columns;

    let open = this.state.open;
    const { isLoading } = this.state;
    const { pdfUrl } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={() => this.navigate("back")}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={() => this.navigate("home")}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>
          <div className="fiscContainer">
            <div className="tabsContainer">
              <Tabs
                value={this.state.tab}
                onChange={value => this.setState({ tab: value })}
                inkBarStyle={{ backgroundColor: "#ab2221", height: "3px" }}
              >
                <Tab
                  value={1}
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  label="Historique"
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de commande</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/commande/
                        </span>
                        historique
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <Modal
                            open={open}
                            showCloseIcon={false}
                            onClose={this.onCloseModal}
                            classNames={{
                              modal: "modal-fisc-json-data"
                            }}
                            styles={{ maxHeight: "100%", height: "100%" }}
                          >
                            <iframe
                              src={pdfUrl}
                              style={{ width: "750px", minHeight: "90vh" }}
                              type="application/pdf"
                            />
                          </Modal>
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&order_id=" +
                                  rowInfo.original.id +
                                  "&action=ticket&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression du ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableCommandesHistorique
                      data={this.state.dataCommandesHistorique}
                    />
                  </div>
                </Tab>
                <Tab
                  value={2}
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  label="Bande de contrôle"
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de commande</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/commande/
                        </span>
                        bande de contrôle
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&order_id=" +
                                  rowInfo.original.id +
                                  "&action=ticket&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression du ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableControlStrip data={this.state.dataControlStrip} />
                  </div>
                </Tab>
                <Tab
                  value={3}
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  label="Lignes de commande"
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de commande</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/commande/
                        </span>
                        lignes
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&order_id=" +
                                  rowInfo.original.id +
                                  "&action=ticket&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression du ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableCommandesLines
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("orderlines", rowInfo, columns)
                      }
                      data={this.state.dataOrderLines}
                    />
                  </div>
                </Tab>
                <Tab
                  value={4}
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"tva"}
                  label="Paiements"
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de commande</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/commande/
                        </span>
                        paiements
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&order_id=" +
                                  rowInfo.original.id +
                                  "&action=ticket&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression du ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TablePaymentLines
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("paymentlines", rowInfo, columns)
                      }
                      data={this.state.dataPayments}
                    />
                  </div>
                </Tab>
                <Tab
                  value={5}
                  style={{ backgroundColor: "#f3f3f3", color: "black" }}
                  key={"payments"}
                  label="Impressions"
                >
                  <div className="fiscHeader">
                    <div className="fiscHeaderLeft">
                      <h1 className="titlePage">Détails de commande</h1>
                      <h3 className="fiscPath">
                        {" "}
                        <span style={{ color: "#9A9A9A" }}>
                          cloture/service/commande/
                        </span>
                        impressions
                      </h3>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "5%",
                        width: "55%"
                      }}
                    >
                      <h3 className="rowInfoTitle">Informations détaillées</h3>
                      <div className="gridRowInfo">
                        <DetailedInformations
                          rowInfo={rowInfoData}
                          columns={columns}
                        />
                        <div
                          className="rowInfo"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <button
                            className="btn btn-fisc btn-impression"
                            onClick={async (e, t) => {
                              this.setState({ isLoading: true });
                              await this.getPdfData();
                              await this.setState({
                                pdfUrl:
                                  config.dataUrl +
                                  "scripts/reporting.php?db=" +
                                  this.state.dbName +
                                  "&order_id=" +
                                  rowInfo.original.id +
                                  "&action=ticket&format=pdf&ts=" +
                                  this.state.timestamp +
                                  "&signature=" +
                                  this.state.signature
                              });
                              this.onOpenModal();
                            }}
                          >
                            <Print
                              style={{
                                lineHeight: "20px",
                                verticalAlign: "middle"
                              }}
                            />
                            Voir l'impression du ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerTable">
                    <TableReceipts
                      goToDetails={(rowInfo, columns) =>
                        this.navigate("receipt", rowInfo, columns)
                      }
                      data={this.state.dataReceipt}
                      pdfReceipt={id => this.pdfReceipt(id)}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div
              id="fisc-loader-overlay"
              className={classnames({ hidden: !isLoading })}
            >
              <div class={classnames("pulse", { hidden: !isLoading })}>
                <img
                  alt="loader L'addition"
                  src={logoLoader}
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsCommande);
