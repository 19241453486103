import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "./Loader";
import forEach from "lodash/forEach";
import { browserHistory } from "react-router";

class Arcplan2 extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      canShowReport: false,
      componentToshow: ""
    };

    this.isInAvailableCategories = this.isInAvailableCategories.bind(this);
  }

  componentWillMount() {
    if (this.props.isAuthenticated) {
      const scriptIscroll = document.createElement("script");

      scriptIscroll.src = "../build/client/js/iscroll.js";
      scriptIscroll.id = "launchIscroll";

      document.body.appendChild(scriptIscroll);

      const scriptVersion = document.createElement("script");
      scriptVersion.src =
        "../build/client/js/version_cb.js?b=" + new Date().getTime();
      scriptVersion.id = "launchVersion";
      document.body.appendChild(scriptVersion);
    }
  }

  isInAvailableCategories(id = 0) {
    let categoryId = id;
    // eslint-disable-next-line
    if (categoryId === 0) {
      categoryId = this.props.params.reportName;
    }

    let canShowReport = false;
    if (this.props.categories) {
      forEach(this.props.categories, category => {
        // eslint-disable-next-line
        if (category.id == categoryId) {
          this.setState({
            canShowReport: true,
            componentToshow: category.link
          });
          canShowReport = true;
        }
        if (!canShowReport && category.childrens.length > 0) {
          forEach(category.childrens, child => {
            // eslint-disable-next-line
            if (child.id == categoryId) {
              this.setState({
                canShowReport: true,
                componentToshow: child.link
              });
              canShowReport = true;
            }
          });
        }
      });
    }
    return canShowReport;
  }

  componentDidMount() {
    document.getElementsByClassName("ronds")[0].className = "ronds hidden";
    if (this.props.isAuthenticated && this.isInAvailableCategories()) {
      const script = document.createElement("script");

      script.src =
        "../build/client/js/launchArcplanBo.js?b=" + new Date().getTime();
      script.crossorigin = "anonymous";
      script.id = "launchArcplanBo";

      document.body.appendChild(script);
    } else {
      document.getElementById("arcBody").classList.add("arcplanLoaderRemoved");
      console.log("404", 1);
      browserHistory.push("/404");
    }
  }

  componentWillReceiveProps(nextProps) {
    document.getElementsByClassName("ronds")[0].className = "ronds";
    if (this.props.params.reportName !== nextProps.params.reportName) {
      if (this.isInAvailableCategories(nextProps.params.reportName)) {
        window.ARCPLAN.arcclient.closeSession();
        window.setTimeout(() => {
          document
            .getElementById("arcBody")
            .classList.remove("arcplanLoaderRemoved");
          const scriptUndefRequire = document.createElement("script");
          scriptUndefRequire.src = "../build/client/js/requireUndef.js";
          scriptUndefRequire.id = "requireUndef";
          document.body.appendChild(scriptUndefRequire);
          document.getElementById("launchArcplanBo").remove();
          const script = document.createElement("script");

          script.src =
            "../build/client/js/launchArcplanBo.js?b=" + new Date().getTime();
          script.crossorigin = "anonymous";
          script.id = "launchArcplanBo";

          document.body.appendChild(script);
          document.getElementById("requireUndef").remove();
        }, 800);
      } else {
        document
          .getElementById("arcBody")
          .classList.add("arcplanLoaderRemoved");
        console.log("404", 2);
        browserHistory.push("/404");
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isAuthenticated && window.ARCPLAN) {
      window.ARCPLAN.arcclient.closeSession();
      window.setTimeout(() => {
        document
          .getElementById("arcBody")
          .classList.remove("arcplanLoaderRemoved");
        const scriptUndefRequire = document.createElement("script");
        scriptUndefRequire.src = "../build/client/js/requireUndef.js";
        scriptUndefRequire.id = "requireUndef";
        document.body.appendChild(scriptUndefRequire);
        document.getElementById("launchIscroll").remove();
        document.getElementById("launchVersion").remove();
        if (document.getElementById("launchArcplanBo")) {
          document.getElementById("launchArcplanBo").remove();
        }
        document.getElementById("requireUndef").remove();
      }, 800);
    }
  }

  render() {
    let myAttr = {
      "data-reporting-name": this.state.componentToshow,
      "data-session-id": this.props.user.sessionId,
      "data-user-id": this.props.user.email
    };

    return this.props.isAuthenticated ? (
      <div
        id="arcPlanNode"
        style={{
          position: "relative",
          margin: "-60px -64px -64px -64px"
        }}
      >
        <Loader show={this.state.isLoading} />
        <div id="arcContainer" className="arcContainer" {...myAttr}>
          {}
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    categories: state.entities.categories,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Arcplan2);
