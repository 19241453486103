import React, { Component } from "react";
import { MuiThemeProvider } from "material-ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../css/fisc.css";
import classnames from "classnames";
import logoLoader from "../img/logo-loader.png";

import TableErrors from "../components/Tables/TableErrors";

import * as fiscApiActions from "../actions/fiscApiActions";
import Popover from "material-ui/Popover";
import { browserHistory } from "react-router";
import IconButton from "material-ui/IconButton";
import ArrowBack from "material-ui/svg-icons/navigation/arrow-back";
import Home from "material-ui/svg-icons/action/home";

class DetailsError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataCreditHistorique: {},
      isLoading: false,
      open: false,
      comment: ""
    };
  }

  goBack() {
    browserHistory.goBack();
  }

  goHome() {
    browserHistory.push("/administration-fiscale");
  }

  openPopover = (event, rowInfo) => {
    // This prevents ghost click.
    event.preventDefault();
    this.setState({
      open: true,
      comment: rowInfo.original.comment,
      anchorEl:
        event.currentTarget.childNodes[
          event.currentTarget.childNodes.length - 1
        ]
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    let { error } = this.props.location.state;
    const { isLoading } = this.state;
    return (
      <MuiThemeProvider>
        <div>
          <div
            className="header-container"
            style={{ marginTop: "0px", color: "white", paddingLeft: "50px" }}
          >
            <div style={{ textAlign: "left" }}>
              <IconButton
                onClick={this.goBack}
                iconStyle={{ color: "white" }}
                tooltip="Retour arrière"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={this.goHome}
                iconStyle={{ color: "white" }}
                tooltip="Accueil"
              >
                <Home />
              </IconButton>
            </div>
          </div>
          <div className="fiscContainer">
            <div>
              <h1
                style={{
                  backgroundColor: "white",
                  marginTop: "0px",
                  paddingTop: "40px",
                  marginLeft: "5%",
                  color: "#9A9A9A",
                  textTransform: "uppercase",
                  fontSize: "40px"
                }}
              >
                Détails des erreurs
              </h1>
            </div>
            <Popover
              open={this.state.open}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              targetOrigin={{ horizontal: "left", vertical: "top" }}
              onRequestClose={this.handleRequestClose}
              style={{ borderRadius: "15px" }}
            >
              <div
                style={{
                  minHeight: "30px",
                  padding: "20px",
                  color: "black",
                  backgroundColor: "white"
                }}
              >
                <span style={{ fontStyle: "italic" }}>Commentaire</span> :{" "}
                {this.state.comment}
              </div>
            </Popover>
            <div className="tabsContainer">
              <div className="containerTable">
                <TableErrors openPopover={this.openPopover} data={error} />
              </div>
            </div>
            <div
              id="fisc-loader-overlay"
              className={classnames({ hidden: !isLoading })}
            >
              <div class={classnames("pulse", { hidden: !isLoading })}>
                <img
                  alt="loader L'addition"
                  src={logoLoader}
                  width="150px"
                  height="150px"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(fiscApiActions, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    user: state.auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsError);
